import React, { useEffect, useState } from 'react';
import EditQuestionForm from './Components/EditQuestionForm';
import { Paper, Tab, Tabs } from "@material-ui/core";
// import { TemplateList } from './Components/TemplateList';
import QuestionsLibrary from './Components/QuestionsLibrary';
import util from '../utils/miniUtils';

const CreateScreeningQuestions = ( { job, sqSave, cancel } ) => {
    const [questionsList, setQuestionsList] = useState( [] );
    // const [templatesList, setTemplatesList] = useState( [] );
    const [tabValue, setTabValue] = useState( "questions" );
    // function for manipulating tabs 
    function handleTabChange( event, newValue ) {
        setTabValue( newValue );
    }
    //Custom tabpanel to show the selected tab value
    function TabPanel( { children, selectedTab, tabValue } ) {
        if( selectedTab !== tabValue ) {
            return "";
        }
        return children;
    }

    async function getQuestionsList(){
        try {
            const request = await util.axios.get( '/get_all_questions/?search=' );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setQuestionsList( data );
        } catch ( error ) {
            util.handleError( error );
        }
    }

    // async function getTemplatesList(){
    //     try {
    //         const request = await util.axios.get( '/get_templates_list' );
    //         const { error, msg, data } = request.data;
    //         if( error ) throw msg;
    //         setTemplatesList( data );
    //     } catch ( error ) {
    //         util.handleError( error );
    //     }
    // }

    useEffect( () => {
        getQuestionsList();
        // getTemplatesList();
    },[] );
    
    return (
        <div className="container ejContainer">
            <h3>Screening Questions -- <span>{job ? job.job_title : ''}</span></h3>
            <div className="row">
                <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12">
                    <EditQuestionForm save={sqSave} cancel={cancel} />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <Tabs
                        className="mb-2"
                        value={tabValue}
                        onChange={handleTabChange}
                        variant="fullWidth"
                        indicatorColor="primary"
                        textColor="primary">
                        <Tab value="questions" label="Questions Library" />
                        {/* <Tab value="templates" label="Templates" /> */}
                    </Tabs>
                    <TabPanel selectedTab={tabValue} tabValue="questions">
                        <Paper className="p-2">
                            <QuestionsLibrary list={questionsList}/>
                        </Paper>
                    </TabPanel>
                    {/* <TabPanel selectedTab={tabValue} tabValue="templates">
                        <Paper className="p-2">
                            <TemplateList list={templatesList} />
                        </Paper>
                    </TabPanel> */}
                </div>
            </div>
        </div>
    );
};

export default CreateScreeningQuestions;