import React, { Fragment, useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import util from "../../../utils/miniUtils";
import selector from "../../../utils/selector";
import { Editor } from "@tinymce/tinymce-react";
import "../../../../assets/css/main.css";
import SAlert from "../../../microComponents/AlertSA";
import { Facebook, Twitter, LinkedIn, LinkRounded } from "@material-ui/icons";
import ModalSelectPartnerClient from "./ModalSelectPartnerClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faSave, faCopy } from "@fortawesome/free-solid-svg-icons";
import { Chip, Paper, TextField, Tooltip } from "@material-ui/core";
import LocationCX from "../../../shared/LocationCX";
import SkillSelector from "../../../shared/selector/SkillSelector";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ModalScreeningQuestions from "./ModalScreeningQuestions";
const classLabelsMUI = "MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled";

function JobPositionForm( props ) {
    const [job, setJobData] = useState( props.job );
    const [backUpJob, setBackUp] = useState( job );
    const [isDisabled, setIsDisabled] = useState( props.job.status === 3 );
    const [marginShow, setMarginShow] = useState( false );
    const [modalVisible, setModalVisible] = useState( false );
    const [modalType, setModalType] = useState( false );
    const [marginLower, setMarginLower] = useState( false );
    const [isVisible, setIsVisible] = useState( true );
    const [recruiters, setRecruiters] = useState( [] );
    const [catalogJobDesc, setCatalogJobDesc] = useState( [] );
    const [disabledByOnboarding, setDisabledByOnboarding] = useState( false );
    const [skillsList, setSkillsList] = useState( [] );
    const [open, setOpen] = useState( false );
    const [showScreeningQuestions, setShowScreeningQuestions] = useState( false );
    // const [skills,setSkills] = useState( props.skills );
    const PARTNER = 4;
    const CLIENT = 3;


  
    const [showFullDescription, setShowFullDescription] = useState( false );
      
    const toggleDescription = () => {
        setShowFullDescription( !showFullDescription );
    };

    useEffect( () => {
    // Listen when update is pressed from accordion
        if ( props.updateChanges !== null ){
            handleUpdateJobPosition();
        }
        // eslint-disable-next-line
    },[props.updateChanges] );
  
    useEffect( () => {
    // Listen when cancel is pressed from accordion
        if ( props.cancelChanges !== null ){
            setJobData( backUpJob );
        }
        // eslint-disable-next-line
    },[props.cancelChanges] );
    useEffect( ()=>{
        setJobData( props.job );
    },[props.job] );
    useEffect( () => {
    // After the job is loaded, we need to update the UI of the deal type and hide partner if is direct client
    // This occurs only one time after load the job information
        if ( job.deal_type === "Direct Client" ){
            setIsVisible( false );
        }else{
            setIsVisible( true );
        }
    },[job] );

    useEffect( () => {
        getRecruiters().then( null );
        getCatalog().then( null );
    }, [] );
    
    useEffect( () => {
        let disable = false;
        props.talents.forEach( d => {
            if ( parseInt( d.status ) === 13 ){
                disable = true;
            }
        } );
        setDisabledByOnboarding( disable );
    }, [props.talents] );

    useEffect( () => {
    // Refresh al fields with the data of the selected job from the sidebar
        setIsDisabled( props.job.status === 3  );
        return () => { };
    }, [props.job.status] );

    async function getRecruiters() {
        try {
            const request = await util.axios.get( `${util.url}/app_resources/users_by_role/1` );
            const { error, msg, users } = request.data;
            if ( error ) throw msg;
            setRecruiters( users );
        } catch ( error ) {
            util.handleError( error );
        }
    }

   
  
    // const getListofSkills = async ( search ) => {
    //     try {
    //         search.replace( /\//g, "" );
    //         const encodedSearch = encodeURIComponent( search );
    //         const response = await util.axios.get( `catalog/catalog_skills_search/${encodedSearch}` );
    //         const { error, msg, data } = response.data;
    //         if ( error ) return util.handleError( msg );
    //         setSkillsList( data );
    //     } catch ( e ) {
    //         util.handleError( e );
    //     }
    // };
    const getCatalog = async () => {
    // Make a request for catalog of job descriptions
        try {
            const response = await util.axios.get( `${util.url}/app_resources/job_descriptions` );
            const { error, msg, job_descriptions } = response["data"];
            if ( error ) return util.handleError( msg );
            setCatalogJobDesc( job_descriptions );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // function handleChange( e ) {
    //     let { name, value } = e.target;
    //     if ( name === "deal_type" ){
    //         if ( value === "Direct Client" ){
    //             setIsVisible( false );
    //         }else{
    //             setIsVisible( true );
    //         }
    //     }
    //     setJobData( { ...job, [name]: value } );
    // }

    // function handleRichTextChange( e ) {
    //     const value = e.toString( "html" );
    //     setJobData( { ...job, 'job_description': value } );
    // }

    function handleUpdateJobPosition() {
        if ( marginLower && job.margin < 20 ){
            util.toast().warn( "You're using a lower margin tan 20%" );
            return;
        }
        util
            .axios
            .patch( `${util.url}/client_requirements/update/${job.id}`, job )
            .then( response => {
                const { error, msg, job } = response.data;
                if ( error ) throw ( msg );
                props.handleChange( job );
                util.toast().success( msg );
                setBackUp( job );
            } )
            .catch( err => util.toast().error( err ) );
    }

    function onConfirmLowerMargin() {
        setMarginLower( true );
    }

    // function selectAction( modalOption ){
    //     setModalType( modalOption );
    //     setModalVisible( true );
    // }
  
    function hideModal(){
        setModalType( PARTNER );
        setModalVisible( false );
    }

    // Copy link to clipboard
    const copyToClipboard = () => {
        const url = window.document.location.origin + `/careers?swe=${util.uuidv4()}&job=${job.id}&fs=true`;
        navigator.clipboard.writeText( url ).then( null );
        setOpen( true );
    };
    function selectedData ( opc, data ) {
        let jobTemp  = job;
        if ( opc === PARTNER ) {
            jobTemp.partner =  data;
        } else {
            jobTemp.client =  data;
        }
        setJobData( Object.assign( {}, jobTemp ) );
    }
  
    // function handleChangeAutoComplete( value ) {
    // // let e ={target:{name: "job_title", value: value}}
    // // handleChange(e);
    // // must trigger job description
    //     const j = catalogJobDesc.filter( ( j ) => j.title === value )[0];
    //     // let e2 ={target:{name: "job_description", value: j.description}}
    //     // handleChange(e2);
    //     setJobData( { ...job, job_title: value, job_description: j.description } );
    // }

    // function handleSkillUpdate( name,value ) {
    //     // setJobData( { ...job, skills: [value] } );
    //     if( name === "skills" ) {
    //         setJobData( ( prevJob ) => ( { ...prevJob, skills: [...prevJob.skills, value] } ) );
    //     }
    //     else {
    //         setJobData( ( prev ) => ( { ...prev, skills: [...value] } ) );
    //     }
    // }
    const RowItem = ( { label, value, person=false } ) => (
        <div className="row mb-2">
            <span className="col-xl-4 col-lg-7 col-sm-5 Jobclient"><span>{label}:</span></span>
            {person ? <span className="col-xl-5 col-lg-5 col-sm-5 Jobclient">{recruiters?.find( el => el.id == value )?.name}</span> : <span className="col-xl-5 col-lg-5 col-sm-5 Jobclient">{value}</span>}
        </div>
    );
    return (
        <Fragment className="container">
            {/* <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-9">
                            <Autocomplete
                                freeSolo
                                value={job.job_title}
                                disableClearable
                                disabled={isDisabled}
                                onChange={( e, value ) => handleChangeAutoComplete( value )}
                                options={catalogJobDesc.map( ( option ) => option.title )}
                                renderInput={( params ) => (
                                    <TextField
                                        {...params}
                                        label="Job Title *"
                                        margin="normal"
                                        name="job_title"
                                        onChange={handleChange}
                                        variant="standard"
                                        InputProps={{ ...params.InputProps, type: "search" }}
                                    />
                                )}
                            />
                        </div>
                        <util.RowComponent
                            disabled={isDisabled}
                            c={3}
                            t={"Certification"}
                            n={"certification"}
                            m={handleChange}
                            v={job.certification||""}
                        />

                    </div>
            
                    <div className="row">
                        
                        <div className="col-md-8">
                            <SkillSelector
                                getSkillList={getListofSkills}
                                list={skillsList}
                                updateFilter={handleSkillUpdate}
                                filteredSkills={job.skills}
                                setSkillsList={setSkillsList}
                            />
                        </div>
                        <util.RowComponent
                            disabled={isDisabled}
                            c={4}
                            t={"Role"}
                            n={"role"}
                            m={handleChange}
                            v={job.role || "Technician"}
                            type={1}
                            d={selector.role()}
                        />
                        <util.RowComponent
                            disabled={isDisabled}
                            c={4}
                            t={"Scope"}
                            n={"scope"}
                            m={handleChange}
                            v={job.scope || "Project Support"}
                            type={1}
                            d={selector.scope()}
                        />
                        <util.RowComponent
                            disabled={isDisabled}
                            c={4}
                            t={"Level"}
                            n={"level"}
                            m={handleChange}
                            v={job.level || "Experienced (L2)"}
                            type={1}
                            d={selector.level()}
                        />
                        <util.RowComponent
                            disabled={isDisabled}
                            c={4}
                            t={"Education"}
                            n={"education"}
                            m={handleChange}
                            v={job.education || "University"}
                            type={1}
                            d={selector.education()}
                        />
              
  
                    </div>              
                    <div className="paddingleft0 col-md-12 mt-2">
                        <label className="text-muted textSmallLH">Job Description *</label>
                        <Editor
                            apiKey='co5tvvfh4ltli0l9urtqbvfbrevuswtkqdmu3lvf0t3lme9p'
                            value={job.job_description || ""}
                            disabled={isDisabled}
                            init={{
                                height: 500,
                                menubar: false,
                                toolbar:
                        "formatselect | bold italic| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat"
                            }}
                            onEditorChange={handleRichTextChange}
                        />
                    </div>
                </div>

                <div className="col-md-6">
                    <b style={{ marginLeft:"-2px" }}>Basic Information</b>
                    <div className="row">
                        <div className={`col-md-3 p-1 mt-2`}>
                            <TextField
                                select
                                size={"small"}
                                disabled={isDisabled}
                                name="deal_type"
                                className="w-100"
                                label="Deal Type *"
                                value={job.deal_type || "Channel Partner"}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    native: true
                                }}
                    
                            >
                                {selector.dealType().map( option => (
                                    <option key={Math.random() * Math.random() } value={option["deal_type"]}>
                                        {option["deal_type"]}
                                    </option>
                                ) )}
                            </TextField>
                        </div>
                        {isVisible ?
                            <div className={`col-md-3 col-xs-12 p-1 mt-2`}>
                                <div className={classLabelsMUI}>Partner Information</div>
                                {job.partner !== null ?
                                    <util.BoxSimple data={job.partner} editInfo={()=>selectAction( PARTNER )} disabled={isDisabled} />
                                    :<button disabled={isDisabled} className="btn btn-default"  onClick={()=> selectAction( PARTNER )}>Select partner <FontAwesomeIcon icon={faPencilAlt}/></button>}

                            </div>:""}

                        <div className={`col-md-3 col-xs-12 p-1  mt-2`}>
                            <div className={classLabelsMUI}>End Client</div>
                            {job.client !== null ? <util.BoxSimple data={job.client} editInfo={()=>selectAction( CLIENT )}  disabled={isDisabled} />: ""}
                        </div>
                        <div className={`col-md-3 col-xs-12 p-1  mt-2`}>
                            <div className={classLabelsMUI}>Client Reference ID</div>
                            <util.RowComponent
                                disabled={isDisabled}
                                n={"client_requirement_id"}
                                m={handleChange}
                                v={job.client_requirement_id || ""}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <util.RowComponent
                            disabled={isDisabled}
                            c={3}
                            t={"Employment Type"}
                            n={"employment_type"}
                            m={handleChange}
                            v={job.employment_type || "Any"}
                            type={1}
                            d={selector.employment_type()}
                        />
                        <util.RowComponent
                            disabled={isDisabled}
                            c={3}
                            t={"Restricted To"}
                            n={"restricted_to"}
                            m={handleChange}
                            v={job.restricted_to}
                            type={1}
                            d={selector.restricted_to()}
                        />

                        <util.RowComponent
                            disabled={isDisabled}
                            c={3}
                            t={"Duration in months *"}
                            n={"duration"}
                            m={handleChange}
                            v={job.duration}
                        />

                        <util.RowComponent
                            disabled={isDisabled}
                            c={3}
                            t={"Priority"}
                            n={"priority"}
                            m={handleChange}
                            v={job.priority || "Mid"}
                            type={1}
                            d={selector.priority()}
                        />

                    </div>
                    <b>Compensation Range</b>
                    <div className="form-row">
                        
                        <div className={`col-md-3 p-1 mt-2 align-self-center`}>
                            <TextField
                                size={"small"}
                                className="w-100"
                                disabled={isDisabled}
                                InputLabelProps={{ shrink: true }}
                                value={ job.buy_from || "" }
                                type="number"
                                label="Minimum"
                                fullWidth={true}
                                InputProps={{
                                    startAdornment: util.symbolDependingCurrencySelected( job.country )
                                }}
                                variant="standard"
                                name="buy_from"
                                onChange={handleChange}
                            />
                        </div>
                        <div className={`col-md-3 p-1 mt-2 align-self-center`}>
                            <TextField
                                size={"small"}
                                className="w-100"
                                disabled={isDisabled}
                                InputLabelProps={{ shrink: true }}
                                value={ job.buy_to || "" }
                                type="number"
                                label="Maximum"
                                fullWidth={true}
                                InputProps={{
                                    startAdornment: util.symbolDependingCurrencySelected( job.country )
                                }}
                                variant="standard"
                                name="buy_to"
                                onChange={handleChange}
                            />
                        </div>
                        <div className={`col-md-3 p-1 mt-2 align-self-center`}>
                            <TextField
                                size={"small"}
                                className="w-100"
                                disabled={isDisabled}
                                InputLabelProps={{ shrink: true }}
                                value={ job.sell_rate || "" }
                                type="number"
                                label="Job Sell Rate"
                                fullWidth={true}
                                InputProps={{
                                    startAdornment: util.symbolDependingCurrencySelected( job.country )
                                }}
                                variant="standard"
                                name="sell_rate"
                                onChange={handleChange}
                            />
                        </div>

                        <util.RowComponent
                            disabled={isDisabled}
                            c={3}
                            t={"Offer Type"}
                            n={"sell_rate_type"}
                            m={handleChange}
                            v={job.sell_rate_type || "Per Hour"}
                            type={1}
                            d={selector.sell_rate_type3}
                        />
                    </div>

                    <b>Location</b>
                    <div className="form-row">
  
                        <LocationCX
                            cols={4}
                            data={job} // contains everything
                            onChange={handleChange}
                            requiredData={{
                                state: false,
                                country: false,
                                city: true,
                            }}
                        />
              
                        <util.RowComponent
                            disabled={isDisabled}
                            c={3}
                            t={"Zip Code"}
                            n={"zipCode"}
                            m={handleChange}
                            v={job.zipCode || ""}
                        />
                    </div>

            

                    <b>Onboarding Contacts</b>
                    <div className="form-row">
                        <util.RowComponent
                            disabled={isDisabled}
                            c={4}
                            t={"Reporting Manager"}
                            n={"reporting_manager"}
                            m={handleChange}
                            v={job.reporting_manager || ""}
                        />
                        <util.RowComponent
                            disabled={isDisabled}
                            c={4}
                            t={"Reporting Manager Phone"}
                            n={"reporting_manager_phone"}
                            m={handleChange}
                            v={job.reporting_manager_phone||""}
                        />
                        <util.RowComponent
                            disabled={isDisabled}
                            c={4}
                            t={"Reporting Manager Address"}
                            n={"reporting_manager_address"}
                            m={handleChange}
                            v={job.reporting_manager_address||""}
                        />

                        <div className={`col-md-4 p-1 mt-2`}>
                            <TextField
                                select
                                disabled={disabledByOnboarding}
                                name="sales_lead"
                                className="w-100"
                                label="Sales Lead *"
                                value={job.sales_lead || "None"}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ native: true }}
                            >
                                <option value=""> -- </option>
                                {recruiters.map( ( option, index ) => (
                                    <option key={ index } value={option.id}>
                                        {option.name}
                                    </option>
                                ) )}
                            </TextField>
                        </div>

                        <div className={`col-md-4 p-1 mt-2`}>
                            <TextField
                                select
                                disabled={disabledByOnboarding}
                                name="lead_recruiter"
                                className="w-100"
                                label="Lead Recruiter *"
                                value={job.lead_recruiter || "None"}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ native: true }}
                            >
                                <option value=""> -- </option>
                                {recruiters.map( ( option, index ) => (
                                    <option key={ index } value={option.id}>
                                        {option.name}
                                    </option>
                                ) )}
                            </TextField>
                        </div>

                        <div className={`col-md-4 p-1 mt-2`}>
                            <TextField
                                select
                                name="secondary_recruiter"
                                className="w-100"
                                label="Secondary Recruiter"
                                value={job.secondary_recruiter || "None"}
                                onChange={handleChange}
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ native: true }}
                            >
                                <option value=""> -- </option>
                                {recruiters.map( ( option, index ) => (
                                    <option key={ index } value={option.id}>
                                        {option.name}
                                    </option>
                                ) )}
                            </TextField>
                        </div>
                        {job.questions && <div className='col-md-12 p-1 mt-2'>
                            <span className="btn btn-sm btn-light" onClick={() => setShowScreeningQuestions( true )}>Screening Questions</span>
                        </div>}
                        
                    </div>
                    {
                        isDisabled ?
                            ""
                            :
                            <Fragment>
                                <button
                                    onClick={handleUpdateJobPosition}
                                    className="float-right btn btn-primary btn-sm"
                                >
                                    <FontAwesomeIcon icon={faSave} className="mr-2" />
                                    Save
                                </button>

                                <button
                                    onClick={()=> {
                                        setJobData( backUpJob );
                                    }}
                                    className="float-right btn text-danger mr-2"
                                >
                                    Cancel
                                </button>
                            </Fragment>

                    }
                </div>
            </div> */}
            <div elevation={3} className="bg-white p-4">
 
                <div className='row mt-2'>
                    <ul className="col-md-3">
                        {job.client_requirement_id ? <li style={{ listStyle:'none' }}>
                            <span className="text-danger">Client Reference:</span>
                            <span className="ml-2">{job.client_requirement_id}</span>
                        </li> : ""}
                        <li style={{ listStyle:'none',marginTop:'5px' }}><Tooltip title="Copy Job Link">

                            <span className="likeLink" onClick={copyToClipboard}>
                                <LinkRounded size={32} icon={faCopy} /><span className="pl-1">Link</span>
                            </span>
                        </Tooltip>
                        </li>
                        <li style={{ listStyle:'none',marginTop:'5px' }}><Tooltip title="Job Role">
                            <div className="Jobclient"><span><img className='jobReadOnlyImg' src="/images/briefcase.png" alt="job role"/></span><span className="pl-1">{job.role}</span>
                            </div></Tooltip>
                        </li>
                        <li style={{ listStyle:'none',marginTop:'5px' }}><Tooltip title="Location">
                            <div className="Jobclient"><span><img className='jobReadOnlyImg'  src="/images/map.png" alt="location" /></span><span className="pl-1"> {util.location( job.country, job.state, job.city )}</span></div>
                        </Tooltip></li>
                        <li style={{ listStyle:'none',marginTop:'5px' }}><Tooltip title="Primary Skills">
                            <div className="Jobclient"><span><img className='jobReadOnlyImg' src="/images/certificate.png" alt="primary skills" /></span><span className="pl-1">{job.certification}</span></div>
                        </Tooltip></li>
                        <li style={{ listStyle:'none',marginTop:'5px' }}><Tooltip title="Salary">
                            <div className="Jobclient"><span><img className='jobReadOnlyImg' src="/images/salary.png" alt="salary" /></span>
                                <span className="pl-1">{util.buyFromBuyTo( job.buy_from, job.buy_to, job.country )} {job.sell_rate_type}</span>
                            </div>
                        </Tooltip>
                        </li>
                        <li style={{ listStyle:'none',marginTop:'5px' }}><Tooltip title="Education">
                            <div className="Jobclient"><span><img className='jobReadOnlyImg'  src="/images/open-book.png" alt="education" /></span><span className="ml-1">{job.education}</span></div>
                        </Tooltip></li>
                        <li style={{ listStyle:'none',marginTop:'5px' }}><Tooltip title="Experience Level">
                            <div className="Jobclient"><span><img  className='jobReadOnlyImg'  src="/images/improve.png" alt="experience level" /></span><span className="ml-1">{job.level}</span></div>
                        </Tooltip></li>
                        <li style={{ listStyle:'none',marginTop:'5px' }}><Tooltip title="Scope">
                            <div className="Jobclient"><span><img  className='jobReadOnlyImg' src="/images/target.png" alt="scope" /></span><span className="ml-1">{job.scope}</span></div>
                        </Tooltip></li>
                    
                        <li style={{ listStyle:'none',marginTop:'5px' }}>
                            {job.level ? <span className="jobsCardView-chips">{job.level}</span> : ""}
                            {job.role ? <span className="jobsCardView-chips">{job.role}</span> : ""}
                            {/* {job.skills ? <span className="jobsCardView-chips">{job.skills}</span> : ""}
                        {job.secondary_skill ? <span className="jobsCardView-chips">{job.secondary_skill}</span> : ""} */}
                        
                        </li>
                        <li style={{ listStyle:'none',marginTop:'5px' }} className="row">
                            <span className="mt-1 col-md-12">Skills:</span> 
                            <div className="col">
                                {job.skills && job.skills.length > 0 ? job.skills.map( el => <div size="small"  key={el.id} value={el.name}  className="jobsCardView-chips mt-1"> {el.name} </div> ) : ''}
                            </div>
                        </li>
                    
                    </ul>
                    <div className="col-md-7">
                        <div>
                            <div>
                                <strong>Client Information</strong>
                                <div className='jobReadOnly'>   
                                    <div className="col-md-8">
                                        <RowItem label="Deal Type" value={job.deal_type}/>
                                        <RowItem label="Restricted To" value={job.restricted_to}/>
                                        <div className="row mt-2">
                                            <span className="col-xl-4 col-lg-8 col-sm-5 Jobclient"><span>Partner Information:</span></span>
                                            <div className="col-xl-8 col-lg-12 col-sm-7 Jobclient">
                                                <div>{job.partner?.company}</div>
                                                <div>{job.partner?.name}
                                                    <a className="text-primary ml-1" href={`mailto:${job.partner?.email}`}>{job.partner?.email}</a>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    
                                        <div className="row mt-2">
                                            <span className="col-xl-4 col-lg-8 col-sm-5 Jobclient"><span>End Client Information:</span></span>
                                            <div className="col-xl-8 col-lg-12 col-sm-5 Jobclient">
                                                <div>{job.client?.company}</div>
                                                <div>{job.client?.name}
                                                    <a className="text-primary ml-1" href={`mailto:${job.client?.email}`}>{job.client?.email}</a>
                                                </div>
                                            
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <RowItem label="Priority" value={job.priority}/>
                                        <RowItem label="Employment Type" value={job.employment_type} />
                                        <RowItem label="Duration in Months" value={job.duration} />
                                    </div>
                                   
                                    
                                    
                                   
                                   
                                   
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="mt-3">
                                <strong>Onboarding Contacts</strong>
                                <div className='jobReadOnly'>
                                    <div className="col-md-8">
                                        {job.reporting_manager ? <RowItem label="Reporting Manager" value={job.reporting_manager} /> : ""}
                                        {job.sales_lead ? <RowItem label="Sales Lead" value={job.sales_lead} person={true} /> : ""}
                                        {job.reporting_manager_phone ? <RowItem label="Reporting Manager Phone" value={job.reporting_manager_phone} /> : ""}
                                    </div>
                                    <div className="col-md-8">
                                        {job.lead_recruiter ? <RowItem label="Lead Recuriter" value={job.lead_recruiter} person={true} /> : ""}
                                        {job.reporting_manager_address ? <RowItem label="Reporting Manager Address" value={job.reporting_manager_address} /> : ""}
                                        {job.secondary_recruiter ? <RowItem label="Secondary Recuiter" value={job.secondary_recruiter} person={true} /> : ""}
                                    </div>
                                </div>
                            </div>
                            {job.questions && <div>
                                <span className="btn btn-sm btn-light" onClick={() => setShowScreeningQuestions( true )}>Screening Questions</span>
                            </div>}
                        </div>
                    </div>
                
                </div>
                  
                <div className="m-2">
                    <strong className="border-bottom mb-3">Job Description</strong>
                    <div
                        dangerouslySetInnerHTML={{ __html: showFullDescription ? job.job_description : job.job_description.substring( 0, 200 ) }}
                        className="jobsReadOnly-desc"></div>
                    {job.job_description.length > 200 && (
                        <a onClick={toggleDescription} className="text-primary iscursor">
                            {showFullDescription ? 'Read less' : 'Read more'}
                        </a>
                    )}
                </div>
            </div>
            

            {marginShow ?
                <SAlert
                    show={marginShow}
                    confirmText="Yes"
                    typeButton="warning"
                    msg={"Do you want to use a lower margin in this Job?"}
                    hideAlert={setMarginShow( false )}
                    onConfirm={onConfirmLowerMargin}
                    opc={0}
                />:""}

            <ModalSelectPartnerClient
                selectedData={selectedData}
                hideModal={hideModal}
                modalVisible={modalVisible}
                modalType={modalType}
            />
            <ModalScreeningQuestions
                show={showScreeningQuestions}
                hideModal={() => setShowScreeningQuestions( false )}
                questions={job.questions} 
                jobId={job.id}
            />
               
        </Fragment>
    );
}

export default JobPositionForm;
