import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalScreeningQuestions = ( { show, hideModal, questions, } ) => {
    function handleType( type ) {
        switch( type ) {
            case 1:
                return "Multiple Choice";
            case 2:
                return "Single Choice";
            case 3:
                return "Numeric";
            case 4:
                return "Text";
        }
    }
    return (
        <Modal size="lg" show={show} onHide={hideModal} centered>
            <Modal.Header>Screening Questions</Modal.Header>
            <Modal.Body>
                {questions?.length > 0 && questions?.map( ( item, index ) =>(
                    <div key={item.id}>
                        <div>
                            <strong>{`Q ${index + 1}. `}{item.question}</strong>
                        </div>
                        <div className="d-flex">
                            <div className="mr-1"><strong>Type:</strong></div>
                            <div>
                                {handleType( item.type )}
                            </div>
                        </div>
                        { item?.options?.length ? <div className="d-flex">
                            <div className="mr-1"><strong>Options:</strong></div>
                            <div>{item.options?.length > 0 && item.options?.map( ( option, index ) => (
                                <div key={index}>{option}</div>
                            ) )}
                            </div>
                        </div> : ""}
                    </div>
                ) )}
            </Modal.Body>
        </Modal>
    );
};

export default ModalScreeningQuestions;