import React from 'react';
import { Modal } from 'react-bootstrap';

const ScreeningQuestionModal = ( { show, handleClose, sqData, applyToJob, response, setResponse } ) => {

    const handleRadioChange = ( event,id  ) => {
        const value = event.target.value;
        setResponse( ( prev ) => {
            const updatedResponse = {
                ...prev,
                [id]: { ...prev[id], answer: value },
            };
            return updatedResponse;
        } );
    };

    const handleTextInputChange = ( event, id ) => {
        const value = event.target.value;
        setResponse( ( prev ) => {
            let temp = { ...prev };
            temp[id].answer = value;
            return temp;
        } );
    };

    const handleMultiCheckBoxChange = ( event, id ) => {
        const value = event.target.value;
        setResponse( ( prev ) => {
            let temp = prev[id];
            const updatedAnswer = Array.isArray( temp.answer ) ? temp.answer : [];
            if ( updatedAnswer.includes( value ) ) {
                updatedAnswer.splice( updatedAnswer.indexOf( value ), 1 );
            } else {
                updatedAnswer.push( value );
            }
            temp.answer = updatedAnswer;
            return { ...prev, [id]: { ...temp } };
        } );
    };
    const renderInputType = ( type, options, id ) => {
        switch ( type ) {
            case 1:
                return (
                    <>
                        <p className="xs-fontSize m-0">
                            <span className="text-danger">*</span>multiple option selected
                        </p>
                        <div className="options-container">
                            
                            {options.map( ( option, index ) => (
                                <div key={index}>
                                    <input
                                        type="checkbox"
                                        name={`option_${index}`}
                                        value={option}
                                        className="mr-1"
                                        id={`${option}_${index}`}
                                        // checked={multiSelected.includes( option )}
                                        checked={response[id]?.answer.includes( option )}
                                        onChange={( event )=>handleMultiCheckBoxChange( event, id )}
                                    />
                                    <label htmlFor={`${option}_${index}`}>{option}</label>
                                </div>
                            ) )}
                        </div>
                    </>
                );
            case 2:
                return (
                    <>  
                        <div className="screening-option-input">
                            {options.map( ( option, index ) => (
                                <div key={`${id}_${index}`}>
                                    <input
                                        type="checkbox"
                                        id={`${option}_${index}`}
                                        name={`option_${index}`}
                                        value={option}
                                        className="mr-1"
                                        checked={response[id]?.answer === option}
                                        onChange={( event ) => handleRadioChange( event, id, type )}
                                    />
                                    <label htmlFor={`${option}_${index}`}>{option}</label>
                                </div>
                            ) )}
                        </div>
                    </>
                );
            case 3:
                return (
                    <>
                        <p className="xs-fontSize m-0">
                            <span className="text-danger">*</span>Please enter a number
                        </p>
                        <input
                            type="number"
                            value={response[id]?.answer}
                            onChange={( event ) => handleTextInputChange( event,id,type )}
                            className="w-100"
                        />
                    </>
                );
            case 4:                
                return (
                    <input
                        className="custom-input w-100"
                        value={response[id]?.answer}
                        onChange={( event ) => handleTextInputChange(  event,id, type )}
                    /> );
            default:
                return null;
        }
    };
    if( !sqData ) {
        return null;
    }
    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            size='lg'>
            <Modal.Body className="screening-popup">
                {sqData.length > 0 && sqData.map( ( item, index ) => (
                    <div key={item.id}>
                        <div className='screening-questions-number'>
                            {`Q ${index + 1}  `} {item.question}
                        </div>
                        <div className="answer-type">
                            {renderInputType( item.type, item.options, item.id )}
                        </div>
                    </div>
                ) )}
            </Modal.Body>
            <Modal.Footer className="screening-modal-footer">
                <span
                    className="btn btn-sm btn-secondary"
                    onClick={handleClose}
                >
  Cancel
                </span>
                <span
                // variant="primary"
                    className="btn btn-sm btn-secondary"
                    onClick={applyToJob}
                >
  Apply
                </span>
            </Modal.Footer>
        </Modal>
    );
};

export default ScreeningQuestionModal;