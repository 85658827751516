import { MuiThemeProvider } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import util from "../../../utils/miniUtils";
import useAllGoalsCompetencyRequest from "../../Manage/useAllGoalsCompetencyRequest";



export default function GoalCompetencyModalPop( { show, handleClose, data, appraisal_id, reloadItems } ) {

    const { 
        list,
        option,setOption,
        getAllGoalsOrCompetencies,
    } = useAllGoalsCompetencyRequest();
    const [goalOrCompetency, setGoalOrCompetency] = useState( false );
    const [selectedRows, setSelectedRows] = useState( [] );
    
    const [repeatedIds, setRepeatedIds] = useState( [] );

    const returnRepeatedIds = ( data ) => {
        let repeated = data.map( item => ( item.goal_competence_id ) ); 
        setRepeatedIds( repeated );
    };
    const assignNewGoalOrCompetency = async ( appraisal_id ) => {
       
        // After component mount, make the request to get the "positions of the current requirement"
        try {
            let ids = selectedRows.map( item => ( item.id ) );
            const request = await util.axios.post( `${util.url}/assign_appraisal_item/${appraisal_id}`, { goals_ids: ids } );
            const { error, data ,msg } = request.data;
          
            handleTable( 2 );
          
            if( selectedRows[0].type ===2 ){
                handleClose();
            }
           
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const handleTable = ( type ) => {
        if ( type === 1 ) {
            setGoalOrCompetency( false );
            getAllGoalsOrCompetencies( 1 );    
            
        } else {
            setGoalOrCompetency( true );
            getAllGoalsOrCompetencies( 2 ); 
        }
        setOption( type );
        
    };
    
    useEffect( () => {
        returnRepeatedIds( data );
        if ( goalOrCompetency===true ) {
            getAllGoalsOrCompetencies( 2 ); 
        } else {

            getAllGoalsOrCompetencies( 1 );  
        }
    
    }, [] );

    return (
        <div>
            <Modal size="xl" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Goals Competency Catalog</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="manage-toolbar my-2">
                        <button
                            className={
                        `btn ${goalOrCompetency ? " btn-secondary" : "btn-primary "} mx-1 manageToolbarBtn sizeBtn`
                            }
                            name="goal"
                            onClick={()=>handleTable( 1 )}>
                    GOAL
                        </button>
                        <button
                            className={
                        `btn ${goalOrCompetency ? " btn-primary" : " btn-secondary"} mx-1 manageToolbarBtn sizeBtn`
                            }
                            name="competency"
                            onClick={()=>handleTable( 2 )}>
                    COMPETENCY
                        </button></div>
               
                    

                    <div className='row'>
                        
                        <div className='col'> <MuiThemeProvider theme={util.defaultTableTheme}>
                            <MaterialTable
                                columns={[
                                    {
                                        field: "title",
                                        title: `${goalOrCompetency?'Competency Title':'Goal Title'}`,
                                        sorting: false,
                                    },
                                    {
                                        field: "Description",
                                        title: `${goalOrCompetency?'Competency Description':'Goal Description'}`,
                                        render: ( rowData ) => {
                                            return <div> {rowData.description}</div>;
                                        }
                                    },
                                
                                ]}
                                data={list}
                                options={{
                                    searchFieldAlignment: "right",
                                    pageSize: 15,
                                    selection: true,
                                    sorting: false,
                                    emptyRowsWhenPaging: false, 
                                    showFirstLastPageButtons: false,
                                    pageSizeOptions: [],
                                    showTitle: false,
                                    paginationType: "stepped",
                                    selectionProps: ( rowData ) => ( {
                                        disabled:   repeatedIds.includes( parseInt( rowData.id ) )
                                    } )
                                }}
                               
                                onSelectionChange={( rows ) => setSelectedRows( rows )}
                               
                            />
                        </MuiThemeProvider>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" className="activeBtn sizeBtn" onClick={handleClose}>
            Close
                    </Button>
                    <Button variant="secondary" className="activeBtn sizeBtn" onClick={() => { assignNewGoalOrCompetency( appraisal_id );}}>
            Select
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}


