import React, { useEffect, useState } from "react";
import InformationBlock from "./InformatonBlock";
import { faHandPaper, faUser, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import Table from "./TableResumeInfo";
import util from "../../utils/miniUtils";
import DraggableAndResizeable from "./DraggableAndResizeble";
import DndDasboardNew from "./DragAndDropDashboard";


export default function Overall(  ){

    const [loading, setLoading] = useState( false );

    const [data, setData] = useState( {
        talents:0,
        employees:0,
        activeJobs:0,
        bySkills:[],
        byRating:[],
        byCertification:[],
        actJobsByClients:[],
        actJobsBySalesRep:[],
        activeJobsBySubmission:[],
        employeesByLocation:[],
        employeesByCertification:[],
        employeesBySkills:[],
    } );

    async function getInformation() {
        try {
            setLoading( true );
            const request = await util.axios.get( `${util.url}/dashboard/overall` );
            const { error, msg, talent, employees,
                activeJobs, bySkills, byRating, byCertification, actJobsByClients, actJobsBySalesRep,
                activeJobsBySubmission, employeesByLocation, employeesByCertification, employeesBySkill,  } = request.data;
            if ( error ) throw msg;
            setLoading( false );
            setData( {
                ...data,
                talents: talent,
                employees,
                activeJobs,
                bySkills,
                byRating,
                byCertification,
                actJobsByClients,
                actJobsBySalesRep,
                // activeJobsBySubmission,
                employeesByLocation:employeesByLocation,
                employeesByCertification:employeesByCertification,
                employeesBySkills:employeesBySkill,
            } );
            
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    }

    useEffect( () => {
        getInformation().then( null );
        // eslint-disable-next-line
    },[] );

    return (
        <div>
            {/* <div className="row">
                <InformationBlock icon={faUserAlt} value={data.talents} title={"Talents"}/>
                <InformationBlock icon={faHandPaper} value={data.activeJobs} title={"Active Jobs"}/>
                <InformationBlock icon={faUser} value={data.employees} title={"Active Employees"}/>
            </div>
            <div className="row mt-0 p-0 tableCustomChecklist">
                <Table title={'By Skills'} data={data.bySkills} />
                <Table title={'Jobs by Client'} data={data.actJobsByClients} />
                <Table title={'By Location'} data={data.employeesByLocation}/>

                <Table title={'By Rating'} data={data.byRating} />
                <Table title={'By SalesRep'} data={data.actJobsBySalesRep} />
                <Table title={'By Certification'}  data={data.employeesByCertification} />

                <Table title={'By Certifications'} data={data.byCertification} />
                {/* <Table title={'By Submission'} data={data.activeJobsBySubmission} /> */}
            {/* <Table title={'By Skills'} data={data.employeesBySkills}/> */}
            {/* </div> */} 
            {/* <DraggableAndResizeable /> */}
            <DndDasboardNew />
            {util.LOADING_SCREEN( loading )}
        </div>
    );
}