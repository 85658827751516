import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { Card, CardContent } from "@material-ui/core";
import util from "../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar, faChartLine, faChartPie } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../redux/actions/datefilter";
import Select from 'react-select';
const ReusableChartComponent = ( {
    endpoint,
    graphLabel,
    graphValue,
    showFilter,
    from,
    to,
    isAppraisal
} ) => {
    const chartRef = React.useRef();
    const [talents, setTalents] = useState( [] );
    const [periodFilter, setPeriodFilter] = useState( [] );
    const [originalTalents, setOriginalTalents] = useState( [] );
    const [selectedPeriod, setSelectedPeriod] = useState( [] );
    const [selectedChart, setSelectedChart] = useState( "bar" );
    const dispatch = useDispatch();

    const { applyFilter } = useSelector( ( state ) => state.filter ) || {};    
    useEffect( () => {
        
        if ( applyFilter ) {
            fetchData();
        }
    }, [applyFilter] );
      

    const fetchData = async () => {
        try {
            
            if( from === undefined ){
                const currentDate = new Date();
                from = currentDate.getFullYear() + '-' +
                  ( currentDate.getMonth() + 1 ) + '-' +
                  currentDate.getDate();
            }

            if( to === undefined ){
                const currentDate = new Date();
                to = currentDate.getFullYear() + '-' +
                ( currentDate.getMonth() + 1 ) + '-' +
                currentDate.getDate();
            }

            let url = endpoint;
            if( showFilter ) {
                if ( from && to ) {
                    url += `?from=${from}&to=${to}`;
                }
                
            }
            const response = await util.axios.get( url );

            const { data, error, msg } = response.data;
            
            const filteredData = data.filter( ( item )=> item[graphLabel] !== null && item[graphLabel] !== "" );
            if( isAppraisal ){
                const period =  [...new Set( filteredData.map( item => item.period ) )];
                const periodOptions= period.map( period => ( { label: period, value: period } ) );
                setPeriodFilter( periodOptions );
                setOriginalTalents( filteredData );
            }
            // console.log( periodFilter );
            
            setTalents( filteredData );

            dispatch( setFilter( false ) );
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
        
    };

    useEffect( () => {
        if ( talents?.length === 0 ) {
            return;
        }
        const colors = generateColors( talents?.length );


        const chartConfig = {
            type: selectedChart,
            data: {
                labels: talents?.map( ( item ) => item[graphLabel] ),
                datasets: [
                    {
                        
                        data: talents?.map( ( item ) => item[graphValue] ),
                        backgroundColor: colors,
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                // maintainAspectRatio: true,
                // responsive: true,
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
                plugins: {
                    legend: {
                        display:true,
                        labels: {
                            
                            font: {
                                color: "#ffffff",
                                weight: "bold",
                            },
                            generateLabels: function ( chart ) {
                                const data = chart.data;
                                if ( data.labels.length && data.datasets.length ) {
                                    return data.labels.map( ( label, i ) => {
                                        const dataset = data.datasets[0];
                                        const value = dataset.data[i];
                                        const backgroundColor = dataset.backgroundColor[i];
                                        return {
                                            text: label,
                                            fillStyle: backgroundColor,
                                            strokeStyle: backgroundColor,
                                            lineWidth: 1,
                                            hidden: false,
                                            index: i,
                                            datasetIndex: 0,
                                            value: value,
                                        };
                                    } );
                                }
                                return [];
                            },
                        },
                    },
                },
            },
        };

        const myChart = new Chart( chartRef.current, chartConfig );

        return () => {
            myChart.destroy();
        };
    }, [talents, selectedChart] );

    const generateColors = ( count ) => {
        const colors = [
            "#0170FF", "#0038FF", "#0425CC", "#000D6B",
            "#68E1FD", "#6F7CFF", "#5B46F9", "#480CA8",
            "#3344FF", "#58FFF0", "#EFF0F9", "#DFE1F0",
            "#B8BACC", "#9193A8", "#75788F", "#5A5C76",
            "#404259", "#23273C", "#1A1B2F", "#0A0D1C",
            "#EFF0F9", "#DFE1F0", "#B8BACC", "#9193A8", 
            "#75788F", "#5A5C76", "#404259", "#23273C", 
            "#1A1B2F", "#0A0D1C",
        ];
        return colors.slice( 0, count );
    };

    
    const handleBarChart = () => {
        setSelectedChart( "bar" );
    };

    const handleLineChart = () => {
        setSelectedChart( "line" );
    };

    const handlePieChart = () => {
        setSelectedChart( "pie" );
    };
    // const renderChart = () => {
    //     if ( selectedChart === "pie" ) {
    //         return (
    //             <canvas ref={chartRef} className="dashboard-canvas" style={{ position: 'relative' }} />
    //         );
    //     } else {
    //         return (
    //             <canvas ref={chartRef} className="dashboard-canvas" style={{ position: 'relative', height: '500px' }} />
    //         );
    //     }
    // };
    const handlePeriodChange = ( selectedPeriod ) =>{
        setSelectedPeriod( selectedPeriod );
        const filteredTalents = originalTalents?.filter( ( item ) => ( selectedPeriod?.length ===0 )|| selectedPeriod?.some( sel => sel.value === item.period ) );
        setTalents( filteredTalents );
    };
    return (
        <Card className="card-chart" >
            <CardContent>
                <div className="chart-content">
                    <div>
                        <span onClick={handleBarChart} className="non-draggable">
                            <FontAwesomeIcon icon={faChartBar} />
                        </span>
                        <span onClick={handlePieChart} className="non-draggable">
                            <FontAwesomeIcon icon={faChartPie} />
                        </span>
                        <span onClick={handleLineChart} className="non-draggable">
                            <FontAwesomeIcon icon={faChartLine} />
                        </span>
                        {isAppraisal && <Select
                            isMulti
                            placeholder="Select period..."
                            value={selectedPeriod}
                            onChange={handlePeriodChange}
                            options={periodFilter}
                            className="mr-2 non-draggable"
                        />}
                    </div>
                    
                </div>
                <div className="chart-wrapper">
                    <canvas ref={chartRef} className="dashboard-canvas" style={{ minHeight: '200px', maxHeight: '400px' }} />


                </div>
                {/* {renderChart()} */}
            </CardContent>
        </Card>

    );
};

export default ReusableChartComponent;
