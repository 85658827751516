/* eslint-disable react/jsx-key */
import React from "react";
import { footer } from "../../data/Data";
import "./footer.css";

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <>
    
            <footer>
                <div className='container'>
                    <div className='box'>
                        <div className='logo'>
                            <img src='../images/demo_logo.jpg' alt='' />
             
                        </div>
                    </div>

                    {footer.map( ( val ,i ) => (
                        <div key={i} className='Cbox'>
                            <h3>{val.title}</h3>
                            <ul>
                                {val.text.map( ( items, index ) => (
                                    <li key={items.list + "-" + index}> {items.list} </li>
                                ) )}
                            </ul>
                        </div>
                    ) )} 
                  
                </div>
               
            </footer>
            <div className='legal'>
                <span>&copy; {currentYear} ejRekruit, All Rights Reserved. Powered By ejAmerica.</span>
            </div>
        </>
    );
};

export default Footer;
