import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faEdit,faMapMarkerAlt,faDollarSign, faTimes, } from '@fortawesome/free-solid-svg-icons';
import en from 'javascript-time-ago/locale/en';
import TimeAgo from 'javascript-time-ago';
import util from '../../../../../../utils/miniUtils';
import SAlert from '../../../../../../microComponents/AlertSA';

TimeAgo.addDefaultLocale( en );
const timeAgo = new TimeAgo( 'en-US' );

const JobCard = ( { job ,deleteShow, onConfirmDeleteJob,hideAlert, HIDE_SWEET_ALERT_DELETE_JOB_POSITION ,handleDelete } ) => {
    
    const { job_title,city,employment_type, state, country, createdAt, level, role, primary_skill, secondary_skill, buy_from ,buy_to, sell_rate_type, candidates_added ,status } = job;    
    const chips = [level, role, primary_skill, secondary_skill];
    let fromatedDate = new Date( createdAt.replaceAll( '/','-' ).replace( ' ','T' ) );
    const [anchorEl, setAnchorEl] = React.useState( null );
    const handleClose = async ( ) => {
        // resetting the anchor for the popover
        setAnchorEl( null );
    };
    return <div>
        <div className='m-2'>
       
            <div className="jobsCardView-card" >

                <div >
                    <span className="mt-1" onClick={() => {
                        handleClose();
                        handleDelete( job.id );
                    }} style={{marginLeft:"99%"}}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>  {deleteShow ?
                        <SAlert
                            show={deleteShow}
                            confirmText="Yes, delete it!"
                            typeButton="danger"
                            msg={`You will delete this Job position`}
                            hideAlert={hideAlert}
                            onConfirm={onConfirmDeleteJob}
                            opc={HIDE_SWEET_ALERT_DELETE_JOB_POSITION}
                        />:""}</div>
                <div className='d-flex justify-content-between'>
                    <h4>{job_title}<h6>{util.jobStatusOnlyIcons( status, true )}</h6></h4>
                    <b>{timeAgo.format( new Date( fromatedDate ) )}</b>
                </div>
                <div className="location d-flex justify-content-between">
                    <span className=' detailCards'><FontAwesomeIcon icon={faMapMarkerAlt} className=" mr-2 " color="#0170ff"/>{util.location( country, state, city )}</span>
                    <span>
                        <b>
                            <FontAwesomeIcon icon={faBuilding} color="#0071C1" className="mr-1" />
					eJAmerica
                        </b>
                       
                    </span>
                </div>
                <div className="detailCards">
                    {chips.map( ( c,i )=>{
                        return c ? <span className="jobsCardView-chips" key={i}>
                            {c}
                        </span> : "";
                    } )}
                </div>


                <div className="detailCards">
                    <span>
               
                        <FontAwesomeIcon icon={faDollarSign} color="#0170ff" className="mr-2"/>
                        <b>Salary Range:</b> {util.buyFromBuyTo( buy_from, buy_to, country )} {sell_rate_type}
                    </span>
                    
                </div>
                
                <div className="d-flex justify-content-between mt-2">
                    <span className="detailCards">
                        <b htmlFor='employement-type'>Employement Type:</b> <span id="employement-type">{employment_type}</span>
                    </span>
                    <span>
                        <b htmlFor='candidates_added'>Candidates added:</b> <span id="employement-type">{candidates_added}</span>
                    </span>
                </div>
            </div>
        
        </div>
       
    </div>;
};

export default JobCard;