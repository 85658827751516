import React from 'react';
import { partners } from "../../data/Data";
import './partners.css';

const CompanyPartner = () => {
    return (
        <div className="company-partners headingsF">
            <h1 style={{ zIndex:"1000" }}> Trusted Company Partners</h1>
            <div className="partners-container">
                {partners.map( ( partner, index ) => (
                    <div className="partner" key={partner.name + index}>
                        <img src={partner.logo} alt={partner.name} />
                        {/* <p>{partner.name}</p> */}
                    </div>
                ) )}
            </div>
        </div>

    );
};

export default CompanyPartner;