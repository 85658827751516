import React, { useState, useEffect, Fragment } from 'react';
import MaterialTable from 'material-table';
import { DropzoneArea } from 'material-ui-dropzone';
import util from '../../../utils/miniUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEdit, faEllipsisV, faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, Form, Modal } from 'react-bootstrap';

const FileManagementTable = ( { employee } ) => {
    const [documents, setDocuments] = useState( [] );
    const [documentTypes, setDocumentTypes] = useState( [] );
    const [currentFiles, setCurrentFiles] = useState( [] );
    const [showEditModal, setShowEditModal] = useState( false );
    const [editingDocument, setEditingDocument] = useState( {} );
    const [disabledDropdowns, setDisabledDropdowns] = useState( {} );

    const handleFileSelect = ( event ) => {
        const file = event.target.files[0];
        if ( file ) {
            setEditingDocument( { ...editingDocument, name: file.name } );
        }
    };
    // Function to open the edit modal with the selected document
    const handleEditClick = ( document ) => {
        setEditingDocument( document );
        setShowEditModal( true );
    };

    // Function to handle the submission of the edit form
    const handleEditSubmit = async ( event ) => {
        event.preventDefault();
    
        try {
            const response = await util.axios.put( `/employee_documents/editEmployeeFiles/${editingDocument.doc_id}`, editingDocument );
            console.log( editingDocument );
    
            const { error, msg } = response.data;
            if ( error ) {
                throw new Error( msg );
            }
    
            fetchDocuments(); 
            setShowEditModal( false ); 
        } catch ( error ) {
            util.handleError( error );
        }
    };
    

    // Function to close the modal
    const handleCloseModal = () => {
        setShowEditModal( false );
    };

    const saveDisabledState = ( disabledDropdowns ) => {
        localStorage.setItem( 'disabledDropdowns', JSON.stringify( disabledDropdowns ) );
    };
    useEffect( () => {
        const savedDisabledDropdowns = JSON.parse( localStorage.getItem( 'disabledDropdowns' ) ) || {};
        setDisabledDropdowns( savedDisabledDropdowns );
        const fetchDocumentTypes = async () => {
            try {
                // Fetch document types based on the employee's country
                const response = await util.axios.get( `/employee_documents/list/${employee.country}` );
                const { error, data, msg } = response.data;
                setDocumentTypes( data );
                if ( error ) throw msg;
            } catch ( error ) {
                util.handleError( error );
            }
        };

        fetchDocumentTypes();
    }, [employee] );

    useEffect( () => {
        fetchDocuments();
    }, [employee] );

    // Fetch documents for the employee
    const fetchDocuments = async () => {
        try {
            const response = await util.axios.get( `/employee_documents/getEmployeeFiles/${employee.employee_id}` );
            const { error,msg, data } = response.data;
            console.log( data );
            setDocuments( data );
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const handleFileUpload = async ( e ) => {
        // setCurrentFiles( uploadedFiles ); // Set the current files
        // // Process file upload here
        // const formData = new FormData();
        // uploadedFiles.forEach( file => {
        //     formData.append( 'files', file );
        // } );
        const uploadedFiles = e.target.files;
        setCurrentFiles( uploadedFiles );

        const formData = new FormData();
        formData.append( 'user',util.user.name );
        for ( const file of uploadedFiles ) {
            formData.append( 'files', file );
        }
        try {
            const response = await util.axios.post( `/employee_documents/uploadDocument/${employee.employee_id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            } );
            const { error,msg } = response.data;
            // Handle response and update local state
            if ( error ) throw msg;
            setCurrentFiles( [] );
        } catch ( error ) {
            util.handleError( error );
        }
        fetchDocuments();
    };

    const handleDownload = async ( rowData ) => {
        try {
            const employeeId = rowData.employee_id; // Replace with actual field name if different
            const fileName = rowData.name; // Replace with actual field name if different
    
            // Constructing the download URL
            const downloadUrl = `${util.url}/employee_documents/downloadDocument/${employeeId}/${fileName}`;
    
            // Initiating the download
            window.location.href = downloadUrl; // Redirects the browser to the download URL
        } catch ( error ) {
            console.error( 'Error initiating download:', error );
            // Optionally, handle error (e.g., show a notification)
        }
    };
    const handleDelete = ( rowData ) => {
        util.axios.delete( `/employee_documents/removeDocument/${rowData.doc_id}` )
            .then( response => {
                // Update the state to reflect the deleted row
                const dataDelete = [...documents];
                const index = dataDelete.findIndex( item => item.doc_id === rowData.doc_id );
                if ( index !== -1 ) {
                    dataDelete.splice( index, 1 );
                    setDocuments( dataDelete );
                }
            } )
            .catch( error => {
                console.error( 'Error deleting document:', error );
                // Optionally, handle error (e.g., show a notification)
            } );
    };
   
      
     
      
    return (
        <div style={{ maxWidth: '100%' }}>
            {/* <DropzoneArea
                onChange={handleFileUpload}
                acceptedFiles={['image/*', 'application/pdf', 'text/*']}
                dropzoneText={"Drag and drop files here or click"}
                showPreviews={true}
                showPreviewsInDropzone={true}
                useChipsForPreview
                previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                previewText="Selected files"
                filesLimit={10}
                initialFiles={currentFiles}
            /> */}
            <MaterialTable
                title="File Management"
                columns={[
                    { title: 'Name', field: 'name', render: rowData => {
                        // Display a link or button to view the document
                        return (
                            <a
                                href={`${util.url}/employee_documents/viewDocument/${rowData.employee_id}/${rowData.name}`} // Replace with the actual path
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {rowData.name}
                            </a>
                        );
                    } },
                    {
                        title: 'Document Type',
                        field: 'document_type',
                        render: rowData => {
                            return (
                                <select
                                    className='form-control'
                                    value={rowData.document_type || ''}
                                    onChange={async ( e ) => {
                                        const newValue = parseInt( e.target.value, 10 );
                                        console.log( 'New Value:', newValue ); // Debugging
                    
                                        // Update logic
                                        try {
                                            const updateData = {  document_type: newValue };
                                            await util.axios.put( `/employee_documents/uploadDocumentData/${rowData.doc_id}`, updateData );
                                            const newDisabledDropdowns = { ...disabledDropdowns, [rowData.doc_id]: true };
                                            setDisabledDropdowns( newDisabledDropdowns );

                                            // Persist the new state
                                            saveDisabledState( newDisabledDropdowns );
                                            // Add logic to update the local state or context to reflect the change
                                        } catch ( error ) {
                                            console.error( 'Error updating document type:', error );
                                        }
                                        fetchDocuments();
                                    }}
                                    disabled={disabledDropdowns[rowData.doc_id]}
                                >
                                    <option value=''>Select a Type</option>
                                    {documentTypes.map( ( type ) => (
                                        <option key={type.id} value={type.id}>{type.name}</option>
                                    ) )}
                                </select>
                            );
                        }
                    },
                    { title: 'Last Updated', field: 'updatedAt', render: rowData => {
                        // Split the date and time
                        const dateTimeParts = rowData.updatedAt.split( ' ' );

                        // Display only the date part
                        const dateOnly = dateTimeParts[0];

                        return (
                            <span>{dateOnly}</span>
                        );
                    } },
                    { title: 'Status', field: 'status',render:rowData=>{
                        return (
                            <span>{rowData.doc_log}</span>
                        );
                    } },
                    {
                        field: "Actions",
                        title: "Actions",
                        sorting: false,
                        render: ( rowData ) => {
                            return <Fragment>
                                <div className='d-flex align-items-center '>
                                    <div className="dropdown">
                                        <button type="text" id="dropdownMenuButton" data-toggle="dropdown" className={"btn"} aria-haspopup="true" aria-expanded="true">
                                            <FontAwesomeIcon icon={faEllipsisV} />
                                        </button>
                                        <div className="dropdown-menu my-drop1" aria-labelledby="dropdownMenuButton">
                                            <button className="dropdown-item text-primary " onClick={() => handleEditClick( rowData )} >
                                                <FontAwesomeIcon icon={faEdit} />  Edit
                                            </button>
                                            <button className="dropdown-item text-danger" onClick={()=>handleDelete( rowData )} >
                                                <FontAwesomeIcon icon={faTrash} />  Delete
                                            </button>
                                            <button className="dropdown-item text-success" onClick={()=>handleDownload( rowData )} >
                                                <FontAwesomeIcon icon={faDownload} />  Download
                                            </button>
                                                                
                                        </div>
                                    </div>
                                                       
                                </div>
                            </Fragment>;
                        },
                    },
                    // {
                    //     title: 'Download',
                    //     field: 'download',
                    //     render: rowData => (
                    //         <span className='ml-3'
                    //             onClick={() => handleDownload( rowData )}
                    //         >
                    //             <FontAwesomeIcon icon={faDownload}/>

                    //         </span>
                    //     )
                    // },
                    // {
                    //     title: 'Actions',
                    //     field: 'actions',
                    //     render: rowData => (
                           
                    //         <div className="util-popover-container">
                    //             <util.PopOverConfirmation
                    //                 callBackOk={() => handleDelete( rowData )}
                    //                 callBackNo={() => console.log( "is fail" )}
                    //             />
                    //         </div>
                    //     )
                    // }
                    
                ]}
                data={documents}
                
                options={{
                    pageSize: 5,
                    pageSizeOptions:[5, 10, 15],
                    tableLayout:"auto",
                    searchFieldAlignment:"left",
                    toolbarButtonAlignment: "left",
                    sorting: true,
                    showSelectAllCheckbox:false,
                    showTitle: false,
                    emptyRowsWhenPaging: false,
                    debounceInterval:500,
                    headerStyle:{ position:'sticky',top:0 },
                    maxBodyHeight:'570px'


      
                }}
                actions={[
                    {
                        icon: () => (
                           
                            <>
                                <input type="file" id="fileInput" accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf" onChange={handleFileUpload} style={{ display: 'none' }} />
                                <button 
                                    className='btn btn-secondary sizeBtn activeBtn mt-2 float-right'
                                    onClick={() => document.getElementById( 'fileInput' ).click()}
                                >
                                    <FontAwesomeIcon icon={faPlusCircle}  className="mr-2" />
                                       Document
                                </button></>
                        ),
                        isFreeAction: true,
                        onClick: () => null,
                    }
                ]}
            />
            {/* Edit Modal */}
            <Modal show={showEditModal} onHide={handleCloseModal}>
                
                <Modal.Header closeButton>
                    <Modal.Title>Edit Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <input type="file" id="fileInput1" accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf" onChange={handleFileSelect} style={{ display: 'none' }} />
                        <button 
                            className='btn btn-secondary sizeBtn activeBtn mt-2 float-right'
                            onClick={() => document.getElementById( 'fileInput1' ).click()}
                        >
                            <FontAwesomeIcon icon={faPlusCircle}  className="mr-2" />
                                     Update Document
                        </button></>
                    <Form onSubmit={handleEditSubmit}  className='mt-5'>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={editingDocument.name || ''}
                                // onChange={(e) => setEditingDocument({ ...editingDocument, name: e.target.value })}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Type</Form.Label>
                            <Form.Control 
                                as="select"
                                defaultValue={editingDocument.document_type}
                                onChange={( e ) => setEditingDocument( { ...editingDocument, document_type: e.target.value } )}
                            >
                                <option value="">Select a Type</option>
                                {documentTypes.map( ( type ) => (
                                    <option key={type.id} value={type.id}>{type.name}</option>
                                ) )}
                            </Form.Control>
                        </Form.Group>
                        
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" onClick={handleEditSubmit}>
                            Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default FileManagementTable;
