import React, { Fragment, useEffect, useState } from "react";
import util from "../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faCopy, faPlus } from "@fortawesome/free-solid-svg-icons";
import AddTest from "./AddTest";
import { Snackbar, Paper } from "@material-ui/core";
import DrawerTestInformation from "./DrawerTestInformation";
import { OverlayTrigger, Tooltip } from "react-bootstrap";


export default function TestsContainer( { talent_id,isEditing } ) {

    const [assignedTests, setAssignedTests] = useState( [] );
    const [loading, setLoading] = useState( false );
    const [showAddTest, setShowAddTest] = useState( false );
    const [open, setOpen] = useState( false );
    const [showDrawer, setShowDrawer] = useState( false );
    const [data, setData] = useState( null );

    async function getAssignedTests() {
        try {
            setLoading( true );
            const response = await util.axios.get( `/tests_user/list/${talent_id}` );
            const { error, msg, tests } = response.data;
            if ( error ) throw msg;
            setAssignedTests( tests );
            setLoading( false );
        } catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    }

    useEffect( () => {
        getAssignedTests().then( null );
    }, [] );

    const copyToClipboard = ( t ) => {
        const url = window.document.location.origin + "/ej_test/?token=" + t.token;
        navigator.clipboard.writeText( url ).then( null );
        setOpen( true );
    };

    const handleClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        setOpen( false );
    };

    const openDrawer = ( t ) => {
        setData( t );
        setShowDrawer( true );
    };
    const tooltip = (
        <Tooltip id="tooltip">
         Add Test
        </Tooltip>
    );
	
    return (
        <div elevation={1}>
            {/* <div className="col-md-12 text-right p-2">
                <button
                    onClick={() => setShowAddTest( !showAddTest )}
                    className={`btn btn-sm ${showAddTest ? "text-danger" : "btn-primary"}`}>
                    {<FontAwesomeIcon icon={showAddTest ? faBan : faPlus} className="mr-2" />}
                    {showAddTest ? "Cancel" : "Add"}
                </button>
            </div> */}
            <div className="container-fluid mt-3" style={{ backgroundColor: "#F4F4F4" }}>
                <b className="custom-title" style={{ color:assignedTests.length === 0 ? 'gray' :'black' }}>Test</b>
                {isEditing?<OverlayTrigger placement="top" overlay={tooltip}>
                    <FontAwesomeIcon
                        className="custom-icon"
                        icon={faPlus}
                        onClick={() => setShowAddTest( !showAddTest )}
                    />
                </OverlayTrigger>:""}
                   
                <table className="custom-table">
                    {assignedTests.length ===0? "" :  <thead>
                        <tr className="text-center">
                            <th style={{ fontSize:'14px' }}>ID</th>
                            <th style={{ fontSize:'14px' }}>Test Link</th>
                            <th style={{ fontSize:'14px' }}>Test Name</th>
                            <th style={{ fontSize:'14px' }}>Score</th>
                            <th style={{ fontSize:'14px' }}>Test Duration</th>
                            <th style={{ fontSize:'14px' }}>Assigned By</th>
                            <th style={{ fontSize:'14px' }}>Date</th>
                            <th style={{ fontSize:'14px' }}>Status</th>
                        </tr>
                    </thead>}
                    <tbody>
                        {assignedTests?.map( ( t, i ) => {
                            return (
                                <tr className="text-center" key={i}>
                                    <td style={{ fontSize:'14px' }}>{util.zeroPad( t.id, 5 )}</td>
                                    <td style={{ fontSize:'14px' }}>
                                        <span className="likeLink" onClick={() => copyToClipboard( t )}>
                                            <FontAwesomeIcon icon={faCopy} />
                                        </span>
                                    </td>
                                    <td style={{ fontSize:'14px' }}>{t.testName}</td>
                                    <td style={{ fontSize:'14px' }}>{t.score !== null ? <span className="likeLink" onClick={() => openDrawer( t )}>{t.score}/{t.numQuestions}</span> : "--"}</td>
                                    <td style={{ fontSize:'14px' }}> {t.testTime} Min.</td>
                                    <td style={{ fontSize:'14px' }}>{t.assignedBy}</td>
                                    <td style={{ fontSize:'14px' }}>{util.humanDateTime( t.createdAt )}</td>
                                    <td style={{ fontSize:'14px' }}>{util.testStatus( t.status )}</td>
                                </tr>
                            );
                        } )}
                    </tbody>
                </table>
                {/* <div className="col-md-12">
                        <table className="table text9em">
                            <thead>
                                <tr className="text-center">
                                    <th>ID</th>
                                    <th>Test Link</th>
                                    <th>Test Name</th>
                                    <th>Score</th>
                                    <th>Test Duration</th>
                                    <th>Assigned By</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {assignedTests?.map( ( t, i ) => {
                                    return (
                                        <tr className="text-center" key={i}>
                                            <td>{util.zeroPad( t.id, 5 )}</td>
                                            <td>
                                                <span className="likeLink" onClick={() => copyToClipboard( t )}>
                                                    <FontAwesomeIcon icon={faCopy} />
                                                </span>
                                            </td>
                                            <td>{t.testName}</td>
                                            <td>{t.score !== null ? <span className="likeLink" onClick={() => openDrawer( t )}>{t.score}/{t.numQuestions}</span> : "--"}</td>
                                            <td>{t.testTime} Min.</td>
                                            <td>{t.assignedBy}</td>
                                            <td>{util.humanDateTime( t.createdAt )}</td>
                                            <td>{util.testStatus( t.status )}</td>
                                        </tr>
                                    );
                                } )}
                            </tbody>
                        </table>
                    </div>
                    <hr /> */}
                {showAddTest ?
                    <AddTest
                        talent_id={talent_id}
                        refreshTestAssignations={() => getAssignedTests()}
                        show={showAddTest}
                        handleClose={() => setShowAddTest( !showAddTest )}
                    />
                    :
                    ""
                }
            </div>
            {util.LOADING_SCREEN( loading )}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message="Test link copied to clipboard"
            />

            {showDrawer ?
                <DrawerTestInformation
                    data={data}
                    showDrawer={showDrawer}
                    closeDrawer={() => setShowDrawer( !showDrawer )}
                />
                :
                ""
            }

        </div>
    );

}