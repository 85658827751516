import React, { Fragment, useState } from "react";
import util from "../../../utils/miniUtils";
import PhoneInput from "react-phone-input-2";
import { ResumeStore } from "./ResumeStore";
import Switch from "@material-ui/core/Switch";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import LocationCX from "../../../shared/LocationCX";
import selector from "../../../utils/selector";

function Personal() {
    
    const data = ResumeStore.useState( s => s.data );
    const datas = ResumeStore.useState( s => s.datas );
    const index = ResumeStore.useState( s => s.index );
    
    function handleChanges( e ) {
        const { name, value } = e.target;
        let u = { ...data, [name]:value };
        
        if ( name === "checkboxFalseEmail" ){
            let random  = util.randomStringPassword().toLowerCase();
            u = { ...data, email: random + "@testmail.com"  };
        }
        if ( name === "checkboxFalsePhone" ){
            u = { ...data, phone_mobile: util.handleValueByRecruiterCountry( '9999999999','phone_mobile' ) };
        }
        
        if ( name === "relocation" ){
            u = { ...data, [name]:e.target.checked };
        }
        
        let t = [...datas];
        t[index] = u;
        
        ResumeStore.update( s => {
            s.data =  u;
            s.datas = t;
        } );
    }
    
    return(
        <Fragment>
            <div className="container mt-3">
                <Tabs defaultActiveKey="personal_information" variant={"pills"}>
                    <Tab eventKey="personal_information" title="Talent Information">
                        <div className="row" >
                            <util.RowComponent
                                c={6}
                                t={"Name"}
                                n={"name"}
                                m={handleChanges}
                                v={data.name ? data.name : ""}
                            />
                            <div className="col-md-1">
                                <label className="labelFormControlMini" htmlFor="checkboxFalseEmail">Email N/A</label>
                                <input onChange={handleChanges} type="checkbox" id="checkboxFalseEmail"  name="checkboxFalseEmail" />
                            </div>
                            <util.RowComponent
                                c={5}
                                t={"Email"}
                                n={"email"}
                                m={handleChanges}
                                v={data.email ? data.email: ""}
                            />
                            <div className="col-md-1">
                                <label className="labelFormControlMini" htmlFor="checkboxFalsePhone">Phone N/A</label>
                                <input onChange={handleChanges} type="checkbox" id="checkboxFalsePhone"  name="checkboxFalsePhone" />
                            </div>
                            <div className="col-md-5 p-1 mt-2">
                                <label className="labelFormControlMini">Phone (Mobile)</label>
                                <PhoneInput
                                    placeholder={"Phone"}
                                    country={'us'}
                                    name={"phone_mobile"}
                                    value={util.handleValueByRecruiterCountry( data.phone_mobile, 'phone_mobile' )}
                                    onChange={phone => {
                                        let e={ target:{ name:"phone_mobile", value: phone } };
                                        handleChanges( e );
                                    }}
                                />
                            </div>
                            <div className="col-md-6 p-1 mt-2">
                                <label className="labelFormControlMini">Phone (Home)</label>
                                <PhoneInput
                                    placeholder={"Phone"}
                                    country={'us'}
                                    name={"phone_home"}
                                    value={util.handleValueByRecruiterCountry( data.phone_home, 'phone_home' )}
                                    onChange={phone => {
                                        let e={ target:{ name:"phone_home", value: phone } };
                                        handleChanges( e );
                                    }}
                                />
                            </div>
                            <div className="col-md-6 p-1 mt-2">
                                <label className="labelFormControlMini">Phone (Other)</label>
                                <PhoneInput
                                    placeholder={"Phone"}
                                    country={'us'}
                                    name={"phone_other"}
                                    value={util.handleValueByRecruiterCountry( data.phone_other, 'phone_other' )}
                                    onChange={phone => {
                                        let e={ target:{ name:"phone_other", value: phone } };
                                        handleChanges( e );
                                    }}
                                />
                            </div>
                            <div className="col-md-6">
                                <div className="text-center">Open To Relocate</div>
                                <br />
                                <div  className="text-center">
                                    <Switch
                                        checked={data.relocation || false}
                                        onChange={handleChanges}
                                        color="primary"
                                        name="relocation"
                                        inputProps={{ "aria-label": "primary checkbox" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="location" title="Location">
                        <div className="row">
                            <util.RowComponent
                                c={10}
                                t={"Address"}
                                n={"address"}
                                m={handleChanges}
                                v={data.address}
                            />
                            <util.RowComponent
                                c={2}
                                ty={"number"}
                                t={"Zip Code"}
                                n={"zip_code"}
                                m={handleChanges}
                                v={data.zip_code || ""}
                            />
                            <div className="container">
                                <div className="row">
                                    <LocationCX
                                        cols={4}
                                        data={data} // contains everything
                                        onChange={handleChanges}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="salary_expectation" title="Salary Expectation">
                        <div className="row">
                            <util.RowComponent
                                c={3}
                                t={"Employment Type"}
                                n={"salary_type"}
                                m={handleChanges}
                                v={data.salary_type || ""}
                                type={1}
                                d={[{ salary_type: "Fulltime" }, { salary_type: "Part-time" }, { salary_type: "Contractor" }]}
                            />
        
                            <util.RowComponent
                                c={3}
                                t={"Expected Salary"}
                                n={"ctc"}
                                ty={"number"}
                                m={handleChanges}
                                v={data.ctc || 0}
                            />
        
                            <util.RowComponent
                                c={3}
                                t={"Currency"}
                                n={"currency"}
                                m={handleChanges}
                                v={util.handleValueByRecruiterCountry( data.currency, 'currency' )}
                                type={1}
                                d={util.currencies()}
                            />
        
                            <util.RowComponent
                                c={3}
                                t={"Per"}
                                n={"salary_mode"}
                                m={handleChanges}
                                v={util.handleValueByRecruiterCountry( data.salary_mode, 'salary_mode' )}
                                type={1}
                                d={selector.sell_rate_type}
                            />
                        </div>
                        <div className="row">
                            {/* Visa type and web profile section */}
                            <util.RowComponent
                                c={3}
                                t={"Work Authorization"}
                                n={"working_status"}
                                m={handleChanges}
                                v={util.handleValueByRecruiterCountry( data.working_status, 'working_status' )}
                                type={1}
                                d={util.working_status}
                            />
                            {data.working_status=== "Other" ?
                                <util.RowComponent
                                    c={3}
                                    t={"Other"}
                                    n={"working_status_other"}
                                    m={handleChanges}
                                    v={data.working_status_other || ""}
                                />
                                :""}
        
                            <util.RowComponent
                                c={3}
                                t={"Talent Source"}
                                n={"platform"}
                                m={handleChanges}
                                v={data.platform ? data.platform : "LinkedIn"}
                                type={1}
                                d={[
                                    { platform: "Bayt" },
                                    { platform: "Bumeran" },
                                    { platform: "CareerBuilder" },
                                    { platform: "CareerJet" },
                                    { platform: "Computrabajo" },
                                    { platform: "Dice" },
                                    { platform: "Empleos Maquila" },
                                    { platform: "Empleos Petroleros" },
                                    { platform: "Empleos TI" },
                                    { platform: "Facebook" },
                                    { platform: "Glassdoor" },
                                    { platform: "InfoJobs" },
                                    { platform: "Indeed" },
                                    { platform: "JobStreet" },
                                    { platform: "Konzerta" },
                                    { platform: "LinkedIn" },
                                    { platform: "Monster" },
                                    { platform: "Naukri" },
                                    { platform: "OCC Mundial" },
                                    { platform: "Reed" },
                                    { platform: "Refferal" },
                                    { platform: "Seek" },
                                    { platform: "SimplyHired" },
                                    { platform: "StepStone" },
                                    { platform: "TechFetch" },
                                    { platform: "Trabajando.com" },
                                    { platform: "Upwork" },
                                    { platform: "www.foundit.in" },
                                    { platform: "Xing" },
                                    { platform: "Ziprecruiter" },
                                    { platform: "Other" }
                                ]}
                            />
        
                            {data.platform === "Other" ?
                                <util.RowComponent
                                    c={3}
                                    t={"Other"}
                                    n={"platform_other"}
                                    m={handleChanges}
                                    v={data["platform_other"] || ""}
                                />
                                :""}
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </Fragment>
    );
}

export default Personal;
