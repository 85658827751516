import React, { useEffect } from "react";
import util from "../utils/miniUtils";
import { MuiThemeProvider, Tooltip } from "@material-ui/core";
import MaterialTable from "material-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import useAssetManager from "./useAssetManager";

function AssignedAssets() {
    const { assetsList, getAssignedAssets } = useAssetManager( [] );
		
    useEffect( () => {
        getAssignedAssets( null );
    },[] );
	
    return(
        <>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    columns={[
                        {
                            title: "Category",
                            field: 'aCategory',
                        },
                        {
                            title: "Type",
                            field: 'aType',
                        },
                        {
                            title: "Item",
                            field: 'item',
                        },
                        {
                            title: "Make",
                            field: 'aMake',
                        },
                        {
                            title: "Model",
                            field: 'aModel',
                        },
                        {
                            title: "Date of Purchase",
                            field: 'date_of_purchase',
                            render: rowData => {
                                return <div>{util.humanDate( rowData.date_of_purchase )}</div>;
                            }
                        },
                        {
                            title: "Assigned User",
                            field: 'assignedUser',
                        }, 
                        {
                            field:"cpu",
                            title:"CPU",
                           
                        }, 
                        {
                            field:"operative_system",
                            title:"OS",
                        },
                        {
                            title: "Amount",
                            field: 'amount',
                            render: rowData => {
                                return <div>{rowData.amount ? util.currencyFormat( rowData.amount ): util.currencyFormat( 0.00 )}</div>;
                            }
                        },
                        {
                            field:"serial_number",
                            title:"Serial Number",
                        },
                        {
                            title: "Credit Card Used",
                            field: 'creditCardUser',
                        },
                        {
                            title: "Notes",
                            field: 'notes',
                            render: value => {
                                return value.notes ?
                                    <Tooltip title={value.notes}>
                                        <span className="text-primary"><FontAwesomeIcon icon={faCommentAlt} /></span>
                                    </Tooltip> :"";
                            }
                        },
                    ]}
                    data={assetsList}
                    options={
                        {
                            searchFieldAlignment:"left",
                            showTitle: false,
                            pageSize: 50,
                            emptyRowsWhenPaging: false,
                            columnsButton:true,
                            sorting: true,
                            padding: "default",
                            tableLayout: "auto",
                            headerStyle:{ position:'sticky',top:0 },
                            maxBodyHeight:'525px',
                            toolbarButtonAlignment: "left",
                        }
                    }
                />
            </MuiThemeProvider>
        </>
    );
}

export default AssignedAssets;