import React,{ Fragment, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { Collapse, Grid } from "@material-ui/core";


const AppraisalLog = ( { appraisalInfo } ) => {
    const { appraisal_log }=appraisalInfo;
    const [state, setState] = useState( {
        expanded: false,
    } );
   
    const expand = () => setState( { ...state, expanded: !state.expanded } );

    return (
        <Fragment>
            <div className="p-4 mb-3">
                <div onClick={expand}  className="d-flex justify-content-between isPointer">
                   
                    <div className="mb-3" style={{ flex:'9' }}>
                        <span className="position-absolute">
                            <FontAwesomeIcon size="2x" className="my-icon" icon={state.expanded ? faCaretUp: faCaretDown} />
                        </span>  
                        <b className="ml-4">
                    Appraisal Log:
                        </b>
                    
                    </div>
                </div>
                <Collapse in={state.expanded}>
                    <div className="p-1 border ap_frame_border">
                   
                        <div className="container-fluid">
                            <Grid container spacing={1} className="mt-3">
                               
                            
                                <Grid item xs={12} md={12} lg={12} className="ml-3">
                                  
                                    {appraisal_log && <div className="row m-1">
                                        <div className="col mb-1 p-0 d-flex align-items-start">
                                            <span className="col-12">
                                                <ul className="wl_logDiv">
                                                    {appraisal_log && appraisal_log.split( '|' ).map( ( item, index ) => item && <li key={index}>{item}</li> )}
                                                </ul>
                                            </span>
                                        </div>
                                    </div>}
                                       
                                </Grid>
                                   
                            </Grid>
                        </div>
                    </div>
                </Collapse>
            </div>
        </Fragment>
    );
};

export default AppraisalLog;
