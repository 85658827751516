import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Paper, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import util from "../../../utils/miniUtils";
import GoalCompetencyModalPop from "../../team_appraisal/goal_competency_modal/GoalCompetencyModalPop";
import BackButton from "../BackButton";
import SetTargetsForm from "./SetTargetsForm";

function SetTargets( { handleBack, data } ) {

    const [show, setShow] = useState( false );
    const [name, setName] = useState();
    const [period, setPeriod] = useState( [] );
    const { appraisal_id } = data;
    const [items, setItems] = useState( [] );
    const [loading, setLoading] = useState( true );
    const [evaluators, setEvaluators] = useState( [] );

    const handleClose = () => {
        getTeamAppraisalDetail();
        setShow( false );
    };
    const handleShow = () => setShow( true );


    //request for getting evaluators
    const getEvaluators = async () => {
        try {
            const request = await util.axios.get( `/get_evaluators` );
            const { error, data, msg } = request.data;
            if ( error ) throw msg;
            let tempData = [...data];
            let evaluatorsSorted = tempData.sort( ( a, b ) => {
                if ( a.name < b.name ) {
                    return -1;
                }
                if ( a.name > b.name ) {
                    return 1;
                }
                return 0;
            } );
            setEvaluators( evaluatorsSorted );
            // console.log( data );
        } catch ( error ) {
            util.handleError( error );
        }
    };



    //request for getting all items in set target form
    const getTeamAppraisalDetail = async () => {
        // After component mount, make the request to get the "positions of the current requirement"
        try {
            setLoading( true );
            const request = await util.axios.get( `${util.url}/get_appraisal_items/${appraisal_id}` );
            const { error, data, employee, period } = request.data;
            const msg = "error";
            if ( error ) throw msg;
            setItems( data );
            setName( employee.account.name );
            setPeriod( period.period );
            setLoading( false );
            console.log( data );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };
    

    function sumOfAllWeights( items ) {
        let totalWeight = 0;
        if ( items && Array.isArray( items ) ) {
            items.map( ( item ) => {
                if ( item && item.weight && !isNaN( parseInt( item.weight ) ) ) {
                    totalWeight += parseInt( item.weight );
                    console.log( item.weight );
                }
            } );
        }
        // console.log( totalWeight );
        return totalWeight;
    }
    

   

    //validation in set target form
    const isValidSetTotalTarget = () => {
        const msg = ( string ) => {
            return { pass: false, msg: `${string}` };
        };

        if ( sumOfAllWeights( items ) != 100 ) {
            return msg( "Total Item's Weight value must equals to 100" );
        }
        for ( let x = 0; x < items.length; x++ ) {
            const item = items[x];
            const itemNumber = ` on item ${x + 1}`;
            // console.log( item );
            // Validate all items with mandatory fields and rules
            const { gc_title, gc_desc, gc_target, l1_evaluator, l2_evaluator, l3_evaluator, hr_evaluator } = item;

            if ( util.noValues.includes( gc_title ) )  return msg( "Goal or Competency Title is is missing" + itemNumber );
            if ( util.noValues.includes( gc_desc ) )   return msg( "Description is is missing" + itemNumber );
            if ( util.noValues.includes( gc_target ) ) return msg( "Target is is missing" + itemNumber );

            if ( parseInt( gc_target ) < 1 || parseInt( gc_target ) > 100 ) return msg( " Target value must be between 1 and 100" + itemNumber );

            if ( util.noValues.includes( l1_evaluator ) || util.noValues.includes( hr_evaluator ) )
                return msg( "L1 Evaluator and HR Evaluator are mandatory" + itemNumber );
            
            if ( !util.noValues.includes( l3_evaluator ) && util.noValues.includes( l2_evaluator ) )
                return msg( "Please select L2 Evaluator to enable L3 Evaluator selection" + itemNumber );

            // Validate no repeated  evaluators
            const idsEvaluators = [];
            if ( !isNaN( parseInt( l1_evaluator ) ) ) idsEvaluators.push( parseInt( l1_evaluator ) );
            if ( !isNaN( parseInt( l2_evaluator ) ) ) idsEvaluators.push( parseInt( l2_evaluator ) );
            if ( !isNaN( parseInt( l3_evaluator ) ) ) idsEvaluators.push( parseInt( l3_evaluator ) );
            if ( !isNaN( parseInt( hr_evaluator ) ) ) idsEvaluators.push( parseInt( hr_evaluator ) );
            if ( ( new Set( idsEvaluators ) ).size !== idsEvaluators.length )
                return msg( "Please select different Evaluators for L1,L2, and L3 " + itemNumber );
        }
        /////////////////////////////////////
        return { pass: true };
    };
    //changing status after sending o appraisee
    const changeStatusToWaiting = async () => {
        const validation = isValidSetTotalTarget();
        
        if ( !validation.pass ) {
            return util.toast().error( validation.msg );
        }
        try {
            let body = { response: true };
            const request = await util.axios.put( `/appraisal_status_update/${appraisal_id}`, body );
            const { error, data, msg } = request.data;
            if ( error ) throw msg;
            handleBack();
        } catch ( error ) {
            util.handleError( error );
        }
    };

    useEffect( () => {
        getTeamAppraisalDetail();
        getEvaluators();
    }, [] );

    if ( loading ) return util.LOADING_SCREEN( loading );
    
    return (
        <>
            <BackButton handleBack={handleBack}/>
           
            <div className="w-100 ml-5">
                <div className="mt-2 d-flex align-items-center">
                    <h5 className="ml-5">
                        {period} Goals and Competencies for {name}
                    </h5>
                    <Tooltip title="Add Goals and Competencies for this employee">
                        <span>
                            <span className="ml-1" onClick={handleShow}>
                                <FontAwesomeIcon className="text-primary ml-1" size="2x" icon={faPlusCircle} />
                            </span>
                        </span>
                    </Tooltip>
                </div>
            </div>
           
            <div elevation={3} className="target-form-paper mt-2 py-4 px-5 rounded w-100 bg-white">
                {
                    items.length === 0 && (
                        <h4 className="d-flex text-center justify-content-center align-items-center text-secondary" style={{ marginTop:"130px" }}>Goals and Competencies are not assigned</h4> )
                }
                {items &&
					items.map( ( item, index ) => (
					    <SetTargetsForm itemNumber={index + 1} key={index} data={item} evaluators={evaluators} reloadItems={getTeamAppraisalDetail} />
					) )}
                
                {
                    // Display only 1 back arrow if items < 5 
                    items.length > 5 && (
                        <BackButton handleBack={handleBack}/>
                    )
                }
            </div>

            <div className="w-100 d-flex justify-content-end mr-5">
                <Tooltip
                    title={
                        items.length >= 3
                            ? "Send this appraisal"
                            : "You must add 3 or more items to enable appraisee button"
                    }>
                    <span className="text-center px-2">
                        <button disabled={items.length <= 2} className=" btn btn-secondary activeBtn sizeBtn  mt-3" onClick={() => changeStatusToWaiting()}>
						Appraisee
                        </button>
                    </span>
                </Tooltip>
            </div>
            <GoalCompetencyModalPop
                reloadItems={getTeamAppraisalDetail}
                appraisal_id={appraisal_id}
                data={items}
                handleClose={handleClose}
                show={show}
            />
        </>
    );
}

export default SetTargets;
