import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaterialTable from "material-table";
import util from "../../utils/miniUtils";
import {
    faPlusCircle,
    faSyncAlt,
    faSave,
    faBan,
} from "@fortawesome/free-solid-svg-icons";
import { MuiThemeProvider } from "@material-ui/core";

function Checklist( props ) {
    const [checkList, setChecklist] = useState( { title: "", isNew: true } );
    const [checkLists, setCheckLists] = useState( [] );

    useEffect( () => {
        util.axios.get( `${util.url}/app_resources/checklist` )
            .then( ( response ) => {
                const { msg, error, checklist } = response.data;
                if ( error ) throw msg;
                setCheckLists( checklist );
            } )
            .catch( ( error ) => {
            } );
        return () => {};
    }, [] );

    function selectCheckList( data ) {
        data.isNew = false;
        setChecklist( data );
    }

    function createNewUser() {
        setChecklist( { title: "", isNew: true } );
    }

    function reloadCheckList( data, id ) {
        setCheckLists( data );
        setChecklist( data.filter( ( cl ) => cl.id === id )[0] );
    }

    function reloadAfterDelete( data ) {
        setCheckLists( data );
        setChecklist( { title: "", isNew: true } );
    }

    return (
        <Fragment>
            <div className="container">
            <div className="mb-3">
                    <UserForm data={checkList} reloadJD={reloadCheckList} relaodAfterDelete={reloadAfterDelete} />
                </div>


                <div className="">
                    <MuiThemeProvider theme={util.defaultTableTheme}>
                        <MaterialTable
                            columns={[
                                {
                                    field: "title",
                                    sorting: true,
                                },
                            ]}
                            data={checkLists}
                            options={{
                                pageSize: 10,
                                sorting: false,
                                showFirstLastPageButtons: false,
                                pageSizeOptions: [],
                                showTitle: false,
                                padding: "default",
                                paginationType:"stepped",
                            }}
                            onRowClick={( event, rowData ) => selectCheckList( rowData )}
                            // actions={[
                            //     {
                            //         icon: () => (
                            //             <FontAwesomeIcon
                            //                 icon={faPlusCircle}
                            //                 id="addRequirementIcon"
                            //             />
                            //         ),
                            //         tooltip: "Add Item to checklist",
                            //         isFreeAction: true,
                            //         onClick: ( event ) => createNewUser(),
                            //     },
                            // ]}
                        />
                    </MuiThemeProvider>
                </div>
                
            </div>
        </Fragment>
    );
}

function UserForm( props ) {
    const [checkList, setCheckList] = useState( props.data );
    const [title, setTitle] = useState( checkList.title === "" ? "" : checkList.title );

    useEffect( () => {
        setTitle( props.data.title );
        setCheckList( props.data );
        return () => {};
    }, [props.data] );

    function handleChange( e ) {
        const { name,  value } = e.target;
        if ( name === "title" ) setTitle( value );
    }


    function handleClick() {
        if ( checkList.isNew ) {
            createItem();
        } else {
            updateItem();
        }
    }

    function createItem() {
        const data = { title };
        // if (name === "") {
        //   util.toast().error("Title can't be empty");
        //   return;

        util
            .axios
            .post( `${util.url}/app_resources/checklist`, data )
            .then( ( response ) => {
                const { msg, error, checklist, id } = response.data;
                if ( error ) throw msg;
                util.toast().success( msg );
                props.reloadJD( checklist, id );
            } )
            .catch( ( error ) => {
            } );
    }

    function updateItem() {
        const data = { title };
        util
            .axios
            .put( `${util.url}/app_resources/checklist/${checkList.id}`, data )
            .then( ( response ) => {
                const { msg, error, checklist } = response.data;
                if ( error ) throw msg;
                util.toast().success( msg );
                props.reloadJD( checklist, checkList.id );
            } )
            .catch( ( error ) => {
            } );
    }

    function deleteItem() {
        util
            .axios
            .delete( `${util.url}/app_resources/checklist/${checkList.id}` )
            .then( ( response ) => {
                const { msg, error, checklist } = response.data;
                if ( error ) throw msg;
                util.toast().success( msg );
                props.relaodAfterDelete( checklist );
            } )
            .catch( ( error ) => {
            } );
    }

    return (
        <Fragment>
            <div></div>
            <div className="">
                <div className="">
                    <div className=" mb-1 mt-3">
                        <input
                            placeholder="Name"
                            value={title}
                            className="form-control"
                            name="title"
                            onChange={handleChange}
                            autoFocus={true}
                        />
                    </div>
                </div>
            </div>
            <div className="">
                {checkList.isNew ? (
                    ""
                ) : (
                    <button
                        onClick={deleteItem}
                        className="btn btn-danger sizeBtn activeBtn float-left m-2"
                    >
                        <FontAwesomeIcon icon={faBan} className="mr-2" />
                        Delete
                    </button>
                )}

                <button
                    onClick={handleClick}
                    className="btn btn-success sizeBtn activeBtn m-2"
                >
                    <FontAwesomeIcon
                        icon={checkList.isNew ? faSave : faSyncAlt}
                        className="mr-2"
                    />
                    {checkList.isNew ? "Save" : "Update"}
                </button>
            </div>
        </Fragment>
    );
}

export default Checklist;
