import { faEdit, faExclamationTriangle, faPaperclip, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MuiThemeProvider, Tooltip } from '@material-ui/core';
import MaterialTable from 'material-table';
import React, { Fragment, useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
import util from '../../../../../utils/miniUtils';
import TalentStatusSelector from '../../../position_detail/TalentStatusSelector';
import TitleExpansion from '../../../position_detail/TitleExpansion';
import '../../../../../../assets/css/main.css';

const JobReadOnly = ( { show, handleClose, position } ) => {
    const [jobData, setJobData] = useState( {} );
    const [ candidates, setCandidates ] = useState( [] );
    const [recruiters, setRecruiters] = useState( [] );

    const getJobData = async ( jobId ) => {
        try {
            const request = await util.axios.get( `/requirements/job_position_information/${jobId}` );
            const { error, msg, position } = request.data;
            if( error ) throw msg;
            setJobData( position );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    const getCandidates = async ( id ) => {
        // Retrieves the list of talents inside this job position
        try {
            const request = await util.axios.get( `/requirements/candidates_of_position/${id}` );
            const { error, msg, candidates } = request.data;
            if ( error ) throw msg;
            setCandidates( [...candidates] );    
        } catch ( error ) {
            util.handleError( error );
        }
    };

    async function getRecruiters() {
        try {
            const request = await util.axios.get( `${util.url}/app_resources/users_by_role/1` );
            const { error, msg, users } = request.data;
            if ( error ) throw msg;
            setRecruiters( users );
        } catch ( error ) {
            util.handleError( error );
        }
    }

    useEffect( () => {
        if( show ) {
            getJobData( position );
            getCandidates( position );
            getRecruiters();
        }
    },[show] );

    const RowItem = ( { label, value, person=false } ) => (
        <div className="row mb-1">
            <span className="col-xl-4 col-lg-12 col-sm-12"><span>{label}:</span></span>
            {person ? <span className="col-xl-8 col-lg-12">{recruiters?.find( el => el.id == value )?.name}</span> : <span className="col-xl-8 col-lg-12">{value}</span>}
        </div>
    );

    return (
        <Modal show={show} onHide={handleClose} size="xl" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div className='d-flex justify-content-between'>
                        <h5 className='w-40'><TitleExpansion position={jobData} /></h5>
                        <div>
                            <span className="">
                                <a
                                    href={`/jobs/position/detail/${jobData.id}?opc=1`} 
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    
                                    <FontAwesomeIcon icon={faEdit} className="mr-2" />Edit
                                </a>
                            </span>
                        </div>
                    </div>
                    {jobData.client_requirement_id ? <div className="w-100 d-flex justify-content-end">
                        <span className="text-danger">Client Refernce:</span>
                        <span className="ml-1">{jobData.client_requirement_id}</span>
                    </div> : ""}

                    <div className='jobReadOnly-grid mb-1'>
                        <ul className="jobsReadOnly-list" >
                            <li><Tooltip title="Job Role">
                                <div><span><img className='jobReadOnlyImg' src="/images/briefcase.png" alt="job role"/></span>{jobData.role}
                                </div></Tooltip>
                            </li>
                            <li><Tooltip title="Location">
                                <div><span><img className='jobReadOnlyImg'  src="/images/map.png" alt="location" /></span>{util.location( jobData.country, jobData.state, jobData.city )}</div>
                            </Tooltip></li>
                            <li><Tooltip title="Primary Skills">
                                <div><span><img className='jobReadOnlyImg' src="/images/certificate.png" alt="primary skills" /></span>{jobData.certification}</div>
                            </Tooltip></li>
                            <li><Tooltip title="Salary">
                                <div><span><img className='jobReadOnlyImg' src="/images/salary.png" alt="salary" /></span>
                                    {util.buyFromBuyTo( jobData.buy_from, jobData.buy_to, jobData.country )} {jobData.sell_rate_type}
                                </div>
                            </Tooltip>
                            </li>
                            <li><Tooltip title="Education">
                                <div><span><img className='jobReadOnlyImg'  src="/images/open-book.png" alt="education" /></span>{jobData.education}</div>
                            </Tooltip></li>
                            <li><Tooltip title="Experience Level">
                                <div><span><img  className='jobReadOnlyImg'  src="/images/improve.png" alt="experience level" /></span>{jobData.level}</div>
                            </Tooltip></li>
                            <li><Tooltip title="Scope">
                                <div><span><img  className='jobReadOnlyImg' src="/images/target.png" alt="scope" /></span>{jobData.scope}</div>
                            </Tooltip></li>
                            <li>
                                {jobData.level ? <span className="jobsCardView-chips">{jobData.level}</span> : ""}
                                {jobData.role ? <span className="jobsCardView-chips">{jobData.role}</span> : ""}
                                {jobData.primary_skill ? <span className="jobsCardView-chips">{jobData.primary_skill}</span> : ""}
                                {jobData.secondary_skill ? <span className="jobsCardView-chips">{jobData.secondary_skill}</span> : ""}
                            </li>
                        </ul>
                        <div>
                            <div className="row m-0">
                                <div className="col p-0 mt-1">
                                    <strong>Client Information :</strong>
                                    <div className='jobReadOnly-grid border-top'>
                                        <RowItem label="Priority" value={jobData.priority} />
                                        <RowItem label="Employement Type" value={jobData.employment_type} />
                                        <RowItem label="Deal Type" value={jobData.deal_type} />
                                        <RowItem label="Restricted To" value={jobData.restricted_to} />
                                        <div className="row mb-1">
                                            <span className="col-xl-4 col-lg-12 col-sm-12"><span>Partner Information:</span></span>
                                            <div className="col-xl-8 col-lg-12 col-sm-12">
                                            <div>{jobData.partner?.company}, {jobData.partner?.name}, <a className="text-primary" href={`mailto:${jobData.partner?.email}`}>{jobData.partner?.email}</a></div>
                                            </div>
                                        </div>
                                        <div className="row mb-1">
                                            <span className="col-xl-4 col-lg-12 col-sm-12"><span>End Client Information:</span></span>
                                            <div className="col-lg-8 col-md-12 col-sm-12">
                                                <div>{jobData.client?.company}, {jobData.client?.name}, <a className="text-primary" href={`mailto:${jobData.client?.email}`}>{jobData.client?.email}</a> </div>
                                                
                                            </div>
                                        </div>
                                        <RowItem label="Duration in Months" value={jobData.duration} />
                                    </div>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col p-0">
                                    <strong>Onboarding Contacts :</strong>
                                    <div className='jobReadOnly-grid border-top'>
                                        {jobData.reporting_manager ? <RowItem label="Reporting Manager" value={jobData.reporting_manager} /> : ""}
                                        {jobData.sales_lead ? <RowItem label="Sales Lead" value={jobData.sales_lead} person={true} /> : ""}
                                        {jobData.reporting_manager_phone ? <RowItem label="Reporting Manager Phone" value={jobData.reporting_manager_phone} /> : ""}
                                        {jobData.lead_recruiter ? <RowItem label="Lead Recuriter" value={jobData.lead_recruiter} person={true} /> : ""}
                                        {jobData.reporting_manager_address ? <RowItem label="Reporting Manager Address" value={jobData.reporting_manager_address} /> : ""}
                                        {jobData.secondary_recruiter ? <RowItem label="Secondary Recuiter" value={jobData.secondary_recruiter} person={true} /> : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='m-2'>
                        <div><strong>Job Description:</strong></div>
                        <div className="jobsReadOnly-desc" dangerouslySetInnerHTML={{ __html: jobData.job_description }}></div>
                    </div>

                    <MuiThemeProvider theme={util.defaultTableTheme}>
                        <MuiThemeProvider theme={util.defaultTableTheme}>
                            <MaterialTable
                                columns={[
                                    {
                                        title: "Talent ID",
                                        field: "id",
                                        disableClick: true,
                                        render: ( data ) => {
                                            return (
                                                <div className="justify-content-between">
                                                    <Link
                                                        to={`/candidates/${data.id}?jp=true&job_id=${position.id}`}
                                                    >
                                                        {data.id}
                                                    </Link>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        title: "Status",
                                        field: "status",
                                        render: data => util.canUseNewMethod( position.createdAt ) ?
                                            <TalentStatusSelector
                                                disabled={true}
                                                statusName={data.statusName}
                                                status={data.status}
                                                candidateId={data.candidate_id}
                                                positionId={data.position_id}
                                                getPositionCandidates={getCandidates}/> : typeof( util.candidateStatus( data.status ) ) === 'number' ?
                                                <div className="text-center">
                                                    {util.candidateStatus(
                                                        data.status                                                    )}
                                                </div> :
                                                <TalentStatusSelector
                                                    disabled={true}
                                                    statusName={data.statusName}
                                                    status={data.status}
                                                    candidateId={data.candidate_id}
                                                    positionId={data.position_id}
                                                    getPositionCandidates={getCandidates}/>
                                    },
                                    {
                                        title: "Name",
                                        field: "name",
                                        render: ( data ) => {
                                            return (
                                                <div className="justify-content-between">
                                                    {data.pendingEvaluationReview ? (
                                                        <Tooltip title="Evaluated">
                                                            <span>
                                                                <FontAwesomeIcon
                                                                    icon={faExclamationTriangle}
                                                                    className="text-warning"
                                                                />
                                                            </span>
                                                        </Tooltip>
                                                    ) : null}
                                                    {data.resume ? (
                                                        <a
                                                            target={"_blank"}
                                                            rel="noopener noreferrer"
                                                            href={util.resumeUrl( data )}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faPaperclip}
                                                                className="mr-2"
                                                            />
                                                        </a>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <Link
                                                        to={`/candidates/${data.id}?jp=true&job_id=${position.id}`}
                                                    >
                                                        {data.name}
                                                    </Link>
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        title: "Email",
                                        field: "email",
                                        disableClick: true,
                                        render: ( data ) => {
                                            return (
                                                <Fragment>
                                                    <a href={`mailto:${data.email}`}>{data.email}</a>
                                                </Fragment>
                                            );
                                        },
                                    },
                                    // {
                                    //     title: "Comments",
                                    //     field: "status_note",
                                    //     disableClick: true,
                                    //     render: ( data ) => <DisplayComments statusNote={JSON.parse( data.status_note )} />
                                    // },
                                    {
                                        title: "Location",
                                        field: "city",
                                        render: ( data ) => {
                                            return util.location(
                                                data.country,
                                                data.state,
                                                data.city
                                            );
                                        },
                                    },
                                    {
                                        title: "Employment Type",
                                        disableClick: true,
                                        customSort: ( a, b ) =>
                                            a.ctc_settings.employmentType.length -
b.ctc_settings.employmentType.length,
                                        hidden: position.ctc_settings === null,
                                        render: ( data ) => {
                                            return data.ctc_settings.employmentType;
                                        },
                                    },
                                    {
                                        title: "Rating",
                                        disableClick: true,
                                        field: "rating",
                                        render: ( data ) => {
                                            return (
                                                <div className="text-center">
                                                    <FontAwesomeIcon
                                                        icon={faStar}
                                                        color="gold"
                                                        className="mr-1"
                                                    />
                                                    {data.rating ? data.rating.toFixed( 2 ) : "0"}
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                data={candidates}
                                options={{
                                    pageSize: 20,
                                    pageSizeOptions: [20, 50, 100],
                                    padding: "default",
                                    sorting: true,
                                    selection: false,
                                    search: false,
                                    showTitle: false,
                                    draggable: true,
                                    toolbar: false,
                                    emptyRowsWhenPaging: false,
                                    paginationPosition: "both",
                                    showSelectAllCheckbox: false,
                                    headerStyle:{ position:'sticky',top:0 },
                                }}
                            />
                        </MuiThemeProvider>;
                    </MuiThemeProvider>
                </>
            </Modal.Body>
        </Modal>
    );
};

export default JobReadOnly;