import React, { Fragment, useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import util from "../../../../utils/miniUtils";
import ListOfTalentsToSubmit from "./ListOfTalentsToSubmit";
import ListOfTalentsSelectedForTemplate from "./ListOfTalentsSelectedForTemplate";

function SubmitTalentModal( { candidates, show, closeHandler, job } ) {
	
    const [selectOption, setSelectOption] = useState( 0 ); // 2 = partner, 1 = client, everest flow
    const [talents] = useState( candidates );
    const [loading] = useState( false );
    const [ setSellRate] = useState( false );
    const [ setContactInformation] = useState( false );
    const [selectedTalents, setSelectedTalents] = useState( [] );
    const [disabled, setDisabled] = useState( false );
	
    useEffect( () => {
        if ( job.partner_id === 0 || job.deal_type === "Direct Client" ){
            setSelectOption( 1 );
            setDisabled( true );
        }
		
        setSelectedTalents( candidates );
    },[] );
	
    const handleSelectChange = ( e ) => {
        const { value } = e.target;
        setSelectOption( parseInt( value ) );
    };
	
    const checkBoxOptions = ( e ) => {
        const { name, checked } = e.target;
        if ( name === "sell_rate" ) setSellRate( checked ? 1:0 );
        if ( name === "contact_information" ) setContactInformation( checked ? 1:0 );
    };
	
    const isResumeAvailable = d => {
        if ( d.resume ){
            const url = util.baseUrl() +  util.AWSURL()+  "/candidate/" + d.id + "/" + d.resume;
            return <a href={url} target="new">
                <FontAwesomeIcon icon={faPaperclip} />
            </a>;
        }
        return "";
    };
	
    // async function submitSelectedTalents() {
    //     try {
    //         setLoading( true );
    //         // Prepare data
    //         let info = {};
    //         info.tls  = selectedTalents;
    //         info.user_type = selectOption;
    //         info.showSellRate = sellRate;
    //         info.showContactInformation = contactInformation;
			
    //         const request = await util.axios.post( `${util.url}/job_position/submit_selected_talents/${job.id}`,info );
    //         const { error, msg, updateTalentData } = request.data;
    //         if ( error ) throw  msg;
    //         setTalents( Object.assign( [], updateTalentData ) );
    //         setSelectedTalents( [] );
    //         setLoading( false );
    //     }catch ( e ) {
    //         setLoading( false );
    //         util.handleError( e );
    //     }
    // }
	
    return(
        <Fragment>
            <Modal
                show={show}
                onHide={closeHandler}
                backdrop="static"
                size="xl"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Talent Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        // Step 1 will show the list of talents inside the job
                        [0,1,2].includes( selectOption ) ?
                            ListOfTalentsToSubmit( disabled, handleSelectChange, selectOption, job, checkBoxOptions, isResumeAvailable, talents, setSelectedTalents )
                            :
                        // ListOfTalentsSelectedForTemplate will display the selected talents
                            <ListOfTalentsSelectedForTemplate talents={selectedTalents} job={job} />
                    }
					
				
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm text-danger" onClick={closeHandler}>
						Discard
                    </button>
                    {[1,2].includes( selectOption ) ?
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                                // console.log( selectedTalents );
                                // setWorkFlow(2)
                            }
                            }>
							Create template
                        </button> : ""
                    }
                </Modal.Footer>
            </Modal>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}

export default SubmitTalentModal;

