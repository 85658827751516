import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import util from "../../utils/miniUtils";
import {
    faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";

function Settings() {
    const [settings, setSettings] = useState( { session_expire_time:"" } );


    useEffect( () => {
        getSettings().then( null );
    },[] );

    async function getSettings() {
        try {
            const request = await util.axios.get( `${util.url}/app_resources/settings` );
            const { error, msg, appSettings } = request.data;
            setSettings( appSettings );
            if ( error ) throw msg;
        }catch ( e ) {
            util.handleError( e );
        }
    }

    async function saveChanges() {
        try {
            const request = await util.axios.put( `${util.url}/app_resources/settings`, settings );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
        }catch ( e ) {
            util.handleError( e );
        }
    }

    function onChangeHandler( e ) {
        const { name, value } = e.target;
        let temp = settings;
        if ( name ==="session_expire_time" ) temp.session_expire_time = value;
        setSettings( Object.assign( {}, temp ) );
    }

    return (
        <Fragment>
            <div className="bg-white container-fluid m-2 p-2">
                <div className="row mt-2 p-2">
                    <div className="col-md-12">
                        <h3>Global Settings</h3>
                        
                    </div>
                    <div className="col-md-6">
                        <b>Time for active session</b>
                        <div>
                            <input
                                title="Time is in seconds"
                                placeholder="Session Time"
                                onChange={onChangeHandler}
                                value={settings.session_expire_time}
                                name="session_expire_time"
                            />
                            <button
                                onClick={saveChanges}
                                className="btn ml-2 btn-secondary sizeBtn activeBtn">
                                <FontAwesomeIcon icon={faSyncAlt} className="mr-2" />
                                    Update
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <b>Hireability Parsing Credits</b>
                        <div className="statsField">
                            <div>
                                <div/>
                                <div>
                                    <div className="mt-3">
                                        <div>Number of parses bought</div>
                                        <div className="text-right">{settings.parse_information ? settings.parse_information.usageLimit:""}</div>
                                    </div>
                                    <div className="mt-3">
                                        <div>Number of parses completed</div>
                                        <div className="text-right">{settings.parse_information ? settings.parse_information.currentUsage:""}</div>
                                    </div>
                                    <div className="mt-3">
                                        <div>Number of parses remaining</div>
                                        <div className="text-right">{settings.parse_information ? settings.parse_information.remainingUsage:""}</div>
                                    </div>
                                    <div className="mt-3">
                                        <div>Account Expiration Date</div>
                                        <div className="text-right">{settings.parse_information ? settings.parse_information.accountValidThrough:""}</div>
                                    </div>
                                    <div className="mt-3">
                                        <div colSpan={2} className=""><b>Product Code</b></div>
                                    </div>
                                    <div className="mt-3">
                                        <input className="form-control" type="text" name="product_code" value={settings.product_code ? settings.product_code:""}/>
                                        <button className="btn btn-secondary sizeBtn activeBtn mt-2"><FontAwesomeIcon icon={faSyncAlt} className="mr-2" /> Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Settings;
