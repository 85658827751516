// import React, { useState } from 'react';
// import util from '../../../utils/miniUtils';

// const IntroVideo = () => {
//     const [form, setForm] = useState( {
//         title: "",
//         description: "",
//         file: null
//     } );

//     const handleChange = ( event ) => {
//         const inputValue = event.target.name === 'file' ? event.target.files[0] : event.target.value;
//         setForm( {
//             ...form,
//             [event.target.name]: inputValue
//         } );
//     };

//     const handleSubmit = ( event ) => {
//         event.preventDefault();

       

//         const videoData = new FormData();
//         videoData.append( "videoFile", form.file );
       
//         videoData.append( "title", form.title );
//         videoData.append( "description", form.description );


//         util.axios
//             .post( `/upload-video-storage`, videoData )
//             .then( ( response ) => {
//                 console.log( response.data );
//             } )
//             .catch( ( error ) => {
//                 console.error( "Axios Error:", error );
//             } );
//     };

//     return (
//         <div>
//             <h6>Upload Video to Youtube channel</h6>
//             <form onSubmit={handleSubmit}>
//                 <div>
//                     <input type="file" accept='video/mp4' name="file" autoComplete='off' placeholder='Add Video File' onChange={handleChange} />

//                     <input type="text" name="title" autoComplete='off' placeholder='Title' onChange={handleChange} />
//                     <input type="text" name="description" autoComplete='off' placeholder='Description' onChange={handleChange} />
//                     <button type="submit">Upload Video</button>
//                 </div>
//             </form>
//         </div>
//     );
// };

// export default IntroVideo;

import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import util from '../../../utils/miniUtils';
import './intro.css';


const IntroVideo = ( { talent_id ,handleClose, updateVideoProfile,setLoading } ) => {
    const [file,setFile]=useState();
    // const [title, setTitle] = useState( '' );
    // const [description, setDescription] = useState( '' );
    const [selectedFileName, setSelectedFileName] = useState( '' ); 
    const [error, setError] = useState( '' );
    const [videoURL, setVideoURL] = useState( '' );
    // const videoRef = useRef( null );
    // const mediaRecorderRef = useRef( null );
    // const [isRecording, setIsRecording] = useState( false );
    // const [recordedChunks, setRecordedChunks] = useState( [] );
    const upload = ()=>{
        if ( !file ) {
            setError( 'Please select a video file.' );
            return;
        }
        const formData=new FormData();
        formData.append( 'video',file );
        // formData.append( 'title', title ); 
        // formData.append( 'description', description );
        util.axios.post( `/upload-video/${talent_id}`,formData )
            .then( res=>{if ( res.data.error === false ) {
                console.log( res.data );
                util.toast().success( res.data.msg );
                setFile( null );
                setError( '' );
                updateVideoProfile( res.data.url );
            } else {
                util.toast().success( res.data.msg );
                setLoading( false );
            }
            } )
                
            .catch( er=>console.log( er ) );

    };
    const handleClick=()=>{
        console.log( "hiii" );
        upload();
        handleClose();
        

    };
   
    // const handleFileChange = ( e ) => {
    //     const selectedFile = e.target.files[0];
    //     setFile( selectedFile );
        
    //     if ( selectedFile ) {
    //         setSelectedFileName( selectedFile.name ); // Update the selected file name
    //         const videoURL = URL.createObjectURL( selectedFile );
    //         setVideoURL( videoURL );
    //     } else {
    //         setSelectedFileName( '' );
    //         setVideoURL( '' ); // Reset the selected file name if no file is selected
    //     }
    // };
    const handleFileChange = ( e ) => {
        const selectedFile = e.target.files[0];
        if ( selectedFile ) {
            const maxSize = 30 * 1024 * 1024; // 30MB in bytes
      
            if ( selectedFile.size <= maxSize ) {
                setFile( selectedFile );
                setSelectedFileName( selectedFile.name );
                const videoURL = URL.createObjectURL( selectedFile );
                setVideoURL( videoURL );
            } else {
                alert( "File size exceeds 30MB limit. Please choose a smaller file." );
                e.target.value = ''; // Clear the file input
                setSelectedFileName( '' );
                setVideoURL( '' );
            }
        } else {
            setSelectedFileName( '' );
            setVideoURL( '' );
        }
    };
      
    const removeVideo = () => {
        setFile( null );
        setSelectedFileName( '' );
        setVideoURL( '' );
    };
    // const startRecording = () => {
    //     navigator.mediaDevices
    //         .getUserMedia( { video: true } )
    //         .then( ( stream ) => {
    //             videoRef.current.srcObject = stream;
    //             mediaRecorderRef.current = new MediaRecorder( stream );
    
    //             mediaRecorderRef.current.ondataavailable = ( event ) => {
    //                 if ( event.data.size > 0 ) {
    //                     setRecordedChunks( [...recordedChunks, event.data] );
    //                 }
    //             };
    
    //             mediaRecorderRef.current.start();
    //             setIsRecording( true );
    //         } )
    //         .catch( ( error ) => {
    //             console.error( 'Error accessing camera:', error );
    //         } );
    // };
    
    // const stopRecording = () => {
    //     mediaRecorderRef.current.stop();
    //     setIsRecording( false );
    
    //     const blob = new Blob( recordedChunks, { type: 'video/webm' } );
    //     const url = URL.createObjectURL( blob );
    //     videoRef.current.src = url;
    // };
    
    // const downloadVideo = () => {
    //     const blob = new Blob( recordedChunks, { type: 'video/webm' } );
    //     const url = URL.createObjectURL( blob );
    //     const a = document.createElement( 'a' );
    //     a.href = url;
    //     a.download = 'recorded-video.webm';
    //     a.click();
    // };
    return (
        <>
            <div className='custom-box'>
                {/* <label htmlFor="title">Title</label>
                <input
                    type="text"
                    className='form-control w-75'
                    id="title"
                    placeholder="Title"
                    value={title}
                    onChange={( e ) => setTitle( e.target.value )}
                />

                <label htmlFor="description">Description</label>
                <input
                    type="text"
                    className='form-control w-75'
                    id="description"
                    placeholder="Description"
                    value={description}
                    onChange={( e ) => setDescription( e.target.value )}
                /> */}

                {selectedFileName?"":<label htmlFor="file-upload" className="custom-file-upload btn btn-secondary sizeBtn activeBtn">
Upload Video                   
                    <input
                        type="file"
                        id="file-upload"
                        accept='video/mp4'
                        onChange={handleFileChange}
                    />
                </label>}
                <p>{selectedFileName}</p>
                {videoURL && (
                    <div className='mt-2'>
                        <ReactPlayer
                            url={videoURL}
                            width="100%"
                            height="100px"
                            controls // Add controls for play/pause, volume, etc.
                        /></div>
                )}
                {selectedFileName?<button className='remove-button' onClick={removeVideo}>Remove Video</button> : " "}
                {error && <p style={{ color: 'red' }}>{error}</p>}
            
                {selectedFileName?<button type="button" onClick={handleClick} className='btn btn-secondary sizeBtn activeBtn mt-2'>Save</button>:""}
            </div>
            <div className='intro-video'><b>Please upload a 2 minutes intro video about yourself, your work and your passion.</b>
                <b>Why you should make an Intro Video?</b>
Intro Video is the most important part of your candidate profile. Intro Video helps you get placed faster as clients can skip screening Interviews for you and take you directly to advanced Interview steps.

                <b>What to say in 2 minutes Intro Video?</b>
Mention your First Name only. How many years of experience, what all technologies you have worked on and the different projects. 

                <b>What not to say in the 2 minutes Intro Video?</b>
Don't mention your contact info - email or Phone number or your company name.  </div>
            {/* <div>Record Video
                <video ref={videoRef} autoPlay />
                {isRecording ? (
                    <button onClick={stopRecording}>Stop Recording</button>
                ) : (
                    <button onClick={startRecording}>Start Recording</button>
                )}
                {recordedChunks.length > 0 && (
                    <button onClick={downloadVideo}>Download Video</button>
                )}</div> */}

        </>
    );
};

export default IntroVideo;

