import React, { Fragment, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ConfirmationModal from "./ConfirmationModal";
import useDropDownOptions from "./useDropDownOptions";
import util from "../../utils/miniUtils";

export default function DropDownOptions( { jobArray, reloadTableData } ) {

    const { openModal, show, hideModal, state } = useDropDownOptions();

    const DropDownItems = ( { icon, option } ) => {
        return (
            <Dropdown.Item
                onClick={( e ) => {
                    e.stopPropagation();
                    e.preventDefault();
                    openModal( option, jobArray );
                }}
                onFocus={( e ) => e.stopPropagation()}
                href="#">
                { icon }
            </Dropdown.Item>
        );
    };

    return (
        <Fragment>
            <Dropdown as="div" className="dropMenuStatus">

                <Dropdown.Toggle as="div"  className="customDropDownChangeStatus sizeBtn activeBtn isCursor">
                    Change Status
                </Dropdown.Toggle>

                <Dropdown.Menu className="jobChangeStatus-dropdown">
                    <DropDownItems icon={util.jobStatusOnlyIcons( 0 )} option={0} />
                    <DropDownItems icon={util.jobStatusOnlyIcons( 1 )} option={1} />
                    <DropDownItems icon={util.jobStatusOnlyIcons( 2 )} option={2} />
                    <DropDownItems icon={util.jobStatusOnlyIcons( 3 )} option={3} />
                    <DropDownItems icon={util.jobStatusOnlyIcons( 4 )} option={4} />
                    <DropDownItems icon={util.jobStatusOnlyIcons( 5 )} option={5} />
                    <DropDownItems icon={util.jobStatusOnlyIcons( 6 )} option={6} />
                </Dropdown.Menu>

            </Dropdown>


            {
                show ?
                    <ConfirmationModal
                        show={show}
                        hideModal={hideModal}
                        data={state}
                        reloadTableData={ ()=>{
                            reloadTableData();
                            hideModal();
                        }
                        }
                    />
                    :
                    null
            }


        </Fragment>
    );
}