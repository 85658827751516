import React from "react";
import CompanyPartner  from "./companyPartners/CompanyPartner";
import Featured from "./featured/Featured";
import Hero from "./hero/Hero";
import '../../../App.css';
import HomePageSearchForm from "./HomePageSearchForm";


const Home = () => {
    return (
        <> 
            <Hero />
            <HomePageSearchForm/>
            <Featured />
            <CompanyPartner/>
        </>
    );
};

export default Home;
