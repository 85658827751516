import { Tooltip } from "@material-ui/core";
import util from "../../../utils/miniUtils";
import React, { Fragment, useEffect, useState } from "react";
import { TalentStore } from "../TalentStore";

export default function ProfilePictureComponent() {
	
    const talent = TalentStore.useState( state => state.talent );
	
    // Internal state to handle up;load picture flow
    const [state, setState] = useState( {
        isUploading: false,
        tempImage: null,
        file: null
    } );

    useEffect( () => {
        isProfilePicAvailable();
    },[talent.profile_picture] );

    useEffect( () => {
        setState( {
            isUploading: false,
            tempImage: null,
            file: null
        } );
        isProfilePicAvailable();
    },[talent.forceReload] );

    const onChangeHandler = ( e ) => {
        let reader = new FileReader();
        reader.onload = function(){
            let output = document.getElementById( 'profilePicture' );
            output.src = reader.result;
        };
        reader.readAsDataURL( e.target.files[0] );
        setState( {
            ...state,
            isUploading: true,
            pictureSrc: reader.result,
            file: e.target.files[0]
        } );
    };

    const cancelUpload = () => {
        setState( { ...state, isUploading: false, pictureSrc: null, file: null } );
        isProfilePicAvailable();
    };

    const uploadPicture = async () => {
        try {
            const userId    = talent.id;
            const infoObject      = new FormData();
            infoObject.append( 'file', state.file );
            // eslint-disable-next-line no-restricted-globals
            infoObject.set( 'name', name );
            const response = await util.axios.post( `candidates/upload_candidate_picture/${userId}`, infoObject );
            let { error, msg, candidate_profile, candidate } = response["data"];
            if ( error ) return util.handleError( msg );
            TalentStore.update( t => {
                t.talent.profile_picture = candidate_profile;
                t.talent.percentage.profilePicture = candidate.percentage.profilePicture;
                t.talent.percentage.total = candidate.percentage.total;
                t.forceReload = Date.now();
            } );
            setState( {
                isUploading: false,
                tempImage: null,
                file: null,
                pictureSrc: null
            } );
        }catch ( e ) {
            util.handleError( e );
        }
    };

    const isProfilePicAvailable = async () => {
        let url;
        if ( !util.noValues.includes( talent.profile_picture ) ) {
            url = "src/public/candidate/" + talent.id + "/" + talent.profile_picture;
        } else {
            url = "/images/avatar.svg"; 
        }
        try {
            const imageUrl = await util.fetchImageUrl( url );
            const profilePictureElement = document.getElementById( 'profilePicture' );
            if ( profilePictureElement ) {
                profilePictureElement.src = imageUrl || "/images/avatar.svg";
            }
        } catch ( error ) {
            console.error( 'Error setting profile picture:', error );
        }
    };

    return(
        <Fragment>
            <div className="mt-0">
                <div className="text-center">
                    <Tooltip placement={"right-end"} title="Click to change the photo">
                        <label htmlFor="my_file">
                            <img
                                width="50%"
                                alt="Profile"
                                id="profilePicture"
                                className="rounded candidateprofile_picture"
                                src=""
                            />
                        </label>
                    </Tooltip>
                </div>
            </div>

            <div className="changeCandidateprofile_picture">
                <input
                    type="file"
                    id="my_file"
                    onChange={onChangeHandler}
                    style={{ display: "none" }}
                    accept=".jpg,.png, jpeg"
                />
                {
                    state.isUploading ?
                        <div>
                            <div className="d-flex justify-content-center p-2">
                                <button onClick={cancelUpload} className="btn btn-danger btn-sm m-2">Cancel</button>
                                <button onClick={uploadPicture} className="btn btn-success btn-sm m-2">Upload</button>
                            </div>
                        </div>
                        :
                        null
                }
            </div>
        </Fragment>
    );
}
