import React, { useEffect } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import useEducation from "./useEducation";
import util from '../../../utils/miniUtils';

const WAIT_INTERVAL = 500;
let timerID;

export default function NewEducationDialog( {
	                                           show,
	                                           hideEducation,
	                                           saveNewEducation,
	                                           updateEducation,
	                                           education,
	                                           isEdit
} ) {
	
    const {
        getSchools,
        getDegrees,
        getFieldOfStudy,
        schoolOptions,
        degreesOptions,
        fieldsOfStudyOptions,
        educationTemp,
        setEducationTemp,
        handleChanges
    } = useEducation();
	
	
    useEffect( () => {
        setEducationTemp( education );
        // eslint-disable-next-line
    }, [education] );
	
	
    return (
        <Dialog
            open={show}
            onClose={hideEducation}
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                {isEdit ? "Edit" : "Add a New"} Education
            </DialogTitle>
            <DialogContent>
                <table className="table">
                    <thead/>
                    <tbody>
                        <tr>
                            <th className="text-right">School</th>
                            <td>
                                <Autocomplete
                                    onInputChange={( event, newInputValue ) => {
                                        clearTimeout( timerID );
                                        timerID = setTimeout( () => {
                                            getSchools( newInputValue );
                                        }, WAIT_INTERVAL );
                                    }}
                                    size='small'
                                    onChange={( e, v ) => setEducationTemp( { ...educationTemp, school: v } )}
                                    getOptionLabel={option => option.name ? option.name : ""}
                                    getOptionSelected={( option, value ) => option.id === value.id}
                                    options={schoolOptions}
                                    value={educationTemp.school ? educationTemp.school : null}
                                    fullWidth
                                    renderInput={( params ) => <TextField {...params} variant="outlined"/>}
                                />
						
                            </td>
                        </tr>
                        <tr>
                            <th className="text-right">Degree</th>
                            <td>
                                <Autocomplete
                                    onInputChange={( event, newInputValue ) => {
                                        clearTimeout( timerID );
                                        timerID = setTimeout( () => {
                                            getDegrees( newInputValue );
                                        }, WAIT_INTERVAL );
                                    }}
                                    size='small'
                                    onChange={( e, v ) => setEducationTemp( { ...educationTemp, degree: v } )}
                                    getOptionLabel={option => option.name ? option.name : ""}
                                    getOptionSelected={( option, value ) => option.id === value.id}
                                    options={degreesOptions}
                                    value={educationTemp.degree ? educationTemp.degree : null}
                                    fullWidth
                                    renderInput={( params ) => <TextField {...params} variant="outlined"/>}
                                />
						
                            </td>
                        </tr>
                        <tr>
                            <th className="text-right">Field of Study</th>
                            <td>
                                <Autocomplete
                                    onInputChange={( event, newInputValue ) => {
                                        clearTimeout( timerID );
                                        timerID = setTimeout( () => {
                                            getFieldOfStudy( newInputValue );
                                        }, WAIT_INTERVAL );
                                    }}
                                    size='small'
                                    onChange={( e, v ) => setEducationTemp( { ...educationTemp, fieldOfStudy: v } )}
                                    getOptionLabel={option => option.name ? option.name : ""}
                                    getOptionSelected={( option, value ) => option.id === value.id}
                                    options={fieldsOfStudyOptions}
                                    value={educationTemp.fieldOfStudy ? educationTemp.fieldOfStudy : null}
                                    fullWidth
                                    renderInput={( params ) => <TextField {...params} variant="outlined"/>}
                                />
						
                            </td>
                        </tr>
                        <tr>
                            <th className="text-right">Start Year</th>
                            <td>
                                <util.InputSlim
                                    type="date"
                                    name="startYear"
                                    onChange={handleChanges}
                                    value={educationTemp.startYear}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="text-right">End Year</th>
                            <td>
                                <util.InputSlim
                                    name="endYear"
                                    type="date"
                                    onChange={handleChanges}
                                    value={educationTemp.endYear}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="text-right">Grade</th>
                            <td>
                                <util.InputSlim
                                    name="grade"
                                    onChange={handleChanges}
                                    value={educationTemp.grade}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th className="text-right">Activities</th>
                            <td>
                                <util.InputSlim
                                    name="activities"
                                    onChange={handleChanges}
                                    value={educationTemp.activities}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="text-right">
                    <span
                        onClick={hideEducation}
                        className="text-danger mr-2 isPointer">
                        <FontAwesomeIcon icon={faTimes} className="mr-2"/>
								Cancel
                    </span>
					
                    {
                        isEdit ?
                            <button onClick={()=> updateEducation( educationTemp )} className="btn-sm btn-primary btn">
                                <FontAwesomeIcon icon={faSave} className="mr-2"/>
								Update
                            </button>
                            :
                            <button onClick={()=> saveNewEducation( educationTemp )} className="btn-sm btn-primary btn">
                                <FontAwesomeIcon icon={faSave} className="mr-2"/>
								Save
                            </button>
                    }
				
                </div>
            </DialogContent>
        </Dialog>
    );
}