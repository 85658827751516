import React from "react";
import ReusableChartComponent from "./ChartControls";
import { useSelector } from "react-redux";

const CandidatesAddedByCountry = () => {
    const { from, to } = useSelector( ( state ) => state.filter ) || {};

    return (
        <div className="chart-container">
            <ReusableChartComponent
                endpoint="new_dashboard/get_top_n_countries_with_most_talent_added"
                chartType="bar"
                graphLabel={"country"}
                graphValue={"total_candidates_added"}
                showFilter={true}
                from={from}
                to={to}
            />
        </div>
    );
};

const CandidatesAddedByRecruiter = () => {
    const { from, to } = useSelector( ( state ) => state.filter ) || {};

    return (
        <div className="chart-container">
            <ReusableChartComponent
                endpoint="new_dashboard/get_candidates_added_by_recruiter_by_job_position"
                chartType="bar"
                graphLabel={"recruiter_name"}
                graphValue={"total_candidates_added"}
                showFilter={true}
                from={from}
                to={to}
            />
        </div>
    );
};
const CandidatesAddedByActiveRecruiter = () => {
    const { from, to } = useSelector( ( state ) => state.filter ) || {};

    return (
        <div className="chart-container">
            <ReusableChartComponent
                endpoint="new_dashboard/get_candidates_added_by_recruiter"
                chartType="bar"
                graphLabel={"recruiter_name"}
                graphValue={"total_candidates_added"}
                showFilter={true}
                from={from}
                to={to}
            />
        </div>
    );
};

const TalentAddedByPlatform = () => {
    const { from, to } = useSelector( ( state ) => state.filter ) || {};

    return (
        <div className="chart-container">
            <ReusableChartComponent
                endpoint="new_dashboard/get_candidates_added_from_each_platform"
                chartType="bar"
                graphLabel={"platform"}
                graphValue={"candidate_count"}
                showFilter={true}
                from={from}
                to={to}
            />
        </div>
    );
};

const CandidatesAddedByWorkingStatus = () => {
    const { from, to } = useSelector( ( state ) => state.filter ) || {};

    return (
        <div className="chart-container">
            <ReusableChartComponent
                endpoint="new_dashboard/get_candidates_added_with_working_status"
                chartType="bar"
                graphLabel={"working_status"}
                graphValue={"candidate_count"}
                showFilter={true}
                from={from}
                to={to}
            />
        </div>
    );
};

const TalentStatus = () => {
    const { from, to } = useSelector( ( state ) => state.filter ) || {};

    return (
        <div className="chart-container">
            <ReusableChartComponent
                endpoint="new_dashboard/get_candidates_by_status"
                chartType="bar"
                graphLabel={"name"}
                graphValue={"candidate_count"}
                showFilter={true}
                from={from}
                to={to}
            />
        </div>
    );
};

const TalentAddedByYear = () => {
    return (
        <div className="chart-container">
            <ReusableChartComponent
                endpoint="new_dashboard/get_talents_by_year"
                chartType="bar"
                graphLabel={"year"}
                graphValue={"total_candidates"}
                showFilter={false}
            />
        </div>
    );
};

const TalentAddedPerMonth = () => {
    return (
        <div className="chart-container">
            <ReusableChartComponent
                endpoint="new_dashboard/get_talents_by_month"
                chartType="bar"
                graphLabel={"month"}
                graphValue={"total_candidates"}
                showFilter={false}
            />
        </div>
    );
};

const TalentAddedPerDay = () => {
    return (
        <div className="chart-container">
            <ReusableChartComponent
                endpoint="new_dashboard/get_talents_by_days"
                chartType="bar"
                graphLabel={"date"}
                graphValue={"total_candidates"}
                showFilter={false}
            />
        </div>
    );
};
const EmployeesByAppraisalScore = () => {
    return (
        <div className="chart-container">
            <ReusableChartComponent
                endpoint="new_dashboard/get_appraisal_score"
                chartType="bar"
                graphLabel={"employee_name"}
                graphValue={"appraisal_score"}
                showFilter={false}
                isAppraisal={true}
            />
        </div>
    );
};

export {
    CandidatesAddedByCountry,
    CandidatesAddedByRecruiter,
    CandidatesAddedByActiveRecruiter,
    TalentAddedByPlatform,
    CandidatesAddedByWorkingStatus,
    TalentStatus,
    TalentAddedByYear,
    TalentAddedPerMonth,
    TalentAddedPerDay,
    EmployeesByAppraisalScore
};
