import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faPlus } from "@fortawesome/free-solid-svg-icons";
import util from "../utils/miniUtils";
import MaterialTable from "material-table";
import { MuiThemeProvider } from "@material-ui/core";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";

function AssetManagerTable( { assetsList, editAssetHandler, setAssetData, setModalShow } ) {
    const [columns,setColumns]=useState( [
        {
            title: "State",
            field: "state",
            hidden:false,
            hiddenByColumnsButton:false,
            render: rowData => {
                return <div>{util.stateDictionary( rowData.state )}</div>;
            }
        },
        {
            title: "ID",
            field: "id_asset_record",
            hidden:false,
            hiddenByColumnsButton:false,
            render: rowData => {
                return <div>{util.zeroPad( rowData.id_asset_record, 5 )}</div>;
            }
        },
        {
            title: "Category",
            field: "aCategory",
            hidden:false,
            hiddenByColumnsButton:false,
        },
        {
            title: "Type",
            field: "aType",
            hidden:false,
            hiddenByColumnsButton:false,
        },
        {
            title: "Item",
            field: "item",
            hidden:false,
            hiddenByColumnsButton:false,
        },
        {
            title: "Make",
            field: "aMake",
            hidden:false,
            hiddenByColumnsButton:false,
        },
        {
            title: "Model",
            field: "aModel",
            hidden:false,
            hiddenByColumnsButton:false,
        },
        {
            title: "Date of Purchase",
            field: "date_of_purchase",
            hidden:false,
            hiddenByColumnsButton:false,
            render: rowData => {
                return <div>{util.humanDate( rowData.date_of_purchase )}</div>;
            }
        },
        {
            title: "Assigned User",
            field: "assignedUser",
            hidden:false,
            hiddenByColumnsButton:false,
        },
        {
            title: "CPU",
            field: "cpu",  hidden:true,
            hiddenByColumnsButton:true,

        },
        {
            title: "OS",
            field: "operative_system",
            hidden:true,
            hiddenByColumnsButton:true,
        },
        {
            title: "Amount",
            field: "amount",
            hidden:true,
            hiddenByColumnsButton:true,
            render: rowData => {
                return <div>{ rowData ? util.currencyFormat( rowData.amount ) : util.currencyFormat( 0.00 )}</div>;
            }
        },
        {
            title: "Serial Number",
            field: "serial_number",
            hidden:true,
            hiddenByColumnsButton:true,
        },
        {
            title: "Credit Card Used",
            field: "creditCardUser",
            hidden:true,
            hiddenByColumnsButton:true,
        },
        {
            title: "Notes",
            field: "notes",
            hidden:true,
            hiddenByColumnsButton:true,
            render: rowData => {
                return rowData.notes ?
                    <Tooltip title={rowData.notes}>
                        <span className="text-primary"><FontAwesomeIcon
                            icon={faCommentAlt} /></span>
                    </Tooltip> : "";
            }
        },
    ] );
    const handleColumnDrag = ( sourceIndex, destinationIndex ) =>{
        const newColumns = [...columns];
        newColumns.splice( destinationIndex, 0, newColumns.splice( sourceIndex, 1 )[0] );
        setColumns( newColumns );
        localStorage.setItem( 'tableColumns', JSON.stringify( newColumns ) );
    };
    useEffect( () => {
        const storedColumns = JSON.parse( localStorage.getItem( 'tableColumns' ) );
        if ( storedColumns ) {
            setColumns( storedColumns );
        }
    }, [] );
    return (
        <>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    title=""
                    columns={columns}
                    data={assetsList}
                    onColumnDragged={handleColumnDrag}

                    options={
                        {
                            searchFieldAlignment:"left",
                            showTitle: true,
                            pageSize: 50,
                            pageSizeOptions:[50, 100, 150],
                            emptyRowsWhenPaging: false,
                            columnsButton:true,
                            sorting: true,
                            padding:"default",
                            tableLayout:"auto",
                            paginationPosition:"bottom",
                            headerStyle:{ position:'sticky',top:0 },
                            maxBodyHeight:'500px',
                            toolbarButtonAlignment: "left",
                        }
                    }
                    actions={[
                        {
                            icon: () => (
                                <div className={"btn btn-secondary sizeBtn activeBtn"}>
                                    <FontAwesomeIcon
                                        icon={faFileExport}
                                        className="mr-2"
                                    />
                            Export
                                </div>
                            ),
                            position: "toolbar",
                            isFreeAction: true,
                            onClick: () => 
                                util.DownloadTableAsCsv( "MuiTable-root",
                                    2,
                                    "Assets List" )
                        },
                        {
                            icon: () =>
                                <div className="btn btn-secondary sizeBtn activeBtn">
                                    <FontAwesomeIcon icon={faPlus} className="mr-2" />
                                        Asset
                                </div>
                            ,
                            position:"toolbar",
                            isFreeAction: true,
                            onClick: () => {
                                setAssetData( {} );
                                setModalShow( true );
                            }
                        },
                    ]}
                    onRowClick={( rowData, rowState ) => {
                        editAssetHandler( rowState.tableData.id );
                    }}
                />
            </MuiThemeProvider>
        </>
    );
}

export default AssetManagerTable;
