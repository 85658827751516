import React, { useEffect, useState } from "react";
import "./header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars,faBuilding,faSearch,faSignOutAlt,faTimes, faTrash, faUser, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { PositionsStore } from "../../../positions/stores/PositionsStore";
import util from "../../../utils/miniUtils";
import { Dropdown } from "react-bootstrap";
import Register from "../../../positions/modals/Register";
import Login from "../../../positions/modals/Login";
import TalentNotification from "../../../positions/navbar/TalentNotification";
import FillProfile from "../../../positions/modals/FillProfileNew";
// import { Link } from "react-router-dom"

const Header = () => {
    const [navList, setNavList] = useState( false );
    const position = PositionsStore.useState( state => state );
    const [show, setShow] = useState( false );
    // Handle display reCaptcha
    useEffect( () => {
        setTimeout( ()=>{
            setShow( false );
        },2500 );
    },[show] );

    useEffect( () => {
        
    },[position.isLogged] );
    
    // Handle changes in search bar or advanced search
   
    const deleteAccount = async() => {
        try {
            const request = await util.axios.get( `/delete_data` );
            const { error, msg } = request.data;
            util.logOut();
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const go_mainPage =()=>{
        const pageUrl = '/';
        window.history.pushState( '', '', pageUrl );
        window.location.reload();
    };

    {util.LOADING_SCREEN( position.loading );}
	
    return (
        <>
            <header>
                <div className='ej_container flex'>
                    <div className='logo isCursor'>
                        <img src='./images/demo_logo.jpg' 
                            style={{ height: '60px', width: 'auto', paddingTop: '20px', objectFit: 'contain' }} 
                            height={"200px"}
                            alt='ejRekruit' onClick={go_mainPage}/>
                    </div>
                    <div className='nav'>
                        <ul 
                        // className={navList ? "small" : "flex"}
                        >
                            {position.isLogged ?
                                <div className="align-items-center d-flex">
                                    {util.user?.name ? <TalentNotification /> : ""}
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{ minWidth: "12rem", display: "flex", justifyContent: "space-between", alignItems: "center"  }}>
                                            {util.customDropDownName( util.user?.name )}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {( window.location.href ).split( '/' ).pop().split( "?" )[0] !== 'careers' ? 
                                                <Dropdown.Item href="/careers">
                                                    <FontAwesomeIcon color="#aeaeae" icon={faSearch} className="mr-1" />
											Search Jobs
                                                </Dropdown.Item> : ""}

                                            {( window.location.href ).split( '/' ).pop().split( "?" )[0] !== 'appliedJobs' ? <Dropdown.Item href="/appliedJobs" >
                                                <FontAwesomeIcon color="#aeaeae" icon={faBuilding} className="mr-1" />
											Applied Jobs
                                            </Dropdown.Item> : "" }
                                            {(  util.user && util.user.permissions && util.user.permissions.talent && !util.user.permissions.employee ) && 
                                            <Dropdown.Item href={`/profile`}>
                                                <FontAwesomeIcon color="#aeaeae" icon={faUserCircle} className="mr-1" />
											Profile
                                            </Dropdown.Item>}
                                            <util.AvailableRole role="Recruiter" selectedRole="recruiter" />
                                            {util.user.role === 2 ? null : <util.AvailableRole role="Talent" selectedRole="talent" />}
                                            <util.AvailableRole role="Client" selectedRole="client" />
                                            <util.AvailableRole role="Partner" selectedRole="partner" />
                                            <util.AvailableRole role="Evaluation" selectedRole="evaluator" />
                                            <util.AvailableRole role="Employee" selectedRole="employee" />
                                            <util.AvailableRole role="Recruitment Manager" selectedRole="recruiter_manager" />
                                            <Dropdown.Item>
                                                <div onClick={()=> deleteAccount()}><FontAwesomeIcon color="#ff7272" icon={faTrash} className="mr-2"/> Delete Account</div>
                                            </Dropdown.Item>
                                            <Dropdown.Item>
                                                <div onClick={()=> util.logOut()}><FontAwesomeIcon color="#ff7272" icon={faSignOutAlt} className="mr-2"/> Logout</div>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                                :
                                <button
                                    onClick={()=>{
                                        PositionsStore.update( s => {
                                            s.loginModal = true;
                                        } );
                                    }}
                                    className="btn btn-sm btn-primary align-self-center align-content-end" style={{ width:"120px",height:"30px" }}>
                                    <FontAwesomeIcon icon={faUser} className="mr-2" />
								Login
                                </button>
                            }
                            
                
                        </ul>
                    </div>
                    {/* <div className='button flex'>
          
            <button className='btn1'>
              <i className='fa fa-sign-in'></i> Log In
            </button>
          </div> */}

                    {/* <div className='toggle'>
                        <button className="btn btn-primary btn-sm" onClick={() => setNavList( !navList )}>{navList ? <FontAwesomeIcon icon={faTimes}/> : <FontAwesomeIcon icon={faBars}/>}</button>
                    </div> */}
                </div>
            </header>
            {position.createAccount ? <Register/>: null}
            {position.loginModal ? <Login/>: null}
            {position.profileProcess ? <FillProfile  />:""}
            {/* {position.advanceFilter ? <AdvancedSearch makeSearch={()=>search()} />:""} */}
        </>
    );
};

export default Header;