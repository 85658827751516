import React, { Fragment, useEffect, useState } from 'react';
import {
    TwitterShareButton,
    FacebookShareButton,
    LinkedinShareButton,
} from "react-share";
import { Facebook, Twitter, LinkedIn, LinkRounded } from "@material-ui/icons";
import { Button, createTheme, MuiThemeProvider, ThemeProvider, Tooltip } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { faCalendarAlt, faClock, faCopy, faEdit, faExclamationTriangle, faLevelUpAlt, faMapMarkerAlt, faMoneyBill, faPaperclip, faStar } from "@fortawesome/free-solid-svg-icons";
import util from '../../../../../../utils/miniUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialTable from 'material-table';
import { Link } from 'react-router-dom';
import TalentStatusSelector from '../../../../position_detail/TalentStatusSelector';
import DisplayComments from '../../../../position_detail/DisplayComments';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { PositionsStore } from '../../../../../../positions/stores/PositionsStore';

const JobDetail = ( { job } ) => {

    const history = useHistory();



    const [open, setOpen] = useState( false );
    const [candidates, setCandidates] = useState( [] );

    const copyToClipboard = () =>{
        const url = window.document.location.origin + `/careers?swe=${util.uuidv4()}&job=${job.id}&fs=true`;
        navigator.clipboard.writeText( url ).then( null );
        setOpen( true );
    };

    const getCandidates = async ( id ) => {
        // Retrieves the list of talents inside this job position
        try {
            const request = await util.axios.get(
            `${util.url}/requirements/candidates_of_position/${id}`
            );
            let { error, msg, candidates } = request.data;
            if ( error ) throw msg;
            // if ( candidates.length > 0 ) {
            //     // Also get information about pending evaluation reviews
            //     const ids = [];
            //     candidates.map( ( c ) => ids.push( c.id ) );
            //     const p = { jobId: id, ids: ids };
            //     const requestEvaluations = await util.axios.post(
            //   `${util.url}/job/talent_has_pending_review`,
            //   p
            //     );
            //     let { talentPendingEvaluationReview } = requestEvaluations.data;
    
            //     if ( talentPendingEvaluationReview.length > 0 ) {
            //         for ( let i = 0; i < candidates.length; i++ ) {
            //             talentPendingEvaluationReview.map( ( t ) => {
            //                 if ( t.talent_id === candidates[i].id ) {
            //                     candidates[i].pendingEvaluationReview = t.hasPending;
            //                 }
            //             } );
            //         }
            //     }
            // }
        
            setCandidates( candidates );
    
            // setPositionId( id );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    
    const handleClose = ( event, reason ) => {
        if ( reason === 'clickaway' ) {
            return;
        }
        setOpen( false );
    };

    useEffect( () => {
        if( Object.keys( job ).length > 0 ) {
            getCandidates( job.id );
        }
    },[job] );

    const theme = createTheme( {
        palette: {
            primary: {
            // Purple and green play nicely together.
                main: "#0071C1",
            },
            secondary: {
            // This is green.A700 as hex.
                main: '#11cb5f',
            },
        },
    } );

    if( Object.keys( job ).length === 0 ) {
        return "";
    }
	
    return (
        <>
            <div className="row bg-white jobCardView-detailsSection">
                <div className="col-md-12">
                    <div className="row ">
                        <div className="col-md-12">
                            <h3 className="pt-2"><p className="detailTitle">{job.job_title}</p></h3>
                        </div>
                        <div className='p-2 w-100 d-flex justify-content-between'>
                            <div className="w-100 d-flex justify-content-start align-self-center">
                                <Tooltip title="Share it in Facebook">
                                    <span className="mr-3">
                                        <FacebookShareButton url={window.location.href} >
                                            <Facebook size={32} style={{ color:'dodgerblue' }} />
                                        </FacebookShareButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Share it in Twitter">
                                    <span className="mr-3">
                                        <TwitterShareButton url={window.location.href} >
                                            <Twitter size={32} style={{ color:'dodgerblue' }} />
                                        </TwitterShareButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Share it in Linkeding">
                                    <span className="mr-3">
                                        <LinkedinShareButton url={window.location.href} >
                                            <LinkedIn size={32} style={{ color:'dodgerblue' }}/>
                                        </LinkedinShareButton>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Copy Job Link">
                                    <span className="likeLink" onClick={copyToClipboard}>
                                        <LinkRounded size={32} icon={faCopy} />
                                    </span>
                                </Tooltip>
                            </div>
                            <ThemeProvider theme={theme}>
                                <a
                                    className='text-primary'
                                    href={`/jobs/position/detail/${job.id}?opc=1`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                   
                                        <FontAwesomeIcon icon={faEdit} className="m-1" /> Edit
                                   
                                </a>
                            </ThemeProvider>
                        </div>
                        <div className="col-md-12">
                            <div className="jobPublicStyle d-flex justify-content-between">
                                <div className="">
                                    
                                    <div>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} className="iconGray"/>
                                        <b>Location</b>: {util.location( job.country, job.state, job.city )}
                                    </div>
                                    <div>
                                        <FontAwesomeIcon icon={faLevelUpAlt} className="iconGray"/>
                                        <b>Level</b>: {job["level"]}
                                    </div>
                                </div>
                                <div className="">
                                    <div>
                                        <FontAwesomeIcon icon={faCalendarAlt} className="iconGray"/>
                                        {util.humanDate( job["createdAt"] )}
                                    </div>
                                    <div className="">
							Job ID: <b>{util.zeroPad( job.id, 5 )}</b>
                                    </div>
                                    
                                </div>
                               
                            </div>
				
                            <div className="jobPublicStyle">
                                <div>
                                    <div className="">
                                        <FontAwesomeIcon icon={faClock} className="mr-1 iconGray"/>
                                        <b>Duration</b>: <u>{job.duration}</u>
                                    </div>
                                    <div className="">
                                        <FontAwesomeIcon icon={faMoneyBill} className="mr-1 iconGray"/>
                                        <b>Salary Range</b>: {util.buyFromBuyTo( job.buy_from, job.buy_to, job.country )} {job.sell_rate_type}
                                    </div>
                                    <span className='mt-4'>
                                        <b>Status:</b> {util.jobStatusOnlyIcons( job.status, true )}
                                    </span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-md-12 jobDescription"
                            dangerouslySetInnerHTML={{ __html: job.job_description }}
                        />
                    </div>
                    {candidates.length > 0 ? <div className="row">
                        <div className='col-12'>
                            <CandidateProgressTable position={job} candidates={candidates} getCandidates={getCandidates}/>
                        </div>
                    </div> : ""}
                </div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message="Job link copied"
            />
        </>
    );
};

export default JobDetail;

const CandidateProgressTable = ( { position, candidates, getCandidates } ) => {

    return <MuiThemeProvider theme={util.defaultTableTheme}>
        <MaterialTable
            columns={[
                {
                    title: "Talent ID",
                    field: "id",
                    disableClick: true,
                    render: ( data ) => {
                        return (
                            <div className="justify-content-between">
                                <Link
                                    to={`/candidates/${data.id}?jp=true&job_id=${position.id}`}
                                >
                                    {data.id}
                                </Link>
                            </div>
                        );
                    },
                },
                {
                    title: "Status",
                    field: "status",
                    render: data => util.canUseNewMethod( position.createdAt ) ?
                        <TalentStatusSelector
                            disabled={true}
                            statusName={data.statusName}
                            status={data.status}
                            candidateId={data.candidate_id}
                            positionId={data.position_id}
                            getPositionCandidates={getCandidates}/> :
                        <div className="text-center">
                            {util.candidateStatus(
                                data.status,
                                data["status_note"]
                            )}
                        </div>
                },
                {
                    title: "Name",
                    field: "name",
                    render: ( data ) => {
                        return (
                            <div className="justify-content-between">
                                {data.pendingEvaluationReview ? (
                                    <Tooltip title="Evaluated">
                                        <span>
                                            <FontAwesomeIcon
                                                icon={faExclamationTriangle}
                                                className="text-warning"
                                            />
                                        </span>
                                    </Tooltip>
                                ) : null}
                                {data.resume ? (
                                    <a
                                        target={"_blank"}
                                        rel="noopener noreferrer"
                                        href={util.resumeUrl( data )}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPaperclip}
                                            className="mr-2"
                                        />
                                    </a>
                                ) : (
                                    ""
                                )}
                                <Link
                                    to={`/candidates/${data.id}?jp=true&job_id=${position.id}`}
                                >
                                    {data.name}
                                </Link>
                            </div>
                        );
                    },
                },
                {
                    title: "Email",
                    field: "email",
                    disableClick: true,
                    render: ( data ) => {
                        return (
                            <Fragment>
                                <a href={`mailto:${data.email}`}>{data.email}</a>
                            </Fragment>
                        );
                    },
                },
                {
                    title: "Comments",
                    field: "status_note",
                    disableClick: true,
                    render: ( data ) => {
                        console.log( "status_note:", data );
                        return <DisplayComments statusNote={JSON.parse( data.status_note )} />; 
                    }
                },
                {
                    title: "Location",
                    field: "city",
                    render: ( data ) => {
                        return util.location(
                            data.country,
                            data.state,
                            data.city
                        );
                    },
                },
                {
                    title: "Employment Type",
                    disableClick: true,
                    customSort: ( a, b ) =>
                        a.ctc_settings.employmentType.length -
b.ctc_settings.employmentType.length,
                    hidden: position.ctc_settings === null,
                    render: ( data ) => {
                        return data.ctc_settings.employmentType;
                    },
                },
                {
                    title: "Rating",
                    disableClick: true,
                    field: "rating",
                    render: ( data ) => {
                        return (
                            <div className="text-center">
                                <FontAwesomeIcon
                                    icon={faStar}
                                    color="gold"
                                    className="mr-1"
                                />
                                {data.rating ? data.rating.toFixed( 2 ) : "0"}
                            </div>
                        );
                    },
                },
            ]}
            data={candidates}
            options={{
                pageSize: 20,
                pageSizeOptions: [20, 50, 100],
                padding: "default",
                sorting: true,
                selection: false,
                search: false,
                showTitle: false,
                draggable: true,
                toolbar: false,
                emptyRowsWhenPaging: false,
                paginationPosition: "both",
                showSelectAllCheckbox: false,
                headerStyle:{ position:'sticky',top:0 },
            }}
        />
    </MuiThemeProvider>;
};