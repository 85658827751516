import React, { useEffect, useState } from "react";
import {
    Card,   
    CardContent,
} from "@material-ui/core";
import util from "../../utils/miniUtils";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../redux/actions/datefilter";
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";

const RecruiterPerformance = () => {
    const [recruiterPerformance, setRecruiterPerformance] = useState( [] );
    const [selectedRecruiters, setSelectedRecruiters] = useState( [] );
    const [selectedCountries, setSelectedCountries] = useState( [] );
    const [recruiter, setRecruiter] = useState( [] );
    const [country, setCountry] = useState( [] );
    let { from, to, applyFilter } = useSelector( ( state ) => state.filter ) || {};
    const dispatch = useDispatch();
    const [originalRecruiterPerformance, setOriginalRecruiterPerformance] = useState( [] );
    
    useEffect( () =>{
        if( applyFilter ) {
            getRecruiterPerformance();
        }
        
    },[applyFilter ] );
    
    const getRecruiterPerformance = async (  ) => {
        try {
            if( from === undefined ){
                const currentDate = new Date();
                from = currentDate.getFullYear() + '-' +
                  ( currentDate.getMonth() + 1 ) + '-' +
                  currentDate.getDate();
            }
            if( to === undefined ){
                const currentDate = new Date();
                to = currentDate.getFullYear() + '-' +
                ( currentDate.getMonth() + 1 ) + '-' +
                currentDate.getDate();
            }
            const response = await util.axios.get(
                `new_dashboard/get_recruiter_performance?from=${from}&to=${to}`
            );

            const { data, error, msg } = response.data;
           
            setOriginalRecruiterPerformance( data );
            setRecruiterPerformance( data );
            const uniqueRecruiters = [...new Set( data?.map( item => item.recruiter_name ) )];
            const uniqueCountries = [...new Set( data?.map( item => item.country ) )];

            // Convert to options format { label: "value", value: "value" }
            const recruiterOptions = uniqueRecruiters?.map( name => ( { label: name, value: name } ) );
            const countryOptions = uniqueCountries?.map( country => ( { label: country, value: country } ) );

            setCountry( countryOptions );
            setRecruiter( recruiterOptions );
            dispatch( setFilter( false ) );
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    };
   

    const handleCountryChange = ( selectedCountries ) => {
        setSelectedCountries( selectedCountries );
  
        let filteredRecruiter = originalRecruiterPerformance?.filter( item =>
            ( selectedRecruiters?.length === 0 || selectedRecruiters?.some( sel => sel.value === item.recruiter_name ) ) &&
                ( selectedCountries?.length === 0 || selectedCountries?.some( sel => sel.value === item.country ) )
        );
        setRecruiterPerformance( [...filteredRecruiter] );
     
        
    };
    const handleRecruiterChange = ( selectedRecruiters ) =>{
        setSelectedRecruiters( selectedRecruiters );
        
        let filteredRecruiter = originalRecruiterPerformance?.filter( item =>
            ( selectedRecruiters?.length === 0 || selectedRecruiters?.some( sel => sel.value === item.recruiter_name ) ) &&
                ( selectedCountries?.length === 0 || selectedCountries?.some( sel => sel.value === item.country ) )
        );
        setRecruiterPerformance( [...filteredRecruiter] );
       
        
    };
    const exportToCsv = () => {
        const headings = [
            "Recuiter Name",
            "Secondary Recruiter Name",
            "Job Title",
            "Job Id",
            "Country",
            "Talent Name",
            "Total Talent Onboarded",
        ];
      
        const csvContent =
          "data:text/csv;charset=utf-8," +
          [headings.join( ',' ), ...recruiterPerformance.map( row =>
              [
                  row.recruiter_name,
                  row.secondary_recruiter_name,
                  row.job_title,
                  row.Job_ID,
                  row.country,
                  row.Onboarded_Talent_Name,
                  row.total_candidates_Onboarded,
              ].join( ',' )
          )].join( '\n' );
      
        const encodedUri = encodeURI( csvContent );
        const link = document.createElement( "a" );
        link.setAttribute( "href", encodedUri );
        link.setAttribute( "download", "table-export.csv" );
        document.body.appendChild( link );
        link.click();
    };
      
    
    return (
        <Card style={{ width: "100%", height: "90%", overflow: 'hidden' }}>
            <CardContent style={{ height: "100%" }} >
                <div style={{ height: "100%", overflow: "auto" }}>
                    <div className="recruiter-filter">
                        <Select
                            isMulti
                            placeholder="Select recruiters..."
                            value={selectedRecruiters}
                            onChange={handleRecruiterChange}
                            options={recruiter}
                            className="mr-2 non-draggable"
                        />
                        <Select
                            isMulti
                            placeholder="Select countries..."
                            value={selectedCountries}
                            onChange={handleCountryChange}
                            options={country}
                            className="mr-2 non-draggable"
                        />
                        <div className={"btn btn-secondary jobButton activeBtn btn-sm export-onboarded non-draggable"} onClick={exportToCsv}>
                            <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                                Export All
                        </div>
                       
                        {/* <button className="m-2 btn btn-secondary sizeBtn activeBtn" onClick={handleApplyClick}>Apply</button> */}
                    </div>
                    
                    
                    {/* <table className="table table-bordered"> */}
                    <table className="custom-table-dashboard">
                        <thead>
                            <tr>
                                <th>Recuiter Name</th>
                                <th>Secondary Recuiter Name</th>
                                <th>Job Title </th>
                                <th>Job Id</th>
                                <th>Country</th>
                                <th>Talent Name</th>
                                <th>Total Talent Onboarded</th>
                                {/* <th>Status</th> */}
                                
                            </tr>
                        </thead>
                        <tbody className="recruiter-report-body">
                            {recruiterPerformance?.map( ( item,index ) => (
                                <tr key={index} className="recruiter-report-rows">
                                    <td>{item?.recruiter_name}</td>
                                    <td>{item.secondary_recruiter_name}</td>
                                    <td>{item?.job_title}</td>
                                    <td>{item?.Job_ID}</td>
                                    {/* <td>{item?.country}</td> */}
                                    <td>
                                        <img width={25} src={util.countryFlag( item?.country )} alt={item?.country} className="mr-1"/>
                                        {item?.country}</td>
                                    <td>{item.Onboarded_Talent_Name}</td>
                                    <td>{item.total_candidates_Onboarded}</td>
                                    {/* <td>{item.status}</td> */}
                                    
                                </tr>
                            ) )}
                        </tbody>
                    </table>
                    
                </div>
            </CardContent>
        </Card>
    );
};

export default RecruiterPerformance;

