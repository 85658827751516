import React,{ useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import util from "../../../utils/miniUtils";
import { Collapse, Grid, Typography } from "@material-ui/core";
import { Badge } from "react-bootstrap";
import UserBox from "../UserBox";


const ReviewDetailsAccordion = ( { item,role,status } ) => {
    
    const { appraisal_item_id, type, gc_desc, gc_title,weight, gc_target, l1_name, l2_name, l3_name, hr_name } = item;
    const [state, setState] = useState( {
        expanded: false,
        item: item,
    } );
    const [attachmentsList,setAttachmentsList]=useState( [] );
    const[loading,setLoading]=useState( false );

   

    const expand = () => setState( { ...state, expanded: !state.expanded } );

    
    
   

    
    useEffect( ()=>{
        getAllAttachments();

    },[] );


    //request for get all attachments
    const getAllAttachments = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `appraisal/get_attachments/${appraisal_item_id}` );
            const { error, attachments, msg } = request.data;
            if ( error ) throw msg;
            setAttachmentsList( attachments );
            // console.log( attachments );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    
   
    return (

        <div className="p-4 mb-3">
            <div onClick={expand}  className="d-flex justify-content-between isPointer">
                <div className="mb-3" style={{ flex:'9' }}>
                    <span className="position-absolute">
                        <FontAwesomeIcon size="2x" className="my-icon" icon={state.expanded ? faCaretUp: faCaretDown} />
                    </span>  
                    <b className="ml-4">
                        {util.isGoalOrCompetence( type )}: 
                    </b><b className="text-secondary ml-1">{gc_title}</b>
                    
                </div>
                <div style={{ flex:'2' }}>
                    <b className="mb-3 text-secondary">Target: {gc_target}</b>
                </div>
               
                <div style={{ flex:'1' }}>
                    <b className="mb-5 text-secondary">Weight: {weight}%</b>
                    
                </div>
            </div>
            <Collapse in={state.expanded}>
                <div className="p-1 border ap_frame_border">
                    <Grid container justifyContent="center">
                        <Grid xs={6} md={3} lg={2}>
                            <UserBox name={l1_name} title="L1 Evaluator" />
                        </Grid>
                        {l2_name ? <Grid xs={6} md={3} lg={2}><UserBox name={l2_name} title="L2 Evaluator" /></Grid> : "" }
                        {l3_name ? <Grid xs={6} md={3} lg={2}><UserBox name={l3_name} title="L3 Evaluator" /></Grid> : " "}
                        <Grid xs={6} md={3} lg={2}><UserBox name={hr_name} title="HR Evaluator" /></Grid>
                    </Grid>
                    <div className="container-fluid mt-2">
                        <Grid container spacing={1} className="p-2">
                            <Grid item  xs={12} md={12} lg={1}>
                                <b>Description:</b>
                            </Grid>
                            <Grid item xs={12} md={12} lg={10} >
                                <div className="ml-3" >  
                                    {gc_desc}</div>     
                            </Grid>

                        </Grid>
                        {attachmentsList.length > 0 ?   <Grid container spacing={2} className="mt-3">
                            <Grid item xs={12} md={2} lg={1}>
                                <b>Attachments:</b>
                            </Grid>
                            <Grid item xs={12} md={6} lg={7} className='ml-2'>
                                {
                                    attachmentsList && attachmentsList.map( ( a,i )=> {
                                        const { appraisal_item_id, name } = a;
                                        return <Badge key={i} pill bg="secondary"  className="mr-1 isPointer badgeCustom">
                                            <a href={`${util.url}/${util.AWSURL}/appraisal_attachments/${appraisal_item_id}/${name}`} target="_blank" rel="noopener noreferrer">{a.name}</a></Badge>;
                                    } )
                                }
                            </Grid>
                           
                           
                           
                           
                        </Grid> : ""}
                        {( ( role == "employee" ) ||  role.includes( 'MANAGER' ) || role.includes( 'L1' ) || role.includes( 'L2' ) || role.includes( 'L3' ) ||  role.includes( 'HR' ) )  && 
                        <div>
                            {status >= 14 && item.employee_score !== null ? (
                                <Grid container spacing={2} className="mt-3 p-3">
                                    <Grid item xs={12} md={12} lg={1}>
                                        <b>Appraisee Feedback:</b>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8} className=" ml-2">
                                        {" "}
                                        <div>{ item.employee_feedback? item.employee_feedback:" "}</div>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={2}>
                                        {" "}
                                        <b className="ml-1">Appraisee Evaluation:</b>
                                        <span className="ml-1">{item.employee_score}%</span>
                                    </Grid>
                                </Grid>
                            ) : (
                                ""
                            )}

                            { status >= 15 && item.l1_score !== null ? (
                                <Grid container spacing={1} className="mt-3">
                                    <Grid item xs={12} md={12} lg={1}>
                                        <b>L1 Feedback:</b>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8} className=" ml-2">
                                        {" "}
                                        <div>{item.l1_feedback?item.l1_feedback:" "}</div>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={2}>
                                        {" "}
                                        <b className="ml-1">L1 Evaluation :</b>
                                        <span className="ml-3 ">{item.l1_score}%</span>
                                    </Grid>
                                </Grid>
                            ) : (
                                ""
                            )}
                            {status >= 16 && item.l2_score !== null ? (
                                <Grid container spacing={1} className="mt-3">
                                    <Grid item xs={12} md={12} lg={1}>
                                        <b>L2 Feedback:</b>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8} className=" ml-2">
                                        {" "}
                                        <div>{item.l2_feedback?item.l2_feedback:" "}</div>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={2}>
                                        {" "}
                                        <b className="ml-1">L2 Evaluation :</b>
                                        <span className="ml-3 ">{item.l2_score}%</span>
                                    </Grid>
                                </Grid>
                            ) : (
                                ""
                            )}
                            {status >= 17 && item.l3_score !== null ? (
                                <Grid container spacing={1} className="mt-3">
                                    <Grid item xs={12} md={12} lg={1}>
                                        <b>L3 Feedback:</b>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8} className=" ml-2">
                                        {" "}
                                        <div>{item.l3_feedback?item.l3_feedback:" "}</div>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={2}>
                                        {" "}
                                        <b className="ml-1">L3 Evaluation :</b>
                                        <span className="ml-3 ">{item.l3_score}%</span>
                                    </Grid>
                                </Grid>
                            ) : (
                                ""
                            )}
                            {status == 18 && item.hr_score !== null ? (
                                <Grid container spacing={1} className="-3">
                                    <Grid item xs={12} md={12} lg={1}>
                                        <b>HR Feedback:</b>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8} className=" ml-2">
                                        {" "}
                                        <div>{item.hr_feedback?item.hr_feedback:""}</div>
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={2}>
                                        {" "}
                                        <b className="ml-1">HR Evaluation :</b>
                                        <span className="ml-3 ">{item.hr_score}%</span>
                                    </Grid>
                                </Grid>
                            ) : (
                                ""
                            )} </div> }

                        
                        
                        {/* {( ( role == "employee" ) && status==14 ) &&
                        
                        <Grid container spacing={2} className="mt-3">
                            <Grid item xs={12} md={12} lg={1}>
                                <b>Self Feedback:</b></Grid>
                            <Grid item xs={12} md={8} lg={8}>
                                <textarea  className="form-control ml-2" 
                                    name="employee_feedback"
                                    value={ state.item.employee_feedback}
                                    disabled
                                />
                                   
                                
                            </Grid>
                            <Grid item xs={12} md={4} lg={2}> <b className="ml-1">Self Evaluation:</b>
                                <span className="ml-2 ">{ state.item.employee_score}%</span> 
                            </Grid>
                        </Grid>} */}
                        {/* {( ( role.includes( 'MANAGER' ) || role.includes( 'L1' ) && status == 15 ) && ( util.user.employee_id === item.l1_evaluator ) ) &&
                        <Grid container spacing={2} className="mt-3">
                            <Grid item xs={12} md={12} lg={1}>
                                <b>L1 Feedback:</b></Grid>
                            <Grid item xs={12} md={8} lg={8} className="ml-2">
                                <textarea
                                    className="form-control" 
                                    name="l1_feedback"
                                    value={ state.item.l1_feedback || "" }
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={2}> <b className="ml-1">L1 Evaluation:</b>
                                <span className="ml-2 ">{ state.item.l1_score}%</span> 
                            </Grid>
                        </Grid>} */}
                        {/* {(  role.includes( 'L2' ) && ( util.user.employee_id === item.l2_evaluator ) ) &&
                        <Grid container spacing={2} className="mt-3">
                            <Grid item xs={12} md={12} lg={1}>
                                <b>L2 Feedback:</b></Grid>
                            <Grid item xs={12} md={8} lg={8} className="ml-2">  <textarea
                                className="form-control" 
                                name="l2_feedback"
                                value={ state.item.l2_feedback || "" }
                                disabled
                            />
                            </Grid>
                            <Grid item xs={12} md={4} lg={2}> <b className="ml-1">L2 Evaluation :</b>
                                <span className="ml-3 ">{ state.item.l2_score}%</span> 
                            </Grid>
                        </Grid>}
                        {(  role.includes( 'L3' ) && ( util.user.employee_id === item.l3_evaluator ) ) &&
                        <Grid container spacing={1} className="mt-3">
                            <Grid item xs={12} md={12} lg={1}>
                                <b>L3 Feedback:</b> </Grid>
                            <Grid item xs={12} md={8} lg={8} className="ml-2">  
                                <textarea
                                    className="form-control" 
                                    name="l3_feedback"
                                    value={ state.item.l3_feedback || "" }
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={2}> <b className="ml-1">L3 Evaluation :</b>
                                <span className="ml-3 ">{ state.item.l3_score}%</span> 
                            </Grid>
                        </Grid>} */}
                        {/* {(  role.includes( 'HR' ) && ( util.user.employee_id === item.hr_evaluator ) ) &&
                        <Grid container spacing={2} className="mt-3">
                            <Grid item xs={12} md={12} lg={1}>
                                <b>HR Feedback:</b></Grid>
                            <Grid item xs={12} md={8} lg={8} className="ml-2"> 
                                <textarea
                                    className="form-control" 
                                    name="l3_feedback"
                                    value={ state.item.hr_feedback || "" }
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={2}> <b className="ml-1">HR Evaluation :</b>
                                <span className="ml-3 ">{ state.item.hr_score}%</span> 
                            </Grid>
                        </Grid>} */}
                    </div>
                    
                </div>
            </Collapse>
        </div>
    );
};

export default ReviewDetailsAccordion ;
