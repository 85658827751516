import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import util from "../../../utils/miniUtils";
import { Collapse, Grid } from "@material-ui/core";
import EvaluatorInfo from "./EvaluatorInfo";

const FinalAppraisalAccordion = ( { item,l2_exist,l3_exist,collapse } ) => {
    const {
        type,
        gc_desc,
        gc_title,
        gc_target,
        weight,
        employee_name,
        employee_score,
        employee_feedback,
        l1_name,
        l2_name,
        l3_name,
        hr_name,
        l1_score,
        l2_score,
        l3_score,
        hr_score,
        l1_feedback,
        l2_feedback,
        l3_feedback,
        hr_feedback,
        general_score
    } = item;
    // const [state, setState] = useState( {
    //     expanded: false,
    //     item: item,
    //     itemBase: item,
    // } );
    const [isCollapse, setIsCollapse] = useState( collapse );
    const toggle = () => {
        setIsCollapse( !isCollapse );
       
    };
    const animate = collapse => {
        setIsCollapse( collapse );
       
    };
    
    useEffect( () => {
        animate( !collapse );
    }, [collapse] );
    // const expand = () => setState( { ...state, expanded: !state.expanded } );

    return (
        <div className="p-3 mb-2 Final">
            <div
                onClick={() => toggle()}
                className="row d-flex border appraisal-finalAccordion justify-content-between align-items-center isPointer"
            >
                <div style={{ flex:6 }}>
                    <span className="position-absolute">
                        <FontAwesomeIcon size="2x" className="my-icon-final" icon={isCollapse ? faCaretUp: faCaretDown} />
                    </span> 
                    <b className="ml-2">
                        {util.isGoalOrCompetence( type )}: 
                    </b><b className="text-secondary">{gc_title}</b>
                </div>
                <div style={{ flex:2 }}>Target:{gc_target}</div>
                <div className=" d-flex justify-content-end" style={{ flex:3 }}>Total Score:{general_score}%</div>
                <div style={{ flex:1 }}>
                    <div className=" d-flex justify-content-end">
                        <div>Weight:{weight}%</div>
                       
                    </div></div>
            </div>
            
            <Collapse in={isCollapse}>
                <div className="border ap_frame_border">
                    <Grid container spacing={2} className='mt-2 p-2'>
                        <Grid item xs={12} md={12} lg={1}>
                            <b>Description:</b>
                        </Grid>
                        <Grid item xs={12} md={12} lg={11}>
                            <div className="ml-4"> {gc_desc}</div>
                               
                        </Grid>
                    </Grid>
                    <div className="mt-1 p-2">
                        <EvaluatorInfo
                            evaluator="Appraisee"
                            name={employee_name}
                            score={employee_score}
                            feedback={employee_feedback}
                        />

                        <EvaluatorInfo
                            evaluator="L1 Evaluator"
                            name={l1_name}
                            score={l1_score}
                            feedback={l1_feedback}
                        />

                        {l2_exist ? ( <EvaluatorInfo
                            evaluator="L2 Evaluator"
                            name={l2_name}
                            score={l2_score}
                            feedback={l2_feedback}
                        /> ) : " "}

                        {l3_exist ? ( <EvaluatorInfo
                            evaluator="L3 Evaluator"
                            name={l3_name}
                            score={l3_score}
                            feedback={l3_feedback}
                        /> ) : "" }

                        <EvaluatorInfo
                            evaluator="HR Evaluator"
                            name={hr_name}
                            score={hr_score}
                            feedback={hr_feedback}
                        />
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

export default FinalAppraisalAccordion;
