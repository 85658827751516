import {  faCopy, faFilter, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MuiThemeProvider } from '@material-ui/core';
import MaterialTable from 'material-table';
import React from 'react';
import util from '../../../utils/miniUtils';
import YelloGrey from '../../../../assets/images/yellow-gry.png';
import Grey from '../../../../assets/images/grey.png';
import Green from '../../../../assets/images/green.png';
import GreenGrey from '../../../../assets/images/green-grey.png';
import RedGrey from '../../../../assets/images/red-grey.png';
import GreenYellow from '../../../../assets/images/green-yellow.png';
import User from "../../../../assets/images/user.png";
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import Filter from '../../shared/Filter';
import SweetAlert from 'react-bootstrap-sweetalert';
const tableRef = React.createRef();
export default function TeamMemberstable( { setFilterByStatus,setFilterData,filterData,filterByStatus, setSelectedITem, buttonDependingOnStatus, data,setLoading,getTeamAppraisal } ) {
    const [users, setUsers] = useState( [] );
    const [copyId,setCopyId]=useState();
    // console.log( data );
    const [selectedRow,setSelectedRow]=useState( [] );
    const [showFilter, setShowFilter] = useState( false );
    const [deleteTalentsShow,setDeleteTalentsShow]=useState( false );
    const [filterDepartament,setFilterDepartment] = useState( [] );
    const[filterPeriod,setFilterPeriod]  = useState( [] );

    useEffect( ()=>{
        tableRef.current && tableRef.current.onQueryChange( undefined );
    }, [filterData,filterByStatus] );
    const all = useRef();
    useEffect( () => {
        setUsers( data );
    }, [] );
    // Function to filter employees by status
    // const filterAppraisee = ( status,data ) => {
    //     if ( status === 'all' ) {
    //         return data;
    //     } else if ( status === 'completed' ) {
    //         return data.filter( user => user.status == 18 );
    //     }
    //     else if( status === 'pendingwithme' ){
    //         return data.filter( user => [null,0,2,3,5,7,9,11,14].includes( user.status ) ); 
    //     } else {
    //         return data.filter( user => user.status < 18 );
    //     }
    // };

    //function for copy dropdown select options
    const handleChange = ( e ) =>{
        const { name, checked } = e.target;
        let period = e.target.getAttribute( 'period' );
        let appraisal_id = e.target.getAttribute( 'appraisal_id' );

        if ( name === "allSelect" ) {
            // console.log( data );
            let tempUser = users.map( ( user ) => {
                return { ...user, isChecked: checked };
            } );
            setUsers( tempUser );
        } else {
            let tempUser = users.map( ( user ) =>
                user.name === name && user.period_id == period && user.appraisal_id == appraisal_id ? { ...user, isChecked: checked } : user
            );
            setUsers( tempUser );
        }

    };
    const copyTargets =  async(  )=>{
        const tempArray=[];
        users.map( ( element )=>{
            if( element.isChecked ){
                let temp={
                   
                    "appraisal_id": element.appraisal_id,
                    "employee_id": element.employee_id,
                    "period_id": element.period_id
                
                };
                tempArray.push( temp );


            }

        } );
       
        try {
            setLoading( true );
            const request = await util.axios.post( `/appraisal_items/copy_to/${copyId}`,tempArray );
            const { error,msg } = request.data;
            if ( error ) throw msg;
            tableRef.current && tableRef.current.onQueryChange( undefined );
            
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );

    };
    
    const urlHandler = ( query )  => {
        let q = `advanced_filter=${showFilter}&`;
        let search = query.search;
        const fixCheckedToUrlEncode = ( data = [], field, especial ) => {
            let pairs = [];
            data.map( ( value ) => {
                if ( value.checked === true ) {
                    // console.log( value );
                    let val = value.value;
                    if ( especial ) {
                        val = val === "Yes" ? 1: 0;
                    }
                    pairs.push( `${ field }=` + encodeURIComponent( val ) );
                }
            } );
            q+= pairs.join( "&" ) + "&";
        };
      
        fixCheckedToUrlEncode( filterData.department, 'department' );
        fixCheckedToUrlEncode( filterData.status, 'status' );
        fixCheckedToUrlEncode( filterData.bonus, 'bonus' );
        fixCheckedToUrlEncode( filterData.period, 'period' );
        
       
        console.log( filterByStatus  );
        return `${util.url}/get_team_appraisal_information?page=${query.page}&rows=${query.pageSize}&either=${filterData}&filterByStatus=${filterByStatus}&search=${search}&${q}`;
    };
    const deleteAppraisal = async () => {
        // console.log( 'delete clicked',selectedRow );
        try {
            if ( !selectedRow || !selectedRow.length ) {
            // Handle case where selectedRow is not defined or is empty
                return;
            }
      
            for ( const item of selectedRow ) {
                const response = await util.axios.put( `/appraisal/delete_appraisal/${item.appraisal_id}/${item.period_id}/${item.employee_id}` );
                const { error, msg } = response.data;
      
                if ( error ) {
                    util.toast().error( msg );
                } else {
                    util.toast().success( msg );
                }
            }
            setDeleteTalentsShow( false );
            getTeamAppraisal();
        } catch ( error ) {
            util.handleError( error );
            setDeleteTalentsShow( false );
        }
    };
      
    const getFilterData = async ()=>{
        try {
            const request = await util.axios.get( `/appraisal/filter` );
            const { error, msg, data, period } = request.data;
            
            const dataArray=data && data.map( ( item,key )=>{
                return {
                    value: item.itemName,
                    id: item.id
                };
            } );
            const periodData=period && period.map( ( item,key )=>{
                return {
                    id: item.id,
                    value: item.period
                };
            } );
            setFilterDepartment( dataArray );
            setFilterPeriod( periodData );
          

            if ( error ) throw msg;
           
        } catch ( error ) {
            util.handleError( error );
        }
    };

    
    useEffect( ()=>{
        getFilterData().then( null );
        // console.log( filter.department );
    },[] );
      
    // if ( loading ) {
    //     return  <Fragment>
    //         <div className="container-fluid p2 ">
    //             <div className="text-center">
    //                 <CircularProgress />
    //             </div>
    //         </div>
    //     </Fragment>;
    // }
    
    
    return <>
        <div className="row mt-3">
                   
            { showFilter && <Filter handleClose={()=> setShowFilter( false )} open={showFilter} reloadData={( d )=>setFilterData( d )} departmentArray={filterDepartament} periodArray= {filterPeriod} />}
            <div className={showFilter ? 'col-md-10' : 'col-md-12'}>
                <MuiThemeProvider theme={util.defaultTableTheme}>
       
                    <MaterialTable
                        tableRef={tableRef}
                        columns={[
                            {
                                title: "EmployeeName",
                                field: "employeeName",
                                render: ( rowData ) => {
                                    return <div> {rowData.name}</div>;
                                }
                            },
                            {
                                title: "Department",
                                field: "department",
                                render: ( rowData ) => {
                                    return <div> {rowData.department}</div>;
                                }
                            },
                            {
                                title: "Period",
                                field: "period",
                                render: ( rowData ) => {
                                    return <div> {rowData.period}</div>;
                                }
                            },
                            {
                                title: "Status",
                                field: "status",
                                // align:'center',
                                render: ( rowData ) => {
                                    return (
                                        <div className= {`${( rowData.l2_exist ||rowData.l3_exist ) ? "small_status" : "sm_status"} ${( rowData.l2_exist ||!rowData.l3_exist ) ? "sm_status" : "small_status"} d-flex justify-content-center align-items-center` }>

                                            <div
                                                onClick={() => rowData}
                                                title="status"
                                                className="d-flex p-1"
                                            >
                                                {( rowData.status == 0 || rowData.status == null ) &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                                                {rowData.status == 1 &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                                                {rowData.status == 3 &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                                                {( rowData.status == 2 || rowData.status == 4 || rowData.status == 6 || rowData.status == 8 || rowData.status == 10 || rowData.status == 12 || rowData.status == 5|| rowData.status == 7 || rowData.status == 9 || rowData.status == 11 ) &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                                                {rowData.status == 13 &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                                                {( rowData.status == 14 || rowData.status == 15 || rowData.status == 16 ||rowData.status == 17 || rowData.status == 18 ) &&
                                    <div>
                                        <span>
                                            <img
                                                className="appraisee  "
                                                alt=""
                                                src={User} />
                                        </span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Green} />
                                    </div>}
                                    
                                                {( rowData.status == 0 || rowData.status == null || rowData.status == 3 || rowData.status == 1  )&&
                                    <div>
                                        <span className="mb-1 ">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                                                {rowData.status == 5 &&
                                    <div>
                                        <span className="mb-1 ">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                                                {rowData.status == 2 &&
                                    <div>
                                        <span className="mb-1 ">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                                                {( rowData.status == 4 || rowData.status == 6 ||  rowData.status == 8 || rowData.status == 10  || rowData.status == 12 || rowData.status == 13 || 
                                    rowData.status == 7 || rowData.status == 9 || rowData.status == 11  ) &&
                                    <div>
                                        <span className="mb-1">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenGrey}/>
                                    </div>}
                                                {rowData.status == 14 &&
                                    <div>
                                        <span className="mb-1 ">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                                                {( rowData.status == 15 || rowData.status == 16 || rowData.status == 17 || rowData.status == 18 ) &&
                                    <div>
                                        <span className="mb-1 ">L1</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Green} />
                                    </div>}

                                                { ( rowData.l2_exist === true ) ? ( <div>
                                                    {( rowData.status == 0 || rowData.status == null || rowData.status == 3 || rowData.status ==5 || rowData.status == 1 || rowData.status == 2 )&&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                                                    {rowData.status == 7 &&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                                   

                                                    {rowData.status == 4 &&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                                                    {( rowData.status == 6 ||  rowData.status == 8 || rowData.status == 10 || rowData.status == 12|| rowData.status == 13 || 
                                    rowData.status == 9 || rowData.status == 11 || rowData.status == 14 ) &&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                                                    {rowData.status == 15 &&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                                                    {( rowData.status == 16 || rowData.status == 17 || rowData.status == 18 ) &&
                                    <div>
                                        <span className="mb-1 ">L2</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Green} />
                                    </div>} </div> ): ( "" ) }


                                                { ( rowData.l3_exist === true ) ? ( <div>
                                                    {( rowData.status == 0 || rowData.status == null ||  rowData.status == 3 || rowData.status ==5 || rowData.status==7 || rowData.status == 1 || rowData.status == 2 || 
                                    rowData.status == 4  )&&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                                                    {rowData.status ==6 &&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                                                    {rowData.status ==9 &&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                                                    {( rowData.status ==8 || rowData.status == 10 || rowData.status == 12 || rowData.status == 13 || rowData.status==11 || rowData.status == 14 || rowData.status == 15 ) &&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                                                    {rowData.status == 16 &&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                                                    {( rowData.status == 17 || rowData.status == 18 ) &&
                                    <div>
                                        <span className="mb-1 ">L3</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Green} />
                                    </div>}</div> ) : ( "" )}
                                    
                                                {( rowData.status == 0 ||  rowData.status == null || rowData.status == 3 || rowData.status ==5 || rowData.status==7 || rowData.status==9 || rowData.status == 1 || rowData.status == 2 || rowData.status == 4 || rowData.status ==6 )&&
                                    <div>
                                        <span className="mb-1 ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Grey} />
                                    </div>}
                                                {rowData.status == 8 &&
                                    <div>

                                        <span className="mb-1  ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={YelloGrey} />
                                    </div>}
                                                {rowData.status == 11 &&
                                    <div>

                                        <span className="mb-1  ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={RedGrey} />
                                    </div>}
                                                {( rowData.status == 10 || rowData.status == 12 || rowData.status == 13 || rowData.status == 14 || rowData.status == 15 || 
                                     rowData.status == 16 ) &&
                                    <div>

                                        <span className="mb-1  ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenGrey} />
                                    </div>}
                                                {rowData.status == 17 &&
                                    <div>

                                        <span className="mb-1  ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={GreenYellow} />
                                    </div>}
                                                {rowData.status == 18 &&
                                    <div>

                                        <span className="mb-1  ">HR</span>
                                        <img
                                            className="img"
                                            alt="status"
                                            src={Green} />
                                    </div>}
                                            </div>
                                        </div>
                                    );
                        
                                },
                            },
              
             
                            {
                                // title: "",
                                editable: "onUpdate",
                                // align:"center",
                                render: ( rowData ) => {
                                    const { status,employee_id }=rowData;
                                    // {console.log( status );}

                                    // let linkPath = "/";
                                    // switch ( status ) {
                                    //     case NO_APPRAISAL:
                                    //     case MANAGER_WORKING_ON_APPRAISAL:
                                    //         linkPath = "/set-targets";
                                    //         break;
                                    //     case WAITING_FOR_EMPLOYEE_APPROVAL:
                                    //     case EMPLOYEE_ACCEPTED:
                                    //     case L1_ACCEPTED:
                                    //     case L2_ACCEPTED:
                                    //     case L3_ACCEPTED:
                                    //     case HR_ACCEPTED:
                                    //     case WAITING_TIME:
                                    //         linkPath = "/check-targets";
                                    //         break;
                                    //     case EMPLOYEE_DECLINED:
                                    //     case L1_DECLINED:
                                    //     case L2_DECLINED:
                                    //     case L3_DECLINED:
                                    //     case HR_DECLINED:
                                    //         linkPath = "/set-targets";
                                    //         break;
                                    //     case EMPLOYEE_START_REVIEW:
                                    //     case L1_START_REVIEW:
                                    //     case L2_START_REVIEW:
                                    //     case L3_START_REVIEW:
                                    //     case HR_START_REVIEW:
                                    //         linkPath = "/start-review";
                                    //         break;
                                    //     case COMPLETED:
                                    //         linkPath = "/final-appraisal";
                                    //         break;
                                    //     default:
                                    //         linkPath = "/";
                                    //         break;
                                    // }
                                    return (
                                        <div className='d-flex'>
                                            <div className='text-center p-1'>
                                                {/* <Link to={linkPath} > */}
                                                {/* <a href={linkPath} target="_blank" rel="noopener noreferrer"> */}
                                                <button className='btn btn-sm px-2 customBtn' style={{ backgroundColor:rowData.buttonControl.buttonColor ,color:rowData.buttonControl.contentColor }}
                                                    // className={`btn btn-sm px-2 customBtn team_bt team statuses${ status && status}`} 
                                                    onClick={() => setSelectedITem( rowData )}>
                                                    {/* {buttonDependingOnStatus( rowData )} */}
                                                    {rowData.buttonControl.return_message}
                                                </button>
                                                {/* </Link> */}
                                                {/* </a> */}
                                            </div>

                               
                                            <div className='w-100'>
                                                <div className="dropdown">
                                                    <div type="text" id="dropdownMenuButton" data-toggle="dropdown" className={"mt-2"} aria-haspopup="false" aria-expanded="true" onClick={()=>setCopyId( rowData.appraisal_id )}>
                                                        <span className="ml-2 isCursor text-muted"><FontAwesomeIcon title='copy' icon={faCopy} /></span>
                                                    </div>
                                                    <div className="dropdown-menu d-menu ap_dropdown" aria-labelledby="dropdownMenuButton">
                                                        
                                                        <button className="dropdown-item">
                                                            <input
                                                                type="checkbox"
                                                                name="allSelect"
                                                                ref={all}
                                                                // checked={
                                                                //     users.filter( ( user ) => user?.isChecked !== true ).length < 1
                                                                // }
                                                                checked={!users.some( ( user ) => user?.isChecked !== true )}
                                                                onChange={handleChange}
                                                            />
                                                            <label className='ml-1'>Select All</label>
                                                        </button>
                                                        {users.filter( x=> x.appraisal_id !== rowData.appraisal_id ).map( ( user, index ) => (
                                                            <button className="dropdown-item"  key={index}>
                                                                <input
                                                                    type="checkbox"
                                                                    name={user.name}
                                                                    checked={user?.isChecked || false}
                                                                    period={user.period_id}
                                                                    appraisal_id={user.appraisal_id}
                                                                    value={user}
                                                                    // checked={rowData.appraisal_id === user.appraisal_id ? false : user?.isChecked || false}
                                                                    onChange={( e )=>handleChange( e,user )}
                                                                />
                                                                <label className='ml-1'>{user.name}-{user.period}</label> 
                                                            </button>
                                                        ) )}
                                                        <button  className="btn btn-success sizeBtn  ml-4" onClick={()=>{copyTargets(); }}>
                                            Paste
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            },
                        ]}
                    

                        data={query =>
                            util.axios.get( urlHandler( query, true ) )
                                .then( response => {
                                    const { data, error, total, page, msg } = response.data;
                                    if ( error ) throw msg;
                                    // console.log( data );
                                    setLoading( false );
                                    return ( {
                                        // data:filterAppraisee(filterByStatus,data)
                                        data: data,
                                        page: page,
					                    totalCount: total
                                        
                                    } );
                                } )
                                .catch( error=>{
                                    util.handleError( error );
                                } )
                        }
                        localization={{
                            toolbar:{
                                searchPlaceholder:"Search",
                                nRowsSelected: ""
                            }
                        }}
                        onSelectionChange={( rows ) => setSelectedRow( rows )}
                        options={{
                            searchFieldAlignment: "left",
                            selection: true,
                            showTitle: false,
                            pageSize: 50,
                            pageSizeOptions:[50,100,150],
                            emptyRowsWhenPaging: false,
                            columnsButton: true,
                            sorting: true,
                            padding: "default",
                            tableLayout: "auto",
                            toolbarButtonAlignment: "left",
                            headerStyle:{ position:'sticky',top:0 },
                            maxBodyHeight:'450px',
                            
                          
                        }}
                        actions={[
                            {
                                tooltip: "Remove All Selected Employees",
                                icon: () => <button className='btn btn-sm btn-danger'><FontAwesomeIcon icon={faTrash} className="mr-2" />
                           Delete</button>,
                                isFreeAction: false,
                                onClick: () => {
                                    setDeleteTalentsShow( true );
                                    // deleteCandidates(data)
                                },
                            },
               
                            {
                                icon: () =>
                                    <div className="btn btn-secondary sizeBtn activeBtn">
                                        <FontAwesomeIcon icon={faFilter} className="mr-2" />
                            Filter
                                    </div>
                                ,
                                position:"toolbar",
                                isFreeAction: true,
                                onClick: () => setShowFilter( !showFilter )
                            },
                            { icon: () =>
                                <>
                                    <label>
          

                                        <select className="form-control mt-1" value={filterByStatus} onChange={( e ) => setFilterByStatus( e.target.value )}>
                                            <option value="all">All Status</option>
                                            <option value="inprogress">In Progress</option>
                                            <option value="completed">Completed</option>
                                            <option value="pendingwithme">Pending With Me</option>
                                        </select></label>
                                </>
                            ,
                            position:"toolbar",
                            isFreeAction: true,
                            },
                            
                        ]} />
                </MuiThemeProvider>
            </div>
            {deleteTalentsShow && 
                                    <SweetAlert
                                        show={deleteTalentsShow}
                                        warning
                                        showCancel
                                        confirmBtnText="Yes, remove it!"
                                        confirmBtnBsStyle="danger"
                                        title="Are you sure?"
                                        onConfirm={() => deleteAppraisal()}
                                        onCancel={() => {
                                            setDeleteTalentsShow( false );
                                        }}
                                        focusCancelBtn
                                    />}
        </div>
        
    </>;
}