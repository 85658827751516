import React, { useEffect, useState } from 'react';
import { createTheme, MuiThemeProvider, Popover } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, } from '@fortawesome/free-solid-svg-icons';
import util from '../../../utils/miniUtils';
import { Modal } from 'react-bootstrap';

const TalentStatusSelector = ( { statusName, status, candidateId, positionId, getPositionCandidates, disabled=false ,data } ) => {
    console.log( data.ctc_settings.employmentType );
    const [statusList, setStatusList] = useState( [] );
    // Comment Modal related state variables
    const [commentModalShow, setCommentModalShow] = useState( false );
    const [state, setState] = useState( { status: status, comment: "" } );

    // Anchor and open variable for the popover. 
    const [anchorEl, setAnchorEl] = React.useState( null );
    const open = Boolean( anchorEl );

    // Triggering the popover on the double click
    const handleClick = ( event ) => {
        if( !disabled ) {
            setAnchorEl( event.currentTarget );
        }
    };

    const getStatusList = async () => {
        try{
            const request = await util.axios.get( 'job_talent_status/list' );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            setStatusList( data );
        } catch ( e ) {
            util.handleError( e );
        }
    };
    
    const handleChange = ( val ) => {
        // const { name, value } = e.target;
        setState( prev =>( { ...prev, status: val } ) );
        setCommentModalShow( true );
        handleClose();
    };

    const handleCommentChange = ( e ) => {
        const { name, value } = e.target;
        setState( prev =>( { ...prev, [name]: value } ) );
    };

    const handleClose = async ( ) => {
        setAnchorEl( null );
    };

    const handleCancel = () => {
        setState( { status: status, comment: "" } );
        // resetting the anchor for the popover
        setAnchorEl( null );
    };

    const theme = createTheme( {
        overrides: {
            MuiTooltip: {
                tooltip: {
                    maxWidth: "250px",
                    fontSize: "0.85em",
                }
            }
        }
    } );

    useEffect( () => {
        getStatusList();
    },[status] );

    function editValue() {
        return <Popover
            id={open ? 'text-input-popover' : undefined}
            open={open}
            anchorEl={anchorEl}
            onClose={handleCancel}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}>
            <div className='d-flex align-items-start flex-column'>
                <div className='d-flex justify-content-between w-100 align-items-center mb-1 p-1'>
                    <label htmlFor="status"><strong>Status</strong></label>
                    <span className='btn btn-outline-danger' onClick={handleCancel}><FontAwesomeIcon icon={faTimes} /></span>
                </div>
                <StatusDropDown list={statusList} value={state.status} handleChange={handleChange} />
                {/* <div className="d-flex align-items-end m-1">
                    <span className='btn btn-outline-danger' onClick={handleCancel}><FontAwesomeIcon icon={faTimes} /></span>
                    <span className='btn btn-outline-primary' onClick={handleClose}><FontAwesomeIcon icon={faCheck} /></span>
                </div> */}
            </div>

        </Popover>;
    }

    return <>
        <MuiThemeProvider theme={theme}>
            {editValue()}
            <div className='isPointer' onClick={handleClick}>
                {statusName ? statusName : util.candidateStatus( status )}
            </div>
        </MuiThemeProvider>
        <CommentModal            
            show={commentModalShow} 
            handleClose={() => setCommentModalShow( false )} 
            form={state}
            setForm={setState}
            candidateId={candidateId}
            positionId={positionId}
            handleChange={handleCommentChange}
            getPositionCandidates={getPositionCandidates}
            employmentType={data.ctc_settings.employmentType}
        />
    </>;
};

export default TalentStatusSelector;

const StatusDropDown = ( { list, value, handleChange } ) => {
    return <div className='talentStatusSelectorContainer'>
        <ul className="talentStatusSelector">
            {list ? list.map( el => ( el.is_parent ? 
                <li key={el.jts_id}
                    className={`talentStatusSelector-parentItem talentStatusSelectorItem ${el.jts_id == value ? ` bg-primary text-light` : ''}`} onClick={() => handleChange( el.jts_id )}>
                    {el.name}
                </li> :
                <li key={el.jts_id}
                    className={`talentStatusSelector-childItem talentStatusSelectorItem ${el.jts_id == value ? ` bg-primary text-light` : ''}`} onClick={() => handleChange( el.jts_id )}>
                    {el.name}
                </li>
            ) ) : ""}
        </ul>
    </div>;
};

const CommentModal = ( { show, handleClose, form, positionId, candidateId, handleChange, getPositionCandidates, setForm,employmentType } ) => {
    const [statusName, setStatusName] = useState( '' );
    // const [fileName, setFileName] = useState( '' );
    const [employment,setEmployment]=useState( "Fulltime Employee" );
    const [file, setFile] = useState( null );
    // const [c2cMsaFile, setC2cMsaFile] = useState( null );
    const [c2cSupplierFile, setC2cSupplierFile] = useState( null );

    const [description, setDescription] = useState( '' );
    const handleFileChange = ( e ) => {
        const selectedFile = e.target.files[0];
        if ( selectedFile ) {
            setFile( selectedFile );
        }
    };
    // const handleC2cMsaFileChange = ( e ) => {
    //     const selectedFile = e.target.files[0];
    //     if ( selectedFile ) {
    //         setC2cMsaFile( selectedFile );
    //     }
    // };
    
    const handleC2cSupplierFileChange = ( e ) => {
        const selectedFile = e.target.files[0];
        if ( selectedFile ) {
            setC2cSupplierFile( selectedFile );
        }
    };
    
    const handleEmploymentChange = ( e ) => {
        setEmployment( e.target.value );
    };
    const getStatusName = async ( id ) => {
        try{
            const request = await util.axios.get( `/job_talent_status/byId/${id}` );
            const { data, error, msg } = request.data;
            if( error ) throw msg;
            setStatusName( data[0]?.name ); 
            console.log( statusName );

        } catch ( e ) {
            util.handleError( e );
        }
    };

    const updateCandidateStatus = async( updatedData, position_id, candidate_id ) => {
        
        try {
            let mappedEmploymentType;
            if ( employmentType === 'Fulltime Employee' ) {
                mappedEmploymentType = 'Employment-agreement';
            } else if ( employmentType === 'Contractor' ) {
                mappedEmploymentType = 'Contractor-ic-agreement';
            } else if ( employmentType === 'Corp to Corp' ) {
                mappedEmploymentType = 'C2C Supplier  Task Order/ SOW';
            }else if( employmentType === 'Any' ){
                if ( employment === 'Fulltime Employee' ) {
                    mappedEmploymentType = 'Employment-agreement';
                } else if ( employment === 'Contractor' ) {
                    mappedEmploymentType = 'Contractor-ic-agreement';
                } else if ( employment
                     === 'Corp to Corp' ) {
                    mappedEmploymentType = 'C2C Supplier  Task Order/ SOW';
                }
            }
           
            console.log( updatedData );
            const dataWithFile = new FormData();
            dataWithFile.append( 'file', file );
            // if ( c2cMsaFile ) {
            //     dataWithFile.append( 'c2cMsaFile', c2cMsaFile );
            // }
            if ( c2cSupplierFile ) {
                dataWithFile.append( 'c2cSupplierFile', c2cSupplierFile );
            }
            dataWithFile.append( 'description', description );
            dataWithFile.append( 'employmentType', mappedEmploymentType );
            dataWithFile.append( 'updatedData', JSON.stringify( updatedData ) ); 
            // dataWithFile.append( 'updatedData',updatedData.comment );

            console.log( dataWithFile );
            const request = await util.axios.put( `/requirements/update_position_candidate/${position_id}/${candidate_id}`,dataWithFile , {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            } );

            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
            getPositionCandidates( position_id );
            setForm( prev => ( { status: prev.status, comment: "" } ) );
        } catch ( error ) {
            util.handleError( error );  
        }
        handleClose();
    };

    const handleSubmit = () => {
      
        updateCandidateStatus( form, positionId, candidateId );
        
    };

    useEffect( () => {
        if( show ) {
            getStatusName( form.status );
        }
    },[form.status] );

    return <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <span >Status will be updated to <strong>{statusName}</strong></span>
                <div>{statusName=== "Onboarded" ? ( <>
                    <span className='mt-2'>Employment Type:<b>{employmentType}</b></span>
                    <div>
                        {employmentType === 'Any' && (
                            <div>
                                <label className='mt-2' htmlFor='employmentTypeSelect'>
            Select Employment Type
                                </label>
                                <select
                                    id='employmentTypeSelect'
                                    className='form-control'
                                    value={employment}
                                    onChange={handleEmploymentChange}
                                >
                                    <option value='Fulltime Employee'>Fulltime Employee</option>
                                    <option value='Contractor'>Contractor</option>
                                    <option value='Corp to Corp'>Corp to Corp</option>
                                </select>

                                {employment === 'Fulltime Employee' && (
                                    <>
                                        <input type="file" id="fileInput" accept="application/pdf, application/msword" onChange={handleFileChange} style={{ display: 'none' }} />
                                        <button 
                                            className='btn btn-secondary activeBtn mt-2 float-right'
                                            onClick={() => document.getElementById( 'fileInput' ).click()}
                                        >
            Upload Employment Agreement 
                                        </button>
                                        {file && <span style={{ marginLeft: '10px' }}>{file.name}</span>}
                                    </>
                                )}
                                {employment === 'Contractor' && (
                                    <>
                                        <input type="file" id="fileInput" accept="application/pdf, application/msword" onChange={handleFileChange} style={{ display: 'none' }} />
                                        <button 
                                            className='btn btn-secondary activeBtn  mt-2 float-right'
                                            onClick={() => document.getElementById( 'fileInput' ).click()}
                                        >Upload Contractor(IC) Agreement </button>
                                        {file && <span style={{ marginLeft: '10px' }}>{file.name}</span>}</>
                                )}
                                {employment === 'Corp to Corp' && (
                                    <div>
                                        {/* <div>
                                            <input type="file" id="fileInput1" accept="application/pdf, application/msword" onChange={handleC2cMsaFileChange} style={{ display: 'none' }} />
                                            <button className='btn btn-secondary activeBtn mt-2 float-right ml-2'
                                                onClick={() => document.getElementById( 'fileInput1' ).click()}
                                            >C2C Supplier Agreement</button>
                                            {c2cMsaFile && <span style={{ marginLeft: '10px' }}>{c2cMsaFile.name}</span>}</div> */}
                                        <div>

                                            <input type="file" id="fileInput2" accept="application/pdf, application/msword" onChange={handleC2cSupplierFileChange} style={{ display: 'none' }} />

                                            <button className='btn btn-secondary activeBtn mt-2 float-right'
                                                onClick={() => document.getElementById( 'fileInput2' ).click()}
                                            >Upload C2C Supplier Task Order/SOW</button>
                                            {c2cSupplierFile && <span style={{ marginLeft: '10px' }}>{c2cSupplierFile.name}</span>}
                                        </div>

                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {employmentType === "Fulltime Employee" && (
                        <>
                            <input type="file" id="fileInput" accept="application/pdf, application/msword" onChange={handleFileChange} style={{ display: 'none' }} />
                            <button 
                                className='btn btn-secondary activeBtn'
                                onClick={() => document.getElementById( 'fileInput' ).click()}
                            >
           Upload Employment Agreement
                            </button>
                            {file && <span style={{ marginLeft: '10px' }}>{file.name}</span>}
                        </>
                    )}
                    {employmentType === "Contractor" && (
                        <>
                            <input type="file" id="fileInput" accept="application/pdf, application/msword" onChange={handleFileChange} style={{ display: 'none' }} />
                            <button 
                                className='btn btn-secondary activeBtn'
                                onClick={() => document.getElementById( 'fileInput' ).click()}
                            >Upload Contractor(IC) Agreement </button>
                            {file && <span style={{ marginLeft: '10px' }}>{file.name}</span>}</>
                    )}
                    {employmentType === "Corp to Corp" && (
                        <>
                            {/* <div>
                                <input type="file" id="fileInput1" accept="application/pdf, application/msword" onChange={handleC2cMsaFileChange} style={{ display: 'none' }} />
                                <button 
                                    className='btn btn-secondary activeBtn'
                                    onClick={() => document.getElementById( 'fileInput1' ).click()}
                                >Corp to Corp C2C Supplier Task Order/ SOW Upload</button>
                                {c2cMsaFile && <span style={{ marginLeft: '10px' }}>{c2cMsaFile.name}</span>}</div> */}
                            <div>
                                <input type="file" id="fileInput2" accept="application/pdf, application/msword" onChange={handleC2cSupplierFileChange} style={{ display: 'none' }} />
                                <button 
                                    className='btn btn-secondary activeBtn'
                                    onClick={() => document.getElementById( 'fileInput2' ).click()}
                                >Upload C2C Supplier  Task Order/SOW</button>
                                {c2cSupplierFile && <span style={{ marginLeft: '10px' }}>{c2cSupplierFile.name}</span>}
                            </div>
                        </>
                    )}</> ) : ""}</div>
                {statusName=== "Onboarded" ? <div className='mt-5'>
                    <label className='mt-5' htmlFor='descriptionInput'>Description</label>
                    <input type="text" id="descriptionInput" className="form-control" value={description} onChange={( e ) => setDescription( e.target.value )} />
                </div>:""}
                <div>
                    <label className='mt-3' htmlFor='comment-box'>Comment</label>
                    <textarea className="form-control" id="comment-box" name="comment" value={form.comment} onChange={handleChange}/>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <span className="btn btn-sm btn-danger" onClick={handleClose}>Close</span>
            <span className='btn btn-sm btn-success' onClick={handleSubmit}>Save</span>
        </Modal.Footer>
    </Modal>;
};
