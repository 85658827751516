import React, { Fragment, useEffect, useState } from "react";
// import "../assets/css/main.css";
import "../assets/css/global.css";
import "../assets/css/style.css";
// import "../assets/css/responsive.css";
// import "../assets/css/tableCustom.css";
import {
    Route,
    BrowserRouter as Router,
    Switch,
    NavLink,
    Redirect,
} from "react-router-dom";
import $ from "jquery";
import Dashboard from "./dashboard";
import Candidates from "./talents";
import CandidateDetail from "./talents/components/detailComponents/CandidateDetail";
import Requirements from "./jobs";
import NewRequirement from "./jobs/components/NewRequirement";
import PositionDetail from "./jobs/components/PositionDetail";
import AppSettings from "./settings/AppSettings";
import { bake_cookie, delete_cookie } from "sfcookies";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faBell,
    faKey,
    faSignOutAlt,
    faUser,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import roleRoutes from "./utils/navigationLinks";
import util from "./utils/miniUtils";
import { Dropdown } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField, Tooltip } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import axios from "axios";
import ListOfRequirements from "./jobs/components/jobLists/recruitment/ListOfRequirements";
import JobsListPartnerClient from "./jobs/components/jobLists/partner_client/JobsListPartnerClient";
import EvaluationsRole from "./evaluations/EvaluationsRole";
import AssetsManager from "./assets_manager/AssetsManager";
import AssignedAssets from "./assets_manager/AssignedAssets";
import EvaluationsByTalent from "./evaluations/EvaluationsByTalent";
import MyTalents from "./jobs/components/jobLists/partner_client/my_talents/MyTalents";
import DailyReport from "./dashboard/recruiter_performance/recruiter_report/DailyReport";
import CryptoJS from 'crypto-js';
import Workload from "./workload";
import { Appraisal } from "./AppraisalSystem/Appraisal";
// import ProfileComponent from "./shared/profile_component/ProfileComponent";
import EmployeeModule from "./settings/users/employees/EmployeeModule";
import NotificationModal from "./shared/CustomizeNotificactionModal/NotificationModal";
import NotificationDropdown from "./shared/CustomizeNotificactionModal/NotificationDropdown";
import Success from "./shared/profile_component/IntroVideo/Success";
import Employees from "./settings/users/employees";


function MainSection( props ) {
    const [showModal, setShowModal] = useState( false );
    const [section, setSection] = useState( "user_account" );
    const [showNotificationModal,setNotificationModal]=useState( false );

    function handleCloseNotificationModal() {
        setNotificationModal( false );
    }

    function logoutYa() {
        delete_cookie( "tokenServer" );
        props.logOut();
    }

    const deleteAccount = async() => {
        try {
            const request = await util.axios.get( `/delete_data` );
            const { error, msg } = request.data;
            util.logOut();
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };
    // console.log( util.user.role );
    function links() {
        switch ( util.user.role ) {
            case 1:
                return roleRoutes.recruiter();
            case 2:
                return roleRoutes.talent( util.user.candidate_id );
            case 3:
                return roleRoutes.client( util.user.id );
            case 4:
                return roleRoutes.partner( util.user.id );
            case 5:
                return roleRoutes.evaluator();
            case 6:
                return roleRoutes.recruiter_manager();
            case 7:
                return roleRoutes.employee();
            case 8:
                return roleRoutes.employee_manager();
            default:
                break;
        }
    }

    useEffect( () => {
        const main = document.getElementById( "main" );
        const initialWidth = window.innerWidth;

        if ( initialWidth < 500 ) {
            $( ".sidebarClass" ).toggleClass( "open" );
            $( "nav" ).toggleClass( "open" );
            main.style.width = "calc(100% - 220px)";
            main.style.width = "100%";
        }

        let width = window.innerWidth;

        $( ".sidebarClass a" ).on( "click", () => {
            if ( width < 500 ) {
                $( ".navbar-toggle" ).trigger( "click" );
            }
        } );
        $( function () {
            $( ".navbar-toggle" ).click( function () {
                $( ".sidebarClass" ).toggleClass( "open" );
                $( "nav" ).toggleClass( "open" );
                if ( width < 500 ) {
                    document.getElementById( "nav" ).className.search( "open" ) === -1
                        ? ( main.className = "invisible" )
                        : ( main.className = "visible" );
                } else {
                    document.getElementById( "nav" ).className.search( "open" ) === -1
                        ? ( main.style.width = "calc(100% - 220px)" )
                        : ( main.style.width = "calc(100% - 70px)" );
                }
            } );
        } );
    }, [] );

    function handleClose() {
        setShowModal( false );
    }

    async function changeRole( selectedRole ) {
        try {
            let role = 2;
            switch ( selectedRole ) {
                case "recruiter":
                    role = 1;
                    break;
                case "talent":
                    role = 2;
                    break;
                case "client":
                    role = 3;
                    break;
                case "partner":
                    role = 4;
                    break;
                case "evaluator":
                    role = 5;
                    break;
                case "recruiter_manager":
                    role = 6;
                    break;
                case "employee":
                    role = 7;
                    break;
                case "employee_manager":
                    role = 8;
                    break;
                default:
                    break;
            }
            let u = util.user;
            u.role = role;

            const request = await axios.put( `${util.url}/auth/change_user_role`, {
                user: u,
            } );
            const { error, msg, accessToken } = request.data;
            if ( error ) throw msg;
            bake_cookie( "tokenServer", accessToken );
            window.history.replaceState( "", "", "/" );
            window.location.reload();
        } catch ( e ) {
            util.handleError( e );
        }
    }

    function AvailableRole( { selectedRole, role } ) {
        if ( util.user.permissions[selectedRole] === 1 ) {
            return (
                <Dropdown.Item>
                    <div
                        onClick={() =>
                            changeRole( selectedRole, util.user.permissions.recruiter )
                        }
                    >
                        {role}
                    </div>
                </Dropdown.Item>
            );
        } else {
            return "";
        }
    }
    
    return (
        <Fragment>
            <Router>
                <nav id="nav" className="navbar navbar-default navbar-fixed">
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <button
                                type="button"
                                className="navbar-toggle collapsed"
                                data-toggle="collapse"
                                data-target="#bs-example-navbar-collapse-1"
                                aria-expanded="false"
                            >
                                <FontAwesomeIcon color="#757575" icon={faBars} />
                            </button>
                        </div>
                        <div className="d-inline-flex">
                            {/* <img src={"/images/KR_OTIS.png"} height={'20px'} className="desktop" alt="logo"/> */}
                            {util.user.role === 2 ? "": <NotificationDropdown />}
                            <div>
                                <span className="text-black-50 mr-3">
                                    {util.humanRole( util.user.role )}
                                </span>
                            </div>
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant="secondary"
                                    size="sm"
                                    id="dropdown-basic"
                                >
                                    <FontAwesomeIcon color="#007bff" icon={faUser} />
                                    <span className="ml-1 dropDownBtnText">
                                        {" "}
                      Hi, {util.user.name}
                                    </span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="mainDropdown">
                                    <AvailableRole role="Recruiter" selectedRole="recruiter" />
                                    <AvailableRole role="Talent" selectedRole="talent" />
                                    <AvailableRole role="Client" selectedRole="client" />
                                    <AvailableRole role="Partner" selectedRole="partner" />
                                    <AvailableRole role="Evaluation" selectedRole="evaluator" />
                                    <AvailableRole role="Employee" selectedRole="employee" />
                                    {/*<AvailableRole role="Employee Manager" selectedRole="employee_manager" />*/}
                                    <AvailableRole
                                        role="Recruitment Manager"
                                        selectedRole="recruiter_manager"
                                    />
                                    <Dropdown.Divider />
                                    <Dropdown.Item>
                                        <div onClick={() => setNotificationModal( true )}>
                                            <FontAwesomeIcon icon={faBell} className="mr-2" /> Notifications
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item>
                                        <div onClick={() => setShowModal( true )}>
                                            <FontAwesomeIcon icon={faKey} className="mr-2" /> Change
                        password
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item>
                                        <div onClick={()=>{deleteAccount();}}>
                                            <FontAwesomeIcon icon={faTrash} className="mr-2" />{" "}
                        Delete Account
                                        </div>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <div onClick={logoutYa}>
                                            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />{" "}
                        Logout
                                        </div>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </nav>
                <div className="sidebarClass">
                    <div className="logo w-100">
                        <a href="/">
                            <img
                                src="/images/demo_logo.jpg"
                                className="desktop"
                                alt="eJRekruit"
                                height={'50px'} style={{ paddingTop: '20px' }}
                            />
                            <img
                                src="/images/icon.png"
                                className="mobile"
                                alt="logo"
                                height={'50px'}
                            />
                        </a>
                    </div>
                    <ul>
                        {links() &&
                links().map( ( item ) => {
                    switch ( item.url ) {
                        case "/":
                            // Only visible for talents
                            return (
                                <li key={item.title}>
                                    <a href={item.url}>
                                        <i
                                            className={item.title + " icon"}
                                            style={{
                                                backgroundImage:
                                  "url('/images/" + item.icon + ".svg')",
                                                color: "gray"
                                            }}
                                        />

                                        <Tooltip title={item.desc ? item.desc : ""}>
                                            <span>{item.title}</span>
                                        </Tooltip>
                                    </a>
                                </li>
                            );
                        default:
                            // Normal view
                            return (
                                <li key={item.title}>
                                    <NavLink to={item.url}>
                                        <i
                                            className={item.title + " icon"}
                                            style={{
                                                backgroundImage:
                                  "url('/images/" + item.icon + ".svg')",
                                                color: "gray"
                                            }}
                                        />
                                        <Tooltip title={item.desc ? item.desc : ""}>
                                            <span>{item.title}</span>
                                        </Tooltip>
                                    </NavLink>
                                </li>
                            );
                    }
                } )}
                    </ul>
                    <div className="versionOfApp">App Version v1.2.25</div>
                </div>
                <main id="main" className="main">
                    <div className="container-fluid">
                        <Switch>
                            {util.user.role === 1 ? (
                                <Fragment>
                                    <Route exact path="/">
                                        <Redirect to={"/jobs"} />
                                    </Route>
                                    <Route exact path="/auth">
                                        <Redirect to={"/jobs"} />
                                    </Route>

                                    <Route
                                        exact
                                        path="/my-recruitment"
                                        component={DailyReport}
                                    />
                                    <Route exact path="/jobs" component={ListOfRequirements} />
                                    <Route exact path="/jobs/new" component={NewRequirement} />
                                    <Route
                                        exact
                                        path="/jobs/position/detail/:id"
                                        component={PositionDetail}
                                    />
                                    <Route exact path="/candidates" component={Candidates} />
                                    <Route exact path="/success" component={Success} />
                                    <Route
                                        exact
                                        path="/candidates/:id"
                                        component={CandidateDetail}
                                    />
                                    <Route exact path="/employees" component={Employees} />
                                    <Route
                                        exact
                                        path="/profile/:id"
                                        render={() => <div>User profile</div>}
                                    />

                                    <Route exact path="/assets" component={AssetsManager} />
                                    <Route exact path="/appraisal" component={Appraisal} />
                                    <Route exact path="/workload" component={Workload} />

                                    {/* 
                                    
                                    {console.log( "eeeeee" )}
                                    <Route exact path="/my_workload/:any" component={Workload} />
                                    {/* <Route exact path="/workload/new" component={NewWorkload} /> */}
                                    {/*<Route exact path="/*"><Redirect to={"/jobs"}/></Route>*/}
                                </Fragment>
                            ) : (
                                ""
                            )}
                            {util.user.role === 2 && util.user.permissions.employee === 1 ? (
                                <Fragment>
                                    <Route exact path="/">
                                        <Redirect to={"/jobs"} />
                                    </Route>
                                    <Route exact path="/talent/profile">
                                        <Redirect to={"/jobs"} />
                                    </Route>
                                    <Route exact path="/jobs" component={Requirements} />
                                    <Route
                                        exact
                                        path="/profile/:id"
                                        component={CandidateDetail}
                                    />
                                    <Route exact path="/appraisal" component={Appraisal} />
                                </Fragment>
                            ) : (
                                ""
                            )}
                            {util.user.role === 3 || util.user.role === 4 ? (
                                <Fragment>
                                    <Route exact path="/">
                                        <Redirect to={"/my_jobs"} />
                                    </Route>
                                    <Route
                                        exact
                                        path="/my_jobs"
                                        component={JobsListPartnerClient}
                                    />
                                    <Route exact path="/my_talents" component={MyTalents} />
                                </Fragment>
                            ) : (
                                ""
                            )}
                            {util.user.role === 5 ? (
                                <Fragment>
                                    <Route exact path="/">
                                        <Redirect to={"/evaluations"} />
                                    </Route>
                                    <Route
                                        exact
                                        path="/evaluations/"
                                        component={EvaluationsRole}
                                    />
                                    <Route
                                        exact
                                        path="/evaluations_by_talents/"
                                        component={EvaluationsByTalent}
                                    />
                                    <Route exact path="/workload" component={Workload} />
                                    <Route exact path="/appraisal" component={Appraisal} />

                                </Fragment>
                            ) : (
                                ""
                            )}
                            {util.user.role === 6 ? (
                                <Fragment>
                                    <Route exact path="/">
                                        <Redirect to={"/jobs"} />
                                    </Route>
                                    <Route exact path="/dashboard" component={Dashboard} />
                                    <Route exact path="/jobs" component={ListOfRequirements} />
                                    <Route exact path="/jobs/new" component={NewRequirement} />
                                    <Route
                                        exact
                                        path="/jobs/position/detail/:id"
                                        component={PositionDetail}
                                    />
                                    <Route exact path="/candidates" component={Candidates} />
                                    <Route
                                        exact
                                        path="/candidates/:id"
                                        component={CandidateDetail}
                                    />
                                    <Route exact path="/settings" component={AppSettings} />
                                    <Route exact path="/assets" component={AssetsManager} />
                                    <Route
                                        exact
                                        path="/my_assigned_assets"
                                        component={AssignedAssets}
                                    />
                                    <Route exact path="/workload" component={Workload} />
                                    <Route exact path="/my_workload/:any" component={Workload} />
                                    <Route exact path="/appraisal" component={Appraisal} />
                                    <Route exact path="/employees" component={Employees} />

                                    {/* <Route exact path="/workload/new" component={NewWorkload} /> */}
                                </Fragment>
                            ) : (
                                ""
                            )}
                            {/* {util.user.role === 7 ? ( */}
                            {/* <Fragment> */}
                            {/* <Route exact path="/jobs" component={ListOfRequirements}/>
                                    <Route exact path="/jobs/new" component={NewRequirement}/>
                                    <Route exact path="/jobs/position/detail/:id" component={PositionDetail}/>
                                    <Route exact path="/candidates" component={Candidates}/>
                                    <Route exact path="/candidates/:id" component={CandidateDetail}/>
                                    <Route exact path="/settings" component={AppSettings}/>
                                    <Route exact path="/assets" component={AssetsManager}/>
                                    <Route exact path="/my_assigned_assets" component={AssignedAssets}/>
                                    <Route exact path="/workload" component={Workload} />
                                    <Route exact path="/my_workload/:any" component={Workload} /> */}
                            {/* <Route exact path="/workload/new" component={NewWorkload} /> */}
                            {/* </Fragment> ) : ""} */}
                            {util.user.role === 7 ? (
                                <Fragment>
                                    <Route path="/">
                                        <Redirect to={"/profile/"} />
                                    </Route>
                                    <Route
                                        exact
                                        path="/profile/"
                                        // component={CandidateDetail}
                                    >
                                        <EmployeeModule employee_id={util.user.employee_id} setSection={setSection} modal={false}/>
                                    </Route>
                                    <Route exact path="/assets" component={AssetsManager} />
                                    <Route exact path="/workload" component={Workload} />
                                    <Route exact path="/my_workload/:any" component={Workload} />
                                    <Route exact path="/appraisal" component={Appraisal} />
                                </Fragment>
                            ) : (
                                ""
                            )}
                            {util.user.role === 8 ? (
                                <Fragment>
                                    <Route exact path="/">
                                        <Redirect to={"/jobs"} />
                                    </Route>
                                    <Route exact path="/jobs" component={ListOfRequirements} />
                                    <Route exact path="/jobs/new" component={NewRequirement} />
                                    <Route
                                        exact
                                        path=""
                                        component={PositionDetail}
                                    />
                                    <Route exact path="/candidates" component={Candidates} />
                                    <Route
                                        exact
                                        path="/candidates/:id"
                                        component={CandidateDetail}
                                    />
                                    <Route exact path="/workload" component={Workload} />
                                    <Route exact path="/appraisal" component={Appraisal} />
                                    <Route exact path="/my_workload/:any" component={Workload} />
                                    <Route exact path="/settings" component={AppSettings} />
                                    <Route exact path="/employees" component={Employees} />
                                </Fragment>
                            ) : (
                                ""
                            )}
                        </Switch>
                    </div>
                </main>
            </Router>
            <ChangePasswordModal
                show={showModal}
                handleClose={handleClose}
                backDropOpen={() => null}
                backDropClose={() => null}
            />
            <NotificationModal 
                show={showNotificationModal}
                handleClose={handleCloseNotificationModal}
            />
        </Fragment>
    );
}

export default MainSection;

function ChangePasswordModal( props ) {
    const [currentPassword, setCurrentPassword] = useState( "" );
    const [password, setPassword] = useState( "" );
    const [passwordConfirm, setPasswordConfirm] = useState( "" );
    const [loading, setLoading] = useState( false );

    async function changePasswordRequest() {
        try {
            if ( currentPassword === "" || password === "" || passwordConfirm === "" ) {
                util.toast().warn( "Please fill all the fields" );
                return;
            }
            if ( password.length <= 7 || passwordConfirm.length <= 7 ) {
                util.toast().warn( "Password must at least 8 characters." );
                return;
            }
            if ( password !== passwordConfirm ) {
                util
                    .toast()
                    .warn( "New password and password confirmation are not equal" );
                return;
            }
            props.backDropOpen();
            setLoading( true );
            let data = {
                user_id: util.user.id
            };
            // data.password = new Buffer( password ).toString( 'base64' );
            // data.current_password = new Buffer( currentPassword ).toString( 'base64' );
            data.password = CryptoJS.AES.encrypt( password, process.env.REACT_APP_PASS_KEY ).toString();
            data.current_password = CryptoJS.AES.encrypt( currentPassword, process.env.REACT_APP_PASS_KEY ).toString();
            const request = await util.axios.put(
        `${util.url}/users/update_password`,
        data
            );
            const { error, msg } = request.data;
            util.toast().success( msg );
            if ( error ) throw msg;
            props.backDropClose();
            setLoading( false );
            // // Clean the fields
            setCurrentPassword( "" );
            setPassword( "" );
            setPasswordConfirm( "" );
        } catch ( e ) {
            props.backDropClose();
            setLoading( false );
            util.handleError( e );
        }
    }

    function onChangeHandler( e ) {
        const { name, value } = e.target;
        if ( name === "currentPassword" ) setCurrentPassword( value );
        if ( name === "password" ) setPassword( value );
        if ( name === "passwordConfirm" ) setPasswordConfirm( value );
    }

    return (
        <Fragment>
            <Dialog
                open={props.show}
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Change your password"}
                </DialogTitle>
                {loading ? (
                    <div style={{ width: "100%", height: "100px" }}>
                        <p>Changing password</p>
                    </div>
                ) : (
                    <div>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                This action will send you a email with a reset password link. We
                will send this email to the registered account.
                            </DialogContentText>
                            <div className="text-center">
                                <TextField
                                    label="Current password"
                                    type="password"
                                    className="w-50"
                                    name="currentPassword"
                                    value={currentPassword}
                                    onChange={onChangeHandler}
                                />
                            </div>
                            <div className="text-center">
                                <TextField
                                    label="New Password"
                                    type="password"
                                    className="w-50"
                                    name="password"
                                    value={password}
                                    onChange={onChangeHandler}
                                />
                            </div>
                            <div className="text-center">
                                <TextField
                                    label="Password confirmation"
                                    type="password"
                                    className="w-50"
                                    name="passwordConfirm"
                                    value={passwordConfirm}
                                    onChange={onChangeHandler}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={props.handleClose} color="default">
                Cancel
                            </Button>
                            <button
                                onClick={() => changePasswordRequest()}
                                className="btn btn-md btn-primary"
                            >
                Change Password
                            </button>
                        </DialogActions>
                    </div>
                )}
            </Dialog>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}
