
import React, { Fragment, useEffect, useState } from 'react';
//import { Collapse } from '@material-ui/core';
import CheckBoxSelector from "../../shared/checkboxSelector";
import util from "../../utils/miniUtils";
import CheckBoxStatusSelector from './CheckboxStatusSelector';

const statusList=util.fixStatusList();



const Filter = ( { reloadData,departmentArray,periodArray } ) => {


    const [filter, setFilter] = useState( {
        ready: false,
        loading: true,
        department: departmentArray,
        bonus:[{ value:!null,label:"Yes",id:1 },{ value:null,label:"No",id:2 }],
        status:statusList,
        period:periodArray,
    } );

   
    useEffect( ()=>{
        // console.log( "Send new filter values to parent component" );
        if ( filter.ready ) {
            // console.log( 'query new data due changes' );
            reloadData( filter );
        }
    }, [filter] );
    // console.log( filter );
    // if ( filter.loading ) {
    //     return util.LOADING_SCREEN( filter.loading );
    // }
    return ( 
        <Fragment >
            <div className="col-md-2">
               
                <CheckBoxSelector
                    isOpenByDeafult={true}
                    dataArray={filter.department}
                    title="Departments"
                    updateData={( data ) =>
                        setFilter( { ...filter,department: data, ready: true } )
                    }
                />
                <CheckBoxSelector
                    isOpenByDeafult={false}
                    dataArray={filter.period}
                    title="Periods"
                    updateData={( data ) =>
                        setFilter( { ...filter, period: data, ready: true } )
                    }
                />
                
                <CheckBoxStatusSelector
                    dataArray={filter.bonus}
                    title="Bonus"
                    updateData={( data ) =>
                        setFilter( { ...filter, bonus: data, ready: true } )
                    }
                /> 

                <CheckBoxStatusSelector
                    dataArray={filter.status}
                    title="Status"
                    updateData={( data ) =>
                        setFilter( { ...filter, status: data, ready: true } )
                    }
                /> 

              

                
            </div>
        </Fragment>
    );
};
 
export default Filter;