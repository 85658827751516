import { faAngleDown, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import util from "../../utils/miniUtils";
import { useDispatch, useSelector } from "react-redux";
import Swal from 'sweetalert2';
import { setFilter, setFrom, setTo } from "../../../redux/actions/datefilter";
import useGetCharts from "./ChartComponent";


const DashboardFeatures = ( { selectedReport, setSelectedReport, reportNames,layouts, setLayouts, setAvailableCharts, newReport, setNewReport, setReportNames } ) =>{
    const dropdownRef = useRef( null );
    const { from, to } = useSelector( ( state ) => state.filter ) || {};
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState( false );
    let chartComp = useGetCharts();
    const accountId = util.user.id;
    
    const updateLayoutData = async ( accountId, layoutData, layoutName ) => {
        
        try {
            const request = await util.axios.put( `${util.url}/new_dashboard/update_layout_data`,{ account_id: accountId ,layout_data:  layoutData , report_name: layoutName, toDate: to, fromDate: from } );
            const { error, msg } = request.data;
            // setReportName( ( prevReportNames ) => [...prevReportNames, layoutName] );
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const handleUpdateLayoutClick = ( newLayout ) =>{
        setLayouts( newLayout );
        updateLayoutData( accountId, newLayout, selectedReport );
        Swal.fire( 'Layout Updated', 'The layout has been successfully updated.', 'success' );  
    };

    const saveLayout = async ( accountId, layoutData, layoutName ) => {
        
        try {
            const request = await util.axios.post( `${util.url}/new_dashboard/post_layout_data`,{ account_id: accountId ,layout_data:  layoutData , report_name: layoutName, toDate: to, fromDate: from } );
            const { error, msg } = request.data;
            setReportNames( ( prevReportNames ) => [...prevReportNames, layoutName] );
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const handleSaveLayoutClick = async( newLayout ) => {
        
        const { value: layoutName } = await Swal.fire( {
            input: 'text',
            inputPlaceholder: 'Enter layout name...',
            inputAttributes: {
                'aria-label': 'Type your Layout Name'
            },
            showCancelButton: true,
            preConfirm: ( value ) => {
                if ( !value.trim() ) {
                    Swal.showValidationMessage( 'Layout name cannot be empty' );
                }
            }
        } );
          
        if ( layoutName ) {
            const isLayoutExist = reportNames.some( ( report ) => report === layoutName );
            if ( isLayoutExist ) {
                Swal.fire( {
                    icon: 'error',
                    title: 'Layout Name Already Exists',
                    text: 'The layout name already exists. Please use a different name.',
                } );
            } else {
                setLayouts( newLayout );
                saveLayout( accountId, newLayout, layoutName );
                Swal.fire( 'Layout Saved', 'The layout has been successfully saved.', 'success' );
            }
        }
    };
    const toggleDropdown = () => {
        setIsOpen( !isOpen );
    };
    useEffect( () => {
        const handleClickOutside = ( event ) => {
            if ( dropdownRef.current && !dropdownRef.current.contains( event.target ) ) {
                setIsOpen( false );
            }
        };
      
        document.addEventListener( "click", handleClickOutside );
      
        return () => {
            document.removeEventListener( "click", handleClickOutside );
        };
    }, [] );
    const handleCreateReport = () =>{
        setNewReport( true );
        setSelectedReport( '' );
        setAvailableCharts( chartComp );
        setLayouts( [] );
        setIsOpen( false );
        
    };
    const addLayout = () =>{
        setNewReport( true );
        setSelectedReport( '' );
        setAvailableCharts( chartComp );
        setLayouts( [] );
        dispatch( setTo( '' ) );
        dispatch( setFrom( '' ) );
        dispatch( setFilter( false ) );

    };
    const deleteLayout= async (  accountId, layoutName ) => {
        
        try {
            
            const request = await util.axios.delete( `${util.url}/new_dashboard/delete_layout?account_id=${accountId}&report_name=${layoutName}` );
            const { error, msg } = request.data;
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const handleDelete = async ( layoutName ) => {
        try {
            const result = await Swal.fire( {
                title: "Confirm Deletion",
                text: "Are you sure you want to delete this report?",
                // icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Delete",
            } );
            
            if ( result.value ) {
                deleteLayout( accountId, layoutName );
                setSelectedReport( "" );
                setLayouts( [] );
                setReportNames( ( prevReportNames ) =>
                    prevReportNames?.filter( ( report ) => report !== layoutName )
                );
      
                Swal.fire( "Deleted", "The report has been deleted.", "success" );
            }
        } catch ( error ) {
            Swal.fire( "Error", "An error occurred while deleting the report.", "error" );
        }
    };
    return (
        <>
            
            <span className="m-2" onClick={addLayout}>
                <FontAwesomeIcon className="text-primary" size="2x" icon={faPlusCircle} />
            </span>
            <div className="d-flex align-items-center dashboard-report">
                    
                <div className="custom-dropdown" ref={dropdownRef}>
                    <div className="selected-option" onClick={toggleDropdown}>
                        {selectedReport ? selectedReport : 'Select a Report'}
                        <FontAwesomeIcon icon={faAngleDown} className='icon'/>
                    </div>
                    {isOpen && (
                        <ul className="dropdown-list">
                            {( selectedReport !== "new report" && <li
                                className={`dropdown-option ${selectedReport === 'new report' ? 'selected' : ''}`}
                                onClick={() => handleCreateReport()}
                            >
        new report
                            </li> )}
                            {reportNames.map( ( reportName, i ) => (
                                <li
                                    key={i}
                                    className={`dropdown-option ${selectedReport === reportName ? 'selected' : ''}`}
                                    onClick={() => { setSelectedReport( reportName ); setIsOpen( false ); }}
                                >
                                    {reportName}
                                    <button 
                                        className="delete-option"
                                        onClick={( e ) => {
                                            e.stopPropagation(); // Stop event propagation here
                                            handleDelete( reportName );
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </li>
                            ) )}
                        </ul>
                    )}
                </div>

                { selectedReport === undefined ||  selectedReport === '' || selectedReport === 'new report' ?
                    <span
                        className="btn btn-secondary sizeBtn activeBtn"
                        onClick={()=> handleSaveLayoutClick( layouts )}>
                        Save
                    </span>:
                    <span
                        className="btn btn-secondary sizeBtn activeBtn"
                        onClick={()=> handleUpdateLayoutClick( layouts )}>
                        Update
                    </span>
                }
            </div>
                
            
        </>
    );
};

export default DashboardFeatures;