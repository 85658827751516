import React, { useState, useEffect } from "react";
import util from "../../utils/miniUtils";
import AllStatus from '../shared/Status/AllStatus';
import SetTargets from '../shared/SetTargets/SetTargets';
import { MANAGER_WORKING_ON_APPRAISAL, WAITING_FOR_EMPLOYEE_APPROVAL,EMPLOYEE_START_REVIEW, WAITING_TIME,EMPLOYEE_ACCEPTED,EMPLOYEE_DECLINED,L1_ACCEPTED, L1_DECLINED, L2_ACCEPTED, L2_DECLINED, L3_ACCEPTED, L3_DECLINED,  L1_START_REVIEW, L2_START_REVIEW, L3_START_REVIEW,HR_ACCEPTED,HR_DECLINED,HR_START_REVIEW, COMPLETED, NO_APPRAISAL } from "../../../constants";
import StartReview from "../shared/StartReview/StartReview";
import CheckTargets from "../shared/CheckTargets/CheckTargets";
import TeamMembersTable from "./components/TeamMemberstable";
import FinalAppraisal from "../shared/FinalAppraisal/FinalAppraisal";



const TABLE_OF_TEAM_MEMBERS = 1;
const DISPLAY_SET_TARGETS = 2;
const DISPLAY_CHECK_TARGETS = 3;
const DISPLAY_START_REVIEW = 4;
const DISPLAY_APPRAISAL_RESULT=5; 

function TeamAppraisal( { role } ) {
   
    //Hooks for which component should display
    const [detailData, setDetailData] = useState( {
        appraisal_id: null,
        employee_id: null,
        name: null,
        componentToMount: TABLE_OF_TEAM_MEMBERS,
    } );
    const [ loading, setLoading ] = useState( false );
    const [data, setData] = useState( [] );
   
    const [filterData, setFilterData] = useState( {

    } );
    const [filterByStatus, setFilterByStatus] = useState( "all" );

    // Show button in teamappraisal table based on status

    const buttonDependingOnStatus = ( rowData ) => {
        const { status, appraisal_id } = rowData;
        // console.log( rowData );
        if ( appraisal_id === null ) return "Set Targets";
        switch ( status ) {
            case NO_APPRAISAL: return "Set Targets";//0
            case MANAGER_WORKING_ON_APPRAISAL: return "Set Targets";//0
            case WAITING_FOR_EMPLOYEE_APPROVAL: return "With Appraisee";//1
            case EMPLOYEE_ACCEPTED: return "L1 Review Targets";//2
            case EMPLOYEE_DECLINED: return "Edit Declined Targets"; // 3 render set target component
            case L1_ACCEPTED: return "L2 Targets Review";//4
            case L1_DECLINED: return "Edit Declined Targets";// 5render set target component
            case L2_ACCEPTED: return "L3 Tragets Review";//6
            case L2_DECLINED: return "Edit Declined Targets";//7 render set target component
            case L3_ACCEPTED: return "HR Targets Review";//8
            case L3_DECLINED: return "Edit Declined Targets";//9 render set target component
            case HR_ACCEPTED: return "View Targets";//10
            case HR_DECLINED: return "Edit Declined Targets";//11 render set target component
            case WAITING_TIME: return "View Targets"; // 12 Read only component
            case EMPLOYEE_START_REVIEW: return "With Appraisee";//13
            case L1_START_REVIEW: return "L1 Evaluation";//14
            case L2_START_REVIEW: return "L2 Evaluation";// Read only component//15 
            case L3_START_REVIEW: return "L3 Evaluation";// Read only component//16
            case HR_START_REVIEW: return "HR Evaluation";// Read only component//17
            case COMPLETED: return "Completed";//18
            default:
                return "No Appraisal";
        }
    };

    //show component based on status changing
    const setSelectedItem = ( rowData ) => {
        const { status, appraisal_id } = rowData;
        if ( appraisal_id === null ) return createAppraisalForEmployee( rowData );
        switch ( status ) {
            case MANAGER_WORKING_ON_APPRAISAL:
                updateDetailInfo( rowData, DISPLAY_SET_TARGETS );
                break;
            case WAITING_FOR_EMPLOYEE_APPROVAL:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            case EMPLOYEE_ACCEPTED:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            case EMPLOYEE_DECLINED:
                updateDetailInfo( rowData, DISPLAY_SET_TARGETS );
                break;
            case L1_ACCEPTED:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            case L1_DECLINED:
                updateDetailInfo( rowData, DISPLAY_SET_TARGETS );
                break;
            case L2_ACCEPTED:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            case L2_DECLINED:
                updateDetailInfo( rowData, DISPLAY_SET_TARGETS );
                break;
            case L3_ACCEPTED:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            case L3_DECLINED:
                updateDetailInfo( rowData,  DISPLAY_SET_TARGETS );
                break;
            case HR_ACCEPTED:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            case HR_DECLINED:
                updateDetailInfo( rowData,  DISPLAY_SET_TARGETS );
                break;
            case WAITING_TIME:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            case EMPLOYEE_START_REVIEW:
                updateDetailInfo( rowData,  DISPLAY_START_REVIEW  );
                break;
            case L1_START_REVIEW:
                updateDetailInfo( rowData,  DISPLAY_START_REVIEW  );
                break;
            case L2_START_REVIEW:
                updateDetailInfo( rowData,  DISPLAY_START_REVIEW  );
                break;
            case L3_START_REVIEW:
                updateDetailInfo( rowData,  DISPLAY_START_REVIEW );
                break;
            case HR_START_REVIEW:
                updateDetailInfo( rowData,  DISPLAY_START_REVIEW );
                break;
            case COMPLETED:
                updateDetailInfo( rowData,  DISPLAY_APPRAISAL_RESULT );
                break;
            default:
                updateDetailInfo( rowData, DISPLAY_SET_TARGETS );
                break;
        }
    };

    const updateDetailInfo = ( rowData, component ) => {
        const { appraisal_id, employee_id, name,status } = rowData;
        setDetailData( {
            appraisal_id: appraisal_id,
            employee_id: employee_id,
            name: name,
            componentToMount: component,
            status:status

        } );
    };

    const handleBack = () => {
        setDetailData( { ...detailData, componentToMount: 1 } );
        getTeamAppraisal();
    };
   
 
    // After component mount, make the request to get the "positions of the current requirement"
    // Team members
    const getTeamAppraisal = async () => {
       
        util.axios.get( `/get_team_appraisal_information` )
            .then( response => {
                setLoading( true );
                const { data, error, msg } = response.data;
                if ( error ) throw msg;
                // console.log( employees );
                setData( data );
                // console.log( data );
                setLoading( false );

                return ( {
                    data: data,
                   
                } );
            } )
            .catch( error=>{
                util.handleError( error );
            } );
        
    };

    const createAppraisalForEmployee = async ( rowData ) => {
        // After component mount, make the request to get the "positions of the current requirement"
        try {
            setLoading( true );
            const { employee_id, name, period_id } = rowData;
            const requestBody = { employee_id: employee_id, period_id: period_id };
            const request = await util.axios.post( `/create_appraisal`, requestBody );
            const { error, data, msg } = request.data;
            if ( error ) throw msg;
            setDetailData( {
                appraisal_id: data.appraisal_id,
                employee_id: data.employee_id,
                name: name,
                componentToMount: 2
            } );
            util.toast().success( msg );
            getTeamAppraisal();
        } catch ( error ) {
            util.handleError( error );
            
        }
        setLoading( false );

    };

    
    useEffect( () => {
        getTeamAppraisal();
    }, [] );

    if ( loading ) return util.LOADING_SCREEN( loading );
    
    return (
        <>
            {/* <Router>
                <Switch> */}
            {/* <Route exact path="/appraisal"> */}

            {detailData.componentToMount === TABLE_OF_TEAM_MEMBERS && (
                <TeamMembersTable
                    filterData={filterData}
                    filterByStatus = {filterByStatus}
                    setFilterByStatus={setFilterByStatus}
                    setFilterData = {setFilterData}
                    setSelectedITem={setSelectedItem} 
                    buttonDependingOnStatus={buttonDependingOnStatus} 
                    data={data} 
                    loading={loading}
                    setLoading={setLoading}
                    role={role}
                    getTeamAppraisal={getTeamAppraisal}
                    
                  
                />
            )}
            {/* </Route> */}
            {/* <Route path="/set-targets"> */}
            {detailData.componentToMount === DISPLAY_SET_TARGETS  ?
                <div className="target-form">
                    <SetTargets data={detailData} handleBack={handleBack}/>  </div>
                :
                ''
            }
            {/* </Route> */}
            {/* <Route path="/check-targets"> */}
            
            {detailData.componentToMount === DISPLAY_CHECK_TARGETS ? 
                <CheckTargets data={detailData} handleBack={handleBack}  teamTargetStatus={detailData.status} role={role}/>
                :
                ''
            }
            {/* </Route> */}
            {/* <Route path="/start-review"> */}
            {detailData.componentToMount === DISPLAY_START_REVIEW ?
                <StartReview 
                    appraisal_id={detailData.appraisal_id} 
                    handleBack={handleBack}  
                    user={{ name: detailData.name }}  
                    currentAppraisal={{}}
                    teamReviewStatus={detailData.status}
                    role={role} />
                  
                    
                :
                ""
            }
            {/* </Route> */}
            {/* <Route path="/final-appraisal"> */}
            { detailData.componentToMount === DISPLAY_APPRAISAL_RESULT  &&
                
                <FinalAppraisal data={detailData} handleBack={handleBack}  role={role} />
        
            }
            {/* </Route> */}
            {/* </Switch>
            </Router> */}
            <AllStatus />
        </>

    );
}

export default TeamAppraisal;
