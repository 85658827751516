import React, { useEffect, useState } from 'react';
import util from '../../utils/miniUtils';

const ScreeningQuestionnaire = ( { sqData } ) => {
    

    function handleAnswer( item ) {
        if( item.type === 1 ) {
            console.log( JSON.parse( item.response ) );
            return JSON.parse( item?.response ).join( ", " );
        } else return item?.response;
    }

    

    if( sqData.length < 0 ) {
        return null;
    }
    
    return (
        <div className="py-2 px-4">
            {sqData.length > 0 && sqData.map( ( item, index ) =>(
                <div key={item.id}>
                    <div className="screening-question"><strong>{`Question ${index + 1}. `}</strong>{item.question}</div>
                    <div className="screening-answer"><strong>{`Answer. `}</strong>{handleAnswer( item )}</div>
                </div>
            )
            ) }
          
        </div>
    );
};

export default ScreeningQuestionnaire;