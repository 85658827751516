import React, { Fragment, useState } from "react";
import NavigationButton from "../shared/NavigationButton";
import { Route, Switch, Redirect, useParams, useRouteMatch } from "react-router-dom";
import WorkLoadTable from './components/WorkLoadTable';
import StatusView from "../shared/pipeview/StatusView";
import PriorityView from "../shared/pipeview/PriorityView";
import CreateWorkLoad from "../shared/CreateWorkLoad";

const MyWorkload = () => {
    let { path, url } = useRouteMatch();
    const [showTableStatus, setShowTableStatus] = useState( true );
    const [tableStatus, setTableStatus] = useState( "all" );
    const GET_WORKLOAD = `/workload/get_all_my_workload?tableStatus=${tableStatus}`;

    const showComponent = ( option ) => {
        switch ( option ) {
            case "view1":
                setShowTableStatus( true );
                return <PriorityView getPath={GET_WORKLOAD} />;
            case "view2":
                setShowTableStatus( true );
                return <WorkLoadTable url={GET_WORKLOAD}/>;
            case "view3":
                setShowTableStatus( true );
                return <StatusView getPath={GET_WORKLOAD} />;
            case "new":
                setShowTableStatus( false );
                return <CreateWorkLoad />;
            default:
                setShowTableStatus( true );
                return <PriorityView getPath={GET_WORKLOAD} />;
        }
    };

    function WorkLoadContainer() {
        let { component_selected } = useParams();

        return (
            <div>
                {/* <h3>{component_selected}</h3> */}
                {showComponent( component_selected )}
            </div>
        );
    }

    return (
        <Fragment>
            <div className="d-flex align-items-center">
                <NavigationButton url={url}/>
                {showTableStatus && <div>
                    {/* <span className="label font-weight-bold">Status</span> */}
                    <select className="form-control" placeholder="Status" value={tableStatus} onChange={( e ) => setTableStatus( e.target.value )}>
                        <option value="all">All Status</option>
                        <option value="open">In Progress</option>
                        <option value="closed">Closed</option>
                    </select>
                </div>}
            </div>
            <Switch>
                <Route exact path={path}>
                    <Redirect to={`${path}/view1`} />
                </Route>
				
                <Route path={`${path}/:component_selected`}>
                    <WorkLoadContainer />
                </Route>
            </Switch>
        </Fragment>
    );
};

export default MyWorkload;
