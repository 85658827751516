import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaterialTable from "material-table";
import util from "../../utils/miniUtils";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { MuiThemeProvider } from "@material-ui/core";
import useAssets from "./useAssets";
import SimpleForm from "./SimpleForm";
//split this component into another one called SimpleForm.js and using custom hoks simplified this component
function AssetsCatalog() {
    const {
        catalogSelected,
        catalog,
        setCatalog,
        item,
        setItem,
        isEdit,
        setIsEdit,
        handleChange,
        handleCategoryChange,
        handleClick,
        createNewItem,
    } = useAssets(); //moved all the logic into the UseAsset customhook

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-3">
                    <div className="text-center p-2">
                        <b>Select a category</b>
                    </div>
                    <select
                        id="selectCategory"
                        onChange={handleCategoryChange}
                        className="form-control"
                    >
                        <option value="0"> -- </option>
                        <option value="1">Categories</option>
                        <option value="2">Type</option>
                        <option value="4">Make</option>
                        <option value="5">Model</option>
                    </select>

                    <MuiThemeProvider theme={util.defaultTableTheme}>
                        <MaterialTable
                            columns={[
                                {
                                    field: "name",
                                    sorting: false,
                                    render: ( d ) => {
                                        return <span className="likeLink">{d.name}</span>;
                                    },
                                },
                            ]}
                            data={catalog}
                            options={{
                                pageSize: 15,
                                sorting: false,
                                showFirstLastPageButtons: false,
                                pageSizeOptions: [],
                                showTitle: false,
                                padding: "dense",
                                paginationType: "stepped",
                            }}
                            onRowClick={( event, rowData ) => {
                                setItem( Object.assign( {}, rowData ) );
                                setIsEdit( true );
                            }}
                            // actions={[
                            //     {
                            //         icon: () => <FontAwesomeIcon icon={faPlusCircle} />,
                            //         tooltip: "Add new Item",
                            //         isFreeAction: true,
                            //         hidden: catalogSelected === 0,
                            //         onClick: () => createNewItem(),
                            //     },
                            // ]}
                        />
                    </MuiThemeProvider>
                </div>
                {catalogSelected === 0 ? (
                    ""
                ) : (
                    <div className="col-md-9 card pt-1">
                        <SimpleForm
                            item={item}
                            handleChange={handleChange}
                            isEdit={isEdit}
                            handleClick={handleClick}
                            catalogSelected={catalogSelected}
                            reloadData={( d ) => {
                                setCatalog( Object.assign( [], d ) );
                                createNewItem();
                            }}
                        />
                    </div>
                )}
            </div>
        </Fragment>
    );
}

export default AssetsCatalog;
