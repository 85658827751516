import { Paper, TextField, TextareaAutosize } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addSq, addSqOption, handleOptionUpdate, handleQuestionUpdate, initializeState, removeSq, removeSqOption } from '../../../redux/actions/JobSqActions';
import TypeSelector from './TypeSelector';
import util from '../../utils/miniUtils';

const QuestionsListForm = ( { questions=[], hideModal=null, save, cancel } ) => {
    const state = useSelector( state => state.sqReducer );
    const dispatch = useDispatch();
    // const [questionsCreated, setQuestionsCreated] = useState( false );

    // Question CRUD functions
    function handleChange( e, questionIndex ) {
        const { name, value } = e.target;
        dispatch( handleQuestionUpdate( name, value, questionIndex ) );
    }

    function intitialState(){
        if( questions.length > 0 ) {
            dispatch( initializeState( questions ) );
        }
    }

    function addQuestion(){
        dispatch( addSq() );
    }

    async function removeQuestion( questionKey ){
        dispatch( removeSq( questionKey ) );
    }

    // Option CRUD functions
    function handleOptionChange( e, optionKey, questionKey ){
        dispatch( handleOptionUpdate( e.target.value, optionKey, questionKey ) );
    }

    function addOption( questionKey ) {
        dispatch( addSqOption( questionKey ) );
    }

    function removeOption( questionKey, optionKey ) {
        dispatch( removeSqOption( optionKey, questionKey ) );
    }

    // async function createJobQuestions() {
    //     const data = {
    //         positionId: jobId,
    //         questions: [...state]
    //     };
    //     try{
    //         const request = await util.axios.post( 'create_job_questions', data );
    //         const { error, msg } = request.data;
    //         if( error ) throw msg;
    //         util.toast().success( msg );
    //         setQuestionsCreated( true );
    //     } catch( error ) {
    //         util.handleError( error );
    //     }
    // }

    function handleSave() {
        function validateQuestions(){
            let isValid = true;
            for( let i = 0; i < state.length; i++ ) {
                if( !state[i].question ) {
                    util.handleError( "Please enter the question!" );
                    isValid = false;
                    break;
                }
                if( state[i].options?.length > 0 ) {
                    state[i].options.forEach( option => {
                        console.log( option );
                        if( option.length < 1 ) {
                            isValid = false;
                        }
                    } );
                    if( !isValid ) {
                        util.handleError( `Please enter the option text for question ${i + 1} !` );
                        break;
                    }
                }
            }
            return isValid;
        }
        return validateQuestions() ? save() : "";
    }

    function handleCancel() {
        if( questions.length > 0 ) hideModal();
        cancel();
    }

    // initializing the redux state
    useEffect( () => {
        intitialState();
    },[] );

    // if ( questionsCreated ) {
    //     return <Redirect to={`/jobs/`} />;
    // }


    return ( <Paper>
        <div className="questionsListForm p-2">
            {state.map( ( question, idx ) => <QuestionForm
                key={idx} 
                item={question}
                index={idx}
                removeQuestion={removeQuestion}
                handleChange={handleChange}
                handleOptionChange={handleOptionChange}
                addOption={addOption}
                removeOption={removeOption}
            /> )}
            <span className="border text-primary px-2 py-1" onClick={addQuestion}>+</span>
        </div>
        <div className="d-flex justify-content-end pb-2">
            <button className="questionform-cancel" onClick={handleCancel}>Cancel</button>
            <button className="questionform-save" onClick={handleSave}>Save</button>
        </div>
    </Paper>
    );
};

export default QuestionsListForm;

const QuestionForm = ( { item, index, handleChange, removeQuestion, handleOptionChange, removeOption, addOption } ) => {
    const { question, type, options } = item;

    function option( type ){
        let charCode = 97;
        if( type == '1' || type == '2' ) {
            return options?.length > 0 ? options.map( ( option, optionIndex ) => <div key={optionIndex} className='my-1 p-1'>
                <span>{String.fromCharCode( charCode + optionIndex )}.</span>
                <input
                    className="mx-1 screening-option"
                    value={option}
                    onChange={( e ) => handleOptionChange( e, optionIndex, index )}
                    placeholder='Option text'
                />
                {options.length > 2 && <span className='m-1 btn bg-light' onClick={() => removeOption( index, optionIndex )}>X</span>}
            </div> ) : "";
        }
    }
    return <>
        <div className='w-100 py-2 d-flex my-2 align-items-start'>
            {/* <span>{`Q.${index}. `}</span> */}
            {/* <div className="w-100"> */}
            <TextField               
                fullWidth
                multiline
                type="text"
                rows={2}
                className="w-100 my-1 screening-inputbox" 
                value={question} 
                name="question" 
                onChange={( e ) => handleChange( e, index )}
                placeholder='Question text' 
                InputProps={{
                    inputComponent: TextareaAutosize,
                    disableUnderline: true,
                    rows: 2
                }}
            />
            {/* <div>
                    <input type="checkbox" name="required" value={item.required ? item.required : false} />
                    <label>Required</label>
                </div>
            </div> */}
            <div className="d-flex m-1 align-items-center">
                <TypeSelector type={type} questionIndex={index}/>
                <span className='m-1 btn bg-light' onClick={() => removeQuestion( index )}>X</span>
            </div>
        </div>
        {( type === 1 || type === 2 ) && (
            <span
                className="border bg-primary text-light px-2 py-1"
                onClick={() => addOption( index )}>
            + Option
            </span>
        )}
        <div className='d-flex flex-column'>{option( type )}</div>
    </>;
};