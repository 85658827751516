import React, { useEffect, useState } from "react";
import {
    Card,   
    CardContent,
} from "@material-ui/core";
import util from "../../utils/miniUtils";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../redux/actions/datefilter";
import Select from 'react-select';

const SalesPerformance = () => {
    const [salesPerformances, setSalesPerformances] = useState( [] );
    const [selectedLeads, setSelectedLeads] = useState( [] );
    const [selectedCountries, setSelectedCountries] = useState( [] );
    const [selectedClients, setSelectedClients] = useState( [] );
    const [selectedPartner, setSelectedPartner] = useState( [] );
    const [salesLead, setSalesLead] = useState( [] );
    const [country, setCountry] = useState( [] );
    const [client, setClient ] = useState( [] );
    const [partner, setPartner ] = useState( [] );
    let { from, to, applyFilter } = useSelector( ( state ) => state.filter ) || {};
    const [originalSalesPerformance, setOrginalSalesPerformance] = useState( [] );

    const dispatch = useDispatch();
    
    useEffect( () =>{
        if( applyFilter ) {
            getSalesPerformance();
        }
    },[applyFilter] );

    const getSalesPerformance = async (  ) => {
        try {
            if( from === undefined ){
                const currentDate = new Date();
                from = currentDate.getFullYear() + '-' +
                  ( currentDate.getMonth() + 1 ) + '-' +
                  currentDate.getDate();
            }
            if( to === undefined ){
                const currentDate = new Date();
                to = currentDate.getFullYear() + '-' +
                ( currentDate.getMonth() + 1 ) + '-' +
                currentDate.getDate();
            }
            const response = await util.axios.get(
                `new_dashboard/get_sales_performance?from=${from}&to=${to}`
            );

            const { data, error, msg } = response.data;
            // console.log( data );
            setSalesPerformances( data );
            setOrginalSalesPerformance( data );
            // const uniqueRecruiters = [...new Set( data?.map( item => item.leadRecruiterName ) )];
            // const uniqueCountries = [...new Set( data?.map( item => item.country ) )];
            const uniqueCountries = [...new Set( data?.flatMap( item => item.country ) )];
            const uniqueSalesLead = [...new Set( data?.flatMap( item => item.sales_lead ) )];
            const uniqueClients = [...new Set( data?.map( ( item ) => item.client_company ) )];
            const uniquePartner = [...new Set( data?.map( ( item ) => item.partner_company ) )];
            
            const countryOptions = uniqueCountries?.map( country => ( { label: country, value: country } ) );
            const leadsOptions = uniqueSalesLead?.map( salesLead => ( { label: salesLead, value: salesLead } ) );
            const clientOptions = uniqueClients?.map( client => ( { label: client , value: client } ) );
            const partnerOptions = uniquePartner?.map( partner => ( { label: partner , value: partner } ) );
            
            setCountry( countryOptions );
            setSalesLead( leadsOptions );
            setClient( clientOptions );
            setPartner( partnerOptions );
            dispatch( setFilter( false ) );
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    };
 
    const handleCountryChange = ( selectedCountries ) => {
        setSelectedCountries( selectedCountries );
  
        let filteredPerformance = originalSalesPerformance?.filter( item =>
            ( selectedCountries.length === 0 || selectedCountries.some( ( sel ) => item.country.includes( sel.value ) // Check if the selected country exists in the item's country array
            )
            ) && ( selectedLeads.length === 0 || selectedLeads.some( ( sel ) =>item.sales_lead.includes( sel.value ) )
            ) );
        setSalesPerformances( [...filteredPerformance] );    
    };
    const handleLeadChange = ( selectedLeads ) => {
        setSelectedLeads( selectedLeads );
  
        let filteredPerformance = originalSalesPerformance?.filter( item =>
            ( selectedCountries.length === 0 || selectedCountries.some( ( sel ) => item.country.includes( sel.value ) // Check if the selected country exists in the item's country array
            )
            ) && ( selectedLeads.length === 0 || selectedLeads.some( ( sel ) =>item.sales_lead.includes( sel.value ) )
            ) );
        setSalesPerformances( [...filteredPerformance] );    
    };
    const handleClientChange = ( selectedClients ) =>{
        setSelectedClients( selectedClients );
        let filteredPerformance = originalSalesPerformance?.filter( item =>
            ( selectedClients.length ===0 || selectedClients?.some( ( sel ) => sel.value === item.client_company ) ) && ( selectedPartner.length ===0 || selectedPartner.some( ( sel ) => sel.value === item.partner_company ) ) &&
            ( selectedCountries.length === 0 || selectedCountries.some( ( sel ) => item.country.includes( sel.value ) // Check if the selected country exists in the item's country array
            )
            ) && ( selectedLeads.length === 0 || selectedLeads.some( ( sel ) =>item.sales_lead.includes( sel.value ) )
            ) );
        setSalesPerformances( [...filteredPerformance] ); 
    };
    const handlePartnerChange = ( selectedPartner ) =>{
        setSelectedPartner( selectedPartner );
        let filteredPerformance = originalSalesPerformance?.filter( item =>
            ( selectedClients.length ===0 || selectedClients?.some( ( sel ) => sel.value === item.client_company ) ) && ( selectedPartner.length ===0 || selectedPartner.some( ( sel ) => sel.value === item.partner_company ) ) && ( selectedCountries?.length === 0 || selectedCountries.some( ( sel ) => item.country.includes( sel.value ) // Check if the selected country exists in the item's country array
            )
            ) && ( selectedLeads?.length === 0 || selectedLeads.some( ( sel ) =>item.sales_lead.includes( sel.value ) )
            ) );
        setSalesPerformances( [...filteredPerformance] ); 
    };
    return (
        <Card style={{ width: "100%", height: "90%", overflow: 'hidden' }}>
            <CardContent style={{ height: "100%" }} >
                <div style={{ height: "100%", overflow: "auto" }}>
                    
                    <div className="recruiter-filter">
                        <Select
                            isMulti
                            placeholder="Select Lead recruiters..."
                            value={selectedLeads}
                            onChange={handleLeadChange}
                            options={salesLead}
                            className="mr-2 non-draggable"
                        />
                        <Select
                            isMulti
                            placeholder="Select Regions..."
                            value={selectedCountries}
                            onChange={handleCountryChange}
                            options={country}
                            className="mr-2 non-draggable"
                        />
                        <Select 
                            isMulti
                            placeholder="Select Clients..."
                            value={selectedClients}
                            onChange={handleClientChange}
                            options={client}
                            className="mr-2 non-draggable"
                        />
                        <Select 
                            isMulti
                            placeholder="Select Partners..."
                            value={selectedPartner}
                            onChange={handlePartnerChange}
                            options={partner}
                            className="mr-2 non-draggable"
                        />
                        {/* <button className="m-2 btn btn-secondary sizeBtn activeBtn" onClick={handleApplyClick}>Apply</button> */}
                    </div>
                    {/* <table className="table table-bordered"> */}
                    <table className="sales-performance">
                        <thead>
                            <tr>
                                <th>Region</th>
                                <th>Sales Lead </th>
                                <th>Partner</th>
                                <th>Client</th>
                                <th>Job Count</th>
                                <th>New</th>
                                <th>Active</th>
                                <th>Hot</th>
                                <th>Hold</th>
                                <th>Closed</th>
                                <th>Filled</th>
                                <th>Dropped</th>
                            </tr>
                        </thead>
                        <tbody className="recruiter-report-body">
                            {salesPerformances?.map( ( item,index ) => (
                                <tr key={index} className="recruiter-report-rows">
                                    <td>
                                        {item.country.map( ( country, index ) => ( <div key={index}><img width={25} src={util.countryFlag( country )} alt={country} className="mr-1"/>
                                            {country}</div> ) )}</td>
                                    <td>{item.sales_lead.map( ( lead, index ) => ( <div key={index}>{lead}</div> ) )}</td>
                                    <td>{item.partner_company}</td>
                                    <td>{item.client_company}</td>
                                    <td>{item.jobs_id.length}</td>
                                    <td>{item.new}</td>
                                    <td>{item.active}</td>
                                    <td>{item.hot}</td>
                                    <td>{item.hold}</td>
                                    <td>{item.closed}</td>              
                                    <td>{item.filled}</td>              
                                    <td>{item.dropped}</td>
                                </tr>
                            ) )}
                        </tbody>
                    </table>
                    
                </div>
            </CardContent>
        </Card>
    );
};

export default SalesPerformance;

