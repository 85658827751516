import React, { Fragment, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaterialTable from "material-table";
import util from "../../utils/miniUtils";
import {
    faPlusCircle,
    faSyncAlt,
    faSave,
    faBan,
} from "@fortawesome/free-solid-svg-icons";
import { Editor } from "@tinymce/tinymce-react";
import { MuiThemeProvider } from "@material-ui/core";

function JobDescription( props ) {
    const [data, setData] = useState( { title: "", description: "", isNew: true } );
    const [jobDescriptions, setJobDescriptions] = useState( [] );

    useEffect( () => {
        util
            .axios
            .get( `${util.url}/app_resources/job_descriptions` )
            .then( ( response ) => {
                const { msg, error, job_descriptions } = response.data;
                if ( error ) throw msg;
                setJobDescriptions( job_descriptions );
            } )
            .catch( ( error ) => {
            } );
        return () => {};
    }, [] );

    function selectJob( data ) {
        data.isNew = false;
        setData( data );
    }

    function createNewJobDescription() {
        setData( { title: "", description: "", isNew: true } );
    }

    function relaodJD( job_descriptions, id ) {
        setJobDescriptions( job_descriptions );
        setData( job_descriptions.filter( ( jd ) => jd.id === id )[0] );
    }

    function relaodAfterDelete( job_descriptions ) {
        setJobDescriptions( job_descriptions );
        setData( { title: "", description: "", isNew: true } );
    }

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-3">
                    <MuiThemeProvider theme={util.defaultTableTheme}>
                        <MaterialTable
                            columns={[
                                {
                                    field: "title",
                                    sorting: false,
                                },
                            ]}
                            data={jobDescriptions}
                            options={{

                                pageSize: 15,
                                sorting: false,
                                showFirstLastPageButtons: false,
                                pageSizeOptions: [],
                                showTitle: false,
                                padding: "dense",
                                paginationType: "stepped",
                            }}
                            onRowClick={( event, rowData ) => selectJob( rowData )}
                            // actions={[
                            //     {
                            //         icon: () => (
                            //             <FontAwesomeIcon
                            //                 icon={faPlusCircle}
                            //                 id="addRequirementIcon"
                            //             />
                            //         ),
                            //         tooltip: "Add Job Description",
                            //         isFreeAction: true,
                            //         onClick: ( event ) => createNewJobDescription(),
                            //     },
                            // ]}
                        />
                    </MuiThemeProvider>
                </div>
                <div className="col-md-9">
                    <JobDescForm data={data} reloadJD={relaodJD}  relaodAfterDelete={relaodAfterDelete} />
                </div>
            </div>
        </Fragment>
    );
}

function JobDescForm( props ) {
    const [jd, setjd] = useState( props.data );
    // console.log( props.data );

    const [title, settitle] = useState( jd.title === "" ? "" : jd.title );
    const [description, setdescription] = useState(
        jd.description === "" ? "" : jd.description
    );

    useEffect( () => {
        settitle( props.data.title );
        setdescription( props.data.description );
        setjd( props.data );
        return () => {};
    }, [props.data] );

    function handleChange( e ) {
        const { value } = e.target;
        settitle( value );
    }

    function handleRichTextChange( e ) {
        const value = e.toString( "html" );
        setdescription( value );
    }

    function handleClick() {
        if ( jd.isNew ) {
            createJobDescription();
        } else {
            updateJobDescription();
        }
    }

    function createJobDescription() {
        const data = { title: title, description: description };
        if ( title === "" ) {
            util.toast().error( "Title can't be empty" );
            return;
        }
        if ( description === "" ) {
            util.toast().error( "Description can't be empty" );
            return;
        }

        util
            .axios
            .post( `${util.url}/app_resources/job_descriptions`, data )
            .then( ( response ) => {
                const { msg, error, job_descriptions, id } = response.data;
                if ( error ) throw msg;
                util.toast().success( msg );
                props.reloadJD( job_descriptions, id );
            } )
            .catch( ( error ) => {
            } );
    }

    function updateJobDescription() {
        const data = { title: title, description: description };
        util
            .axios
            .put( `${util.url}/app_resources/job_descriptions/${jd.id}`, data )
            .then( ( response ) => {
                const { msg, error, job_descriptions } = response.data;
                if ( error ) throw msg;
                util.toast().success( msg );
                props.reloadJD( job_descriptions, jd.id );
            } )
            .catch( ( error ) => {
            } );
    }

    function deleteJobDescription() {
        util
            .axios
            .delete( `${util.url}/app_resources/job_descriptions/${jd.id}` )
            .then( ( response ) => {
                const { msg, error, job_descriptions } = response.data;
                if ( error ) throw msg;
                util.toast().success( msg );
                props.relaodAfterDelete( job_descriptions );
            } )
            .catch( ( error ) => {
            } );
    }

    return (
        <Fragment>
            <div className="col-md-12 mb-1 mt-3">
                <input
                    placeholder="Position Title"
                    value={title}
                    className="form-control"
                    name="title"
                    onChange={handleChange}
                    autoFocus={true}
                />
            </div>
            <div className="col-md-12">
                <Editor
                    apiKey='co5tvvfh4ltli0l9urtqbvfbrevuswtkqdmu3lvf0t3lme9p'
                    value={description || ""}
                    init={{
                        height: 500,
                        menubar: false,
                        plugins: "lists",
                        toolbar:"formatselect | bold italic underline code| alignleft aligncenter alignright alignjustify | bullist numlist | removeformat",
                    }}
                    onEditorChange={handleRichTextChange}
                />
                {jd.isNew ? (
                    ""
                ) : (
                    <button
                        onClick={deleteJobDescription}
                        className="btn btn-danger float-left activeBtn sizeBtn m-2"
                    >
                        <FontAwesomeIcon icon={faBan} className="mr-2" />
            Delete
                    </button>
                )}

                <button
                    onClick={handleClick}
                    className="btn btn-success activeBtn sizeBtn m-2"
                >
                    <FontAwesomeIcon
                        icon={jd.isNew ? faSave : faSyncAlt}
                        className="mr-2"
                    />
                    {jd.isNew ? "Save" : "Update"}
                </button>
            </div>
        </Fragment>
    );
}

export default JobDescription;
