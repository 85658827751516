import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import util from "../../../utils/miniUtils";
import SubCatalogList from "./subCatalog_List";
import SubItemCatalog from "./subCatalogItem";
import CatalogCreationModal from "./catalogCreationModel";

const EDIT_ITEM = 2;
const NEW_ITEM = 1;

const CatalogCatagory = ( { settings } ) => {
    const { title, getListData, deletePath, parentData } = settings;
    const [newType, setNewType] = useState( null );
    const [newName, setNewName] = useState( 'Catalogs' );
    const [editId, setEditId] = useState( null );
    const [screening_Q, setScreening_Q] = useState( null );
    const [searchInput, setSearchInput] = useState( '' ); // Step 1: State for search input

    const [state, setState] = useState( {
        showExtraColumn: false,
        childData: [],
        parentData: parentData,
        showModal: false,
        id: editId,
    } );

    
    const reloadData = ( newType ) => {
        setEditId( null ); // Clear the editId
        setNewType( newType );
        getCatalogsData( newType, newName );
    };

    const getCatalogsData = async ( type, names ) => {
        try {
            const request = await util.axios.get( `${getListData}/${type}` );
            const { msg, error, data } = request.data;
            console.log( data );
            if ( error ) throw msg;
            setScreening_Q( type );
            setState( { ...state, childData: data[0], showExtraColumn: true, showModal: false } );
            setNewName( names );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // ... (rest of your code)

    // Step 2: Update the search input state as the user types
    const handleSearchInputChange = ( e ) => {
        setSearchInput( e.target.value );
    };

    // Step 3: Filter the childData based on search input

    const filteredChildData = state.childData.filter( ( d ) => {
        if ( newType === "job_sq" ) {
           
            return d.question && d.question.toLowerCase().includes( searchInput.toLowerCase() );
        } else {
            return d.name && d.name.toLowerCase().includes( searchInput.toLowerCase() );
        }
    } );
      
    const editCatalogData = ( id ) => {
        setEditId( id );
   
        // open modal, modal will do the request
        setState( { ...state, showModal: true, actionType: EDIT_ITEM, id: id } );
   
    };

    const onSelect = ( e, type,name ,id ) => {
        e.stopPropagation();
        getCatalogsData( type ,name );
        setNewType( type );
        setState( { ...state,id:id } );
        setSearchInput('')
    };

    const onDelete = async ( e, id ) => {
        e.stopPropagation();
        try {
            const request = await util.axios.delete( `${deletePath}/${newType}/${id}` );
            const { msg, error } = request.data;
            if( error ) throw( msg );
            util.toast().success( msg );
            reloadData( newType );
        } catch ( error ) {
            util.handleError( error ); 
        }
    };

    const onEdit = ( e, id ) => {

        e.stopPropagation();
        editCatalogData( id );
  
    
    };

 

    return (
        <Fragment>
            <div className="col-md-4 col-sm-6 bg-white">
                <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
                    <b>{title}</b>
                </div>
                <div className="listContainer">
                    {state.parentData.map( ( d, i ) => {
                        return <SubCatalogList active={state.id} key={i} data={d} onSelect={onSelect} onDelete={onDelete} onEdit={onEdit} />;
                    } )}
                </div>
            </div>
          {newType == null? null: <div className="col-md-4 col-sm-6 bg-white ml-1">
                <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
                    <b>{newName} List</b>
                    <FontAwesomeIcon
                        className="isCursor text-primary"
                        onClick={() =>
                            setState( {
                                ...state,
                                showModal: true,
                                actionType: NEW_ITEM,
                                id: editId,
                            } )
                        }
                        icon={faPlus}
                    />
                </div>
                {/* Step 1: Search input */}
                <div className="input-group mb-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={handleSearchInputChange}
                    />
                </div>
                <div style={{ maxHeight: '500px', overflowY: 'scroll', position: 'sticky', paddingRight: '10px' }}>
                    {/* Step 4: Render filtered items */}
                    {filteredChildData.map( ( d, i ) => (
                        <SubItemCatalog key={i} data={d} onDelete={onDelete} onEdit={onEdit} ScreenQ={screening_Q} />
                    ) )}
                </div>
            </div>}
            {state.showModal && <CatalogCreationModal show={state.showModal} handleClose={() => setState( { ...state, showModal: false } )} settings={settings} reloadData={reloadData} actionType={state.actionType} id={editId} type={newType} name={newName} />}
        </Fragment>
    );
};

export default CatalogCatagory;
