import ProgressBar from "react-bootstrap/ProgressBar";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function PercentageSection( { percentage } ) {
	
    const profileIcon = ( status ) =>{
        return status ?
            <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-2"/>
            :
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger mr-2"/>;
    };
	
    return (
        <div className="text-center">
            <b className="w-100 text-center">Profile {percentage.total === "100%" ? "Completed" : `at ${percentage.total}`}</b>
            {percentage.total.split( "%" )[0] !== "100" ?
				
                <ProgressBar
                    variant="primary"
                    animated={percentage.total.split( "%" )[0] !== "100"}
                    now={percentage.total.split( "%" )[0]}
				
                /> : ""
            }
			
            <div className="container">
                <div className="row checklistOfCompletedProfile">
                    <div className="col-md-12 w-100">
                        <div>{profileIcon( percentage.profilePicture )} Profile Picture</div>
                        <div>{profileIcon( percentage.name )} Name</div>
                        <div>{profileIcon( percentage.email )} Email</div>
                        <div>{profileIcon( percentage.phone_mobile )} Phone</div>
                        <div>{profileIcon( percentage.salaryExpectation )} Salary Expectation</div>
                        <div>{profileIcon( percentage.skills )} Skills</div>
                        <div>{profileIcon( percentage.workHistory )} Work History</div>
                        <div>{profileIcon( percentage.videoProfile )} Video Profile</div>
                        

                   
                    </div>
                    {/*<div className="col-md-6">*/}
                    {/*	*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
}

export default React.memo( PercentageSection );