import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import usePipeView from './usePipeView';
import { Card } from "react-bootstrap";
import util from '../../../utils/miniUtils';
import ManageWorkLoadDetail from '../../manage_workload/components/ManageWorkLoadDetail';

const StatusView = ( { getPath } ) => {
    const [itemId, setItemId] = useState( '0' );
    
    const {
        loading,
        workloadData,
        getWorkloadStatusView,
        categories,
        onDragEnd,
        workloadDetail,
        showWorkLoadDetail,
        closeWorkLoadDetail,
    } = usePipeView();
    useEffect( () => {
        getWorkloadStatusView( getPath );
    },[] );

    const handleChange = ( e ) => {
        const { value } = e.target;
        setItemId( value );
    };

    if( !workloadData ){
        return null;
    }

    return (
        <>
            {
                workloadDetail.show &&
            <ManageWorkLoadDetail workload_id={workloadDetail.workload_id} handleClose={closeWorkLoadDetail} show={workloadDetail.show} permissionLevel={workloadDetail.permission_level} />
            }
            <div className='row my-1 ml-1'>
                <div className='col d-flex justify-content-start'>
                    <div className="form-group">
                        <label htmlFor="categorySelector">Workload Category</label>
                        {categories && <select className='form-control' id="categorySelector" value={itemId} onChange={handleChange}>
                            {categories.map( ( item, index ) => <option key={item.wl_category_id} value={index} >{item.nameCategory}</option> )}
                        </select> }
                    </div>
                </div>
            </div>
            {workloadData.map( ( category, index ) => { if( String( index ) === itemId ) {
                
                return <div className='row mb-2 ml-1' key={index}>
                    <div className='col'>
                        <DragDropContext onDragEnd={( result ) => onDragEnd( result, "status", getPath )} direction="horizontal">
                            <div className="d-flex flex-nowrap wl-pipeviewContainer-Status">
                                {category.columns.map( ( item, index ) => (
                                    <div className="mr-2 p-2 d-flex flex-column wl-pipeviewColumn" key={index}>
                                        <h4>{item.name}</h4>
                                        <Droppable droppableId={`${item.wl_status_category}, ${item.wl_status_id}`}>
                                            {( provided, snapshot ) => (
                                                <div
                                                    {...provided.droppableProps} ref={provided.innerRef}
                                                    className={`col pipeColumn rounded m-1 d-flex flex-column ${snapshot.isDraggingOver && "wl-bg-D3E0EA"}`}>
                                                    {item.listItems.map( ( a, index ) => (
                                                        <Draggable key={a.id} draggableId={ `${a.id}` } index={index}>
                                                            {( provided, snapshot ) => {
                                                                return <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}>
                                                                    <Card className={`mb-2 wl_card ${snapshot.isDragging && "wl-bg-F5F4F4"}`}>
                                                                        <Card.Body className={item.name === "New" || item.name==="Open" ?
                                                                            'wl-status customColor': 'wl-status'}>
                                                                            <Card.Title>
                                                                                <span className="text-danger">{a.expected_due_date}</span>
                                                                                <span className="d-flex justify-content-between">
                                                                                
                                                                                    <span className='pr-2 wl-des'>{a.short_description}</span>
                                                                                   
                                                                                    <Card.Text>
                                                                                        <span className="">
                                                                                            <span className="text-primary wl_viewBtn" onClick={( e ) => showWorkLoadDetail( e, a.id )}>View</span>
                                                                                        </span>
                                                                                    </Card.Text>
                                                                                </span>
                                                                                
                                                                            </Card.Title>
                                                                            <Card.Subtitle className="text-muted d-flex justify-content-between">
                                                                                <span>{a.customer_name}</span>
                                                                                <span>{a.priority}</span>
                                                                            </Card.Subtitle>
                                                                           
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>; 
                                                            }}
                                                        </Draggable>
                                                    ) )}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </div>
                                ) )}
                            </div>
                        </DragDropContext>
                    </div>
                </div>; } } )}
            {util.LOADING_SCREEN( loading )}
        </>
    );
};
 
export default StatusView;