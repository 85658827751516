import React, { Fragment } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import util from "../../utils/miniUtils";

export default function ExportSelected( { data, columns } ) {

    const retreiveInfo = async () => {
        try {
            if ( !data || !Array.isArray( data ) ) {
                console.error( "Invalid data. Cannot export to CSV." );
                return;
            }
    
            const headers = columns.map( ( column ) => column.field );
            const csvData = [headers.join( "," )];
    
            data.forEach( ( row ) => {
                const rowData = headers.map( ( header ) => row[header] );
                csvData.push( rowData.join( "," ) );
            } );
    
            const csvString = csvData.join( "\n" );

            const request = await util.axios.post(
                `${util.url}/export/jobs/candidates`, data
            );
            let { error, msg, info } = request.data;
            const blob = new Blob( [info], { type: "text/csv;charset=utf-8;" } );
            const link = document.createElement( "a" );
            const url = URL.createObjectURL( blob );
    
            link.setAttribute( "href", url );
            link.setAttribute( "download", "selected_rows.csv" );
            link.style.display = "none";
    
            document.body.appendChild( link );
            link.click();
            document.body.removeChild( link );
    
            URL.revokeObjectURL( url );
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };


    return (
        <Fragment>
            <div className={"btn btn-secondary sizeBtn activeBtn"} onClick={()=>retreiveInfo()} >
                <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                Export Candidates
            </div>
        </Fragment>
    );
}
