import React, { Fragment, useEffect, useState } from "react";
import countryList from '../../../utils/location/country.json';
import util from "../../../utils/miniUtils";
import axios from "axios";


export  default function LocationView( { requiredData = null, data:{ country, state, city,phone_mobile,address,zip_code,tax_identification_number }, onChange } ) {
   
    const [states, setStates] = useState( [] );
    const [required, setRequired] = useState( {} );
    
    useEffect( () => {
        if ( requiredData !== null ) {
            setRequired( requiredData );
        }
    }, [] );
    
    const [countryState, setCountryState] = useState( {
        loading: false,
        countries: [],
        errorMessage: "",
    } );

    useEffect( () => {
        const fetchData = async () => {
            try {
                // fetch spinner
                setCountryState( {
                    ...countryState,
                    loading: true,
                } );

                //  fetch data
                const dataUrl = `https://restcountries.com/v3.1/all`;
                const response = await axios.get( dataUrl );
                setCountryState( {
                    ...countryState,
                    countries: response.data,
                    loading: false,
                } );
            } catch ( error ) {
                setCountryState( {
                    ...countryState,
                    loading: false,
                    errorMessage: "Sorry Something went wrong",
                } );
            }
        };

        fetchData();
    }, [] );
    const { loading, countries } = countryState;
    // console.log( countries );
    useEffect( () => {
        if ( util.noValues.includes( country ) ){
            let e = { target:{ value: util.user.country, name:"country" } };
            onChange( e );
        }
    },[country] );
	
    useEffect( () => {
        getStates( country );
    },[country] );
	
    // Retrieve list of countries
    const getStates = ( country ) => {
        if ( util.noValues.includes( country ) ){
            for ( let i = 0; i < countryList.length; i++ ) {
                if ( countryList[i].name === util.user.country ){
                    setStates( Object.assign( [], countryList[i].states ) );
                }
            }
        }else{
            for ( let i = 0; i < countryList.length; i++ ) {
                if ( countryList[i].name === country ){
                    setStates( Object.assign( [], countryList[i].states ) );
                }
            }
        }
        // const v = states.sort( ( a,b ) => ( a.name > b.name ) ? 1 : ( ( b.name > a.name ) ? -1 : 0 ) );
        // console.log(v)
    };
    const searchSelectedCountry = countries.find( ( obj ) => {
        if ( obj.name.common === country ) {
            return true;
        }
        return false;
    } );
    // console.log( "searchSelectedCountry", searchSelectedCountry );
    // const handleChangePhone = ( e ) => {
    //     const inputValue = e.target.value;
    //     const formattedValue = inputValue.replace( /[^0-9]/g, '' ); // Remove non-numeric characters
      
    //     if ( formattedValue.length <= 10 ) {
    //         onChange( e );
    //     }
    // };
	
    return(
		
        <Fragment>
           
            <div>
                {/* <div className="mt-3 col-12">
                    {loading === true ? (
                        <div>
                            <p>...loading</p>
                        </div>
                    ) : (
                        <div>
                            <div>
                                <p><span>Country:</span> {country ? country : "No country selected"}</p>
                            </div>
                        </div>
                    )}
                </div> */}
                {country === "India" ? (
                    <div className="col-md-12 mt-3">
                        <p><span>PAN Card:</span> <span className="ml-5">{tax_identification_number}</span></p>
                    </div>
                ) : null}
                {/* {searchSelectedCountry && (
                    <div className="d-flex mt-3">
                        <div className="col-md-2 form-control ml-3">
                            <img
                                style={{ width: '20px', height: '20px' }}
                                src={searchSelectedCountry?.flags?.png}
                                alt=""
                            />
                        </div>
                        <div className="col-md-2 form-control ml-3">
                            <p>
                                {searchSelectedCountry?.idd?.root}
                                {searchSelectedCountry?.idd?.suffixes[0]}
                            </p>
                        </div>
                        <div className="col-md-7 ml-1">
                            <p><span>Phone:</span> {phone_mobile}</p>
                        </div>
                    </div>
                )} */}

                <div className="mt-3">
                    <div className="col-md-12">
                        <p><span>Address:</span> <span style={{ marginLeft:'3.2rem' }}>{address ? address : ""}</span></p>
                    </div>
                    <div className="col-12">
                        <p><span>City:</span><span style={{ marginLeft:'5.2rem' }}> {city || ""}</span></p>
                    </div>
                    
                </div>

                <div className="mt-3">
                    <div className="col-md-12">
                        <p><span>Zip Code:</span><span className="ml-5"> {zip_code || ""}</span></p>
                    </div>
                    <div className="col-12">
                        
                        <p><span>State:</span><span style={{ marginLeft:'4.5rem' }}> {state ? state : "No state selected"}</span></p>
                    </div>
                   
                </div>
            </div>

        </Fragment>
    );
}