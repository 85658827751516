import React, { useEffect, useState } from "react";
import {
    Card,   
    CardContent,
} from "@material-ui/core";
import util from "../../utils/miniUtils";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../redux/actions/datefilter";
import Select from 'react-select';


const WorkloadByCategory = () => {
    const [categories, setCategories] = useState( [] );
    let { from, to, applyFilter } = useSelector( ( state ) => state.filter ) || {};
    const [categoryOptions, setCategoryOptions] = useState( [] );
    const [originalCategoryList, setOriginalCategoryList] = useState( [] );
    const [selectedCategories, setSelectedCategories] = useState( [] );
    const dispatch = useDispatch();
    
    useEffect( () =>{
        if( applyFilter ) {
            getWorkloadByCategory();
        }
    },[applyFilter] );

    const getWorkloadByCategory = async (  ) => {
        try {
            if( from === undefined ){
                const currentDate = new Date();
                from = currentDate.getFullYear() + '-' +
                  ( currentDate.getMonth() + 1 ) + '-' +
                  currentDate.getDate();
            }
            if( to === undefined ){
                const currentDate = new Date();
                to = currentDate.getFullYear() + '-' +
                ( currentDate.getMonth() + 1 ) + '-' +
                currentDate.getDate();
            }
            const response = await util.axios.get(
                `new_dashboard/get_workload_by_category?from=${from}&to=${to}`
            );
            
            const { data, error, msg } = response.data;
            
            setCategories( data );
            setOriginalCategoryList( data );
            const uniqueCategories = [...new Set( data?.map( item => item.Category_Name ) )];
            const categoryList = uniqueCategories?.map( ( category ) =>( { label: category, value: category } ) );
            setCategoryOptions( categoryList );
            dispatch( setFilter( false ) );
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    };
    const handleResolverChange = ( selectedCategories ) =>{
        setSelectedCategories( selectedCategories );
        const filteredCategories = originalCategoryList?.filter( ( item ) => ( selectedCategories?.length ===0 || selectedCategories?.some( ( el ) => el.value === item.Category_Name ) ) );
        setCategories( [...filteredCategories ] );
    };

    return (
        <Card style={{ width: "100%", height: "90%", overflow: 'hidden' }}>
            <CardContent style={{ height: "100%" }} >
                <div style={{ height: "100%", overflow: "auto" }}>
                    <div className="recruiter-filter">

                        <Select 
                            isMulti
                            options={categoryOptions}
                            value={selectedCategories}
                            onChange={handleResolverChange}
                            placeholder='Select Categories'
                            className="mr-2 non-draggable"
                        />
                    </div>  
                    {/* <table className="table table-bordered"> */}
                    <table className="workload-resolver-table">
                        <thead>
                            <tr>
                                <th>Category Name</th>
                                
                                <th>Ticket Count</th>
                                {/* <th>Partner Name</th> */}
                                <th>Average Business Days SLA</th>
                                
                                <th>Average Calendar Days SLA</th>
                                
                            </tr>
                        </thead>
                        <tbody className="recruiter-report-body">
                            {categories?.map( ( item,index ) => (
                                <tr key={index} className="recruiter-report-rows">
                                    
                                    <td>{item.Category_Name}</td>
                                    
                                    <td>{item.Ticket_Count}</td>
                                    
                                    <td>{item.Average_Business_Days_SLA}</td>
                                    
                                    <td>{item.Average_Calendar_Days_SLA}</td>
                                    

                                </tr>
                            ) )}
                        </tbody>
                    </table>
                    
                </div>
            </CardContent>
        </Card>
    );
};

export default WorkloadByCategory;

