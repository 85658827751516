import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner, ListGroup, ListGroupItem } from 'react-bootstrap';
import util from '../../../utils/miniUtils';

const RoleDropdown = ( { id } ) => {
    const [options, setOptions] = useState( [] );
    const [loading, setLoading] = useState( false );

    useEffect( () => {
        const fetchOptions = async () => {
            try {
                setLoading( true );
                const response = await util.axios.get( `/role_permissions/role_dropdown/${id}` );
                const { data } = response.data;
                setOptions( data );
            } catch ( error ) {
                console.error( 'Failed to fetch options', error );
            } finally {
                setLoading( false );
            }
        };

        fetchOptions();
    }, [id] );

    const handleSave = async () => {
        try {
            setLoading( true );
            const selectedRoles = options.filter( option => option.checked ).map( option => option.role_id );
            await util.axios.post( `/role_permissions/link_permissions/${id}`, { selectedRoles } );
            // Add any additional logic after saving
        } catch ( error ) {
            console.error( 'Failed to save selections', error );
        } finally {
            setLoading( false );
        }
    };

    const toggleChecked = ( roleId ) => {
        setOptions( options.map( option => {
            if ( option.role_id === roleId ) {
                return { ...option, checked: !option.checked };
            }
            return option;
        } ) );
    };

    return (
        <div>
            <Form>
                <Form.Group controlId="roleDropdown">
                    <Form.Label>Select Roles</Form.Label>
                    {loading ? (
                        <Spinner animation="border" />
                    ) : (
                        <ListGroup>
                            {options.map( ( option ) => (
                                <ListGroupItem key={option.role_id}>
                                    <Form.Check 
                                        type="checkbox"
                                        label={option.name}
                                        checked={option.checked}
                                        onChange={() => toggleChecked( option.role_id )}
                                    />
                                </ListGroupItem>
                            ) )}
                        </ListGroup>
                    )}
                </Form.Group>
                <Button onClick={handleSave} disabled={loading}>
                    {loading ? 'Saving...' : 'Save'}
                </Button>
            </Form>
        </div>
    );
};

export default RoleDropdown;
