import util from "./miniUtils";

const routes = {};

routes.recruiter = () => {
    return [
        {
            title: "My Recruitment",
            icon: "Dashboard",
            url: "/my-recruitment",
        },
        {
            title: "Jobs",
            icon: "Jobs",
            url: "/jobs",
        },
        {
            title: "Talents",
            icon: "talent",
            url: "/candidates",
        },
        {
            title: "Employees",
            icon: "employee",
            url: "/employees",
        },
        {
            title: "Assets",
            icon: "laptop",
            url: "/assets",
        },
        {
            title: "Workload",
            icon: 'workload',
            url: '/workload'
        },
        util.user.employee_id !== null ? {
            title: "Appraisal",
            icon: "appr",
            url: "/appraisal",
        } : '',
        // {
        //     title: "Assets",
        //     icon: "laptop",
        //     url: "/assets",
        // },
        // {
        //     title: "Workload",
        //     icon: 'workload',
        //     url: '/workload'
        // },
        // util.user.employee_id !== null ? {
        //     title: "Appraisal",
        //     icon: "appr",
        //     url: "/appraisal",
        // } : '',
        // {
        //     title:"Settings",
        //     icon:'grear',
        //     url:'/settings'
        // }
    ];
};

routes.talent = () => {
    return [
        {
            title: "Job Search",
            icon: 'Jobs',
            url: '/jobs'
        },
        {
            title: "Profile",
            icon: 'talent',
            url: '/profile/' + util.user.candidate_id
        }
    ];
    // if ( util.user.permissions.employee === 1 ) {
    //     links.push( {
    //         title: "Appraisal",
    //         icon: "appr",
    //         url: "/appraisal",
    //     } );
    // }
    // return links;
};

routes.employee = () => {
    return [
        {
            title: "Profile",
            icon: 'talent',
            url: '/profile/'
        }
        // {
        //     title: "Assets",
        //     icon: 'laptop',
        //     url: '/assets'
        // },
        // {
        //     title: "Workload",
        //     icon: 'workload',
        //     url: '/workload'
        // },
        // util.user.employee_id !== null ? {
        //     title: "Appraisal",
        //     icon: "appr",
        //     url: "/appraisal",
        // } : '',
    ];

};

routes.client = () => {
    return [
        {
            title: "My Jobs",
            icon: 'Jobs',
            desc: "Jobs sent to eJRekruit",
            url: '/my_jobs'
        },
        {
            title: "My Talents",
            icon: 'Jobs',
            desc: "Talents assigned to you by eJRekruit",
            url: '/my_talents'
        }
    ];
};
routes.evaluator = () => {
    return [
        {
            title: "Evaluations by Jobs",
            icon: 'Jobs',
            url: '/evaluations'
        },
        {
            title: "Evaluations by Talents",
            icon: 'Jobs',
            url: '/evaluations_by_talents'
        }
        // {
        //     title: "Workload",
        //     icon: 'workload',
        //     url: '/workload'
        // },
        // util.user.employee_id !== null ? {
        //     title: "Appraisal",
        //     icon: "appr",
        //     url: "/appraisal",
        // } : '',

    ];
};
routes.partner = () => {
    return [
        {
            title: "My Jobs",
            icon: 'Jobs',
            desc: "Jobs sent to eJRekruit",
            url: '/my_jobs'
        },
        {
            title: "My Talents",
            icon: 'Jobs',
            desc: "Talents assigned to you by eJRekruit",
            url: '/my_talents'
        }
    ];
};

routes.recruiter_manager = () => {
    return [
        {
            title: "Dashboard",
            icon: 'Dashboard',
            url: '/dashboard'
        },
        {
            title: "Jobs",
            icon: 'Jobs',
            url: '/jobs'
        },
        {
            title: "Talent",
            icon: 'talent',
            url: '/candidates'
        },
        {
            title: "Employees",
            icon: "employee",
            url: "/employees",
        },
        {
            title: "Assets",
            icon: 'laptop',
            url: '/assets'
        },
        {
            title: "Workload",
            icon: 'workload',
            url: '/workload'
        },
        util.user.employee_id !== null ? {
            title: "Appraisal",
            icon: "appr",
            url: "/appraisal",
        } : '',
        {
            title: "Settings",
            icon: 'grear',
            url: '/settings'
        },

    ];
};
routes.employee_manager = () => {
    return [
        {
            title: "Jobs",
            icon: 'Jobs',
            url: '/jobs'
        },
        {
            title: "Talent",
            icon: 'talent',
            url: '/candidates'
        },
        {
            title: "Employees",
            icon: "employee",
            url: "/employees",
        },
        {
            title: "Workload",
            icon: 'workload',
            url: '/workload'
        },
        util.user.employee_id !== null ? {
            title: "Appraisal",
            icon: "appr",
            url: "/appraisal",
        } : '',
    ];
};

routes.admin = () => {
    return [
        {
            title: "Jobs",
            icon: 'Jobs',
            url: '/jobs'
        },
        {
            title: "Workload",
            icon: 'workload',
            url: '/workload'
        },
        {
            title: "Employees",
            icon: "employee",
            url: "/employees",
        },
        {
            title: "Appraisal",
            icon: "appr",
            url: "/appraisal",
        }
        // {
        //     title: "Workload",
        //     icon: 'workload',
        //     url: '/workload'
        // },
        // {
        //     title: "Appraisal",
        //     icon: "appr",
        //     url: "/appraisal",
        // }
    ];
};


export default routes;
