import { faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import util from "../../../utils/miniUtils";
import SocialMediaPopUp from "./SocialMediaPopUp";

export default function SocialMediaContainer( { talent_id ,isEditing } ) {

    const initialUrls = JSON.parse( localStorage.getItem( "socialMediaUrls" ) ) || {
        facebook: "",
        github: "",
        linkedin: "",
        twitter: "",
        web: "",
    };
    
    const [urls, setUrls] = useState( initialUrls );
    const socialMediaPlatforms = [
        { name: 'LinkedIn', image: 'LinkedIn.png', url: 'linkedin' },
        { name: 'Facebook', image: 'Facebook.png', url: 'facebook' },
        { name: 'Twitter', image: 'Twitter.png', url: 'twitter' },
        { name: 'Github', image: 'github.png', url: 'github' },
        { name: 'Web', image: 'Web.png', url: 'web' },
    ];

    const [loading, setLoading] = useState( false );

    const [showModal, setShowModal] = useState( false );


    const toggleModal = () => {
        setShowModal( !showModal );
    };
    
    const startLoading = () => setLoading( true );
    const stopLoading = () => setLoading( false );

    useEffect( () => {
        getSocialMedia().then( null );
    }, [] );

    // get all available SocialMedia
    const getSocialMedia = async () => {
        try {
            setLoading( true );
            const response = await util.axios.get(
        `talent/social_media_list/${talent_id}/`
            );
            const { error, msg, data } = response.data;
            if ( error ) throw new Error( msg );
            setUrls( ( prevUrls ) => ( {
                ...prevUrls,
                ...data,
            } ) );
            setLoading( false );
        } catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    };

    const handleChanges = ( e ) => {
        const { name, value } = e.target;
        setUrls( ( prevUrls ) => ( {
            ...prevUrls,
            [name]: value,
        } ) );
    };

    const updateUrls = async () => {
        try {
            startLoading();
            const response = await util.axios.put( `talent/social_media_update/${talent_id}/`, urls );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            util.toast().success( "Social Media Updated" );
            // setUrls( Object.assign( {}, data ) );
            setUrls( ( prevUrls ) => ( {
                ...prevUrls,
                ...data,
            } ) );
          
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
    // Save the 'urls' state to LocalStorage whenever it changes
    useEffect( () => {
        localStorage.setItem( "socialMediaUrls", JSON.stringify( urls ) );
    }, [urls] );
    const hasSocialMediaLinks = () => {
        return Object.values( urls ).some( ( url ) => url !== "" );
    };
    const tooltip = (
        <Tooltip id="tooltip">
          Add Social Media Links
        </Tooltip>
    );
    return (
        <div className="container-fluid mt-3 " style={{ backgroundColor: "#F4F4F4" }}>
            <div className="row">
                <b className="custom-title ml-3" style={{ flex: '1', width: '200px' , color: hasSocialMediaLinks() ? "black" : "gray", }}>Social Media</b>
                {isEditing?<OverlayTrigger placement="top" overlay={tooltip}>
                    <FontAwesomeIcon
                        className="custom-icon mr-4"
                        icon={faPlus}
                        onClick={toggleModal}
                    />
                </OverlayTrigger>:""}
                <div className="col-md-12">
                    <div>
                        {socialMediaPlatforms.map( ( platform ) => {
                            const link = urls[platform.url];
                            if ( link ) {
                                return (
                                    <div key={platform.url} className="row">
                                        <div className="col-md-1 text-center">
                                            <a target="_blank" rel="noopener noreferrer" href={link}>
                                                <img width="30px" src={`/images/${platform.image}`} alt={`${platform.name} icon`} />
                                            </a>
                                        </div>
                                        <div className="col-md-7 p-2">
                                            <div>
                                                <b>{platform.name}:</b> <a target="_blank" rel="noopener noreferrer" href={link}>{link}</a>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            return null; // Hide the row if there is no link for this platform
                        } )}
                    </div>
                </div>
            </div>
            {showModal&&<SocialMediaPopUp showModal={showModal} toggleModal={toggleModal} handleChanges={handleChanges} updateUrls={updateUrls} urls={urls}/>}
        </div>
    );
}

