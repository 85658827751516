import React, { useEffect, useState } from 'react';
import { Drawer } from "@material-ui/core";

const SqFilter = ( { show, filterState, setFilterState, handleClose, questions, setCandidates, candidates } ) => {

    function initialFilterState(){
        let initialState = {};
        questions.forEach( el => {
            if( el.type === 1 || el.type === 2 ) {
                initialState[el.id] = el.options.map( option => ( { value: option, checked: false } ) );
            }
        } );
        return initialState;
    }

    function handleType( type ) {
        switch( type ) {
            case 1:
                return "Multiple Choice";
            case 2:
                return "Single Choice";
            case 3:
                return "Numeric";
            case 4:
                return "Text";
        }
    }

    function handleChange( e, id ) {
        const { value, checked } = e.target;
        const temp = { ...filterState };

        temp[id] = temp[id].map( el => {
            if( el.value === value ) {
                return { ...el, checked };
            } else return { ...el };
        } );

        filterCandidates( temp, candidates );
        setFilterState( { ...temp } );
    }

    function filterCandidates( filterResponse, candidates ) {
        const finalCandidates = [];
      
        for ( const candidate of candidates ) {
            const { response } = candidate;
            const candidateResponse = {};
      
            for ( const el of response ) {
                candidateResponse[el.id] = el.type === 1 ? JSON.parse( el.response ) : [el.response];
            }
      
            const candidateMatchesFilter = Object.keys( filterResponse ).every( ( question ) => {
                const filteredOptions = filterResponse[question].filter( ( el ) => el.checked ).map( ( el ) => el.value );
                const candidateOptions = candidateResponse[question] || [];
      
                return compareArrays( filteredOptions, candidateOptions );
            } );
      
            if ( candidateMatchesFilter ) {
                finalCandidates.push( candidate.id );
            }
        }

        setCandidates( filterArrayById( candidates, finalCandidates ) );
    }

    function filterArrayById( array1, array2 ) {
        return array1.filter( item => array2.includes( item.id ) );
    }
      
    function compareArrays( array1, array2 ) {
        const set2 = new Set( array2 );
        return array1.every( ( item ) => set2.has( item ) );
    }
    
    useEffect( () => {
        setFilterState( initialFilterState() );
    },[] );
    
    if( !show ) {
        return null;
    }
    return (
        <Drawer anchor="right" open={show} onClose={handleClose}>
            <div className="collab-container container pt-2" style={{ width: .45 * window.innerWidth }}>
                {questions.filter( question => question.type !== 3 && question.type!==4 ).map( ( el, index ) => (
                    <div key={index}>
                        {/* Questions */}
                        <div>
                            <strong>
                                <span className="mr-1">{index + 1}.</span>
                                <span>{el.question}</span>
                            </strong>
                        </div>
                        {/* Type */}
                        <div className='ml-3'>
                            <span className='mr-1'>
                                <strong>Type:</strong>
                            </span>
                            {handleType( el.type )}
                        </div>
                        {/* Options */}
                        {el.options ? <div className="ml-3">       
                            {el.options.map( ( option, index ) => <div className="border rounded d-flex p-2 mb-1 bg-light" key={`option-${index}`}>                     
                                <input
                                    type="checkbox"
                                    id={el.id + option}
                                    onChange={( e ) => handleChange( e, el.id, index )}
                                    value={option}
                                    checked={filterState[el.id].find( el => el.value == option ).checked}
                                />
                                <label htmlFor={el.id + option} className="ml-2 w-75 w-100 mb-0">{option}</label>
                            </div> )
                            }
                        </div> : ""}
                    </div> ) 
                )}
            </div>
        </Drawer>
    );
};

export default SqFilter;