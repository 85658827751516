import React, { Fragment, useState } from 'react';
import ListOfCandidates from "./components/listComponents/ListofCandidates";
import "@fortawesome/free-solid-svg-icons";

const Candidates = () => {
    const [addModalShow, setAddModalShow] = useState( false );
	
    // Create New candidate modal
    const createNewCandidate = () => {
        setAddModalShow( true );
    };
    // Close the add new candidate modal
    const closeAddModal = () => {
        setAddModalShow( false );
    };

    return (
        <Fragment>
           
            <div className="row px-4 mt-4">
                <ListOfCandidates
                    addCandidate={createNewCandidate}
                    addModalShow={addModalShow}
                    closeAddModa={closeAddModal} />
            </div>
        </Fragment>
    );
};

export default Candidates;
