import React, { useState, useEffect, Fragment } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import MaterialTable from 'material-table';
import util from '../../utils/miniUtils';

const EmployeeDocumentCatalogPanel = () => {
    const [documents, setDocuments] = useState( [] );
    const [selectedDocument, setSelectedDocument] = useState( { name:'',country:'' } );
    const [showModal, setShowModal] = useState( false );
    const [isLoading, setIsLoading] = useState( false );
    const countriesList = ['USA', 'Canada', 'UK', 'Australia', 'Germany', 'France', 'India', 'Mexico', 'Global'];


    const fetchData = async () => {
        setIsLoading( true );
        try {
            const response = await util.axios.get( '/employee_documents/list' );
            const { data,error,msg } = response.data;
            setDocuments( data );
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        } finally {
            setIsLoading( false );
        }
    };

    useEffect( () => {
        fetchData();
    }, [] );

    const handleInputChange = ( event ) => {
        setSelectedDocument( { ...selectedDocument, [event.target.name]: event.target.value } );
    };

    const handleModalClose = () => {
        setSelectedDocument( null );
        setShowModal( false );
    };

    const handleModalSave = async () => {
        let response;
        try {
            if ( selectedDocument.id ) {
                response = await util.axios.put( `/employee_documents/update/${selectedDocument.id}`, selectedDocument );
            } else {
                response = await util.axios.post( '/employee_documents/create', selectedDocument );
            }
            const { error , msg } = response.data;
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
        setSelectedDocument( { name:'',country:'' } );
        handleModalClose();
        fetchData();
    };

    const handleDelete = async ( id ) => {
        let response;
        try {
            response = await util.axios.delete( `/employee_documents/delete/${id}` );
            const { error, msg } = response.data;
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
        fetchData();
    };

    const handleRowClick = ( rowData ) => {
        setSelectedDocument( rowData );
        setShowModal( true );
    };


    if ( isLoading ) return util.LOADING_SCREEN( isLoading );

    return (
        <Fragment>
            <MaterialTable
                title="Employee Document Catalog"
                columns={[
                    { title: 'Name', field: 'name' },
                    { title: 'Country', field: 'country' },
                ]}
                data={documents}
                actions={[
                    {
                        icon: 'add',
                        tooltip: 'Add Document',
                        isFreeAction: true,
                        onClick: () => setShowModal( true )
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Delete Document',
                        onClick: ( event, rowData ) => handleDelete( rowData.id )
                    }
                ]}
                onRowClick={( event, rowData ) => handleRowClick( rowData )}
            />
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedDocument ? 'Edit Document' : 'Add Document'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="name" 
                                value={selectedDocument ? selectedDocument.name : ''} 
                                onChange={handleInputChange} 
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedDocument ? selectedDocument.country : ''} 
                                name="country"
                                onChange={handleInputChange}
                            >
                                {/* Populate your dropdown options here */}
                                <option value="">Select a country</option>
                                {countriesList.map( ( country, index ) => (
                                    <option key={index} value={country}>{country}</option>
                                ) )}
                            </Form.Control>

                        </Form.Group>

                    </Form>                
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                            Close
                    </Button>
                    <Button variant="primary" onClick={handleModalSave}>
                            Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default EmployeeDocumentCatalogPanel;
