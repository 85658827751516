import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import util from "../utils/miniUtils";

const useAssetManager = () => {
    const [loading, setLoading] = useState( false );
    const [assetsList, setAssetsList] = useState( [] );
    const [modalShow, setModalShow] = useState( false );
    const [showInputFactory, setShowInputFactory] = useState( 0 );
    const [isEditing, setIsEditing] = useState( false );
    const [showAlert, setShowAlert] = useState( { show: false, id: 0 } );
    // catalogs
    const [assetData, setAssetData] = useState( {} );
    const [assetMake, setAssetMake] = useState( [] );
    const [assetModel, setAssetModel] = useState( [] );
    const [assetsTypes, setAssetsTypes] = useState( [] );
    const [assetsTypesBase, setAssetsTypesBase] = useState( [] );
    const [accounts, setAccounts] = useState( [] );
    const [categories, setCategories] = useState( [] );

    const getAssetsList = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `${util.url}/assets_manager/list` );
            const { error, msg, assets, assetsMake, assetsModel, accounts, categories, assetsTypes } = request.data;
            if ( error ) throw msg;
            setAssetsList( assets );
            setAssetMake( assetsMake );
            setAssetModel( assetsModel );
            setAccounts( accounts );
            setCategories( categories );
            setAssetsTypes( assetsTypes );
            setAssetsTypesBase( assetsTypes );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };
    
    const getAssignedAssets = async() => {
        try {
            const user = util.user;
            const request = await util.axios.get( `${util.url}/my_assets/${user.id}` );
            const { error, msg, assets } = request.data;
            if ( error ) throw msg;
            setAssetsList( assets );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    useEffect( () => {
        if ( !modalShow ) {
            setIsEditing( false );
        }
    }, [modalShow] );

    const handleChange = ( e ) => {
        const { name, value } = e.target;
        const isItemNameEqualsValue = a => a.name === value;
        let t = { ...assetData };
        let temp;
        if ( value !== "" && value !== undefined ) {
            switch ( name ) {
                case "aType":
                    t.aType = value;
                    temp = assetsTypes.filter( isItemNameEqualsValue )[0];
                    t.asset_type = temp["id_asset_type"];
                    setAssetData( t );
                    break;
                case "aCategory":
                    if ( value !== "" ) {
                        t.aCategory = value;
                        temp = categories.filter( a => a.name === value )[0];
                        t.asset_category = temp["id_asset_category"];
                        setAssetData( t );
                        // Filter only types of the category selected
                        let temp2 = [...assetsTypesBase];
                        let x = temp2.filter( at => at.category_id === t.asset_category );
                        setAssetsTypes( x );
                    }
                    break;
                case "aMake":
                    t.aMake = value;
                    temp = assetMake.filter( a => a.name === value )[0];
                    t.asset_make = temp["id_asset_make"];
                    setAssetData( t );
                    break;
                case "aModel":
                    t.aModel = value;
                    temp = assetModel.filter( a => a.name === value )[0];
                    t.asset_model = temp["id_asset_model"];
                    setAssetData( t );
                    break;
                case "assignedUser":
                    t.assignedUser = value;
                    temp = accounts.filter( a => a.name === value )[0];
                    t.assigned_user = temp["id"];
                    setAssetData( t );
                    break;
                case "creditCardUser":
                    t.creditCardUser = value;
                    temp = accounts.filter( a => a.name === value )[0];
                    t.credit_card_user = temp["id"];
                    setAssetData( t );
                    break;
                default:
                    setAssetData( { ...assetData, [name]: value } );
                    break;
            }
        } else {
            if ( name === "aCategory" ) {
                setAssetData( {} );
            } else {
                setAssetData( { ...assetData, [name]: value } );
            }
        }

    };

    const InputNewElement = ( { typeElement, show, handleCancel } ) => {
        const [data, setData] = useState( "" );

        function handleChangeThis( e ) {
            const { value } = e.target;
            setData( value );
        }

        function selectPlaceholder() {
            switch ( typeElement ) {
                case 1: return "Which type it is ?";
                case 2: return "Which make it is ?";
                case 3: return "Which model it is ?";
                case 4: return "Name of the category";
                case 5: return "Name of the type";
                default: break;
            }
        }
        function customID() {
            switch ( typeElement ) {
                case 1: return "typeId";
                case 2: return "makeId";
                case 3: return "modelID";
                case 4: return "categoryId";
                case 5: return "typeId";
                default: break;
            }
        }

        async function saveNewElement() {
            try {
                const info = { type: typeElement, name: data, category_id: assetData.asset_category };
                const request = await util.axios.post( `${util.url}/assets_manager/add`, info );
                const { error, msg, newData } = request.data;
                if ( error ) throw msg;
                // populate again catalog modified
                switch ( typeElement ) {
                    case 2: setAssetMake( newData ); break;
                    case 3: setAssetModel( newData ); break;
                    case 4: setCategories( newData ); break;
                    case 5:
                        setAssetsTypes( newData );
                        setAssetsTypesBase( newData );
                        break;
                    default: break;
                }

                // Select the new option in the select
                handleCancel();

            } catch ( error ) {
                util.handleError( error );
            }
        }

        if ( typeElement === show ) {
            return (
                <>
                    <input
                        id={customID()}
                        className="form-control mt-2"
                        name="date_of_purchase"
                        type="text"
                        value={data ? data : ""}
                        placeholder={selectPlaceholder()}
                        onChange={handleChangeThis}
                    />
                    <button id="btnCancel" className="btn btn-sm text-danger mt-1 sizeBtn" onClick={() => handleCancel()} >
                        Cancel
                    </button>
                    <button id="btnSave" className="btn btn-sm btn-primary mt-1 ml-2 sizeBtn" onClick={saveNewElement} >
                        <FontAwesomeIcon icon={faSave} className="mr-2" />
                        Save
                    </button>
                </>
            );
        } else {
            return "";
        }
    };

    async function upsertAssets( operation = 'create' ) { 
        try {
            let request;
            setLoading( true );
            if( operation === 'create' ){
                request = await util.axios.post( `${util.url}/assets_manager/${operation}`, { assetData } );
            } else{
                request = await util.axios.put( `${util.url}/assets_manager/${operation}`, { assetData } );
            }
            const { error, msg, assets } = request.data;
            if ( error ) throw msg;
            setAssetsList( assets );
            setModalShow( false );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    }

    async function saveNewAssets() {
        upsertAssets();
    }

    async function updateNewAssets() {
        upsertAssets( 'update' );
    }

    const editAsset = ( index ) => {
        setAssetData( { ...assetsList[index] } );
        setModalShow( true );
        setIsEditing( true );
        // populates the list of type
        let temp = [...assetsTypesBase];
        let x = temp.filter( at => at.category_id === assetsList[index].asset_category );
        setAssetsTypes( x );
    };

    // Send a request to delete the selected asset record
    async function onConfirmDeleteAsset() {
        try {
            setShowAlert( { show: false, id: 0 } );
            setLoading( true );
            let objToDelete = assetsList.filter( a => a["id_asset_record"] === showAlert.id )[0];
            const request = await util.axios.post( `${util.url}/assets_manager/delete/${objToDelete["id_asset_record"]}`, { item: objToDelete } );
            const { error, msg, assets } = request.data;
            if ( error ) throw msg;
            setLoading( false );
            setAssetsList( assets );
            setModalShow( false );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    }

    return (
        {
            loading, setLoading,
            assetsList, setAssetsList,
            modalShow, setModalShow,
            showInputFactory, setShowInputFactory,
            isEditing, setIsEditing,
            showAlert, setShowAlert,
            assetData, setAssetData,
            assetMake, setAssetMake,
            assetModel, setAssetModel,
            assetsTypes, setAssetsTypes,
            assetsTypesBase, setAssetsTypesBase,
            accounts, setAccounts,
            categories, setCategories,
            getAssetsList,
            getAssignedAssets,
            handleChange,
            InputNewElement,
            saveNewAssets,
            updateNewAssets,
            editAsset,
            onConfirmDeleteAsset
        }
    );
};

export default useAssetManager;
