import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import util from '../../utils/miniUtils';

const LocationComponent = ( { setJob, job } ) => {
    return <>
        <LocationInput name="city" label="City *" setJob={setJob} value={job.city} />
        <LocationInput name="zipcode" label="Zipcode" setJob={setJob} value={job.zipCode} />
    </>;
};

export default LocationComponent;

const LocationInput = ( { name, label, setJob, value } ) => {
    const [searchText, setSearchText] = useState( value );
    const [result, setResult] = useState( [] );
    const [startSearch, setStartSearch] = useState( false );
    const [loading, setLoading] = useState( false );

    useEffect( () => { 
        setSearchText( value );
    },[value] );

    const handleInputChange = ( e ) => {
        setStartSearch( true );
        setSearchText( e.target.value );
    };

    const handleClear = () => {
        setSearchText( '' );
        setJob( prev => ( {
            ...prev,
            country: "",
            state: "",
            city: "",
            zipCode: "",
            latitude: "",
            longitude: ""
        } ) );
    };

    const handleClick = ( value ) =>{
        const { country, state, city, county, state_district } = value.address;
        const { lat, lon } = value;
        setStartSearch( false );
        if( name === 'city' ) {
            setJob( prev => ( {
                ...prev,
                country: country,
                state: state,
                city: city ? city : county ? county : state_district,
                latitude: lat,
                longitude: lon
            } ) );
        } else {
            setJob( prev => ( {
                ...prev,
                country: country,
                state: state,
                city: city ? city : county ? county : state_district,
                latitude: lat,
                longitude: lon,
                zipCode: value.name
            } ) );
        }
        setResult( [] );
        setSearchText( value.name );

    };

    async function getLocationInfo( location, name ) {
        if ( location && startSearch ) {
            setLoading( true );
            const type = name === 'zipcode' ? 'postalcode' : 'city';
            const url = `https://nominatim.openstreetmap.org/search?${type}=${encodeURIComponent( location )}&format=json&addressdetails=1`;
            try {
                const response = await fetch( url );    
                const data = await response.json();
                setResult( name === 'city' ? data.filter( el => el.type !== 'postcode' ) : data );
            } catch ( error ) {
                util.handleError( error );
            }
        }
        setLoading( false );
    }

    useEffect( () => {
        const delayDebounceFn = setTimeout( () => {
            // Replace this with your actual search logic
            getLocationInfo( searchText, name );
        }, 500 ); // Adjust the delay as needed (e.g., 500 milliseconds)
            
        return () => clearTimeout( delayDebounceFn );
    }, [searchText] );

    return <div className='col-md-5 p-1 mt-2 align-self-center position-relative'>
        <TextField
            className="w-100"
            size={"small"}
            variant="outlined"
            label={label}
            placeholder={label}
            InputLabelProps={{ shrink: true }}
            value={searchText}
            onChange={handleInputChange}
            InputProps={{
                endAdornment: searchText && (
                    <InputAdornment position="end">
                        <IconButton
                            edge="end"
                            onClick={handleClear}
                        >
                       x
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
        {result.length > 0 && !loading ? <div className="locationComponent-dropdown">
            {result.map( ( el ) => (
                <div key={el.place_id} className="location-option" onClick={() => handleClick( el )}>
                    {el.display_name}
                </div>
            ) )}
        </div> : loading ? <div className="location-option">
           loading...
        </div> : ""}
    </div>;
    
};