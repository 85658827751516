import React, { useEffect } from 'react';
import Header from '../../LandingPage/common/header/Header';
import Pages from '../../LandingPage/pages/Pages';
import { PositionsStore } from "../stores/PositionsStore";


const Positions = () => {
    const position = PositionsStore.useState( state => state );

    const injectSection = () => {
        switch ( position["sectionSelected"] ) {
            // case 'Main':
            //     return <JobSearch />;
            case 'JobsAppliedTalent':
                return <div>Applied jobs will be shown here</div>;
                
            case 'Profile':
                return <div>User profile as a talent will be shown here</div>;
        }
    };
    
    useEffect( () => {
        PositionsStore.update( s => {
            s.homePage = true;
        } );
    } );


    return (
        <>
            <Header />
            {
                position.homePage && window.location.search === "" ?
                    <Pages />
                    :
                    <div
                        className={`${position["sectionSelected"] === 'Profile' ? 'container-fluid profilePublicWidth' : ''} bg-white`}>
                        {injectSection()}
                    </div>
            }
        </>
    );
};

export default Positions;