import React, { useEffect, Fragment } from "react";
import SAlert from "../../microComponents/AlertSA";
import Modal from "react-bootstrap/Modal";
import {
    faBan,
    faCheckCircle,
    faSave,
    faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSimpleForm from "./useSimpleForm";

export default function SimpleForm( {
    item,
    handleChange,
    isEdit,
    handleClick,
    catalogSelected,
    reloadData,
} ) {
    const {
        catalog,

        show,
        setShow,
        getCatalog,
        deleteItem,
        recategorizeTypes,
    } = useSimpleForm();  //moved the logit into useSimpleForm custom hook

    useEffect( () => {
        if ( catalogSelected === 1 || catalogSelected === 2 ) {
            getCatalog( catalogSelected ).then( null );
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [catalogSelected] );

    return (
        <Fragment>
            <table className="table">
                <thead />
                <tbody>
                    {catalogSelected === 2 ? (
                        <Fragment>
                            <tr>
                                <th>Category</th>
                            </tr>
                            <tr>
                                <td>
                                    <select
                                        name="category_id"
                                        value={item.category_id}
                                        onChange={handleChange}
                                        className="form-control"
                                    >
                                        <option value={null} />
                                        {catalog.map( ( c ) => {
                                            return (
                                                <option value={c["id_asset_category"]}>{c.name}</option>
                                            );
                                        } )}
                                    </select>
                                </td>
                            </tr>
                        </Fragment>
                    ) : (
                        <Fragment />
                    )}

                    <tr>
                        <th>Title</th>
                    </tr>
                    <tr>
                        <td>
                            <input
                                type="text"
                                name="name"
                                value={item.name}
                                onChange={handleChange}
                                className="form-control"
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>Description</th>
                    </tr>
                    <tr>
                        <td>
                            <textarea
                                name="description"
                                value={item.description || ""}
                                cols="30"
                                rows="10"
                                onChange={handleChange}
                                className="form-control"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="buttons">
                {isEdit ? (
                    <button
                        onClick={() =>
                            catalogSelected === 1
                                ? setShow( {
                                    ...show,
                                    alert: true,
                                    id: item.category_id,
                                    modal: false,
                                } )
                                : deleteItem( catalogSelected, item, reloadData )
                        }
                        className="btn btn-danger sizeBtn activeBtn float-left m-2"
                    >
                        <FontAwesomeIcon icon={faBan} className="mr-2" />
            Delete
                    </button>
                ) : (
                    ""
                )}

                <button
                    onClick={handleClick}
                    className="btn btn-success sizeBtn activeBtn m-2"
                >
                    <FontAwesomeIcon
                        icon={isEdit ? faSave : faSyncAlt}
                        className="mr-2"
                    />
                    {isEdit ? "Update" : "Save"}
                </button>

                <button
                    onClick={handleClick}
                    className="btn btn-danger sizeBtn activeBtn m-2"
                >
          Cancel
                </button>

                {show.alert ? (
                    <SAlert
                        show={show.alert}
                        confirmText="Yes"
                        typeButton="danger"
                        msg="Delete this category?"
                        hideAlert={() => setShow( { ...show, alert: false } )}
                        onConfirm={() => setShow( { ...show, alert: false, modal: true } )}
                    />
                ) : (
                    ""
                )}

                <Modal
                    show={show.modal}
                    onHide={() => setShow( { ...show, modal: false } )}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Recategorize</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Select a new category</p>
                        <select
                            className="form-control"
                            onChange={( e ) =>
                                setShow( { ...show, selectedReplace: e.target.value } )
                            }
                        >
                            <option value={null} />
                            {catalog
                                .filter(
                                    ( c ) => item["id_asset_category"] !== c["id_asset_category"]
                                )
                                .map( ( c ) => {
                                    return (
                                        <option value={c["id_asset_category"]}>{c.name}</option>
                                    );
                                } )}
                        </select>
                        <div className="text-center mt-2">
                            <button
                                onClick={() =>
                                    recategorizeTypes( catalogSelected, item, reloadData )
                                }
                                className="btn btn-success btn-sm"
                            >
                                <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                Recategorize elements
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </Fragment>
    );
}
