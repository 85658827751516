import React, { useEffect, useState } from "react";
import { Chip, CircularProgress } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { TalentStore } from "../../TalentStore";

export default function UploadResumeForm( {
	                                         resumeLoading,
	                                         onUploadNewResume,
	                                         onCancelResumeUpload,
	                                         isVisible,
	                                         onChangeHandler,
	                                         updateStoredTalentResume,
	                                         talent_id,
    resumeData
} ) {
	
    const [isUploading] = useState( resumeLoading );
	
   
       const onChangeHandler1 = (event)=>{
        onChangeHandler(event.target.files[0])
            onUploadNewResume( talent_id, updateStoredTalentResume,event.target.files[0] );
           
    }
	

    if ( isUploading ) {
        return <div className="text-center"><CircularProgress className="m-2"/></div>;
    }
	
    return (
        <div className="row">
          
            <div className="form-group col-md-6 mb-0">
             Resume   {resumeData? <label htmlFor="filesX" className={`btn btn-secondary sizeBtn activeBtn ${isVisible}`} style={{ marginLeft:'1rem' }}>
					update
                </label>
                    :
                    <label htmlFor="filesX" className={"btn btn-secondary sizeBtn activeBtn"} style={{ marginLeft:'1rem' }}>
                upload
                    </label>
                }

                {resumeData?<Chip classes={`${isVisible}`}
                    label= {resumeData.name}
                    onDelete={() => onCancelResumeUpload()} className="iconGray rounded text-primary ml-3" />
                    : null}
                <input
                    style={{ display: "none" }}
                    type="file"
                    id="filesX"
                    multiple={false}
                    onChange={onChangeHandler1}
                    name="file"
                    accept=".pdf,.doc,.docx"
                />
            </div>
          
            {/* <div className="col-md-6">
                <div className={`text-center ${isVisible}`}>
                    <button
                        className={"btn btn-secondary sizeBtn activeBtn"} 
                        onClick={onCancelResumeUpload}
                    >
						Cancel
                    </button>
                   
					
                </div>
            </div> */}
        </div>
    );
	
}