import util from "../../../utils/miniUtils";
import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClock, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Collapse from '@material-ui/core/Collapse';

export default function ApplyToJob( { 
    job, 
    // showScreening,
    jobSq,
    setShowScreening 
} ) {
    
	
    const [loading, setLoading] = useState( false );
    const [showMore, setShowMore] = useState( false );
   
    const handleShow = () => setShowScreening( true );

    const applyJob = async () => {
        try {
            setLoading( true );
            const data = {
                id: util.user.candidate_id,
                fromTalent: true
            };
            const request = await util.axios.post( `/requirements/add_candidate_new_method/${job.id}`, { data: data, sqResponse: [] } );
            const { error, msg } = request["data"];
            util.toast().success( msg );
            setTimeout( ()=>{
                window.location.reload();
            },1500 );
            if ( error ) throw  msg;
			
        } catch ( e ) {
            util.handleError( e );
        }
    };

    function handleApplyToJob(){
        if( jobSq ) {
            handleShow();
        } else {
            applyJob();
        }
    }
	
    return(
        <Fragment>
            <p className="detailLocation">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-1 iconGray"/>
                {util.location( job.country, job.state, job.city )}
            </p>
            <p className="detailDuration">
                <FontAwesomeIcon icon={faClock} className="mr-1 iconGray"/>
				Duration: <u>{job.duration}</u>
            </p>
            <p className="detailDuration">
				Job ID: <b>{util.zeroPad( job.id, 5 )}</b>
            </p>
            <div>
                <Collapse in={showMore} collapsedHeight={100}>
                    <div className="text-justify" dangerouslySetInnerHTML={{ __html:job.job_description }}/>
                </Collapse>
                <div className="text-right">
                    <span
                        onClick={()=>setShowMore( !showMore )}
                        className="likeLink ">
                        {showMore ? "Show less": "Show More"}
                    </span>
                </div>
            </div>
			
            <div className="d-flex justify-content-center">
                <button
                    onClick={handleApplyToJob}
                    className="btn btn-sm btn-primary"
                >
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    {jobSq ? "Proceed" : "Apply"} 
                </button>
                
            </div>
            <p className="text-center mt-2">
				By applying to this job you are accepting the <b>terms and conditions</b>
            </p>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}