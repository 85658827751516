export const initializeState = ( questions ) => {
    return {
        type: "INITIALIZE_STATE",
        payload: questions
    };
};

export const handleQuestionUpdate = ( name, value, questionIndex ) => {
    return { 
        type: "HANDLE_QUESTION_UPDATE",
        payload: {
            name,
            value,
            questionIndex
        }
    };
};

export const handleQuestionTypeUpdate = ( questionIndex, value ) => {
    return { 
        type: "HANDLE_QUESTION_TYPE_UPDATE",
        payload: {
            value,
            questionIndex
        }
    };
};

export const addSq = () => {
    return {
        type: 'ADD_QUESTION'
    };
};

export const removeSq = ( questionIndex ) => {
    return {
        type: 'REMOVE_QUESTION',
        payload: questionIndex
    };
};

export const handleOptionUpdate = ( value, optionIndex, questionIndex ) => {
    return {
        type: 'HANDLE_OPTION_UPDATE',
        payload: {
            value, optionIndex, questionIndex
        }
    };
};

export const addSqOption = ( questionIndex ) => {
    return {
        type: 'ADD_SQ_OPTION',
        payload: questionIndex
    };
};

export const removeSqOption = ( optionIndex, questionIndex ) => {
    return {
        type: 'REMOVE_SQ_OPTION',
        payload: { optionIndex, questionIndex }
    };
};

export const addQuestionFromLibrary = ( question ) => {
    return {
        type: 'ADD_QUESTION_FROM_LIBRARY',
        payload: { ...question }
    };
};

export const addQuestionsFromTemplate = ( templateQuestions ) => {
    return {
        type: 'ADD_QUESTION_FROM_TEMPLATE',
        payload: [...templateQuestions]
    };
};