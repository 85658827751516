import React, { useEffect, useState } from "react";
import {
    Card,   
    CardContent,
} from "@material-ui/core";
import util from "../../utils/miniUtils";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../redux/actions/datefilter";
import Select from "react-select";
import FunnelChart from "./RecruiterFunnel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";


const RecruiterReport = () => {
    const [recruiters, setRecruiters] = useState( [] );
    const [selectedRecruiters, setSelectedRecruiters] = useState( [] );
    const [selectedCountries, setSelectedCountries] = useState( [] );
    const [selectedLeads, setSelectedLeads] = useState( [] );
    const [recruiterOptions, setRecruiterOptions] = useState( [] );
    const [countriesOptions, setCountriesOptions] = useState( [] );
    const [leadsOptions, setLeadsOptions] = useState( [] );
    const [parentStatusList, setParentStatusList] = useState( [] );
    const [originalRecruiterReport, setOriginalRecruiterReport] = useState( [] );
    let { from, to, applyFilter } = useSelector( ( state ) => state.filter ) || {};

    const dispatch = useDispatch();
    
    useEffect( () =>{
        if( applyFilter ) {
            getStatusList();
            getRecruiterReports();
        }
    },[applyFilter] );

    const getRecruiterReports = async (  ) => {
        try {
            if( from === undefined ){
                const currentDate = new Date();
                from = currentDate.getFullYear() + '-' +
                  ( currentDate.getMonth() + 1 ) + '-' +
                  currentDate.getDate();
            }
            if( to === undefined ){
                const currentDate = new Date();
                to = currentDate.getFullYear() + '-' +
                ( currentDate.getMonth() + 1 ) + '-' +
                currentDate.getDate();
            }
            const response = await util.axios.get(
                `new_dashboard/get_recruiter_report?from=${from}&to=${to}`
            );
            
            const { result, error, msg } = response.data;
            setOriginalRecruiterReport( result );
            setRecruiters( result );
            
            const uniqueLeadRecruiters = [...new Set( result?.map( ( item ) => item.leadRecruiterName ) )];
            const uniqueCountries = [...new Set( result?.map( ( item ) => item.job_country ) )];
            const uniqueSalesLead = [...new Set( result?.map( ( item ) => item.salesLeadName ) )];
            const leadRecruiterOptions = uniqueLeadRecruiters?.map( ( recruiter ) => ( { label: recruiter, value: recruiter } ) );
            const countryOptions = uniqueCountries?.map( ( countries ) => ( { label: countries, value: countries } ) );
            const salesLeadOptions = uniqueSalesLead?.map( ( leads ) => ( { label: leads, value: leads } ) );
            setCountriesOptions( countryOptions );
            setRecruiterOptions( leadRecruiterOptions );
            setLeadsOptions( salesLeadOptions );
            dispatch( setFilter( false ) );
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    };
    const getStatusList = async () => {
        try{
            const request = await util.axios.get( '/job_talent_status/list/parentStatus' );
            const { data, error, msg } = request.data;
            if( error ) throw msg;   
            
            setParentStatusList( data );
            
        } catch ( e ) {
            util.handleError( e );
        }
    };
    const getTotalValue =() =>{
        const statusSum = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0 };

        // Iterate through the 'data' array and calculate the sum
        recruiters.forEach( item => {
            Object.keys( statusSum ).forEach( status => {
                statusSum[status] += item[status];
            } );
        } );
        return statusSum;
    };
    const totalValues = getTotalValue();
    // useEffect( () => {
    //     getStatusList();
    // },[applyFilter] );
    const handleRecruiterChange = ( selectedRecruiters ) =>{
        setSelectedRecruiters( selectedRecruiters );
        
        const filteredreport = originalRecruiterReport?.filter( ( item ) => ( selectedRecruiters?.length === 0 ||selectedRecruiters?.some( sel =>sel.value === item.leadRecruiterName ) ) && ( selectedCountries?.length === 0 || selectedCountries?.some( ( el ) => el.value === item.job_country ) ) && ( selectedLeads?.length === 0 || selectedLeads?.some( ( el ) => el.value === item.salesLeadName ) ) );
        
        setRecruiters( filteredreport );


    };
    const handleCountryChange = ( selectedCountries ) => {
        setSelectedCountries( selectedCountries );
        const filteredReport = originalRecruiterReport?.filter( ( item ) => ( selectedCountries?.length === 0 || selectedCountries?.some( ( el ) => el.value === item.job_country ) ) && ( selectedRecruiters.length === 0 || selectedRecruiters?.some( ( el )=> el.value === item.leadRecruiterName ) ) && ( selectedLeads?.length === 0 || selectedLeads?.some( ( el ) => el.value === item.salesLeadName ) ) );
        setRecruiters( filteredReport );
        
    };
    const handleLeadChange = ( selectedLeads ) => {
        setSelectedLeads( selectedLeads );
        const filteredReport = originalRecruiterReport?.filter( ( item ) => ( selectedLeads?.length === 0 || selectedLeads?.some( ( el ) => el.value === item.salesLeadName ) ) && ( selectedRecruiters.length === 0 || selectedRecruiters?.some( ( el )=> el.value === item.leadRecruiterName ) ) && ( selectedCountries?.length === 0 || selectedCountries?.some( ( el ) => el.value === item.job_country ) ) );
        setRecruiters( filteredReport );
        
    };
    const exportToCsv = () => {
        let headings = [];
        const heading = [
            "Job Title",
            "Lead Recuiter Name",
            "Job Id",
            "Client Name",
            "Sales Lead Name",
            "Country",
        ];
        const statusArray = parentStatusList.map( ( item ) => item.name );
        
        headings = heading.concat( statusArray );
        
        const csvContent =
          "data:text/csv;charset=utf-8," +
          [headings.join( ',' ), ...recruiters.map( row =>
              [
                  row.job_title,
                  row.leadRecruiterName,
                  row.job_id,
                  row.clientName,
                  row.salesLeadName,
                  row.job_country,
                  row['1'],
                  row['2'],
                  row['3'],
                  row['4'],
                  row['5'],
                  row['6'],
                  row['7'],
                  row['94'],
                  row['97'],
                  row['106'],
                  row['107'],

              ].join( ',' )
          )].join( '\n' );
      
        const encodedUri = encodeURI( csvContent );
        const link = document.createElement( "a" );
        link.setAttribute( "href", encodedUri );
        link.setAttribute( "download", "table-export.csv" );
        document.body.appendChild( link );
        link.click();
    };

    return (
        <Card style={{ width: "100%", height: "90%", overflow: 'hidden' }}>
            <CardContent style={{ height: "100%" }} >
                <div style={{ height: "100%", overflow: "auto" }}>
                    <div className="recruiter-filter">

                        <Select 
                            isMulti
                            placeholder="Select Lead Recruiters..."
                            value={selectedRecruiters}
                            onChange={handleRecruiterChange}
                            options={recruiterOptions}
                            className="mr-2 non-draggable"
                        />  
                        <Select 
                            isMulti
                            placeholder="Select Countries.."
                            value={selectedCountries}
                            options={countriesOptions}
                            onChange={handleCountryChange}
                            className="mr-2 non-draggable"
                        />                  
                        <Select 
                            isMulti
                            placeholder="Select Sales Lead.."
                            value={selectedLeads}
                            options={leadsOptions}
                            onChange={handleLeadChange}
                            className="mr-2 non-draggable"
                        />   
                        <div className={"btn btn-secondary jobButton activeBtn btn-sm export-onboarded non-draggable"} onClick={exportToCsv}>
                            <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                                Export All
                        </div>       

                    </div>
                    <div className="recruiter-funnel">
                        <div className="funnel-report">
                            <h4 className="funnel-total">Total</h4>
                            { parentStatusList?.length>0 && recruiters?.length > 0 &&   (     <FunnelChart parentStatusList={parentStatusList} data={totalValues} /> )}

                        </div>
                        <table className="recruiter-report-table">
                            <thead>
                                <tr>
                                    <th>Job Title </th>
                                    <th>Lead Recuiter Name</th>
                                    <th>Job Id</th>
                                    {/* <th>Last Change User</th> */}
                                    <th>Client Name</th>
                                    {/* <th>Partner Name</th> */}
                                    <th>Sales Lead Name</th>
                                
                                    <th>Country</th>
                                    {parentStatusList?.map( ( el, index ) => {

                                        return  ( <th key={index}>{el.name}</th> );
                                    } )}
                                    {/* <th>Submitted Today</th> */}
                                </tr>
                            </thead>
                            <tbody className="recruiter-report-body">
                                {recruiters?.map( ( item,index ) => (
                                    <tr key={index} className="recruiter-report-rows">
                                        {/* <td>{item.recruiter_name}</td> */}
                                        <td>{item.job_title}</td>
                                        <td>{item.leadRecruiterName}</td>
                                        <td>{item.job_id}</td>
                                        {/* <td>{item.lastChangeUser}</td> */}
                                        <td>{item.clientName}</td>
                                        {/* <td>{item.partnerName}</td> */}
                                        <td>{item.salesLeadName}</td>
                                    
                                        {/* <td>{item.job_country}</td> */}
                                        <td>
                                            <img width={25} src={util.countryFlag( item.job_country )} alt={item.job_country} className="mr-1"/>
                                            {item.job_country}</td>
                                    
                                        {Object.keys( item ).map( ( key ) => {
                                            if ( /^\d+$/.test( key ) ) {
                                                return <td key={key} >{item[key]}</td>;
                                            }
                                            return null; 
                                        } )}

                                    </tr>
                                ) )}
                            </tbody>
                        </table>
                        
                    </div>
                    
                </div>
                
   
            </CardContent> 
        </Card>
    );
};

export default RecruiterReport;

