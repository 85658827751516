import { faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createTheme, MuiThemeProvider, Popover } from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const DisplayComments = ( { statusNote, isStr } ) => {
    const [state, setState] = useState( [] );

    // Anchor and open variable for the popover. 
    const [anchorEl, setAnchorEl] = React.useState( null );
    const open = Boolean( anchorEl );

    // Triggering the popover on click
    const handleClick = ( event ) => {
        setAnchorEl( event.currentTarget );
    };

    const handleClose = async ( ) => {
        // resetting the anchor for the popover
        setAnchorEl( null );
    };

    const theme = createTheme( {
        overrides: {
            MuiTooltip: {
                tooltip: {
                    maxWidth: "250px",
                    fontSize: "0.85em",
                }
            }
        }
    } );

    useEffect( () => {
        setState( statusNote ? [...statusNote] : [] );
    },[statusNote] );

    function editValue() {
        return <Popover
            id={open ? 'text-input-popover' : undefined}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}>
            <div className="positionCandidate-comment">
                {isStr ? <div>{state}</div> : <ul className="p-0 m-0">
                    {state.length > 0 && state.map( el => (
                        <CommentItem
                            key={el.timeStamp}
                            comment={el.comment ? el.comment?.value : el.statusName}
                            timeStamp={el?.timeStamp}
                            userName={el.comment ? el.comment?.user?.name : null} 
                            prevStatus={el.comment ? el.comment?.prevStatus?.name: null}
                            presentStatus={el.statusName}
                        />
                    ) )}

                </ul>}
            </div>
        </Popover>;
    }
    return <>
        {state.length > 0 ? <MuiThemeProvider theme={theme}>
            {editValue()}
            <span onClick={handleClick}>
                <FontAwesomeIcon className="text-secondary" icon={faComment} />
            </span>
        </MuiThemeProvider> : ""}
    </>;
};

export default DisplayComments;

const CommentItem = ( { comment, timeStamp, userName, prevStatus, presentStatus } ) => {
    return <li>
        <span>{timeStamp}</span>
        <strong className="bg-light px-1">{userName}</strong>
        <em className='bg-secondary text-light px-1 rounded'>at {presentStatus}</em>
        <span>: {comment}</span>
    </li>;
};