import React, { useState, Fragment } from "react";
import MaterialTable from "material-table";
import util from "../../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { MuiThemeProvider } from "@material-ui/core";
import JobDescription from "./JobDescription";
import { CLIENT_ROLE } from "../../../../../constants";

const user = util.user;

function ListOfRequirements() {
    const [jdModalVisible, setJdModalVisible] = useState( false );
    const [jobDescription, setJobDescription] = useState( "" );
    const [data, setData] = useState( [] );
    
    function jdHideModal() {
        setJdModalVisible( false );
    }
    
    function openModalJD( data ) {
        setJobDescription( data );
        setJdModalVisible( true );
    }

    return (
        <div>
            <MuiThemeProvider theme={util.defaultTableTheme}>
                <MaterialTable
                    title="Jobs positions"
                    columns={[
                        {
                            title: "Job. Id",
                            field: "id",
                            type: "numeric",
                            defaultSort: "desc",
                            render: ( row ) => {
                                return (
                                    <span className="likeLink" onClick={()=> openModalJD( row )}>
                                        {util.zeroPad( row.id, 5 )}
                                    </span>
                                );
                            },
                        },
                        {
                            title: "Location",
                            field: "country",
                            render: ( row ) => {
                                return util.location( row.country, row.state, row.city );
                            },
                        },
                        {
                            title: "Job Title",
                            field: "id",
                            width:"25%",
                            render: ( row ) => {
                                return (
                                    <span className="likeLink" onClick={()=> openModalJD( row )}>
                                        {row.job_title}
                                    </span>
                                );
                            },
                        },
                        {
                            title: "Rate",
                            field: "sell_rate",
                            hidden: user.role === CLIENT_ROLE,
                            render: data => {
                                if ( user.role === CLIENT_ROLE ){
                                    if ( data.personName ){
                                        return `--`;
                                    }else{
                                        return `$${data.sell_rate}/${util.noValues.includes( data.sell_rate_type ) ? "Hour":data.sell_rate_type}`;
                                    }
                                }else{
                                    if ( user.role === 4 ){
                                        return `$${data.sell_rate}/${util.noValues.includes( data.sell_rate_type ) ? "Hour":data.sell_rate_type}`;
                                    }
                                }
                            }
                        },
                        {
                            title: "Talents", field: "talentCount",
                            render: row => {
                                return (
                                    <span className="likeLink" onClick={()=> openModalJD( row )}>
                                        {row.talentCount}
                                    </span>
                                );
                            }
                        },
                        {
                            title: "Date", field: "createdAt",
                            render: data => {
                                return <span className="textSmall">{util.humanDate( data.createdAt, false )}</span>;
                            }
                        },
                        { title: "Partner", field: "company",
                            render:data =>{
                                return ( data.company ) ? data.company: <span className="badge badge-secondary ">Direct Client</span>;
                            }
                        },
                        { title: "Partner Contact", field: "personName",
                            render:data =>{
                                return ( data.personName ) ? data.personName: <span className="badge badge-secondary ">Direct Client</span>;
                            } },
                        {
                            title: "Client", field: "endClientName" },
                        
                        {
                            title: <div className="text-center">Status</div>,
                            field: "status",
                            render: ( row ) => {
                                return <i>{util.jobStatusOnlyIcons( row.status )}</i>;
                            },
                        },
                        
                    ]}
                    data={query =>
                        util.axios.get( util.urlHandlerJobPositions2( query ) )
                            .then( response => {
                                const { jobPositions, total, page, error, msg } = response.data;
                                if ( error ) throw  msg;
                                setData( jobPositions );
                                return ( {
                                    data: jobPositions,
                                    page: page,
                                    totalCount: total
                                } );
                            } )
                            .catch( error=>{
                                util.handleError( error );
                            } )
                    }
                    options={{
                        sorting: true,
                        showTitle:false,
                        toolbarButtonAlignment: "left",
                        searchFieldAlignment:"left",
                        pageSize: 50,
                        pageSizeOptions:[50, 100, 150],
                        emptyRowsWhenPaging: false,
                    }}
                    actions={[
                        {
                            icon: () => <div className={"btn btn-secondary btn-sm activeBtn"}>
                                <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                                Export
                            </div>,
                            tooltip: "Export All",
                            isFreeAction: true,
                            onClick: () => util.tableToCSV()
                        }
                    ]}
                />
            </MuiThemeProvider>

            {/* HTML TABLE TO EXPORT */}
            <table id="#exportTable" className="displayOff">
                <thead>
                    <tr>
                        <td>Job ID</td>
                        <td>Location</td>
                        <td>Job Title</td>
                        {
                            user.role === CLIENT_ROLE ?
                                null
                                :
                                <Fragment>
                                    <td>Sale Rate</td>
                                    <td>Offer Type</td>
                                </Fragment>
                        }
                        
                        
                        <td>Talents</td>
                        <td>Date</td>
                        <td>Partner</td>
                        <td>Partner Contact</td>
                        <td>Client</td>
                        <td>Status</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.length > 0 && data.map( ( c, i ) => {
                            return(
                                <tr key={i}>
                                    <td>{util.zeroPad( c.id, 5 )}</td>
                                    <td>{util.location( c.country, c.state, c.city )}</td>
                                    <td>{c.job_title}</td>
                                    {
                                        user.role === CLIENT_ROLE ?
                                            null
                                            :
                                            <Fragment>
                                                <td>{util.currencyFormat( data.sell_rate )}</td>
                                                <td>{util.noValues.includes( c.sell_rate_type ) ? "Hour":c.sell_rate_type }</td>
                                            </Fragment>
                                    }
                                    <td>{c.talentCount}</td>
                                    <td>{util.humanDateTime( c.createdAt )}</td>
                                    <td>{c.company}</td>
                                    <td>{c.personName}</td>
                                    <td>{c.endClientName}</td>
                                    <td>{util.jobStatusOnlyText( c.status )}</td>
                                    
                                </tr>
                            );
                        } )
                    }
                </tbody>
            </table>

            {/*Modal for Job Description - FOR TALENT, Client and Partner */}
            <JobDescription jdModalVisible={jdModalVisible} jdHideModal={jdHideModal}  jobDescription={jobDescription} />
        </div>
    );
}


export default ListOfRequirements;
