import React, { Fragment, useEffect, useMemo, useState } from "react";
import PhoneInput from "react-phone-input-2";
import util from "../../../utils/miniUtils";
import selector from "../../../utils/selector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import {
    // Collapse,
    InputAdornment,
    TextField,
    // FormControl,InputLabel, Select
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { currency } from '../../../utils/currencies';
import usePersonalInformation from "./usePersonalInformation";
import { TalentStore } from "../TalentStore";
import ResumeContainer from "./upload_resume/ResumeContainer";
import CountryAutoFill from "../../../talents/components/listComponents/CountryAutoFill";
import Location from "../../../talents/components/listComponents/Location";
import { setDefaultLocale } from "javascript-time-ago";
import LocationView from "../../../talents/components/listComponents/LocationView";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

export default function ProfileTalent( { isResumeAvailable,talentState,setTalentState } ) {
    const {
        // handleChanges,
        setData,
        getListOfJobTitles,
        jobTitles,
        selected,
        setSelected,
        updateJobTitles
    } = usePersonalInformation();

    // const [talentState, setTalentState] = useState( {} );
    const [showVideo, setShowVideo] = useState( false );

    const handleLinkClick = () => {
        setShowVideo( true );
    };
    

    function handleChanges( e ){
        const { name, value } = e.target;
        setTalentState( prevState => ( {
            ...prevState,
            [name]: value
        } ) );
    }
    
    // const style = {
    //     maxWidth: 120
    // };
	
    const talent = TalentStore.useState( state => state.talent );
    // const [showMoreFields, setShowMoreFields] = useState( false );
	
    useEffect( () => {
        setSelected( talent.jobTitles );
        // eslint-disable-next-line
    }, [] );
	
    useEffect( () => {
        iconDependingCurrencySelected();
        // eslint-disable-next-line
    }, [talent.currency] );
	
    useEffect( () => {
        getListOfJobTitles();
        // eslint-disable-next-line
    }, [] );
	
    useEffect( () => {
        updateJobTitles();
        // eslint-disable-next-line
    }, [selected] );
	
    useMemo( () => {
        setTalentState( talent );
    },[talent] );

    if ( talent === null ) {
        return "loading...";
    }
   
    function iconDependingCurrencySelected() {
        for ( let x = 0; x < currency.length; x++ ) {
            const c = currency[x];
            if ( c.code === talent.currency || c.symbol === talent.currency ) {
                return c.symbol;
            }
        }
        return `$`;
    }
   
    return (
        <Fragment>
            <div className="d-flex">
                <div className="col-md-6 mt-2">
                    {/* <p className="ml-3"><span>Full Name:</span> {talentState?.name || ""}</p>
                    <div className="mt-3">
                        <p className="ml-3"><span>Email:</span> {talentState?.email || ""}</p>
                    </div> */}
                    <div className="col-md-12 mt-3">
                        <p><span>Job Title:</span> <span className="ml-5">{selected.map( job => job.name ).join( ", " )}</span></p>
                    </div>


                    <LocationView
                        data={talentState} // contains everything
                        onChange={handleChanges}
                    />

                    <div className="mt-3">
                        <div className="col-md-12">
                            <p><span>Currency:</span> <span className="ml-5">{talentState?.currency}</span></p>
                        </div>
                        <div className="col-md-12">
                            <p><span>Relocation:</span> <span style={{ marginLeft:'2.3rem' }}>{talentState?.relocation === "1" ? "Yes" : "No"}</span></p>
                        </div>
                    </div>

                    <div className="mt-3">
                        <div className="col-md-12">
                            <p><span>Current Salary:</span><span className="ml-3"> {talentState?.current_salary ? `${talentState.current_salary} ${iconDependingCurrencySelected()}` : "0"}</span></p>
                        </div>
                        <div className="col-md-12">
                            <p><span>Expected Salary:</span> <span className="ml-1">{talentState?.ctc ? `${talentState.ctc} ${iconDependingCurrencySelected()}` : "0"}</span></p>
                        </div>
                    </div>
                    <div className="">
                        <p className="col-md-12"><span>Per:</span><span style={{ marginLeft:'6rem' }}> {util.handleValueByRecruiterCountry( talentState?.salary_mode, 'salary_mode' )}</span></p>
                    </div>
                </div>


                <div className="col-md-6 mt-4">
                    <p className="col-md-12"><span>Employment Type:</span><span className="ml-3"> {talentState?.salary_type || ""}</span></p>

                    <div className="col-md-12 mt-3">
                        <p><span>Work Authorization:</span><span className="ml-2"> {talent.working_status !== "other" ? talent.working_status : "Other"}</span></p>
                        {talent.working_status === "other" ? (
                            <p><span>Work Authorization (Others):</span><span className="ml-2"> {talentState?.working_status_other || ""}</span></p>
                        ) : null}
                    </div>

                  
                    {/* <div className="col-md-12 mt-3">
                        <p><span>Work Authorization:</span> {talent.working_status !== "other" ? talent.working_status : "Other"}</p>
                        {talent.working_status === "other" ? (
                            <p><span>Work Authorization (Others):</span> {talentState?.working_status_other || ""}</p>
                        ) : null}
                    </div> */}
                    <div className=" mt-3">
                        <p className="col-md-12"><span>Highest Education:</span> <span className="ml-3">{talentState?.highestEducation || ""}</span></p>
                        <p className="col-md-12"><span>Last Job Title:</span> <span className="ml-5">{talentState?.wh_position || ""}</span></p>
                    </div>
                    <div className=" mt-3">
                        <p className="col-md-12"><span>Notice Period:</span> <span className="ml-5">{talentState?.noticePeriod || "2 Weeks"}</span></p>
                        <p className="col-md-12"><span>How did you find us:</span><span className="ml-2"> {talentState?.platform || ""}</span></p>
                    </div>
                   
                    <div className=" mt-3">
                        <span className="d-flex col-md-12">Phone (Home): <p><span className="ml-5">+{talentState?.phone_home || ""}</span></p></span>
                       
                        <span className="d-flex col-md-12">Phone (Other):  <p><span className="ml-5">+{talentState?.phone_other || ""}</span></p></span>
                       
                    </div>
                        
                    <div className="d-flex col-md-12 mt-2">
                        <span>Resume:</span>
                        {/* Replace the ResumeContainer component with the content you want to display */}
                        {/* <ResumeContainer talent={talent}/> */}
                        {/* For example, you can display the file name if the talent has uploaded a resume */}
                        <span> <a
                            target="new"
                            href={isResumeAvailable( talent )}
                            // className={"btn btn-secondary sizeBtn activeBtn"}
                            className="isCursor"
                        ><p style={{ marginLeft:'5.5rem' }}>{talentState.resume ? 'View Resume' : "No resume uploaded"}</p></a></span>
                    </div>
                    {/* <div className="d-flex col-md-12 mt-2">
                        <div>Click to watch intro video:</div>
                       
                    </div>
                    <div className="d-flex col-md-12 mt-2">
                        <Link to="#">
                            <div onClick={handleLinkClick}>
                                {talentState?.video_profile || 'Click to view video'}
                            </div>
                        </Link>
                        {showVideo && (
                            <ReactPlayer
                                url={talentState?.video_profile || ''}
                                width="100%"
                                height="auto"
                                controls
                            />
                        )}
                    </div> */}
                </div>

            </div>
			
            
            
        </Fragment>
    );
	
}
