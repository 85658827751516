import MaterialTable from "material-table";
import React, { Fragment, useEffect, useState } from "react";
import util from "../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import NewCertificationDialog from "./NewCertificationDialog";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function TableOfCertifications( { talent_id,isEditing } ) {
	
    const [certificationsFromTalent, setCertificationsFromTalent] = useState( [] );
    const [loading, setLoading] = useState( false );
    const [showModal, setShowModal] = useState( false );
    const [editMode, setEditMode] = useState( false );
    const [certData, setCertData] = useState( null );
	
    const startLoading = () => setLoading( true );
    const stopLoading = () => setLoading( false );
	
    useEffect( () => {
        getCertificationsFromTalent().then( null );
    }, [] );
	
    const getCertificationsFromTalent = async () => {
        try {
            setLoading( true );
            const response = await util.axios.get( `talent/certification_list/${talent_id}/` );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            setCertificationsFromTalent( Object.assign( [], data ) );
            setLoading( false );
        } catch ( e ) {
            setLoading( false );
            util.handleError( e );
        }
    };
	
    const deleteCertification = async ( row, reloadData ) => {
        try {
            startLoading();
            let { id } = row;
            const response = await util.axios.delete( `talent/certification_delete/${talent_id}/${id}/` );
            const { error, msg, data } = response["data"];
            if ( error ) throw new Error( msg );
            await getCertificationsFromTalent();
            util.toast().success( "Certification Deleted" );
            stopLoading();
        } catch ( e ) {
            stopLoading();
            util.handleError( e );
        }
    };
	
    function editCertification( row ) {
        // console.log(row)
        setCertData( row );
        setEditMode( true );
        setShowModal( true );
    }
	
    function newCertification () {
        // console.log(row)
        setCertData( {
            certification: null,
            organization: null,
            notExpire: 1,
            issueDate: "",
            expirationDate: "",
            credential_id: "",
            credential_url: "",
        } );
        setEditMode( false );
        setShowModal( true );
    }
    const titleColor = certificationsFromTalent.length === 0 ? 'gray' : 'black';
    const tooltip = (
        <Tooltip id="tooltip">
          Add new certification
        </Tooltip>
    );
    return (
        <Fragment>
            {/* <MaterialTable
                isLoading={loading}
                columns={[
                    {
                        title: 'Certification Name', field: 'certificationName',
                    },
                    {
                        title: 'Company', field: 'company',
                    },
                    {
                        title: 'Issue Date', field: 'issueDate',
                        render: row => util.humanDate( row.issueDate )
                    },
                    {
                        title: 'Expire?', field: 'notExpire',
                        render: row => row.notExpire === 1 ? "No" : "Yes"
                    },
                    {
                        title: 'Expiration Date', field: 'expirationDate',
                        render: row => util.humanDate( row.expirationDate )
                    },
                    {
                        title: '', sorting: false,
                        width: "5%",
                        render: row => {
                            return (
                                <FontAwesomeIcon
                                    className="iconGray isPointer"
                                    onClick={() => editCertification( row )}
                                    icon={faPencilAlt}
                                />
                            );
                        }
                    },
                    {
                        title: '', sorting: false,
                        width: "5%",
                        render: row => {
                            return (
                                <util.PopOverConfirmation
                                    callBackOk={() => deleteCertification( row )}
                                    callBackNo={() => console.log( "is fail" )}
                                />
							
                            );
                        }
                    },
                ]}
                data={certificationsFromTalent}
                title="Certifications"
                options={{
                    pageSize: 10,
                    sorting: true,
                    search: true,
                    showFirstLastPageButtons: true,
                    pageSizeOptions: [10, 30, 50, 100],
                    padding: "dense",
                    paginationType: "normal",
                    tableLayout: "auto",
                    addRowPosition: "first",
                    emptyRowsWhenPaging: false,
                    toolbar: true
                }}
                actions={[
                    {
                        icon: () => <FontAwesomeIcon icon={faPlus}/>,
                        isFreeAction: true,
                        tooltip: `Add new certification`,
                        onClick: () => {
                            newCertification();
                        }
                    }
                ]}
            /> */}
            <div className="container-fluid">
                <b className="custom-title" style={{ color:titleColor }}>Certifications</b>
                {isEditing? <OverlayTrigger placement="top" overlay={tooltip}>
                    <FontAwesomeIcon
                        className="custom-icon"
                        icon={faPlus}
                        onClick={newCertification}
                    />
                </OverlayTrigger> : ""}
                <table className="custom-table">
                    {certificationsFromTalent.length===0 ? "" : <thead>
                        <tr>
                            <th style={{ width: '30%',fontSize:'14px' }}>Certification Name</th>
                            <th style={{ width: '20%',fontSize:'14px' }}>Company</th>
                            <th style={{ width: '15%',fontSize:'14px' }}>Issue Date</th>
                            <th style={{ width: '10%',fontSize:'14px' }}>Expire</th>
                            <th style={{ width: '15%',fontSize:'14px' }}>Expiration Date</th>
                            <th style={{ width: '5%',fontSize:'14px' }}></th>
                            <th style={{ width: '5%',fontSize:'14px' }}></th>
                        </tr>
                    </thead>}
                    <tbody>
                        {certificationsFromTalent.map( ( row, index ) => (
                            <tr key={index}>
                                <td style={{ fontSize:'14px' }}>{row.certificationName}</td>
                                <td style={{ fontSize:'14px' }}>{row.company}</td>
                                <td style={{ fontSize:'14px' }}>{util.humanDate( row.issueDate )}</td>
                                <td style={{ fontSize:'14px' }}>{row.notExpire === 1 ? "No" : "Yes"}</td>
                                <td style={{ fontSize:'14px' }}>{util.humanDate( row.expirationDate )}</td>
                                <td style={{ fontSize:'14px' }}>
                                    <div className="custom-icon-container">
                                        <FontAwesomeIcon
                                            onClick={() => editCertification( row )}
                                            icon={faPencilAlt}
                                            style={{ width:'12px' }}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="util-popover-container">
                                        <util.PopOverConfirmation
                                            callBackOk={() => deleteCertification( row )}
                                            callBackNo={() => console.log( "is fail" )}
                                        />
                                    </div>
                                </td>
                            </tr>
                        ) )}
                    </tbody>
                </table>
            </div>
			
            {
                showModal ?
                    <NewCertificationDialog
                        talent_id={talent_id}
                        showModal={showModal}
                        editMode={editMode}
                        certData={certData}
                        reloadData={getCertificationsFromTalent}
                        hideModal={() => setShowModal( false )}
                    />
                    :
                    null
            }
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}