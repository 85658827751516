import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Modal, Button,  Spinner, Form, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash, faTimes,faCheck } from '@fortawesome/free-solid-svg-icons';
import util from "../../../utils/miniUtils";
import MaterialTable from "material-table";
import PermissionModal from './PermissionModal';
import RoleModal from './RoleModal';

const RolePermissionPanel = () => {
    const [roles, setRoles] = useState( [] );
    const [permissions, setPermissions] = useState( [] );
    const [selectedRole, setSelectedRole] = useState( null );
    const [selectedPermission, setSelectedPermission] = useState( null );
    const [showRoleModal, setShowRoleModal] = useState( false );
    const [showPermissionModal, setShowPermissionModal] = useState( false );
    const [isLoading, setIsLoading] = useState( false ); 

    const fetchData = async () => {
        setIsLoading( true ); // Set loading to true when the fetch starts
        try {
            const rolesRes = await util.axios.get( '/role_permissions/role/list' );
            const permsRes = await util.axios.get( '/role_permissions/permission/list' );
            setRoles( rolesRes.data.data );
            setPermissions( permsRes.data.data );
        } catch ( error ) {
            console.error( 'Error fetching data', error );
        } finally {
            setIsLoading( false ); // Set loading to false once the fetch is completed
        }
    };

    const handlePermissionSave = async ( newPermissionData ) => {
        try {
            let request;
            if ( selectedPermission ) {
                request = await util.axios.put( `/role_permissions/update_permission/${selectedPermission.permission_id}`, newPermissionData );
            } else {
                request = await util.axios.post( `/role_permissions/create_permission`, newPermissionData );
            }
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
        fetchData();
    };

    const handleSaveRole = async ( newRoleData ) => {
        try {
            let request;
            if ( selectedRole ) {
                request = await util.axios.put( `/role_permissions/update_role/${selectedRole.role_id}`, newRoleData );
            } else {
                request = await util.axios.post( `/role_permissions/create_role`, newRoleData );
            }
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
        fetchData();
    };

    const handleDeleteRole = async ( id ) => {
        try {
            let request = await util.axios.put( `/role_permissions/delete_role/${id}` );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
        fetchData();

    };

    const handleDeletePermission = async ( id ) => {
        try {
            let request = await util.axios.put( `/role_permissions/delete_permission/${id}` );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
        } catch ( error ) {
            util.handleError( error );
        }
        fetchData();

    };
    

    useEffect( () => {
    // Fetch roles and permissions on component mount
        fetchData();
    }, [] );

    const handlePermissionSelect = ( permission ) => {
        setSelectedPermission( permission );
        setShowPermissionModal( true );
    };

    const handleRoleSelect = ( role ) => {
        setSelectedRole( role );
        setShowRoleModal( true );
    };

    return (
        <>
            {isLoading ? (
            // Display a loading indicator while the data is being fetched
                <div className="text-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <Tabs defaultActiveKey="roles" id="admin-panel-tabs">
                    <Tab eventKey="roles" title="Roles">
                        <MaterialTable
                            title="Role List"
                            columns={[
                                { title: 'Name', field: 'name' },
                                { title: 'Description', field: 'description' },
                                { title: 'Status', field: 'status', render: rowData => rowData.status === 0
                                    ? <FontAwesomeIcon icon={faCheck} className="text-success" />
                                    : <FontAwesomeIcon icon={faTimes} className="text-danger" />
                                }
                            ]}
                            data={roles}
                            actions={[
                                {
                                    icon: () =>  <FontAwesomeIcon icon={faPlus} onClick={() => {/* editRole */}} />,
                                    tooltip: 'Add Role',
                                    isFreeAction: true,
                                    onClick: ( event ) => {
                                        handleRoleSelect( null );
                                    }
                                },
                                {
                                    icon: () =>  <FontAwesomeIcon icon={faEdit} onClick={() => {/* editRole */}} />,
                                    tooltip: 'Edit Role',
                                    onClick: ( event, rowData ) => {
                                        handleRoleSelect( rowData );
                                    }
                                },
                                {
                                    icon: () =>  <FontAwesomeIcon icon={faTrash} onClick={() => {/* editRole */}} />,
                                    tooltip: 'Delete Role',
                                    onClick: ( event, rowData ) => {
                                        handleDeleteRole( rowData.role_id );
                                    }
                                }
                            ]}
                            options={{
                                actionsColumnIndex: -1
                            }}
                        />
                    </Tab>
                    <Tab eventKey="permissions" title="Permissions">
                        <MaterialTable
                            title="Permission List"
                            columns={[
                                { title: 'Name', field: 'name' },
                                { title: 'Module', field: 'module' },
                                { title: 'Action', field: 'action' },
                                { title: 'Description', field: 'description' }

                                // Add other columns as needed
                            ]}
                            data={permissions}
                            actions={[
                                {
                                    icon: () =>  <FontAwesomeIcon icon={faPlus} onClick={() => {/* editRole */}} />,
                                    tooltip: 'Add Permission',
                                    isFreeAction: true,
                                    onClick: ( event ) => {
                                        handlePermissionSelect( null );
                                    }
                                },
                                {
                                    icon: () =>  <FontAwesomeIcon icon={faEdit} onClick={() => {/* editRole */}} />,
                                    tooltip: 'Edit Permission',
                                    onClick: ( event, rowData ) => {
                                        handlePermissionSelect( rowData );
                                    }
                                },
                                {
                                    icon: () =>  <FontAwesomeIcon icon={faTrash} onClick={() => {/* editRole */}} />,
                                    tooltip: 'Delete Permission',
                                    onClick: ( event, rowData ) => {
                                        handleDeletePermission( rowData.role_id );
                                    }
                                }
                            ]}
                            options={{
                                actionsColumnIndex: -1
                            }}
                        />
                    </Tab>
                </Tabs>
            )};
            <RoleModal
                show={showRoleModal}
                onClose={() => setShowRoleModal( false )}
                role={selectedRole}
                onSave={handleSaveRole}
                isEditing={selectedRole != null}
            />

            {/* Permission Detail Modal */}
            <PermissionModal
                show={showPermissionModal}
                handleClose={() => setShowPermissionModal( false )}
                handleSave={handlePermissionSave}
                permission={selectedPermission}
            />
        </>
    );
};

export default RolePermissionPanel;

