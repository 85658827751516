import WelcomeComponent from './WelcomeComponent';
import ActiveJob from './ActiveJob';
import { CandidatesAddedByActiveRecruiter, CandidatesAddedByCountry, CandidatesAddedByRecruiter, CandidatesAddedByWorkingStatus, EmployeesByAppraisalScore, TalentAddedByPlatform, TalentStatus } from './ChartList';
import JobData from './JobData';
import CandidateByJobPosition from './CandidateByJobPosition';
import TalentDaysTab from './TalentDaysTab';
import util from '../../utils/miniUtils';
import { useState } from 'react';
import { useEffect } from 'react';
import RecruiterPerformance from './RecruiterPerformance';
import RecruiterDetails from './RecruiterDetails';
import RecruiterReport from './RecruiterReport';
import WorkloadByResolver from './WorkloadByResolver';
import WorkloadByCategory from './WorkloadByCategory';
import WorkloadByGroup from './WorkloadByGroup';
import SalesPerformance from './SalesPerformance';
import MonthlyReport from './MonthlyReport';
import IncentiveReport from './IncentiveReport';

const useGetCharts = () => {
    const [chartComp, setChartComp] = useState( [] );
    const componentMapping = {
        "WelcomeComponent": WelcomeComponent,
        "ActiveJob": ActiveJob,
        "JobData": JobData,
        "CandidatesAddedByActiveRecruiter": CandidatesAddedByActiveRecruiter,
        "CandidateByJobPosition": CandidateByJobPosition,
        "CandidatesAddedByCountry": CandidatesAddedByCountry,
        "CandidatesAddedByRecruiter":CandidatesAddedByRecruiter, "CandidatesAddedByWorkingStatus": CandidatesAddedByWorkingStatus, "TalentAddedByPlatform": TalentAddedByPlatform, "TalentStatus" :TalentStatus, "TalentDaysTab": TalentDaysTab,"EmployeesByAppraisalScore":EmployeesByAppraisalScore,
        'RecruiterPerformance':RecruiterPerformance,'RecruiterDetails':RecruiterDetails,"RecruiterReport":RecruiterReport,"WorkloadByResolver": WorkloadByResolver,"WorkloadByGroup": WorkloadByGroup, "WorkloadByCategory":WorkloadByCategory, "SalesPerformance":SalesPerformance,"MonthlyReport":MonthlyReport,"IncentiveReport": IncentiveReport
    };
    useEffect( () => {
        const getCharts = async () => {
            try {
                const response = await util.axios.get( `new_dashboard/get_charts/` );
                const { data, error, msg } = response.data;
                const charts = data.map( ( item ) => {
                    const initialState = JSON.parse( item.initialState );
                    // const component = item.component.replace( /['"]+/g, '' );
                    const component = componentMapping[item.component];
                    return {
                        id: item.id,
                        category: item.category,
                        title: item.title,
                        initialState,
                        componentType: item.componentType,
                        component: component,
                    };
                } );
                setChartComp( charts );
                if ( error ) throw msg;
            } catch ( e ) {
                util.handleError( e );
            }
        };

        getCharts();
    }, [] );

    return chartComp;
};

export default useGetCharts;
