import React, { Fragment, useEffect, useState } from "react";
import util from "../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPencilAlt, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import ButtonCX from "../../../microComponents/ButtonCX";
import { ADD, EDIT } from "../../../../constants";
import FormInterView from "../../../shared/FormInterView";

function Evaluations( { evaluations, talent_id, talent } ) {

    const [interviews, setInterviews] = useState( [] );
    const [showForm, setShowForm] = useState( false );
    const [isDisabled] = useState( false );
    const [actionType, setActionType] = useState( ADD );
    const [index, setIndex] = useState( 0 );
    const [emails, setEmailTemplates] = useState( [] );
    const [usedEvaluator, setUsedEvaluator] = useState( [] );


    useEffect( () => {
        getListOfEvaluations().then( null );
        getEmailTemplates().then( null );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[] );

    useEffect( () => {
        // Create an array with the names of the used evaluator
        setUsedEvaluator( evaluations.map( e=> e.interviewer ) );
    },[evaluations] );

    function canShowAddButton() {
        if ( interviews.length === 0 && !showForm ){
            // We don't have anything, must show the button
            return true;
        }else{
            return !showForm;
        }
    }

    async function getListOfEvaluations() {
        try {
            const request = await util.axios.get( `${util.url}/talent/evaluation_list/${talent_id}` );
            const { error, msg, interviews } = request.data;
            if ( error ) throw msg;
            setInterviews( interviews );
        } catch ( error ) {
            util.handleError( error );
        }
    }
    async function getEmailTemplates() {
        try {
            const request = await util.axios.get( `${util.url}/app_resources/email_templates` );
            const { error, msg, emails } = request.data;
            setEmailTemplates( emails );
            if ( error ) throw msg;

        } catch ( error ) {
            util.handleError( error );
        }
    }

    async function sendEvaluationRequest( id ) {
        try {
            let template = null;
            emails.forEach( email => {
                if ( email["deletable"] === 0 ){
                    template = email;
                }
            } );
            const request = await util.axios.post( `${util.url}/talent/send_evaluation_request/${id}`, template );
            const { error, msg, interviewsUpdated } = request.data;
            if ( error ) throw msg;
            setInterviews( interviewsUpdated );
        } catch ( error ) {
            util.handleError( error );
        }
    }

    function addInterviewForm() {
        setShowForm( true );
        setActionType( ADD );
    }

    function showEditForm( index ) {
        setShowForm( true );
        setActionType( EDIT );
        setIndex( index );
    }

    function reloadInterviews( data ) {
        setShowForm( false );
        setInterviews( Object.assign( [], data ) );
    }

    function saveAndSend( d1,d2,d3 ) {
        sendEvaluationRequest( d2 ).then( null );
        // setInterviews(Object.assign([], interviewsArray))
        // setTemp({id:interview_id})
        // setEmailModal(true)
    }

    return(
        <Fragment>
            {/* Evaluations in Progress */}
            <div className="inProgressEvaluations">

                {interviews.length === 0 ? ""
                    :
                    <Fragment>
                        <b>Evaluations in Progress</b>
                        {/* <table className="table tableEvaluationsTalent">
                            <thead>
                                <tr>
                                    <th width="10%">Schedule Date</th>
                                    <th width="10%">Evaluator</th>
                                    <th width="10%">Email</th>
                                    <th width="40%">Note</th>
                                    <th className="text-center" width="20%">Actions</th>
                                    <th className="text-center" width="5%">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {interviews && interviews.map( ( interview, index ) => {
                                    return (
                                        <tr key={Math.random() * Math.random()}>
                                            <td>{util.humanDateTime( interview.date )}</td>
                                            <td>{interview.name}</td>
                                            <td>{interview.email}</td>
                                            <td>{interview.note}</td>
                                            <td className="text-center">{interview.status === undefined ? "":
                                                interview.status === 0 && !showForm && !isDisabled ?
                                                    <Fragment>
                                                        <div className="btn-group">
                                                            <button onClick={()=> showEditForm( index )} className="btn btn-sm btn-default"><FontAwesomeIcon icon={faPencilAlt}/></button>
                                                            {interview["emailSent"] === 0 ?
                                                                <button onClick={()=> {
                                                                    sendEvaluationRequest( interview.id ).then( null );
                                                                }
                                                                } className="btn btn-sm btn-default"><FontAwesomeIcon icon={faEnvelope}/></button>:
                                                                `Email sent on ${util.humanDateTime( interview["emailSentDate"] )}`
                                                            }
                                                        </div>
                                                    </Fragment>
                                                    :
                                                    "" // We will not show anything if the status is different that 0 (0 is editable/ in progress)
                                            }
                                            </td>
                                            <td className="text-center">{util.statusCheck( interview.status )}</td>
                                        </tr>
                                    );
                                } )}
                            </tbody>
                        </table> */}
                        <table className="custom-table">
                            <thead>
                                <tr>
                                    <th width="10%" style={{ fontSize:'14px' }}>Schedule Date</th>
                                    <th width="10%" style={{ fontSize:'14px' }}>Evaluator</th>
                                    <th width="10%" style={{ fontSize:'14px' }}>Email</th>
                                    <th width="40%" style={{ fontSize:'14px' }}>Note</th>
                                    <th className="text-center" width="20%" style={{ fontSize:'14px' }}>Actions</th>
                                    <th className="text-center" width="5%" style={{ fontSize:'14px' }}>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {interviews && interviews.map( ( interview, index ) => {
                                    return (
                                        <tr key={Math.random() * Math.random()}>
                                            <td style={{ fontSize:'14px' }}>{util.humanDateTime( interview.date )}</td>
                                            <td style={{ fontSize:'14px' }}>{interview.name}</td>
                                            <td style={{ fontSize:'14px' }}>{interview.email}</td>
                                            <td style={{ fontSize:'14px' }}>{interview.note}</td>
                                            <td className="text-center" style={{ fontSize:'14px' }}>
                                                {interview.status === undefined ? (
                                                    ""
                                                ) : interview.status === 0 && !showForm && !isDisabled ? (
                                                    <Fragment>
                                                        <div className="btn-group">
                                                            <button
                                                                onClick={() => showEditForm( index )}
                                                                className="btn btn-sm btn-default"
                                                            >
                                                                <FontAwesomeIcon icon={faPencilAlt} className='iconGray' />
                                                            </button>
                                                            {interview["emailSent"] === 0 ? (
                                                                <button
                                                                    onClick={() => {
                                                                        sendEvaluationRequest( interview.id ).then( null );
                                                                    }}
                                                                    className="btn btn-sm btn-default"
                                                                >
                                                                    <FontAwesomeIcon icon={faEnvelope} className='iconGray' />
                                                                </button>
                                                            ) : (
                                        `Email sent on ${util.humanDateTime( interview["emailSentDate"] )}` 
                                                            )}
                                                        </div>
                                                    </Fragment>
                                                ) : (
                                                    "" // We will not show anything if the status is different than 0 (0 is editable/in progress)
                                                )}
                                            </td>
                                            <td className="text-center">{util.statusCheck( interview.status )}</td>
                                        </tr>
                                    );
                                } )}
                            </tbody>
                        </table>

                    </Fragment>
                }
                {!isDisabled && canShowAddButton() ?
                    <div className="p-2">
                        <ButtonCX
                            text={"Schedule Evaluation"}
                            onClick={()=> addInterviewForm()}
                            // icon={faPlusCircle}
                        />
                    </div>
                    :
                    ""}

                {showForm ?
                    <div className="p-2">
                        <FormInterView
                            cancel={()=> setShowForm( false )}
                            reloadInterviews={( data )=> reloadInterviews( data )}
                            candidate={talent}
                            actionType={actionType}
                            index={index}
                            interviews={interviews}
                            usedEvaluators={usedEvaluator}
                            sendEvaluationRequest={sendEvaluationRequest}
                            saveAndSend={saveAndSend}
                            fromTalent={true}
                        />
                    </div>
                    :
                    ""}
            </div>
        </Fragment>
    );

}


export default Evaluations;