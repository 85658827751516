import { toast } from "react-toastify";
import util from "../../../../utils/miniUtils";
import { useState } from "react";
import { TalentStore } from "../../TalentStore";

function useUploadResume() {
	
    const [resumeState, setResumeState] = useState( {
        action: 1,
        selectedFile: null,
        isVisible: "displayOff",
        tempFile: null,
        resume: null,
        resumeLoading:false,
        loading:false
    } );
	
    const onChangeHandler = ( file ) => {

        const fileSize = ( file.size / 1024 / 1024 ).toString();
        if ( fileSize.split( "." )[0] > 2 ) {
            toast.error( "File size is exceeded, max 2 MB" );
            onCancelResumeUpload();
        } else {
            setResumeState( {
                ...resumeState,
                selectedFile: file,
                isVisible: "displayOn"
            } );
           
        }
    };
	
    const onCancelResumeUpload = () => {
        setResumeState( {
            ...resumeState,
            selectedFile: null,
            isVisible: "displayOff",
            tempFile: null,
            resume: resumeState.tempFile
        } );
    };
	

    const onUploadNewResume = async ( talent_id, reloadAction,rfile ) => {
        try {
          
           
            setResumeState( { ...resumeState,resumeLoading: true, loading:true } );
            const data = new FormData();
            data.append( "file", rfile );
            // eslint-disable-next-line no-restricted-globals
            data.set( "name", name );
            const request = await util.axios.post( `/candidates/upload_resume/${talent_id}`, data );
            const { error, msg, resume } = request.data;
            if ( error )  throw msg;
            toast.success( msg );
            setResumeState( {
                ...resumeState,
                selectedFile: null,
                isVisible: "displayOff",
                tempFile: null,
                resume: resume,
                resumeLoading:false,
                loading:false
            } );
            TalentStore.update( t => {
                t.talent.resume = resume;
                t.talent.resumeName= rfile.name;
            } );
            reloadAction();
        }catch ( e ) {
            setResumeState( { ...resumeState,resumeLoading: false, loading:false } );
            util.handleError( e );
        }
    };
	
    const onChangeResumeFile = () => {
        // console.log( "clikdj" );
        setResumeState( {
            ...resumeState,
            selectedFile: null,
            isVisible: "displayOn",
            resume: null,
            tempFile: resumeState.resume
        } );
    };
	
    const isResumeAvailable = d => {
        if ( d.resume )
            return util.baseUrl() +"/"+ util.AWSURL() +"/candidate/" + d.id + "/" + d.resume;
        return "";
    };
	
    return {
        isResumeAvailable,
        onChangeResumeFile,
        onUploadNewResume,
        onCancelResumeUpload,
        onChangeHandler,
        resumeState, setResumeState
    };
}

export default useUploadResume;