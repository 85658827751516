import React, { Fragment } from "react";
import "@fortawesome/free-solid-svg-icons";
import { Tabs, Tab } from "react-bootstrap";
import JobDescription from "./job/JobDescription";
import Users from "./users/Users";
import Checklist from "./job/Checklist";
import Settings from "./job/Settings";
import EmailTemplates from "./job/EmailTemplates";
import AssetsCatalog from "./assets/AssetsCatalog";
import Test from "./tests/Test";
import WithdrawCatalog from "./job/withdraw_catalog/WithdrawCatalog";
import CostToCompany from "./job/cost_to_company/CostToCompany";
// import Workload from "./workload";
import Departments from "./departments/";
import Groups from "./groups";
import Category from "./categories";
import JobStatus from "./job/job_status";
import Role from "./roles/components/RolePermissionPanel"; 
import EmployeeDocuments from './documentlist/EmployeeDocumentCatalogPanel';
import CatalogModule from "./catalog_module";

function AppSettings() {

    return (
        <Fragment>
            
            <div className="row mt-4">
                <div className="col-md-12">
                    <Tabs mountOnEnter={true} unmountOnExit={true} defaultActiveKey="users" >
                        <Tab eventKey="users" title="Users">
                            <Users />
                        </Tab>
                        
            
                        <Tab eventKey="job_description" title="Job" id={"job_description"}>
                            <br/>
                            <Tabs mountOnEnter={true} variant={"pills"} unmountOnExit={true} defaultActiveKey="settings" >
                                <Tab eventKey="settings" title="Setting">
                                    <Settings />
                                </Tab>
                                <Tab eventKey="ctc" title="Cost To Company">
                                    <CostToCompany />
                                </Tab>
                                <Tab eventKey="job_description" title="Job Description">
                                    <JobDescription />
                                </Tab>
                                <Tab eventKey="checklist" title="Checklist">
                                    <Checklist />
                                </Tab>
                                <Tab eventKey="email_templates" title="Email Template">
                                    <EmailTemplates />
                                </Tab>
                                {/*<Tab eventKey="beeline" title="Beeline Docs">*/}
                                {/*  <Documents />*/}
                                {/*</Tab>*/}
                                <Tab eventKey="test" title="Test" id="testTab">
                                    <Test />
                                </Tab>
                                <Tab eventKey="withDraw" title="Withdraw" id="withDrawTab">
                                    <WithdrawCatalog />
                                </Tab>
                                <Tab eventKey="jobStatus" title="Talent Status" id="jobStatusTab">
                                    <JobStatus />
                                </Tab>
                            </Tabs>
                        </Tab>
            
                        <Tab eventKey="assets" title="Asset">
                            <AssetsCatalog />
                        </Tab>

                        {/* <Tab eventKey="workload" title="Workload">
                            <Workload />
                        </Tab> */}

                        <Tab eventKey="departments" title="Departments">
                            <Departments />
                        </Tab>

                        <Tab eventKey="groups" title="Groups">
                            <Groups />
                        </Tab>
                        <Tab eventKey="category" title="Workload Category">
                            <Category />
                        </Tab>
                        <Tab eventKey="catalog" title="Catalogs">
                            <CatalogModule />
                        </Tab>
                        <Tab eventKey="roles" title="Role Permissions"> 
                            <Role /> 
                        </Tab> 
                        <Tab eventKey="EmployeeDocuments" title="Employee Documents"> 
                            <EmployeeDocuments /> 
                        </Tab> 
                    </Tabs>
                </div>
            </div>
        </Fragment>
    );
}

export default AppSettings;
