import React, { Fragment, useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import util from "../../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faCheck } from "@fortawesome/free-solid-svg-icons";

export default function DrawerTestInformation( { showDrawer, closeDrawer, data } ) {
	
    const [test, setTest] = useState( null );
    const [loading, setLoading] = useState( false );
	
    async function getTestResultInformation() {
        try{
            setLoading( true );
            const response = await util.axios.get( `/tests_user/detail_of_test/${data.talent_id}/${data.test_id}` );
            const { error, msg, test } = response.data;
            if ( error ) throw msg;
            setTest( test );
            setLoading( false );
        }catch ( e ){
            setLoading( false );
            util.handleError( e );
        }
    }
	
    useEffect( () => {
        if ( data ){
            getTestResultInformation().then( null );
        }
    },[data] );
	
    if ( test === null ){
        return "";
    }
	
    return(
        <Fragment>
            <Drawer anchor="right" open={showDrawer} onClose={closeDrawer}>
                {test ?
                    <div className="container" style={{ width: window.innerWidth * .45 }}>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <p><b>Test name</b>: {test.name}</p>
                                <p dangerouslySetInnerHTML={{ __html: test.desc }}/>
                                <p><b>Test duration</b>: {test.time} minutes</p>
                                <hr/>
                                {test?.questions?.map( ( q, i ) => {
                                    return (
                                        <Fragment key={i}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h4><b>Question #{i + 1}</b></h4>
                                                </div>
                                                <div className="col-md-12">
                                                    <p dangerouslySetInnerHTML={{ __html: q.question }}/>
                                                    <b>Talent Answer:</b>
                                                    <span className={`${q.answerTalent.classe} p-2`}>
                                                        {q.answerTalent.classe === "text-success" ?
                                                            <FontAwesomeIcon icon={faCheck} className="mr-1"/>
                                                            :
                                                            <FontAwesomeIcon icon={faBan} className="mr-1"/>
                                                        }
                                                        {q.answerTalent.answer}
														
                                                    </span>
												
                                                </div>
                                                <div className="col-md-12">
													
                                                    {q.type === 2 ?
                                                        <img width="100%"
														     src={util.fetchImageUrl( `src/public/test/${test.id}/${q.filename}` )}
														     alt="Test image"/>
                                                        :
                                                        null
                                                    }
												
                                                </div>
                                            </div>
                                            <hr/>
                                        </Fragment>
                                    );
                                } )}
							
                            </div>
                        </div>
                    </div>
                    :
                    ""
                }
            </Drawer>
            {util.LOADING_SCREEN( loading )}
        </Fragment>
    );
}