import React, { Fragment, useState } from "react";
import { Modal } from "react-bootstrap";
import EmployeeModule from "./EmployeeModule";
import util from "../../../utils/miniUtils";

const EmployeeModal = ( { show, employee_id, handleClose, data } ) => {

    const [section, setSection] = useState( util.user.role === 7 ? "user_account" : "employee" );

    return ( 
        <Fragment>
            <Modal
                size={section === "user_account" ? "md": "xl"}
                fullscreen={true}
                show={show}
                onHide={() => {
                    handleClose();}}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{data?.name} Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EmployeeModule setSection={setSection} employee_id={employee_id} modal={true}/>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};
 
export default EmployeeModal;