import { faColumns, faFileExport, faFilter, faPlusCircle, faTable, faTableTennis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MuiThemeProvider } from '@material-ui/core';
import MaterialTable from 'material-table';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom';
import SAlert from '../../../../../microComponents/AlertSA';
import DropDownOptions from '../../../../../shared/jobs/DropDownOptions';
import util from '../../../../../utils/miniUtils';
import SideBarFilter from '../SideBarFilter';
import JobReadOnly from './JobReadOnly';
import ExportSelected from '../../../../../shared/jobs/ExportSelected';
import ExportCandidates from '../../../../../shared/jobs/ExportCandidates';

const JobsTable = ( {
    filterState, setFilterState,
    reloadTableData,
    tableRef,
    tableColumns,
    handleColumnDrag,
    urlHandlerJobPositions,
    state, setState,
    data, setData,
    showColumns,
    handleChange,
    handleSave,
    showDuplicate,
    hideAlert,
    onConfirmDuplicate,
    HIDE_SWEET_ALERT_DUPLICATE,
    csShow,
    onConfirmChangeStatus,
    HIDE_SWEET_ALERT_CHANGE_STATUS,
    sendEmailCheckbox,
    saShow,
    onConfirmStopProcess,
    HIDE_SWEET_ALERT_STOP_PROCESS,
    deleteShow,
    onConfirmDeleteJob,
    HIDE_SWEET_ALERT_DELETE_JOB_POSITION,
    backDropOpen,
    columns,
    showReadOnly,
    readOnlyPosition,
    handleReadOnlyClose
} ) => {

    return (
        <>
            <div className="row">
                {  filterState.showFilter ?
                    <div className='col-md-2'>
                        <div>
                            <SideBarFilter
                                reloadData={
                                    ( filter )=> {
                                        setFilterState( prevState => ( {
                                            ...prevState,
                                            ...filter
                                        } ) );
                                        reloadTableData();
                                    }
                                }
                            />
                        </div>
                    </div>
                    :
                    null
                }
                <div className={filterState.showFilter ? 'col-md-10': 'col-md-12'}>
                    <MuiThemeProvider theme={util.defaultTableTheme}>
                       
                        <MaterialTable
                            tableRef={tableRef}
                            title="Jobs positions"
                            // columns={selectedColumns.filter( column => column !== null )}
                            columns={tableColumns}
                            onColumnDragged={handleColumnDrag}
                            data={query =>
                                util.axios.get( urlHandlerJobPositions( query ) )
                                    .then( response => {
                                        const { jobPositions, total, page, error, msg } = response.data;
                                        if ( error ) throw  msg;
                                        setState( { ...state, jobs: jobPositions } );
                                        return ( {
                                            data: jobPositions,
                                            page: page,
                                            totalCount: total
                                        } );
                                    } )
                                    .catch( error=>{
                                        util.handleError( error );
                                    } )
                            }
                            localization={{
                                toolbar:{
                                    searchPlaceholder:"Search by Job Title",
                                    nRowsSelected: ""
                                }
                            }}
                            options={{
                                pageSize: 50,
                                pageSizeOptions:[50, 100, 150],
                                tableLayout:"auto",
                                searchFieldAlignment:"left",
                                toolbarButtonAlignment: "left",
                                sorting: true,
                                selection: true,
                                showSelectAllCheckbox:false,
                                showTitle: false,
                                emptyRowsWhenPaging: false,
                                // search:!filterState.showFilter ,
                                paginationPosition:"both",
                                debounceInterval:500,
                                // columnsButton:true,
                                headerStyle:{ position:'sticky',top:0 },
                                maxBodyHeight:'570px'


                                // selectionProps: rowData => ( {
                                //     disabled: rowData.status === 3 || rowData.status === 4,
                                // } ),
      
                            }}
                            actions={[
                                {
                                    icon: () => <DropDownOptions jobArray={ data } reloadTableData={reloadTableData} />,
                                    isFreeAction: false,
                                    onClick: ( event, data ) => setData( data ),
                                },
                                {
                                    icon: () => <ExportSelected data={ data } columns={tableColumns} />,
                                    isFreeAction: false,
                                    onClick: ( event, data ) => setData( data ),
                                },
                                {
                                    icon: () => <ExportCandidates data={ data } columns={tableColumns} />,
                                    isFreeAction: false,
                                    onClick: ( event, data ) => setData( data ),
                                },
                                // {
                                //     icon: () => <div className={"btn btn-secondary sizeBtn activeBtn"}>
                                //         <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                                //         Export
                                //     </div>,
                                //     tooltip: "Export Selected",
                                //     isFreeAction: false,
                                //     onClick: () => util.tableToCSVSelectedOnly()
                                // },
                                { icon:()=><div>
                                    <div className="dropdown">
                                        <div type="text" id="dropdownMenuButton" data-toggle="dropdown" className={"btn"} aria-haspopup="false" aria-expanded="true" >
                                            <span className="ml-1 isCursor text-secondary">
                                                <ViewColumnIcon title='Show/Hide Columns' className="iconGrey" />
                                            </span>
                                        </div>
                                        <div className="dropdown-menu drp " aria-labelledby="dropdownMenuButton" style={{ width:'900%' }}>
                                                        
                                                        
                                            {/* {columns.map( ( c ) => (
                                                <button className="dropdown-item"  key={c.field}>
                                                    <input
                                                        // type="checkbox"
                                                        // name={column.field}
                                                        // checked={selectedColumns.find( col => col.field === column.field )}
                                                        // value={column}
                                                        // onClick={() => handleColumnChange( column )}
                                                        type="checkbox"
                                                        value={c.field}
                                                        checked={showColumns.includes( c.field )}
                                                        onChange={handleChange}
                                                    />
                                                    <label className='ml-1'>{c.title}</label> 
                                                </button>
                                            ) )} */}
                                            <div className="drp-contain">             
                                                        
                                                {columns.map( ( c, index ) => {
                                                    const mycolumn = index % 3;
                                                    const myrow = Math.floor( index / 3 );
                                                    return(
                                                        <button className="dropdown-item"  key={c.field} style={{ gridColumn: `${mycolumn + 1}`, gridRow: `${myrow + 1}` }}>
                                                            <input
                                                                type="checkbox"
                                                                value={c.field}
                                                                checked={showColumns.includes( c.field )}
                                                                onChange={handleChange}
                                                            />
                                                    
                                                            <label className='ml-1'>{c.title}</label> 
                                                        </button>
                                                    );} )}</div>
                                            <button  className="btn btn-light btn-sm mr-3 float-right" 
                                                onClick={()=>{handleSave(); }}
                                            >
                                           Save
                                            </button>
                                        </div>
                                    </div>
                                </div>,
                                isFreeAction: true,
                                onClick: () => null
                                },
                                {
                                    icon: () => (
                                        <Link to={"/jobs/new"} >
                                            <div className='text-primary'>
                                                <FontAwesomeIcon icon={faPlusCircle} id="addRequirementIcon" className="mr-2" />
                                       
                                            </div>
                                        </Link>
                                    ),
                                    isFreeAction: true,
                                    onClick: () => null,
                                },

                                {
                                    icon: () => <div className={"btn btn-secondary sizeBtn activeBtn"}>
                                        <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                                        Export
                                    </div>,
                                    tooltip: "Export All",
                                    isFreeAction: true,
                                    onClick: () => util.tableToCSV()
                                },
                               
                                {
                                    icon: () =>
                                        <div className="btn btn-secondary sizeBtn activeBtn">
                                            <FontAwesomeIcon icon={faFilter} className="mr-2" />
                                   Filter
                                        </div>
                                    ,
                                    position:"toolbar",
                                    isFreeAction: true,
                                    onClick: () => {
                                        if ( filterState.showFilter ) {
                                            setFilterState( { ...filterState, 
                                                startDate:"",
                                                endDate:"",
                                                countries:[],
                                                status:[],
                                                leadRecruiters:[],
                                                clients:[],
                                                partners:[],
                                                salesLead:[],
                                                showFilter: false 
                                            } );
                                        } else {
                                            setFilterState( { ...filterState, showFilter: true } );
                                        }
                                    }  
                                },
                            ]}
                        />
                    </MuiThemeProvider>

                    {/* Read-only view pop-up */}
                    {showReadOnly ? <JobReadOnly
                        show={showReadOnly}
                        handleClose={handleReadOnlyClose}
                        position={readOnlyPosition} /> : ""}
       
                    {/* Confirm Alert */}
                    {showDuplicate ?
                        <SAlert
                            show={showDuplicate}
                            confirmText="Yes"
                            typeButton="muted"
                            msg={"Do you want to duplicate this Job Position details?"}
                            hideAlert={hideAlert}
                            onConfirm={onConfirmDuplicate}
                            opc={HIDE_SWEET_ALERT_DUPLICATE}
                        />:""}


                    {/* This modal will be displayed every time the recruiter wants to change the status of some jobs */}
                    {csShow
                        ? <SweetAlert
                            show={csShow}
                            warning
                            showCancel
                            confirmBtnText="Yes, change it!"
                            confirmBtnBsStyle="danger"
                            title="Are you sure?"
                            onConfirm={onConfirmChangeStatus}
                            onCancel={hideAlert}
                            focusCancelBtn
                        >
                            <div className="">{`You will change status of ${data.length} job ${data.length > 1 ? "positions":"position"} from ${util.jobStatusOnlyIcons( data[0].status )} to ${data[0].status === 1? "Hold": "Active"}.`}</div>
                            <div>
                                <br/>
                                <label htmlFor="sendEmailCheckbox">
                                    <input id="sendEmailCheckbox"
                                        type="checkbox"
                                        className="mr-1"
                                        name="sendEmailCheckbox"
                                        onClick={sendEmailCheckbox}/>
                            Send email notification to talents
                                </label>
                            </div>
                        </SweetAlert>
                        : ''}

    
                    {/* This SweetAlert is used to confirm that the user will stop process on some Job positions*/}
                    {saShow ?
                        <SAlert
                            show={saShow}
                            confirmText="Yes, stop it!"
                            typeButton="danger"
                            msg={`You will stop ${data.length} job ${data.length > 1 ? "positions":"position"} process.`}
                            hideAlert={hideAlert}
                            onConfirm={onConfirmStopProcess}
                            opc={HIDE_SWEET_ALERT_STOP_PROCESS}
                        />:""}

                    {saShow
                        ? <SweetAlert
                            show={saShow}
                            warning
                            showCancel
                            confirmBtnText="Yes, stop it!"
                            confirmBtnBsStyle="danger"
                            title="Are you sure?"
                            onConfirm={onConfirmStopProcess}
                            onCancel={hideAlert}
                            focusCancelBtn
                        >
                            <div className="">{`You will stop ${data.length} job ${data.length > 1 ? "positions":"position"} process.`}</div>
                            <div>
                                <br/>
                                <label htmlFor="sendEmailCheckbox">
                                    <input id="sendEmailCheckbox"
                                        type="checkbox"
                                        className="mr-1"
                                        name="sendEmailCheckbox"
                                        onClick={sendEmailCheckbox}/>
                            Send email notification to talents
                                </label>
                            </div>
                        </SweetAlert>
                        : ''}
            
                    {/* This SweetAlert is used to confirm that the user will change the status on some Job positions*/}
                    {csShow ?
                        <SAlert
                            show={csShow}
                            confirmText="Yes, change it!"
                            typeButton="danger"
                            msg={`You will change status of ${data.length} job ${data.length > 1 ? "positions":"position"} from ${util.jobStatusOnlyIcons( data[0].status )} to ${data[0].status === 1? "Hold": "Active"}.`}
                            hideAlert={hideAlert}
                            onConfirm={onConfirmChangeStatus}
                            opc={HIDE_SWEET_ALERT_CHANGE_STATUS}
                        />:""}

                    {csShow
                        ? <SweetAlert
                            show={csShow}
                            warning
                            showCancel
                            confirmBtnText="Yes, change it!"
                            confirmBtnBsStyle="danger"
                            title="Are you sure?"
                            onConfirm={onConfirmChangeStatus}
                            onCancel={hideAlert}
                            focusCancelBtn
                        >
                            <div className="">{`You will change status of ${data.length} job ${data.length > 1 ? "positions":"position"} from ${util.jobStatusOnlyIcons( data[0].status )} to ${data[0].status === 1? "Hold": "Active"}.`}</div>
                            <div>
                                <br/>
                                <label htmlFor="sendEmailCheckbox">
                                    <input id="sendEmailCheckbox"
                                        type="checkbox"
                                        className="mr-1"
                                        name="sendEmailCheckbox"
                                        onClick={sendEmailCheckbox}/>
                            Send email notification to talents
                                </label>
                            </div>
                        </SweetAlert>
                        : ''}
    
                    {/* This SweetAlert is used to confirm that the user will change the status on some Job positions*/}
                    {deleteShow ?
                        <SAlert
                            show={deleteShow}
                            confirmText="Yes, delete it!"
                            typeButton="danger"
                            msg={`You will delete this Job position`}
                            hideAlert={hideAlert}
                            onConfirm={onConfirmDeleteJob}
                            opc={HIDE_SWEET_ALERT_DELETE_JOB_POSITION}
                        />:""}
    
                    {util.LOADING_SCREEN( backDropOpen )}
                </div>
    
            </div>
            <table id="#exportTable" className="displayOff">
                <thead>
                    <tr>
                        <td>Job ID</td>
                        <td>Location</td>
                        <td>Job Title</td>
                        <td>Client Reference ID</td>
                        <td>Job Sell Rate</td>
                        <td>Created</td>
                        <td>Created By</td>
                        <td>Updated By</td>
                        <td>Partner Name</td>
                        <td>End Client</td>
                        <td>Status</td>
                        <td>Talents</td>
                        <td>Reporting Manager</td>
                        <td>Sales Lead</td>
                        <td>Lead Recruiter</td>
                        <td>Secondary Recruiter</td>
                        <td>Restricted To</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        // console.log( state.jobs )
                        state.jobs.length > 0 && state.jobs.map( ( job, i ) => {
                            return(
                                <tr key={i}>
                                    <td>{util.zeroPad( job.id, 5 )}</td>
                                    <td>{util.location( job.country, job.state, job.city )}</td>
                                    <td>{job.job_title}</td>
                                    <td>{job.client_requirement_id}</td>
                                    <td>{util.noValues.includes( job.sell_rate_type ) ? "Hour":job.sell_rate_type }</td>
                                    <td>{util.humanDateTime( job.createdAt )}</td>
                                    <td>{job.created_by}</td>
                                    <td>{job.updated_by}</td>
                                    <td>{job.company}</td>
                                    <td>{job.client_company}</td>
                                    <td>{util.jobStatusOnlyText( job.status )}</td>
                                    <td>{job.candidates_added}</td>
                                    <td>{job.reporting_manager}</td>
                                    <td>{job.sales_lead}</td>
                                    <td>{job.lead_recruiter}</td>
                                    <td>{job.secondary_recruiter}</td>
                                    <td>{job.restricted_to}</td>
                                    
                                </tr>
                            );
                        } )
                    }
                </tbody>
            </table>
        </>
    );
};

export default JobsTable;