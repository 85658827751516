import { faBan, faPlusCircle, faSave, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, Grid, InputLabel, MuiThemeProvider, Paper, TextField } from '@material-ui/core';
import MaterialTable from 'material-table';
import React, { Fragment } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import util from '../../utils/miniUtils';
import AllStatus from '../shared/Status/AllStatus';
import useAllGoalsCompetencyRequest from './useAllGoalsCompetencyRequest';





const Period = () => {
    const { getAllPeriods,period,removePeriod,id,setId }=useAllGoalsCompetencyRequest();
    const [form,setForm]=useState( {} );
    const [time,setTime]=useState( { isNew: true } );
    const[loading,setLoading]=useState( false );
  
   

    function handleChanges( e ) {
        const { name, value } = e.target;
        setForm( { ...form, [name]: value } );
    }

    function handleRichTextChange( e ) {
        //const value = e.toString("html");
        const value = e.target.value;
        setForm( { ...form,['notes']: value } );
    }
    const createaNewPeriod = async ( ) => {
        try {
            setLoading( true );
            let temp = { ...form };
            const request = await util.axios.post( `${util.url}/create_period`, temp );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            setLoading( false );
            getAllPeriods( );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    const editPeriod = async ( id ) => { //appraisal_item_id
        try {
            // setLoading( true );
            const request = await util.axios.put( `${util.url}/edit_period/${id}`, form );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            getAllPeriods(  );
        } catch ( error ) {
            util.handleError( error );
        }
    };
    function handleClick() {
        if ( time.isNew ) {
            createaNewPeriod();
        } else {
            editPeriod( id );
            time.isNew = true;

            setTime( time );
        }
    }
    const handleCancel = () => {
        setForm( "",time.isNew=true );
    
    };
    useEffect( ()=>{
        getAllPeriods();

    },[] );
    const handleDateChange = ( e ) => {
        const { name, value } = e.target;
        setForm( { ...form, [name]: value, ready: true } );
    };
    function createNewPeriod() {
        setForm( "",time.isNew=true );
        setTime( { isNew: true } );
    }
    const textAreaRef = useRef();
    if ( loading ) {
        return  <Fragment>
            <div className="container-fluid p2 ">
                <div className="text-center">
                    <CircularProgress />
                </div>
            </div>
        </Fragment>;
    }
    return (
        <>

            <div className="row">
                <div elevation={3} style={{ flex:'4' }} className='ml-2 mr-2 mt-3 bg-white'>
               
                   

                    <div className='row'>
                      
                        <div className='col'> <MuiThemeProvider theme={util.defaultTableTheme}>
                            <MaterialTable
                                columns={[
                                    {
                                        title:"Period",
                                        field: "period",
                                        render: ( rowData ) => {
                                            return <div> {rowData.period}</div>;
                                        },
                                    },
                                    {
                                        title:"Start Date",
                                        field: "startDate",
                                        render: ( rowData ) => {
                                            return <div> {rowData.startDate}</div>;
                                        },
                                    },
                                    {
                                        title:"End Date",
                                        field: "endDate",
                                        render: ( rowData ) => {
                                            return <div> {rowData.endDate}</div>;
                                        },
                                    },
                                  
                               
                                ]}
                                data={period}
                                options={{
                                    searchFieldAlignment: "left",
                                    pageSize: 6,
                                    selection: true,
                                    sorting: false,
                                    showFirstLastPageButtons: false,
                                    pageSizeOptions: [],
                                    showTitle: false,
                                    // padding: "dense",
                                    paginationType: "stepped",
                                }}
                                onRowClick={( event, rowData ) => {
                                    setForm( Object.assign( {}, rowData, time.isNew = false ) );
                                    setId(  rowData.id  );
                                    // selectTarget( rowData );
                                    // console.log( rowData );
                                }}
                                // actions={[
                                //     {
                                //         icon: () => (
                                //             <FontAwesomeIcon
                                //                 icon={faPlusCircle}
                                //                 id="addRequirementIcon"
                                //                 className="text-primary" 
                                //             />
                                //         ),
                                //         tooltip: `Add New Period`,
                                //         isFreeAction: true,
                                //         onClick: ( ) => createNewPeriod(  )
                                //     },
                                // ]}
                   
                  
                            />
                        </MuiThemeProvider>
                        </div>
                    </div>
                </div>
                <div elevation={3} style={{ flex:'5' }} className='ml-2 mr-2 mt-3 bg-white'>
                    <Grid  container className='mt-3'>
                        
                        <Grid item xs={12} md={12} lg={5} className='p-2 ml-2'> 
                            <TextField
                                fullWidth
                                variant='outlined'
                                label='Period'
                                name={"period"}
                                onChange={handleChanges}
                                value={form.period||""}
                            /></Grid>
                        <Grid item xs={12} md={12} lg={3} className="ap_per">
                            <InputLabel>Start Date</InputLabel> 
                            <input
                                className="form-control"
                                value={form.startDate || ""}
                                type="date"
                                onChange={handleDateChange}
                                id="startDate"
                                name="startDate"
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={3} className="ml-2 ap_per">
                            <InputLabel>End Date</InputLabel>
                            <input
                                className="form-control"
                                value={form.endDate || " "}
                                type="date"
                                onChange={handleDateChange}
                                id="endDate"
                                name="endDate"
                            />
                        </Grid>
                    </Grid>
                   
                   
                    <div className='row mt-3 ml-1 mr-2'>
                        <div className='col-md-12'>
                            <TextField
                                ref={textAreaRef}
                                fullWidth
                                variant='outlined'  
                                label="Notes"
                                rows={8}  
                                maxRows={8}  
                                multiline                          
                                value={form.notes||""}
                                onChange={handleRichTextChange}

                            />
                        </div>
                  
                    </div>


                    <div className="mt-5">
                       
                      
                        {time.isNew ? (
                            ""
                        ) : (
                            <button
                                onClick={removePeriod}
                                className="btn btn-secondary sizeBtn activeBtn mr-2 mt-1"
                            >
                                <FontAwesomeIcon icon={faBan} className="ml-2" />
           Delete
                            </button>
                        )}

                        <button
                            onClick={handleClick}
                            className="btn btn-secondary sizeBtn activeBtn mr-2 mt-1"
                        >
                            <FontAwesomeIcon
                                icon={time.isNew ? faSave : faSyncAlt}
                                className="mr-2"
                            />
                            {time.isNew ? "Create" : "Update"}
                        </button>
                        <span className="likeLink  mr-2 ap_btn mt-5" onClick={() => { handleCancel(); }}>Cancel</span> 
                    </div>
                </div>

                  
                     
            </div>
            <div className='mt-5'>
                <AllStatus/>
            </div>
           
           
               
        </>
    );
};

export default Period;
