import React from "react";
import TableOfCertifications from "./TableOfCertifications";

export default function CertificationsContainer( { talent_id ,isEditing } ) {
	
    return (
        <div className="mt-3">
            <div className="row">
                <div className="col-md-12">
                    <div  style={{ backgroundColor: "#F4F4F4" }}>
                        <TableOfCertifications  talent_id={talent_id} isEditing={isEditing} />
                    </div>
                </div>
            </div>
        </div>
    );
}

