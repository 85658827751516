export const nav = [
    {
        text: "Login",
        path: "/",
    },
    // {
    //   text: "about",
    //   path: "/about",
    // },
    // {
    //   text: "services",
    //   path: "/services",
    // },
    // {
    //   text: "blog",
    //   path: "/blog",
    // },
    // {
    //   text: "pricing",
    //   path: "/pricing",
    // },
    // {
    //   text: "contact",
    //   path: "/contact",
    // },
];
// export const featured = [
//     {
//     // cover: "../images/hero/h1.png",
//         name: "React Developer",
//         total: "122 Vacancies",
//     },
//     {
//     // cover: "../images/hero/h2.png",
//         name: "Full Stack Developer",
//         total: "155 Vacancies",
//     },
//     {
//     // cover: "../images/hero/h3.png",
//         name: "Software Engineer",
//         total: "300 Vacancies",
//     },
//     {
//     // cover: "../images/hero/h4.png",
//         name: "Database Administrator",
//         total: "80 Vacancies",
//     },
//     {
//     // cover: "../images/hero/h6.png",
//         name: "Backend Developer",
//         total: "80 Vacancies",
//     },
// ];

export const footer = [
    {
        title: "Address",
        text: [{ list: 
            `100 SPECTRUM CENTER DR Suite 949 IRVINE CA 92618,
            P O BOX 54210 IRVINE CA USA 92619`
        }],
    },
    {
        title: "Contact Us",
        text: [{ list: "+1-800-259-9578" }],
    },
  
];

// export const featureData = [
//     {
//         id: 1,
//         name: "React Js Developer",
//         location:"san francisco,US",
//         skills: "React",
//         salary:'40$ to 90$'
//     },
//     {
//         id: 2,
//         name: "React Js Developer",
//         location:"san francisco,US",
//         skills: "React",
//         salary:'40$ to 90$'
//     },
//     {
//         id: 3,
//         name: "React Js Developer",
//         location:"san francisco,US",
//         skills: "React",
//         salary:'40$ to 90$'
//     },
//     {
//         id: 4,
//         name: "React Js Developer",
//         location:"san francisco,US",
//         skills: "React",
//         salary:'40$ to 90$'
//     },
//     {
//         id: 5,
//         name: "React Js Developer",
//         location:"san francisco,US",
//         skills: "React",
//         salary:'40$ to 90$'
//     },
//     {
//         id: 6,
//         name: "React Js Developer",
//         location:"san francisco,US",
//         skills: "React",
//         salary:'40$ to 90$'
//     },
//     {
//         id: 7,
//         name: "React Js Developer",
//         location:"san francisco,US",
//         skills: "React",
//         salary:'40$ to 90$'
//     },
//     {
//         id: 8,
//         name: "React Js Developer",
//         location:"san francisco,US",
//         skills: "React",
//         salary:'40$ to 90$'
//     },
//     {
//         id: 9,
//         name: "React Js Developer",
//         location:"san francisco,US",
//         skills: "React",
//         salary:'40$ to 90$'
//     },
//     {
//         id: 10,
//         name: "React Js Developer",
//         location:"san francisco,US",
//         skills: "React",
//         salary:'40$ to 90$'
//     },
// ];

export const partners = [
    {
        name: "Telstra",
        logo: "../images/partners/telstra.png",
    },
    {
        name: "Dai",
        logo: "../images/partners/dai.png",
    },
    {
        name: "Energy Australia",
        logo: "../images/partners/EnergyAus.png",
    },
    {
        name: "NBN",
        logo: "../images/partners/nbn.png",
    },
    {
        name: "Optus",
        logo: "../images/partners/optus.png",
    },
    {
        name: "Nab",
        logo: "../images/partners/nab.png",
    },
    {
        name: "Westpac",
        logo: "../images/partners/westpac.png",
    }
];


