import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MaterialTable from "material-table";
import util from "../../utils/miniUtils";
import { faEllipsisV, faPlusCircle, faTrash, faSearch, faFilter, } from "@fortawesome/free-solid-svg-icons";
import { MuiThemeProvider } from "@material-ui/core";
import SAlert from "../../microComponents/AlertSA";
import UserFilter from "./UserFilter";
import ModalUser from './components/ModalUser';
import useUsers from './useUsers';
import Employees from './employees';

const tableRef = React.createRef();
const ACCOUNTS = 1;
const EMPLOYEES = 2;


function Users() {
    const {
        isVisible,
        loading,
        user,
        data, setData,
        show, setShow,
        state, setState,
        searchData,
        handleAdvancedSearchClose,
        urlHandler,
        modalVisible,
        hideModal,
        createNewUser,
        hideAlert,
        onConfirmChangePassword,
        onConfirmDeleteUser
    } = useUsers( tableRef );

    const [section, setSection] = useState( ACCOUNTS );


    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div className="p-0 pt-2 pb-2 pl-2">

                        <button onClick={()=>setSection( ACCOUNTS )} className="btn sizeBtn btn-secondary activeBtn">Accounts</button>
                        {/* <button onClick={()=>setSection( EMPLOYEES )} className="ml-2 btn sizeBtn btn-secondary activeBtn">Employees</button> */}
                    </div>
                    {state.showFilter ?
                        <UserFilter
                            filterData={state}
                            searchData={searchData}
                            closeAdvanceSearch={handleAdvancedSearchClose}
                        />
                        :
                        ""
                    }
                    {
                        section === ACCOUNTS ?
                            <MuiThemeProvider theme={util.defaultTableTheme}>
                                <MaterialTable
                                    title="Users"
                                    tableRef={tableRef}
                                    columns={[
                                        {
                                            title: 'Name', field: 'name', searchable: true,
                                            render: data =>{
                                                return <span onClick={()=>modalVisible( true, data )} className="likeLink">{data.name}</span>;
                                            }
                                        },
                                        {
                                            title: 'Application Role', field: 'role', searchable: true,
                                            render: data =>{
                                                return <div className="text-left">{util.roleToString( data.role )}</div>;
                                            }
                                        },
                                        {
                                            title: 'Secondary Role', field: 'role', searchable: true,
                                            render: data =>{
                                                const roles = util.SecondaryRoleList( data );
                                                return (
                                                    <div className="text-left">
                                                        {
                                                            roles.length === 1 ?
                                                                roles[0]
                                                                :
                                                                <span>
                                                                    {
                                                                        roles.map( ( d, i ) =>{
                                                                            return <div key={i}>- {d}</div>;
                                                                        } )
                                                                    }
                                                                </span>
                                                        }
                                                    </div>
                                                );
                                            }
                                        },
                                        {
                                            title: 'Email', field: 'email', searchable: true,
                                            render: d=>{
                                                return <span><a href={`mailto:${d.email}`}>{d.email}</a></span>;
                                            }
                                        },
                                        {
                                            title: 'Company', field: 'company', searchable: true,
                                            render: d=>{
                                                return <div className="text-left">{d.company}</div>;
                                            }
                                        },
                                        {
                                            title: 'Location', field: 'country', searchable: true,
                                            render: d=>{
                                                return <div className="text-left">{d.country}</div>;
                                            }
                                        },
                                        {
                                            title: 'Phone 1', field: 'phone1',
                                            render: d=>{
                                                return <div className="text-center">
                                                    <a href={`tel:${d.phone1}`}>{util.formatPhone( d.phone1 )}</a>
                                                    {d.phone1 !== null ?
                                                        <a rel="noopener noreferrer" className="ml-2" target={"_blank"} href={util.whatsapp( d.phone1, `Hello, my name is ${util.user.name} from eJAmerica` )}>
                                                            <img width={16} src="images/whatsapp.png" alt="whatsapp"/>
                                                        </a>:""
                                                    }
                                                </div>;
                                            }
                                        },
                                        {
                                            title: 'Phone 2', field: 'phone2',
                                            render: d=>{
                                                return <div className="text-center"><a href={`tel:${d.phone2}`}>{util.formatPhone( d.phone2 )}</a></div>;
                                            }
                                        },
                                        {
                                            title: 'Options',
                                            render: data => {
                                                return (
                                                    <div className="text-center">
                                                        <div className="dropdown ">
                                                            <button type="text" id="dropdownMenuButton" data-toggle="dropdown" className={"btn"} aria-haspopup="true" aria-expanded="true">
                                                                <FontAwesomeIcon icon={faEllipsisV} />
                                                            </button>
                                                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                <button className="dropdown-item" onClick={()=>{
                                                                    setData( data );
                                                                    show.password = true;
                                                                    setShow( show );
                                                                }}>
                                                            Reset Password
                                                                </button>
                                                                <button className="dropdown-item text-danger" onClick={()=>{
                                                                    setData( [data] );
                                                                    show.deactivateUser = true;
                                                                    setShow( show );
                                                            
                                                                }}>
                                                            Delete
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            },
                                        }
                                    ]}
                                    data={query =>
                                        util.axios.get( urlHandler( query, true ) )
                                            .then( response => {
                                                const { users, total, page, error, msg } = response.data;
                                                if ( error ) throw msg;
                                                return ( {
                                                    data: users,
                                                    page: page,
                                                    totalCount: total
                                                } );
                                            } )
                                            .catch( error=>{
                                                util.handleError( error );
                                            } )
                                    }
                                    options={{
                                        pageSize: 20,
                                        pageSizeOptions:[20, 50, 100],
                                        sorting: true,
                                        selection: true,
                                        showSelectAllCheckbox:false,
                                        showTitle:false,
                                        exportButton:true,
                                        debounceInterval:500,
                                        search:!state.showFilter ,
                                        tableLayout:"auto",
                                        headerStyle:{ position:'sticky',top:0 },
                                        maxBodyHeight:'450px',
                                    }}
                                    localization={{ toolbar:{ searchPlaceholder:"Search for partner..." } }}
                                    actions={[
                                        // {
                                        //     icon: () => <div className="btn btn-primary btn-sm text-white"><FontAwesomeIcon  icon={faPlusCircle} /> Add User</div>,
                                        //     tooltip: 'Add User',
                                        //     isFreeAction: true,
                                        //     onClick: () => createNewUser()
                                        // },
                                        {
                                            icon: () => <div className="btn btn-danger sizeBtn text-white"><FontAwesomeIcon  icon={faTrash} /> Delete</div>,
                                            tooltip: 'Delete',
                                            onClick: ( event, data ) => {
                                                setData( data );
                                                show.deactivateUser = true;
                                                setShow( show );
                                            },
                                        },
                                        {
                                            icon: () =>
                                                <div className="btn btn-secondary sizeBtn activeBtn">
                                                    <FontAwesomeIcon icon={faFilter} className="mr-2" />
                                            Filter
                                                </div>
                                            ,
                                            position:"toolbar",
                                            isFreeAction: true,
                                            onClick: () => setState( { ...state, showFilter: true } )
                                        },
                                    ]}
                                />
                            </MuiThemeProvider>
                            :
                            <Employees />
                    }
                    
                </div>
                {isVisible ? <ModalUser modalVisible={isVisible} user={user}  hideModal={hideModal} />:""}
                
                {/* This SweetAlert is displayed when we want to reset the user password */}
                {show.password ?
                    <SAlert
                        show={show.password}
                        msg="You will reset the password of this user"
                        hideAlert={hideAlert}
                        onConfirm={onConfirmChangePassword}
                    />:""}
                {/* This SweetAlert is displayed when we want to deactivate a user */}
                {show.deactivateUser ?
                    <SAlert
                        show={show.deactivateUser}
                        msg={`You want to delete this ${ data.length === 1 ? "user":"users"}`}
                        hideAlert={hideAlert}
                        onConfirm={onConfirmDeleteUser}
                    />:""}
                {util.LOADING_SCREEN( loading )}
            </div>
        
        </Fragment>
    );
}

export default Users;