// import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import QuestionsListForm from './QuestionsListForm';

const EditQuestionForm = ( { save, cancel } ) => {
    // const [saveAsTemplate, setSaveAsTemplate] = useState( false );
    // const [templateName, setTemplateName] = useState( '' );

    return (
        <>
            {/* <div>
                <FormControlLabel control={<Checkbox
                    checked={saveAsTemplate} 
                    onChange={() => setSaveAsTemplate( !saveAsTemplate )}
                    color="primary"
                />} 
                label="Add Screening Questions" />
                {saveAsTemplate && <input 
                    type="text" 
                    placeholder="Template Name" 
                    value={templateName} 
                    onChange={( e ) => setTemplateName( e.target.value )} />}
            </div> */}
            <QuestionsListForm save={save} cancel={cancel} />
        </>
    );
};

export default EditQuestionForm;