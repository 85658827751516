import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";
import util from '../../../utils/miniUtils';
const FileAttachment = ( { appraisal_id, reloadAttachmentsOnItem } ) => {

    const onSelectFiles = async  ( e ) => {
        
        try {
            const data = new FormData();
            [...e.target.files].map( file => {
                data.append( "file", file );
            } );
            
            // console.log( data );
            const request = await util.axios.post( `/exceptional_achivement/upload/file/${appraisal_id}`, data );
            const { error, msg, attachments } = request.data;
            if ( error ) throw msg;
            reloadAttachmentsOnItem( attachments );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    return (
        <section className="d-flex">
            <label className="my-label p-1 border text-secondary text-center">
                <FontAwesomeIcon icon={faPaperclip} className='iconGray mr-1' /> Attach File
                <input accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
text/plain, application/pdf" 
                type="file" 
                className="my-input invisible" 
                name="files" 
                onChange={onSelectFiles} 
                multiple 
                />
            </label>
        </section>
    );
};

export default FileAttachment;

