import React, { useEffect, useState } from 'react';
import util from '../../utils/miniUtils';
import { Card, CardContent } from '@material-ui/core';

const ActiveJob = () => {
    const [ activeJobs, setActiveJobs ] = useState( [ ] );
   
    useEffect( () =>{
        getActiveJob(  );
    },[] );

    const getActiveJob = async ( ) => {
        try {
            const response = await util.axios.get(
                `new_dashboard/get_active_job/`
            );  
            const { data, error, msg } = response.data;
            
            setActiveJobs( data );
            
            if ( error ) throw msg;
        } catch ( e ) {
            util.handleError( e );
        }
    };
    
    return (
        <>
            <Card style={{ width: "100%", height: "70%" }}>
                <CardContent>
                    
                    <div>
                        {activeJobs.length >0 && (
                            <h2 >{activeJobs[0].active_positions}</h2> )}
                    </div>

                </CardContent>
            </Card>
            
        </>
    );
};

export default ActiveJob;