import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, Fragment } from "react";
import useAllGoalsCompetencyRequest from './useAllGoalsCompetencyRequest';
import { CircularProgress, MuiThemeProvider } from "@material-ui/core";
import util from "../../utils/miniUtils";
import MaterialTable from "material-table";
import {  faPlusCircle, faEllipsisV, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import Period from './Period';
import ModalNewTargets from './ModalNewTargets';
import ModalEditTargets from './ModalEditTargets';
import AllStatus from '../shared/Status/AllStatus';

const tableRef = React.createRef();
const GoalCompetencyTable = () => {
    const {
        form, setForm,
        list ,
        id, setId,
        loading,
        setOption,
        createaNewGoalOrCompetency,
        getAllGoalsOrCompetencies,
        editaSingleGoal,
        handleChanges, handleRichTextChange, removeTargetItem, getAllPeriods, getGroupFromGoals, handleMultiSelect, groupList,updateApplicableGroup,getGroupList  } = useAllGoalsCompetencyRequest();
    const [goalOrCompetency, setGoalOrCompetency] = useState( false );
    const [showPeriodTable, setShowPeriodTable] = useState( false );

    //for modal pop up
    const [show, setShow] = useState( false );
    const [edit,setEdit] = useState( false );
    const handleClose = () => setShow( false );
    const handleShow = () => {
        setForm( "" );
        setShow( true );};

    useEffect( ()=>{
        getGroupList();
    },[show] );


    const handleEditClose = () => setEdit( false );
    
    const handleEditShow = ( rowData ) => {
        setId( rowData.id );
        setForm( rowData );
        getGroupFromGoals( rowData.id );

        setEdit( true );};


    // To show goal or competency table
    const handleTable = ( type ) => {
        if ( type === 1 ) {
            setShowPeriodTable( false );
            setGoalOrCompetency( false );
            getAllGoalsOrCompetencies( 1 );

        } else {
            setShowPeriodTable( false );
            setGoalOrCompetency( true );
            getAllGoalsOrCompetencies( 2 );
        }
        setOption( type );

    };
    

    useEffect( () => {
        if ( goalOrCompetency === true ) {
            getAllGoalsOrCompetencies( 2 );
        } else {
            getAllGoalsOrCompetencies( 1 );
        }
    }, [] );

  
    function handlePeriod() {
        getAllPeriods();
        setShowPeriodTable( true );
    }
    function handleCreate(){
        createaNewGoalOrCompetency( goalOrCompetency ? 2 : 1 );
        setForm( "" );

    }
    function handleEdit( id ){
       
        editaSingleGoal( id );
        updateApplicableGroup( id );
   

    }
    
    
    
    if ( loading ) {
        return  <Fragment>
            <div className="container-fluid p2 ">
                <div className="text-center">
                    <CircularProgress />
                </div>
            </div>
        </Fragment>;
    }
    

    return (
        <>

            <div className="manage-toolbar my-2">
                <button
                    className={
                        `btn ${!showPeriodTable ? goalOrCompetency ? " btn-secondary" : "btn-primary " : "btn-secondary"} mx-1 manageToolbarBtn sizeBtn`
                    }
                    name="goal"
                    onClick={() => handleTable( 1 )}>
                    Goal
                </button>
                <button
                    className={
                        `btn ${!showPeriodTable ? goalOrCompetency ? " btn-primary" : "btn-secondary " : "btn-secondary"} mx-1 manageToolbarBtn sizeBtn`
                    }
                    name="competency"
                    onClick={() => handleTable( 2 )}>
                    Competency
                </button>

                <button className={
                    `btn ${showPeriodTable ? "btn-primary" : "btn-secondary"}  mx-1 manageToolbarBtn sizeBtn`
                }
                name="period" onClick={() => handlePeriod()}>Period</button>


            </div>
            {!showPeriodTable ?
                (
                    <div className="row">
                        <div className='col'>
                            <MuiThemeProvider theme={util.defaultTableThemeSlim}>
                                <MaterialTable
                                    tableRef={tableRef}
                                    columns={[
                                        {
                                            field: "title",
                                            title: `${goalOrCompetency ? 'Competency Title' : 'Goal Title'}`,
                                            sorting: false,
                                            render: ( rowData ) => {
                                                return <Fragment>
                                                    <div className='d-flex align-items-center '>
                                                        <div className="dropdown">
                                                            <button type="text" id="dropdownMenuButton" data-toggle="dropdown" className={"btn"} aria-haspopup="true" aria-expanded="true">
                                                                <FontAwesomeIcon icon={faEllipsisV} />
                                                            </button>
                                                            <div className="dropdown-menu my-drop" aria-labelledby="dropdownMenuButton">
                                                                <button className="dropdown-item text-primary" onClick={()=>handleEditShow( rowData )} >
                                                                    <FontAwesomeIcon icon={faEdit} />  Edit
                                                                </button>
                                                                <button className="dropdown-item text-danger" onClick={()=>removeTargetItem( rowData.id )} >
                                                                    <FontAwesomeIcon icon={faTrash} />  Delete
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div> {rowData.title }</div>
                                                       
                                                    </div>
                                                </Fragment>;
                                            },
                                        },
                                      
                                        {
                                            field: "source of evidence",
                                            title: 'Source of Evidence',
                                            render: ( rowData ) => {
                                                return <div> {rowData.evidence}</div>;
                                            },
                                        },
                                        {
                                            field: "applicable group",
                                            title: 'Applicable Group',
                                            render: ( rowData ) => {
                                                // console.log( rowData.applicable_group );
                                                return <div>{ rowData.applicable_group.join( ", " )}</div>;

                                            },
                                            
                                        },
                                        {
                                            field: "default target",
                                            title: 'Default Target',
                                            align:"center",
                                            render: ( rowData ) => {
                                                return <div> {rowData.default_target}</div>;
                                            },
                                        },
                                        {
                                            field: "description",
                                            title: 'Description',
                                            render: ( rowData ) => {
                                                return <div> {rowData.description}</div>;
                                            },
                                        },
                                    ]}
                                    data={list}
                                    localization={{
                                        toolbar:{
                                            searchPlaceholder:"Search",
                                            nRowsSelected: ""
                                        }
                                    }}
                                    options={{
                                        searchFieldAlignment: "left",
                                        selection: true,
                                        showTitle: false,
                                        pageSize: 7,
                                        emptyRowsWhenPaging: true,
                                        columnsButton: true,
                                        sorting: true,
                                        padding: "default",
                                        tableLayout: "fixed",
                                        toolbarButtonAlignment: "left",
                                        headerStyle: { backgroundColor: "#DFE1E0", color: "black" },
                                    }}
                                    actions={[
                                        {
                                            icon: () => (
                                                <FontAwesomeIcon
                                                    icon={faPlusCircle}
                                                    id="addRequirementIcon"
                                                    className="text-primary" 
                                                    onClick={()=>handleShow()}
                                                />
                                            ),
                                            tooltip: `${goalOrCompetency?'Add New Competency':'Add New Goal'}`,
                                            isFreeAction: true,
                                        },
                                    ]}

                                />
                            </MuiThemeProvider>
                            <AllStatus />
                            {show ? ( <ModalNewTargets
                                closeModal={() => handleClose()}
                                show={show}
                                onHide={''}
                                goalOrCompetency={goalOrCompetency}
                                groupList={groupList}
                                handleChange={handleMultiSelect}
                                handleCreate={handleCreate}
                                form={form}
                                handleChanges={handleChanges}
                                handleRichTextChange={handleRichTextChange}
                                    
                            /> ) : "" }
                            {edit?  (  <ModalEditTargets
                                closeModal={() => handleEditClose()}
                                edit={edit}
                                onHide={''}
                                goalOrCompetency={goalOrCompetency}
                                groupList={groupList}
                                handleChange={handleMultiSelect}
                                handleEdit={()=>handleEdit( id )}
                                form={form}
                                handleChanges={handleChanges}
                                handleRichTextChange={handleRichTextChange} /> ) : "" }
                        </div> 
                    </div> )
                : ( <Period /> )}
        </>
    );
};


export default GoalCompetencyTable;




