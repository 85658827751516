import Drawer from "@material-ui/core/Drawer";
import Tooltip from "@material-ui/core/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimesCircle,faPlusCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import util from "../../utils/miniUtils";
import SAlert from "../../microComponents/AlertSA";

const AddAssetDrawer = ( { modalShow, setModalShow, isEditing, assetData, handleChange,  categories, showInputFactory, setShowInputFactory, InputNewElement, assetsTypes, assetMake, assetModel, accounts, setAssetData, updateNewAssets, saveNewAssets, setShowAlert, showAlert, onConfirmDeleteAsset } ) => {
    return <Drawer anchor="right" open={modalShow} onClose={() => setModalShow( false )}>
        <div className="container pt-0" style={{ width: window.innerWidth * .30 }}>
            <div className="row">
                <div className="col-md-12 p-0">
                    <div className="bg-primary w-100 text-white p-2">
                        <h4>{isEditing ? "Update Asset" : "Create Asset"}</h4>
                        <div>
                            <h5>{
                                assetData.id_asset_record ?
                                    `ID ` + util.zeroPad( assetData.id_asset_record, 5 ) : "Adding Details"
                            }</h5>
                        </div>
                    </div>

                    <table className="table tableAssets">
                        <thead />
                        <tbody>
                            <tr>
                                <th>Category</th>
                                <td className="justify-content-between">
                                    <select name="aCategory"
                                        id="aCategory"
                                        value={assetData.aCategory} className="form-control" onChange={handleChange}>
                                        <option value={null} />
                                        {categories.map( ( option ) => {
                                            return <option key={option.name} value={option.name}>{option.name}</option>;
                                        } )}
                                    </select>
                                    <InputNewElement
                                        show={showInputFactory}
                                        typeElement={4}
                                        handleCancel={() => setShowInputFactory( 0 )}
                                    />
                                </td>
                                <td>
                                    <Tooltip title="Create new Category">
                                        <div id="btnNewCat" onClick={() => setShowInputFactory( 4 )} className="text-primary fa-2x">
                                            <FontAwesomeIcon icon={faPlusCircle} />
                                        </div>
                                    </Tooltip>
                                </td>
                            </tr>
                            {
                                assetData.aCategory !== undefined && assetData.aCategory !== null && assetData.aCategory !== "" ?
                                    <Fragment>
                                        <tr>
                                            <th>Type</th>
                                            <td className="justify-content-between">
                                                <select
                                                    id="aType"
                                                    name="aType" value={assetData.aType} className="form-control"
                                                    onChange={handleChange}>
                                                    <option value={null} />
                                                    {assetsTypes.map( ( option, i ) => {
                                                        return <option key={i} value={option.name}>{option.name}</option>;
                                                    } )}
                                                </select>
                                                <InputNewElement
                                                    show={showInputFactory}
                                                    typeElement={5}
                                                    handleCancel={() => setShowInputFactory( 0 )}
                                                />
                                            </td>
                                            <td>
                                                <Tooltip title="Create new Type">
                                                    <div id="btnNewType" onClick={() => setShowInputFactory( 5 )}
                                                        className="text-primary fa-2x">
                                                        <FontAwesomeIcon icon={faPlusCircle} />
                                                    </div>
                                                </Tooltip>

                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Item</th>
                                            <td className="justify-content-between">
                                                <input
                                                    name="item"
                                                    value={assetData.item ? assetData.item : ""}
                                                    className="form-control"
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Make</th>
                                            <td className="justify-content-between">
                                                <select
                                                    name="aMake" id="aMake" value={assetData.aMake} className="form-control"
                                                    onChange={handleChange}>
                                                    <option value={null} />
                                                    {assetMake.map( ( option, i ) => {
                                                        return <option key={i} value={option.name}>{option.name}</option>;
                                                    } )}
                                                </select>
                                                <InputNewElement
                                                    show={showInputFactory}
                                                    typeElement={2}
                                                    handleCancel={() => setShowInputFactory( 0 )}
                                                />
                                            </td>
                                            <td>
                                                <Tooltip title="Create new Make">
                                                    <div
                                                        id="btnNewMake"
                                                        onClick={() => setShowInputFactory( 2 )}
                                                        className="text-primary fa-2x">
                                                        <FontAwesomeIcon icon={faPlusCircle} />
                                                    </div>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Model</th>
                                            <td className="justify-content-between">
                                                <select
                                                    id="aModel"
                                                    name="aModel"
                                                    value={assetData.aModel ? assetData.aModel : ""} className="form-control"
                                                    onChange={handleChange}>
                                                    <option value={null} />
                                                    {assetModel.map( ( option, i ) => {
                                                        return <option key={i} value={option.name}>{option.name}</option>;
                                                    } )}
                                                </select>
                                                <InputNewElement
                                                    show={showInputFactory}
                                                    typeElement={3}
                                                    handleCancel={() => setShowInputFactory( 0 )}
                                                />
                                            </td>
                                            <td>
                                                <Tooltip title="Create new Model">
                                                    <div
                                                        id="btnNewModel"
                                                        onClick={() => setShowInputFactory( 3 )}
                                                        className="text-primary fa-2x">
                                                        <FontAwesomeIcon icon={faPlusCircle} />
                                                    </div>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>State</th>
                                            <td className="justify-content-between">
                                                <select
                                                    id="state"
                                                    name="state" value={assetData.state} className="form-control"
                                                    onChange={handleChange}>
                                                    <option value={null} />
                                                    {util.assetStates().map( ( option, i ) => {
                                                        return <option key={i} value={option.name}>{option.name}</option>;
                                                    } )}
                                                </select>
                                            </td>
                                        </tr>
                                        {assetData.state === "In Stock" ?
                                            <tr>
                                                <th>Location</th>
                                                <td className="justify-content-between">
                                                    <select name="location" value={assetData.location}
                                                        className="form-control" onChange={handleChange}>
                                                        <option value={null} />
                                                        {util.assetLocation().map( ( option, i ) => {
                                                            return <option key={i}
                                                                value={option.location}>{option.location}</option>;
                                                        } )}
                                                    </select>
                                                </td>
                                            </tr> : null}
                                        <tr>
                                            <th>Purchase Date</th>
                                            <td>
                                                <input
                                                    className="form-control"
                                                    name="date_of_purchase"
                                                    type="date"
                                                    value={assetData["date_of_purchase"] ? assetData["date_of_purchase"] : new Date().toISOString().split( "T" )[0]}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        {assetData.aCategory === "Software" || assetData.aCategory === "Hardware" ?
                                            <tr>
                                                <th>License/Warranty end date</th>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        name="lw_end_date"
                                                        type="date"
                                                        value={assetData["lw_end_date"] ? assetData["lw_end_date"] : new Date().toISOString().split( "T" )[0]}
                                                        onChange={handleChange}
                                                    />
                                                </td>
                                            </tr>
                                            : null
                                        }

                                        <tr>
                                            <th>
                                                Serial Number
                                            </th>
                                            <td>
                                                <input
                                                    className="form-control"
                                                    name="serial_number"
                                                    value={assetData["serial_number"] ? assetData["serial_number"] : ""}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        {assetData.aCategory === "Hardware" ?
                                            <Fragment>
                                                <tr>
                                                    <th>CPU</th>
                                                    <td>
                                                        <input
                                                            className="form-control"
                                                            name="cpu"
                                                            value={assetData.cpu ? assetData.cpu : ""}
                                                            onChange={handleChange}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Operative System</th>
                                                    <td>
                                                        <select name="operative_system"
                                                            value={assetData["operative_system"]}
                                                            className="form-control" onChange={handleChange}>
                                                            <option value={null} />
                                                            {util.assetOS().map( ( option, i ) => {
                                                                return <option key={i}
                                                                    value={option.name}>{option.name}</option>;
                                                            } )}
                                                        </select>
                                                    </td>
                                                </tr>
                                            </Fragment> : null}
                                        <tr>
                                            <th>Amount</th>
                                            <td>
                                                <input
                                                    className="form-control"
                                                    name="amount"
                                                    type="number"
                                                    value={assetData.amount | ""}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Assigned User</th>
                                            <td className="justify-content-between">
                                                <select name="assignedUser" value={assetData.assignedUser ? assetData.assignedUser : ""}
                                                    className="form-control" onChange={handleChange}>
                                                    <option value={null} />
                                                    {accounts.map( ( option, i ) => {
                                                        return <option key={i} value={option.name}>{option.name}</option>;
                                                    } )}
                                                </select>
                                            </td>
                                            <td>
                                                <Tooltip title="Remove assignation">
                                                    <div onClick={() => {
                                                        let t = { ...assetData };
                                                        t.assignedUser = "";
                                                        t.assigned_user = 0;
                                                        setAssetData( Object.assign( {}, t ) );
                                                    }} className="text-danger fa-2x">
                                                        <span><FontAwesomeIcon icon={faTimesCircle} /></span>
                                                    </div>
                                                </Tooltip>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Credit Card User</th>
                                            <td className="justify-content-between">
                                                <select name="creditCardUser" value={assetData.creditCardUser}
                                                    className="form-control" onChange={handleChange}>
                                                    <option value={null} />
                                                    {accounts.map( ( option, i ) => {
                                                        return <option key={i} value={option.name}>{option.name}</option>;
                                                    } )}
                                                </select>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Notes</th>
                                            <td colSpan={2}>
                                                <textarea
                                                    rows={3}
                                                    className="form-control"
                                                    name="notes"
                                                    value={assetData.notes ? assetData.notes : ""}
                                                    onChange={handleChange}>
                                                </textarea>
                                            </td>
                                        </tr>
                                    </Fragment>
                                    : null
                            }
                        </tbody>
                    </table>
                    {assetData.aCategory !== undefined && assetData.aCategory !== null && assetData.aCategory !== "" ?

                        <Fragment>
                            <div className="text-center mb-3">
                                <button className="btn btn-sm text-danger mr-3" onClick={() => setModalShow( false )}>
                                    Close
                                </button>
                                {isEditing ?
                                    <button className="btn btn-secondary sizeBtn activeBtn"
                                        onClick={() => updateNewAssets()}>Update</button> :
                                    <button className="btn btn-secondary sizeBtn activeBtn" onClick={() => saveNewAssets()}>Create
                                        new asset</button>
                                }
                            </div>
                            {isEditing ?
                                <div className="text-right buttonToDeleteAsset">
                                    <button
                                        title="Delete this asset record"
                                        className="btn text-danger btn-sm"
                                        onClick={() => setShowAlert( { show: true, id: assetData["id_asset_record"] } )}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </button>
                                </div> : ""}
                        </Fragment>

                        : ""}
                </div>
            </div>
            {showAlert.show ?
                <SAlert
                    show={showAlert.show}
                    confirmText="Yes"
                    typeButton="danger"
                    msg="Delete this asset record?"
                    hideAlert={() => setShowAlert( { show: false, id: 0 } )}
                    onConfirm={() => onConfirmDeleteAsset()}
                /> : ""}
        </div>
    </Drawer>;
};
export default AddAssetDrawer;