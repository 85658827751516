import { useState } from 'react';
import util from '../../utils/miniUtils';

const useWorkload = () => {
    /***************************************
    *                                      *
    *                States                *
    *                                      *
    ***************************************/
    const [loading, setLoading] = useState( false );
    const [workloadData, setWorkloadData] = useState( [] );
    const [departmentList, setDepartmentList] = useState( [] );
    const [groupList, setGroupsList] = useState( [] );
    const [membersOfGroup, setMembersOfGroup] = useState( [] );
    const [categoriesByGroup, setCategoriesByGroup] = useState( [] );
    const [statusListByCategory, setStatusListByCategory] = useState( [] );
    const [workloadSimple, setWorkloadSimple] = useState( [] );
    const [attachmentFiles, setAttachmentFiles] = useState( [] );
    const [customerList, setCustomerList] = useState( [] );
    const [workLoadCreated, setWorkLoadCreated] = useState( false );
    const [categoryPrefix, setCategoryPrefix] = useState( [] );
    const [workload, setWorkload] = useState( {
        id: null,
        department_id: null,
        wl_category_id: null,
        parent_ticket_id: null,
        customer_id: undefined,
        resolver_id: null,
        group_id: null,
        created_by: null,
        updated_by: null,
        status: null,
        status_from_categories: null,
        ticket_number: null,
        short_description: null,
        long_description: null,
        ticket_category: null,
        ticket_type: null,
        item: null,
        reference_number: null,
        priority: "Medium",
        region: "Americas",
        country: util.user.country ? util.user.country:"USA",
    } );

    
    /***************************************
    *                                      *
    *               REQUESTS               *
    *                                      *
    ***************************************/
    const getWrokloadList = async ( ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/workload/WorloadList` );
            const { error, msg, workLoadList } = request.data;
            if ( error ) throw msg;
            setLoading( false );
            setWorkload( workLoadList );
         
            // setDepartmentList( depList );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };

    // This request provides information for all the selectors required in create Workload
    const getInitialRequest = async ( ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/workload/get_create_initial_request` );
            const { error, msg, customerList, grpList } = request.data;
            if ( error ) throw msg;
            setLoading( false );
            setCustomerList( customerList );
            setGroupsList( grpList );
            // setDepartmentList( depList );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };

    // Detail of an specific workload
    const getWorkLoadDetail = async ( workload_id = null ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/workload/get_workload_detail/${workload_id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setLoading( false );
            setWorkload( { ...data } );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };
    
    // List of all workload alloted to the logged user
    const getWorkLoad = async ( path ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( path );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setWorkloadData( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    // Provide list of possibles user who will create the workload
    const getCustomerList = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/workload/customer_employee_list` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setCustomerList( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };
    
    // Retrieve list of all available groups
    const getGroupList = async () => {
        try {
            setLoading( true );
            const request = await util.axios.get( '/groups/simple-list' );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setGroupsList( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };
    
    // After we select a category, we can get the workload status that belong to the selected category
    const getStatusListToCategory = async ( category_id ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/get_all_status_category/by_category/${category_id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            // console.log( data );
            setStatusListByCategory( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    // After we select a group, we can get the members that belong to the selected group
    const getAssignedMembersToGroup = async ( group_id ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/groups/get_assigned_members/${group_id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            setMembersOfGroup( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    // After we select a group, we can get the categories that belong to the selected group
    const getCategories = async ( group_id ) => {
        try{
            setLoading( true );
            const request = await util.axios.get( `/groups/get_categories/${group_id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setCategoriesByGroup( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    // Get Category detail from category id
    const getCategoryPrefix = async ( category_id ) => {
        try{
            setLoading( true );
            const request = await util.axios.get( `/wl_categories/detail/${category_id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setCategoryPrefix( data[0].prefix );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    // Retrieve a list of all available workloads for parent selection
    const getWorkLoadSimpleList = async ( ) => {
        try{
            setLoading( true );
            const request = await util.axios.get( `/workload/list_simple` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setWorkloadSimple( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    // Get the list of all available departments
    const getDepartments = async () => {
        try{
            setLoading( true );
            const request = await util.axios.get( `/department/simple-list` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setDepartmentList( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    // Get List of all department by user or employeeId
    const getDepartmentsByUser = async ( id ) => {
        try{
            setLoading( true );
            const request = await util.axios.get( `/department/workload_list/${id}` );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            setDepartmentList( data );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    // Get Attachments
    const getAttachments = async ( id ) => {
        try {
            const request = await util.axios.get( `/workload/get_attachment/${id}` );
            const { error, msg, attachments } = request.data;
            if ( error ) throw msg;
            setAttachmentFiles( attachments );
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // Save attachments
    const saveAttachments = async ( id ) => {
        try {
            const data = new FormData();
            [...attachmentFiles].map( file => {
                data.append( "file", file );
            } );
            const request = await util.axios.post( `/workload/upload_attachment/${id}`, data );
            const { error, msg } = request.data;
            if ( error ) throw msg;
        } catch ( error ) {
            util.handleError( error );
        }
    };

    // Save/Create new Workload
    const saveNewWorkload = async ( newWorkload ) => {
        try {
            setLoading( true );
            const request = await util.axios.post( `/workload/new_workload`, { ...newWorkload } );
            const { error, msg, data } = request.data;
            if( error ) throw msg;
            if( attachmentFiles.length > 0 ) saveAttachments( data.id );
            setLoading( false );
            setWorkLoadCreated( true );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };

    // Update changes on Workload
    const updateWorkLoad = async ( temp_workload ) => {
        try {
            setLoading( true );
            const { id } = temp_workload;
            const request = await util.axios.put( `/workload/update_workload/${id}`, { ...temp_workload } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
            setLoading( false );
            setWorkLoadCreated( true );
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };

    //Acknowledge Workload
    const handleAcknowledge = async ( e, d ) => {
        e.stopPropagation();
        let statusList = [];
        try {
            setLoading( true );
            const request = await util.axios.get( `/get_all_status_category/by_category/${d.wl_category_id}` );
            const { error, msg, data } = request.data;
            if ( error ) throw msg;
            statusList = [ ...data ];
        } catch ( error ) {
            util.handleError( error );
        }
        // Change the status
        let newStatus = {
            status: statusList[1].wl_status_id,
            status_from_categories: statusList[1].wl_status_category
        };
        // Updating the workload status
        try {
            const request = await util.axios.put( `/workload/update_workload/${d.id}`, { ...newStatus } );
            const { error, msg } = request.data;
            if( error ) throw msg;
            util.toast().success( msg );
            setWorkLoadCreated( true );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
    };

    /***************************************
    *                                      *
    *            BUSINESS LOGIC            *
    *                                      *
    ***************************************/

    function handleChange( e ) {
        const { name, value } = e.target;
        setWorkload( ( prev )=>{
            return {
                ...prev,
                [name]: value,
            };
        } );
        // console.log( name, value );
    }
    
    // Only saves files in memory
    const addAttachment = ( e ) => {
        setAttachmentFiles( [...e.target.files] );
        const data = new FormData();
        [...e.target.files].map( file => {
            data.append( "file", file );
        } );
    };

    // Only saves files in memory
    const deleteAttachment = ( index ) => {
        let temp = attachmentFiles.filter( ( a,i )=> i !== index );
        setAttachmentFiles( Object.assign( [], temp ) );
    };

    const ticketNumber = async ( prefix, id ) => {
        return  `${prefix}${id}`;
    };
    
    return {
        loading, 
        setLoading,
        workloadData, 
        setWorkloadData,
        departmentList, 
        setDepartmentList,
        getWorkLoad,
        getDepartments,
        saveNewWorkload,
        groupList,
        membersOfGroup,
        statusListByCategory,
        getGroupList,
        getAssignedMembersToGroup,
        getCategories,
        categoriesByGroup,
        getStatusListToCategory,
        workloadSimple,
        getWorkLoadSimpleList,
        getWorkLoadDetail,
        workLoadCreated,
        workload, 
        handleChange, 
        setWorkload, 
        updateWorkLoad,
        attachmentFiles, 
        addAttachment, 
        deleteAttachment, 
        getCustomerList,
        customerList,
        getInitialRequest,
        getWrokloadList,
        handleAcknowledge,
        getAttachments,
        getDepartmentsByUser,
        ticketNumber,
        categoryPrefix,
        getCategoryPrefix,
    };
};

export default useWorkload;