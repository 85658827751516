import { MuiThemeProvider } from '@material-ui/core';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import JobDetail from './JobDetail';
import util from '../../../../../../utils/miniUtils';
import JobCard from './JobCard';
import SideBarFilter from '../../SideBarFilter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const CardView = ( { list, filterState, setFilterState, reloadTableData, setTableState, page, totalCount,deleteShow, hideAlert,onConfirmDeleteJob, HIDE_SWEET_ALERT_DELETE_JOB_POSITION,handleDelete,row, pageSize } ) => {
    const [selectedJob, setSelectedJob] = useState( {} );

    useEffect( () => {
        if ( filterState.ready ) {
            reloadTableData();
        }
    },[filterState] );

    return (
        <div className='row'>
           
            {  filterState.showFilter ?
                <div className='col-md-2'>
                    <div>
                        <SideBarFilter
                            reloadData={
                                ( filter )=> {
                                    setFilterState( prevState => ( {
                                        ...prevState,
                                        ...filter
                                    } ) );
                                }
                            }
                        />
                    </div>
                </div>
                :
                null
            }
            <div className={filterState.showFilter ? "col-md-10" : "col-md-12"}>
                <div className="row">
                    <div className='col-4'>
                        <MuiThemeProvider theme={util.defaultTableThemeSlim}>
                            <MaterialTable
                                columns={[
                                    {
                                        field: "job_title",
                                        render: p => {
                                            return <div className={selectedJob.id === p.id ? "bg-light p-1" : "p-1"}>
                                                <JobCard job={p} deleteShow={deleteShow} 
                                                    onConfirmDeleteJob={onConfirmDeleteJob}
                                                    hideAlert={hideAlert}
                                                    HIDE_SWEET_ALERT_DELETE_JOB_POSITION={ HIDE_SWEET_ALERT_DELETE_JOB_POSITION}
                                                    handleDelete={handleDelete}
                                                    row={row}/></div>;
                                        }
                                    }
                                ]}
                                totalCount={totalCount}
                                page={page}
                                data={list}
                                options={{
                                    pageSize: pageSize,
                                    sorting: false,
                                    showFirstLastPageButtons: true,
                                    pageSizeOptions: [],
                                    searchFieldAlignment:"left",
                                    toolbarButtonAlignment: "left",
                                    tableLayout:"fixed",
                                    // padding: "dense",
                                    search: true,
                                    paginationType: "normal",
                                    showTitle:false,
                                    emptyRowsWhenPaging:false,
                                    columnsButton: false,
                                    paging:true,
                                    paginationPosition:"bottom",
                                    maxBodyHeight:'70vh',
                                    debounceInterval:500,

                                }}
                                actions={[
                                    {
                                        icon: () => (
                                            <div className="d-flex align-items-center">
                                                <Link to={"/jobs/new"}>
                                                    <span className='text-primary'>
                                                        <FontAwesomeIcon icon={faPlusCircle} id="addRequirementIcon" className="mr-3 ml-2" />
                                                    </span>
                                                </Link>
                                                <span className="btn btn-primary btn-sm ml-2">
                                                    <FontAwesomeIcon icon={faFilter} />
                                                </span>
                                            </div>
                                        ),
                                        isFreeAction: true,
                                        onClick: () => {
                                            if ( filterState.showFilter ) {
                                                setFilterState( { ...filterState, 
                                                    startDate:"",
                                                    endDate:"",
                                                    countries:[],
                                                    status:[],
                                                    leadRecruiters:[],
                                                    clients:[],
                                                    partners:[],
                                                    salesLead:[],
                                                    createdBy:[],
                                                    showFilter: false 
                                                } );
                                            } else {
                                                setFilterState( { ...filterState, showFilter: true } );
                                            }
                                        } 
                                    },
                                    // ... other actions
                                ]}
                                  
                              
                                onRowClick={( event, rowData ) =>{
                                    setSelectedJob( rowData );
                                }}
                                onChangePage={page => {
                                    setTableState( prev => ( { ...prev, page: page } ) );
                                }}
                                onSearchChange={search => {
                                    setTableState( prev => ( { ...prev, search: search } ) );
                                }}
                            />
                        </MuiThemeProvider>
                    </div>
                    <div className='col-8 w-100'>
                        {Object.keys( selectedJob ).length ? 
                            <JobDetail job={selectedJob} /> : 
                            <div className="noJobSelected-img"></div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardView;