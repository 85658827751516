import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faEye, faEyeSlash, faMapMarkedAlt, faPaperPlane, faPhone, } from "@fortawesome/free-solid-svg-icons";
import util from "../../../utils/miniUtils";
import React, { Fragment } from "react";
import PercentageSection from "./PercentageSection";
import ProfilePictureComponent from "./ProfilePictureComponent";
import { Collapse, Tooltip } from "@material-ui/core";
import { TalentStore } from "../TalentStore";
import usePersonalInformation from "../personal_information/usePersonalInformation";

function Sidebar() {
	
    const { sendLinkToCandidate, selectedNote, setSelectedNote } = usePersonalInformation();
	
    const talent = TalentStore.useState( state => state.talent );
	
    const srcValue = "/images/whatsapp2.png";
	
    const ItemList = ( { icon, data, img } ) => {
        return (
            <Fragment>
                <tr>
                    <td>
                        {
                            img ?
                                img
                                :
                                <FontAwesomeIcon icon={icon} className="iconGray"/>
                        }
                    </td>
                    <td className="sidebarInfoText9em p-2">
                        {data}
                    </td>
                </tr>
            </Fragment>
        );
    };
	
    return (
        <Fragment>
			
            <ProfilePictureComponent/>
			
            <div className="p-3">
                <div className="sidebarTalentName">{talent.name}</div>
                <div className="sidebarTalentSkillName">{talent.skill_name}</div>
				
                <table className="">
                    <thead/>
                    <tbody>
                        <ItemList
                            icon={faEnvelope}
                            data={<a href={`mailto:${talent.email}`}>{talent.email}</a>}
                        />
					
                        <ItemList
                            icon={faPhone}
                            data={<a href={`tel:${talent.phone_mobile}`}>{util.formatPhone( talent.phone_mobile )}</a>}
                        />
					
                        {
                            util.user.role !== 2 ?
                                <ItemList
                                    img={<img width={16} src={srcValue} alt="whatsapp" className="mr-2"/>}
                                    data={<a target="_blank" rel="noopener noreferrer"
								         href={util.whatsapp( talent.phone_mobile, `Hello, my name is ${util.user.name} from eJAmerica` )}>
									Send WhatsApp
                                    </a>}
                                />
                                :
                                null
                        }
					
                        <ItemList
                            icon={faMapMarkedAlt}
                            data={util.location( talent.country, talent.state, talent.city )}
                        />
                    </tbody>
                </table>
				
                {![2, 3, 4, 5, 7].includes( util.user.role ) ?
                    <Fragment>
                        
                        <div className="text-center mt-4">
                            <Tooltip title="This will send to the talent to allow him complete his profile">
                                <span>
                                    <button
                                        onClick={sendLinkToCandidate}
                                        className="btn activeBtn sizeBtn btn-secondary  mr-1">
                                        <FontAwesomeIcon icon={faPaperPlane} className="mr-2"/>
                                        {util.noValues.includes( talent.link_sent ) ?
                                            "Link"
                                            :
                                            "Link Again"
                                        }

                                    </button>
                                </span>
                            </Tooltip>
                            <div className="sidebarInfoText9em">
                                {util.noValues.includes( talent.link_sent ) ?
                                    "Link never sent" :
                                    `Link sent: ${talent.link_sent_date}`
                                }
                            </div>
                        </div>
                        <hr/>
                        <b>Notes:</b>
						
                        {
                            talent.notes.length > 0 ?
                                talent.notes.map( ( note, i ) => {
                                    return (
                                        <div className="longTextOnButton" key={i}>
                                            <Collapse in={selectedNote === i} collapsedHeight={18}>
                                                <span className="mr-2">
                                                    {
                                                        selectedNote === i ?
                                                            <FontAwesomeIcon onClick={() => setSelectedNote( null )}
															                 icon={faEyeSlash}/>
                                                            :
                                                            <FontAwesomeIcon onClick={() => setSelectedNote( i )}
															                 icon={faEye}/>
                                                    }
                                                </span>
                                                {note.note}
                                            </Collapse>
										
                                        </div>
                                    );
                                } )
                                :
                                " -- "
                        }
					
                    </Fragment>
                    : ""}
				
				
                <hr/>
				
                {
                    talent.percentage ?
                        <PercentageSection percentage={talent.percentage}/>
                        :
                        null
                }
            </div>
        </Fragment>
    );
}

export default Sidebar;