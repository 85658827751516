import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import util from '../../../utils/miniUtils';
import JobStatusForm from './JobStatusForm';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


const StatusList = ( { title, statusList, clickable=false, handleClick, selectedStatus, saveNewStatus, updateStatus, deleteStatus, updateOrder } ) => {
    const [itemList, setItemList] = useState( statusList );
    const [showModal, setShowModal] = useState( false );
    const [showDeleteModal, setShowDeleteModal] = useState( false );
    const [isEdit, setIsEdit] = useState( false );
    const [deleteItem, setDeleteItem] = useState( {} );
    const [form, setForm] = useState( { 
        active: 1,
        name: "",
        description: ""
    } );

    const handleClose = () => {
        setShowModal( false );
        setIsEdit( false );
        // Emptying the from
        setForm( { 
            active: 1,
            name: "",
            description: ""
        } );
    };

    const handleSubmit = ( data ) => {
        if( isEdit ) {
            if( data.name && data.description && data.jts_id ) {
                updateStatus( data, selectedStatus );
                handleClose();
            }  else {
                util.toast().error( 'Please fill all the values' );
            }
        } else {
            if( data.name && data.description ) {
                saveNewStatus( data, selectedStatus );
                handleClose();
            }  else {
                util.toast().error( 'Please fill all the values' );
            }
        }
    };

    const handleChange = ( e ) => {
        const { name, value, checked } = e.target;
        if( name === 'active' ) {
            setForm( prev => {
                return {
                    ...prev,
                    [name]: checked ? 1 : 0
                };
            } );
        } else {
            setForm( prev => {
                return {
                    ...prev,
                    [name]: value
                };
            } );
        }
    };

    const handleEdit = ( statusData ) => {
        setIsEdit( true );
        setForm( {
            jts_id: statusData.jts_id,
            name: statusData.name,
            description: statusData.description,
            active: statusData.active
        } );
        setShowModal( true );
    };

    const handleDelete = ( data ) => {
        setShowDeleteModal( true );
        setDeleteItem( data );
    };

    const handleDeleteModalClose = () => {
        setShowDeleteModal( false );
    };

    const handleDrop = ( result ) => {
        const { source, destination } = result;
        // Ignore drop outside droppable container
        if ( !destination ) return;

        // Ignore if the drop source.index is same as destination.index
        if( destination.index === source.index ) return;

        
        let updatedList = [...itemList];
        // Remove dragged item
        const [reorderedItem] = updatedList.splice( source.index, 1 );
        // Add dropped item
        updatedList.splice( destination.index, 0, reorderedItem );
        updatedList = updatedList.map( ( el, i ) => ( { ...el, order: i + 1 } ) );
        // Update State
        setItemList( updatedList );

        //update backend
        updateOrder( updatedList );

    };

    console.table( itemList );

    useEffect( () => {setItemList( statusList );}, [statusList] );

    return (
        <>
            <div className="col-lg-4 col-md-6 col-sm-12 bg-white">
                <div className="d-flex justify-content-between align-items-center mt-1 mb-2">
                    <b>{title}</b>
                    <FontAwesomeIcon                        
                        className="isCursor text-primary"
                        onClick={() => setShowModal( true )}
                        icon={faPlus} />
                </div>
                <DragDropContext onDragEnd={handleDrop}>
                    <Droppable droppableId={title}>
                        {( provided ) => (
                            <div className="listContainer" {...provided.droppableProps} ref={provided.innerRef}>
                                {itemList && itemList.map( ( el, index ) => {
                                    if( clickable ) {
                                        return  <Draggable key={el.jts_id} draggableId={String( el.jts_id )} index={index}>
                                            {( provided ) => (
                                                <div className={`border rounded p-1 mb-1 ${el.jts_id === selectedStatus && 'activeBlock'}`}
                                                    ref={provided.innerRef} 
                                                    {...provided.dragHandleProps} 
                                                    {...provided.draggableProps}>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className='w-100 p-1 d-flex flex-column' onClick={() => {if( clickable ) {
                                                            handleClick( el.jts_id );   
                                                        }}
                                                        }>
                                                            <span>{el.name}</span>
                                                            <span className='xs-fontSize text-secondary'>{el.description}</span>
                                                        </div>
                                                        <span className='d-flex'>
                                                        <span className='text-secondary' onClick={() => handleEdit( el )}>
                                                                <FontAwesomeIcon className="isCursor" icon={faEdit} />
                                                        </span>
                                                        <span className='text-secondary mr-1' onClick={() => handleDelete( el )}>
                                                                <FontAwesomeIcon className="isCursor" icon={faTrash}/>
                                                        </span>
                                                            
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>;
                                    } else {
                                        return el.jts_id == selectedStatus ? "" : <Draggable key={el.jts_id} draggableId={String( el.jts_id )} index={index}>
                                            {( provided ) => (
                                                <div className={`border rounded p-1 mb-1 ${el.jts_id === selectedStatus && 'activeBlock'}`}
                                                    ref={provided.innerRef} 
                                                    {...provided.dragHandleProps} 
                                                    {...provided.draggableProps}>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <div className='w-100 p-1 d-flex flex-column' onClick={() => {if( clickable ) {
                                                            handleClick( el.jts_id );   
                                                        }}
                                                        }>
                                                            <span>{el.name}</span>
                                                            <span className='xs-fontSize text-secondary'>{el.description}</span>
                                                        </div>
                                                        <span className='d-flex'>
                                                        <span className='text-secondary' onClick={() => handleEdit( el )}>
                                                                <FontAwesomeIcon className="isCursor" icon={faEdit} />
                                                        </span>
                                                            <span className='text-secondary mr-1' onClick={() => handleDelete( el )}>
                                                                <FontAwesomeIcon className="isCursor" icon={faTrash}/>
                                                            </span>
                                                            
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>;
                                    }
                                } )}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <JobStatusForm
                show={showModal} 
                handleClose={handleClose} 
                handleSave={handleSubmit} 
                form={form} 
                handleChange={handleChange} 
                isEdit={isEdit} />
            {/* Modal when the delete button is clicked */}
            <DeleteModal 
                show={showDeleteModal} 
                handleClose={handleDeleteModalClose} 
                handleSubmit={deleteStatus} 
                item={deleteItem} />
        </>
    );
};

export default StatusList;

const DeleteModal = ( { show, handleClose, handleSubmit, item } ) => {

    const handleDeleteBtn = ( id ) => {
        handleSubmit( id );
        handleClose();
    };
    return <Modal show={show} onHide={handleClose}>
        <Modal.Header>Delete Job Talent Status</Modal.Header>
        <Modal.Body className='d-flex flex-column justify-content-between'>
            <span>Do you want to delete status <strong>{item.name}</strong>?</span>
            <span className='xs-fontSize'>This cannot be undone.</span>
        </Modal.Body>
        <Modal.Footer>
            <span className='btn btn-sm btn-danger ' onClick={() => handleDeleteBtn( item.jts_id )}>Delete</span>
            <span className='btn btn-sm btn-primary' onClick={handleClose}>Cancel</span>
        </Modal.Footer>
    </Modal>;
};