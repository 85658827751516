import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setFilter } from '../../../redux/actions/datefilter';

const CategoryDropdown = ( { availableCharts, setLayouts, setAvailableCharts, uniqueCategories } ) =>{
    const dispatch = useDispatch();
    
    
    const handleOptionChange =( chartId ) =>{
        const availableChart = availableCharts.find( ( item )=> item.id == chartId );
        const updatedAvailableCharts = availableCharts.filter( ( item ) => item.id != chartId );
        // setSelectedCharts( [availableChart] );
        // setSelectedCharts( ( prev ) => [...prev, availableChart] );
        dispatch( setFilter( true, chartId ) );
        setLayouts( prev => [...prev, availableChart?.initialState] );
        setAvailableCharts( updatedAvailableCharts );
    };
    
    return (
        <>
            {/* todo
            make a multilevel dropdown 
            */}
            <select className="p-2 dashboard-category" value="" onChange={( e ) => handleOptionChange( e.target.value )}>
                <option value="">Select Chart</option>
                {uniqueCategories.map( ( category ) => (
                    <optgroup key={category} label={category}>
                        {availableCharts
                            .filter( item => item.category === category )
                            .map( item => (
                                <option key={item.id} value={item.id}>
                                    {item.title}
                                </option>
                            ) )}
                    </optgroup>
                ) )}
            </select>
    
        </>
    );
};

export default CategoryDropdown;