import React, { Fragment, useEffect, useState } from "react";
import util from "../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPlus, faPlusCircle, faSave } from "@fortawesome/free-solid-svg-icons";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import ButtonCX from "../microComponents/ButtonCX";
import { ADD, EDIT, EVALUATOR } from "../../constants";
import { TextField } from "@material-ui/core";

function FormInterView( props ) {

    const [isLoading, setIsLoading] = useState( false );
    const [show, setShow] = useState( false );
    const [interviewers, setInterviewers] = useState( [] );
    const [usedEvaluator] = useState( props.interviews );
    const [interviewer_id, setInterviewerId] = useState( props.actionType === EDIT ? props.interviews[props.index].interviewer_id : 0 );
    const [date, setDate] = useState( props.actionType === EDIT ? props.interviews[props.index].date : new Date().toISOString().split( "." )[0] );
    const [note, setNote] = useState( props.actionType === EDIT ? props.interviews[props.index].note : "" );
    const [name, setName] = useState( props.actionType === EDIT ? props.interviews[props.index].name : "" );
    const [email, setEmail] = useState( props.actionType === EDIT ? props.interviews[props.index].email : "" );

    useEffect( () => {
        // Get the existent list of interviewers in the system
        getInterviewers().then( null );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    function handleChanges( e ) {
        const { name, value } = e.target;
        if ( name === "date" ) setDate( value );
        if ( name === "note" ) setNote( value );
        if ( name === "name" ) {
            interviewers.forEach( inter => {
                if ( inter.name === value ) {
                    setInterviewerId( inter.id );
                    setName( inter.name );
                    setEmail( inter.email );
                }
            } );
        }
    }

    async function saveNewInterview( opc ) {
        try {
            setIsLoading( true );
            let position_id;
            let candidate_id;

            // Validate if this component was required in Talent profile
            if ( props.fromTalent ) {
                position_id = 0;
                candidate_id = parseInt( window.location.pathname.split( "/" )[2] );
            } else {
                if ( props.candidate ) {
                    position_id = props.candidate.position_id;
                    candidate_id = props.candidate.candidate_id;
                }
            }


            // Validates mandatory fields
            if ( date === "" ) throw new Error( "Select a date" );
            if ( interviewer_id === null || interviewer_id === 0 ) throw new Error( "Select a interviewer" );
            // Prepare variables
            let d, request;
            // Check if is from Talent
            let fromTalentData = props.fromTalent ? 1 : 0;
            // Select type of action
            if ( props.actionType === ADD ) {
                // Create object for new interview
                d = {
                    job_id: position_id,
                    candidate_id: candidate_id,
                    interviewer_id: interviewer_id,
                    note,
                    date: date.split( "." )[0],
                    fromTalentData
                };
                request = await util.axios.post( `${util.url}/job_position/client_interviews_add/${position_id}/${candidate_id}`, d );
            } else {
                // Updating values of an existent interview
                d = {
                    job_id: position_id,
                    candidate_id: candidate_id,
                    interviewer_id: interviewer_id,
                    note,
                    date: date.split( "." )[0],
                    fromTalentData
                };
                const interviewID = props.interviews[props.index].id;
                request = await util.axios.put( `${util.url}/job_position/client_interviews_edit/${position_id}/${candidate_id}/${interviewID}`, d );
            }
            // Receive response for request that can be for creation or updating information
            const { error, msg, interviews, createdInterview, index } = request.data;
            if ( error ) throw msg;
            util.toast().success( msg );
            setDate( "" );
            setNote( "" );
            setInterviewerId( { name: "", email: "" } );
            setIsLoading( false );
            props.cancel();
            props.reloadInterviews( interviews );
            if ( opc === 2 ) {
                props.saveAndSend( index, createdInterview.id, interviews );
            }
        } catch ( error ) {
            setIsLoading( false );
            util.handleError( error );
        }
    }

    async function getInterviewers() {
        try {
            setIsLoading( true );
            const request = await util.axios.get( `${util.url}/app_resources/users_by_role/${EVALUATOR}` );
            const { error, msg, users } = request.data;
            if ( error ) throw msg;
            // Populate list of evaluator only with the ones that aren't used
            let cleanArray = [];
            users.forEach( u => {
                let used = false;
                usedEvaluator.forEach( us => {
                    // if (u.name === us.name && u.name !== props.interviews[props.index].name){
                    if ( u.name === us.name && u.name ) {
                        used = true;
                    }
                } );
                if ( !used ) cleanArray.push( u );
            } );
            setInterviewers( cleanArray );
            setIsLoading( false );
        } catch ( error ) {
            setIsLoading( false );
            util.handleError( error );
        }
    }
    const tooltip = (
        <Tooltip id="tooltip">
         Add Evaluator
        </Tooltip>
    );
    return (
        <Fragment>
            <div className="row">
                <div className="col-md-4">
                    {/* <label className="labelFormControlMini">Schedule Date</label> */}
                    <TextField
                        label="Schedule Date"
                        variant="outlined"
                        size="small"
                        style={{ marginTop:'12px' }}                        value={date.split( "." )[0]}
                        type="datetime-local"
                        name="date"
                        min={new Date().toISOString().split( "T" )[0] + "T00:00:00"}
                        onChange={handleChanges}
                    />
                </div>
                {props.actionType === EDIT ?
                    <util.RowComponent
                        disabled
                        c={3}
                        t={"Evaluator"}
                        n={"name"}
                        v={name || ""}
                    /> :
                    <util.RowComponent
                        disabled={props.isDisabled}
                        c={3}
                        t={"Evaluator"}
                        n={"name"}
                        m={handleChanges}
                        v={name || ""}
                        type={1}
                        d={interviewers}
                    />
                }
               
                <util.RowComponent
                    disabled
                    c={3}
                    t={"Email"}
                    n={"email"}
                    m={handleChanges}
                    v={email || ""}
                />
                <div className="col-md-2" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div onClick={() => setShow( true )}>
                        <OverlayTrigger placement="top" overlay={tooltip}>
                            <FontAwesomeIcon icon={faPlus} color="rgb(10, 160, 241)"/>
                        </OverlayTrigger>
                    </div>
                </div>

                <div className="col-md-12">
                    <label>Notes</label>
                    <textarea className="form-control" rows={2} value={note} name="note" onChange={handleChanges} />
                </div>
                <div className="w-100 text-right p-2">
                    <ButtonCX
                        variant="text-danger"
                        text="Cancel"
                        onClick={() => props.cancel()}
                    />
                    <ButtonCX
                        text={props.actionType === ADD ? "Save Evaluation" : "Update Evaluation"}
                        onClick={() => saveNewInterview( 1 )}
                        // icon={faPlusCircle}
                    />
                    {props.actionType === EDIT ? "" :
                        // This button only appears when is teh creation of a new interview
                        <ButtonCX
                            text="Schedule & Send"
                            variant="btn-secondary"
                            onClick={() => saveNewInterview( 2 )}
                            icon={faEnvelope}
                        />
                    }
                </div>
            </div>

            <ModalAddInterviewer
                show={show}
                closeShow={() => setShow( false )}
                addCreatedUser={( data ) => {
                    // Add new user to user array
                    interviewers.push( data );
                    setInterviewers( Object.assign( [], interviewers ) );
                    setInterviewerId( data.id );
                    setName( data.name );
                    setEmail( data.email );
                }} />
            {util.LOADING_SCREEN( isLoading )}
        </Fragment>
    );
}



function ModalAddInterviewer( props ) {
    const [show, setShow] = useState( props.show );
    const [user, setUser] = useState( { name: "", email: "", phone_mobile: "" } );
    function handleChanges( e ) {
        const { name, value } = e.target;
        setUser( { ...user, [name]: value } );
    }

    useEffect( () => {
        setShow( props.show );
        return () => { };
    }, [props.show] );


    function cleanFields() {
        // noinspection JSCheckFunctionSignatures
        setUser( Object.assign( {}, { name: "", phone: "", email: "" } ) );
        props.closeShow();
    }

    async function saveData() {
        try {
            if ( user.name.length === 0 ) throw new Error( "Name cannot be empty" );
            if ( user.phone.length < 11 ) throw new Error( "Phone is not valid" );
            if ( !util.validateEmail( user.email ) ) throw new Error( "Invalid email " );
            const request = await util.axios.post( `${util.url}/users/interviewers/new/`, { user } );
            const { error, msg, userNew } = request.data;
            if ( error ) throw msg;
            props.addCreatedUser( userNew );
            props.closeShow();
        } catch ( error ) {
            util.handleError( error );
        }
    }

    return (
        <Fragment>
            <Modal size="md" show={show} onHide={props.closeShow}>
                <Modal.Header closeButton>
                    <Modal.Title>Evaluator</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Fragment>
                        <div className="w-100">
                            <table className="table table-borderless">
                                <tbody>
                                    <tr>
                                        <td colSpan={3}>
                                            <util.RowComponent
                                                c={12}
                                                t={"Name"}
                                                n={"name"}
                                                m={handleChanges}
                                                v={user.name}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>
                                            <label className="labelFormControlMini">Phone</label>
                                            <PhoneInput
                                                placeholder={"Phone"}
                                                country={'us'}
                                                name={"phone"}
                                                value={user.phone}
                                                onChange={phone => {
                                                    let e = { target: { name: "phone", value: phone } };
                                                    handleChanges( e );
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3}>
                                            <util.RowComponent
                                                c={12}
                                                t={"Email"}
                                                n={"email"}
                                                m={handleChanges}
                                                v={user.email}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <Modal.Footer>
                            <button className="btn btn-sm text-danger" onClick={cleanFields}>Cancel</button>
                            <button className="btn btn-sm btn-primary" onClick={() => saveData()} >
                                <FontAwesomeIcon icon={faSave} className="mr-2" />
                                Save
                            </button>
                        </Modal.Footer>
                    </Fragment>
                </Modal.Body>
            </Modal>
            {/*<Backdrop  open={loading} style={{zIndex:50000}}  addEndListener={null}>*/}
            {/*  <CircularProgress color="primary" />*/}
            {/*</Backdrop>*/}
        </Fragment>
    );
}

export default FormInterView;