import { faArrowLeft, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, InputAdornment, Paper, TextField, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import util from '../../../utils/miniUtils';
import AppraisalLog from '../AppraisalLog';
import MediumDeviceStatus from '../Status/MediumDeviceStatus';
import Status from '../Status/Status';
import AcceptOrDeclineModal from './AcceptOrDeclienModal';
import CheckTargetAccordion from './checkTargetAccordion';

const EMPLOYEE_DECLINED = 3;
const L1_DECLINE        = 5;
const L2_DECLINE        = 7;
const L3_DECLINE        = 9;
const HR_DECLINE        = 11;

function CheckTargets( { data, handleBack, role } ) {

    const [show, setShow] = useState( false );
    const [showAlert, setShowAlert] = useState( false );
    const [loading, setLoading] = useState( true );
    const [items,setItems]=useState( [] );
    const [ appraisalInfo, setAppraisalInfo ] = useState( null );
    const { appraisal_id, name } = data;
    const handleClose = () => setShow( false );
    const handleShow = () => setShow( true );
    const [bonus,setBonus]=useState( {} );
    const [showSave,setShowSave]=useState( false );


    function sumOfAllWeights(){
        let totalWeight = 0;
        {items && items.map ( ( item )=>{
            totalWeight=totalWeight+parseInt( item.weight );
        } );
        return totalWeight;
        }
    }

    //Request to display all targets in check target component
    const checkTargetsDetail = async ( appraisal_id ) => {
        try {
            setLoading( true );
            const request = await util.axios.get( `/get_appraisal_items_evaluator/${appraisal_id }` );
            const { error, RO, appraisal_info ,msg } = request.data;
            if ( error ) throw msg;
            setAppraisalInfo( appraisal_info );
            setItems( RO );
            setLoading( false );
           
        } catch ( error ) {
            setLoading( false );
            util.handleError( error );
        }
    };
    

    // In case that this APPRAISAL has been declined, a messaage will exists and this is how we displayed
    const showDeclinedMessageIfExist = ( status ) => {
        let declinedMSG = null;
        switch ( status ) {
            case EMPLOYEE_DECLINED: declinedMSG = { user: "Appraisee", msg: appraisalInfo.employee_declined_msg }; break;
            case L1_DECLINE: declinedMSG = { user: "L1 Evaluator", msg: appraisalInfo.l1_declined_msg }; break;
            case L2_DECLINE: declinedMSG = { user: "L2 Evaluator", msg: appraisalInfo.l2_declined_msg }; break;
            case L3_DECLINE: declinedMSG = { user: "L3 Evaluator", msg: appraisalInfo.l3_declined_msg }; break;
            case HR_DECLINE: declinedMSG = { user: "HR Evaluator", msg: appraisalInfo.hr_declined_msg }; break;
            case null: declinedMSG = null; break;
            default: declinedMSG = null;
        }
        if ( declinedMSG ) {
            return (
                <Grid container spacing={1} className="ml-4">
                    {/* <Grid>{ util.suspenseDotPopOverDeclined( declinedMSG )}</Grid> */}
                    <Grid item  xs={12} md={12} lg={12}><b className='text-danger'>Person Who Declined Appraisal:</b> Appraisal declined by The {declinedMSG.user}</Grid>
                    <Grid item xs={12} md={12} lg={12} ><b className='text-danger'>Reason For Declined Appraisal:</b> {declinedMSG.msg}</Grid>
                    
                    
                </Grid>
            );
        }
    };

    //  function for display accept or display buttons in check target component
    const shouldButtonsBeVisible = () => {
        console.log( appraisalInfo.status );
       
        if ( role === "employee" && appraisalInfo.status === 1 ) return true;
        // if ( role === "manager" && appraisalInfo.status === 2 ) return true;
        if ( ( role.includes( 'L1' ) && appraisalInfo.status === 2 ) && ( util.user.employee_id === items[0].l1_evaluator ) ) return true;
        if ( ( role.includes( 'L2' )  && appraisalInfo.status === 4 ) && ( util.user.employee_id === items[0].l2_evaluator ) ) return true;
        if ( ( role.includes( 'L3' )  && appraisalInfo.status === 6 ) && ( util.user.employee_id === items[0].l3_evaluator ) )  return true;
        if ( ( role.includes( 'HR' )  && appraisalInfo.status === 8 ) && ( util.user.employee_id === items[0].hr_evaluator ) )  return true;
        return false;
    };
    //Evaluation time button visible function
    const moveButtonBeVisible = () => {
        if ( role === "employee" && appraisalInfo.status === 12 ) return true;
       
        if ( ( role.includes( 'HR' ) ) && appraisalInfo.status === 10 ) return true;
        return false;
    };

    // To move status function
    const moveAppraisalToNextStatus = async () => {
        try {
            let body = { response: true };
            const request = await util.axios.put( `/appraisal_status_update/${appraisal_id}`, body );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            handleBack();
        } catch ( error ) {
            util.handleError( error );
        }

    };
    const handleChange=( e )=>{
        const { name,value }=e.target;
        setBonus( { [ name]:value } );
        setShowSave( true );
        

    };
    const createAllocatedBonus= async ( appraisal_id ) => { 
        try {
            setLoading( true );
            const request = await util.axios.put( `${util.url}/appraisal/allocated_bonus/${appraisal_id}`, bonus );
            const { error, msg } = request.data;
            if ( error ) throw msg;
            setShowSave( false );
        } catch ( error ) {
            util.handleError( error );
        }
        setLoading( false );
        setBonus( " " );
    };
   
    useEffect( ( ) => {
        checkTargetsDetail( appraisal_id );
    },[] );
    const handleSave=()=>{
        setShowAlert( true ) ;
        if( ( role.includes( 'HR' ) && appraisalInfo.status === 8  ) && ( util.user.employee_id === items[0].hr_evaluator ) ){
            createAllocatedBonus( appraisal_id );}


    };

    if ( loading ) return util.LOADING_SCREEN( loading );
    
    return (
        <>
            <div className="w-100">
                <Tooltip title="Previous">
                    <span>
                        <button className="isPointer btn btn-outline-primary btn-sm rounded-circle mt-1" onClick={handleBack}><FontAwesomeIcon icon={faArrowLeft} /></button></span>
                </Tooltip>
        
            </div>
            <Status appraiseeStatus={appraisalInfo.status} l2_exist={appraisalInfo.l2_exist} l3_exist={appraisalInfo.l3_exist}/>
            <MediumDeviceStatus appraiseeStatus={appraisalInfo.status} l2_exist={appraisalInfo.l2_exist} l3_exist={appraisalInfo.l3_exist}/>
            
            <div elevation={3} className="w-90 ml-2 bg-white">
                  
                
               
                <div className="row">
                   
                    <div className="col-md-12 d-flex justify-content-between mb-3 px-4 py-2">
                        <div className="userPicture d-flex justify-content-between align-items-center">
                            <span className='p-1'><FontAwesomeIcon color='dodgerblue' size="1x" icon={faUserAlt} /></span>
                            <b className='p-1'>{name}</b>
                        </div>
                        <div className="appraisalWeight"><b>Total weight: {sumOfAllWeights()}%</b></div>
                    </div>
                    {/* <div  className="col-md-12 d-flex justify-content-between mb-3 px-4 py-2">{appraisalInfo.appraisal_log}</div> */}
                </div>
                
                {appraisalInfo && showDeclinedMessageIfExist( appraisalInfo?.status )}
               
               
                
                {/* { console.log( items )} */}
                <div>
                    {items && items.map( ( item, index ) => (
                        
                        <div key={index} >
                            <CheckTargetAccordion data={item} />
                        </div>
                    ) )}
                </div>
                {( ( role.includes( 'HR' ) && appraisalInfo.status === 8  ) && ( util.user.employee_id === items[0].hr_evaluator ) )? ( 
                    <>
                        <div className="row ml-3 mt-2">
                            <b className='col-md-3'>Bonus for Achieving the Target:</b>
                           
                            {/* <FontAwesomeIcon icon={faDollarSign} className="mt-2 mr-1"/> <input className="col-md-2 form-control" type='number' name="allocated_bonus" 
                             placeholder='Allocated Bonus' onChange={handleChange} value={bonus.allocated_bonus || "" }/> */}
                            <TextField label='Allocated Bonus' name="allocated_bonus"  variant='outlined' className='mt-2'
                                onChange={handleChange} value={bonus.allocated_bonus || "" } InputProps={{
                                    endAdornment: <InputAdornment position='end'>$</InputAdornment>
                                }} /> 

                               
                        </div>
                        
                    </> )
                    : ""}

                <div>
                    <AppraisalLog appraisalInfo={appraisalInfo}/>
                </div>
                {
                    shouldButtonsBeVisible() ?
                
                        <div className="d-flex justify-content-end ">
                            <div> 
                                <button onClick={handleSave} className="btn-primary btn-sm mt-2 mb-2 mr-3">
                                    Accept
                                </button>
                            </div>
                            <div>
                                <button className="btn-danger btn-sm mt-2 mb-2 mr-2" onClick={handleShow}>
                                    Decline
                                </button>
                                <AcceptOrDeclineModal handleBack={handleBack} handleClose={handleClose} show={show} appraisal_id={appraisal_id}/> 
                            </div>
                        </div>
                        :null
                }

              
                {
                    moveButtonBeVisible() ?
                
                        <div className="d-flex justify-content-end ">
                            <div> 
                                <button onClick={()=>setShowAlert( true )} className="btn-primary btn-sm mt-2 mb-2 mr-3">
                                  Evaluation Time
                                </button>
                            </div>
                           
                        </div>
                        :null
                }
           

            </div>    
            <util.confirmationAlert 
                showCancel={true}
                title="Are you sure?"
                typeButton='primary'
                confirmText="Yes" 
                show={showAlert} 
                msg="This action will move Appraisal to the next step" 
                hideAlert={()=>setShowAlert( false )} 
                onConfirm={moveAppraisalToNextStatus}
            />
        </>
    );
}

export default CheckTargets;
