import { faPaperclip, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paper, Tooltip } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import util from '../../../utils/miniUtils';
import CustomerSelector from '../../shared/CustomerSelector';
import ParentTicketSearch from '../../shared/ParentTicketSearch';
import useManageWorkload from '../useManageWorkLoad';

const EXCEL_FILES = '.xls,.xlsx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const WORD_FILES = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document';
const PPT_FILES = '.ppt,.pptx,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation';

const CreateDuplicateWorkload = ( { show, handleClose, data, reload } ) => {
    /***************************************
    *                                      *
    *        States of the component       *
    *                                      *
    ***************************************/
    const { departmentList,
        groupList,
        customerList,
        membersOfGroup,
        loading,
        getAssignedMembersToGroup,
        getCategories,
        categoriesByGroup,
        saveNewWorkload,
        removeAttachmentfile,
        attachmentFiles,
        addAttachment,
        getInitialRequest,
        getWrokloadList,
        getStatusListToCategory,
        statusListByCategory,
        getDepartmentsByUser,
        getWorkLoadSimpleList, 
        workloadSimple } = useManageWorkload();

    // const [isRecurrence, setIsRecurrence] = useState( false );
    const [isChildTicket, setIsChildTicket] = useState( false );
    const [showSubmit, setShowSubmit] = useState( true );

    const [workload, setWorkload] = useState( {
        department_id: null,
        wl_category_id: null,
        parent_ticket_id: null,
        customer_id: null,
        resolver_id: null,
        group_id: null,
        created_by: util.user.employee_id ? util.user.employee_id: null,
        updated_by: null,
        status: null,
        status_from_categories: null,
        ticket_number: null,
        short_description: null,
        long_description: null,
        ticket_category: null,
        ticket_type: null,
        item: null,
        reference_number: null,
        priority: "Medium",
        region: "Americas",
        country: util.user.country ? util.user.country:"USA",
    } );

    const formValidation = () => {
        const { wl_category_id, resolver_id, customer_id, short_description, group_id } = workload;
        if( customer_id &&
            group_id &&
            wl_category_id && 
            resolver_id &&
            short_description 
        ) {
            setShowSubmit( true );
        } else {
            setShowSubmit( false );
        }
    };
    
    function handleChange( e ) {
        const { name, value } = e.target;
        setWorkload( ( prev )=>{
            return {
                ...prev,
                [name]: value,
            };
        } );
        // Setting the status to the first value of the status list
        if( workload.wl_category_id !== null && statusListByCategory ){
            setWorkload( ( prev ) => {
                return {
                    ...prev,
                    status: statusListByCategory[0]?.wl_status_id,
                    status_from_categories: statusListByCategory[0]?.wl_status_category
                };
            } );}
        // Check for neccessary values
        formValidation();
    }

    function handleDescription( e ) {
        const value = e.toString( "html" );
        setWorkload( ( prev ) => {
            return {
                ...prev,
                long_description: value,
            };
        } );
    }

    const toggleChildTicket = () => {
        if( !isChildTicket ){
            setWorkload( ( prev ) => {
                return {
                    ...prev,
                    parent_ticket_id: null
                };
            } );
        } 
        setIsChildTicket( !isChildTicket );
    };
    
    const RowLeft = ( { children, title } ) => {
        return (
            <div className="row">
                <div className="col mb-1 p-2 d-flex align-items-center">
                    <span className="col-2 mr-2 p-0 font-weight-bold">{title}:</span>
                    <span className="col-10 p-0">{children}</span>
                </div>
            </div>
        );
    };

    const handleSaveWorkload = async ( data ) => {
        await saveNewWorkload( data );
        handleClose();
        reload();
    };

    useEffect( () => {
        setWorkload( ( prev ) => {
            return {
                ...prev,
                status: statusListByCategory[0]?.wl_status_id,
                status_from_categories: statusListByCategory[0]?.wl_status_category
            };
        } ); 
    },[workload.group_id, statusListByCategory] );

    useEffect( ()=>{
        // Every time group id changes, need to retrieve new information for status and categories
        if ( workload.group_id !== null ){
            getAssignedMembersToGroup( workload.group_id );
            getCategories( workload.group_id );
            getStatusListToCategory( workload.wl_category_id );
        }
    },[workload.group_id] );

    useEffect( () => {
        // Every Time wl_category_id changes, need to retrieve new information for status
        if( workload.wl_category_id !== null ){
            getStatusListToCategory( workload.wl_category_id );
        }
    },[workload.wl_category_id] );

    useEffect( () => {
        if( workload.parent_ticket_id ) {
            setIsChildTicket( true );
        }
    },[workload.parent_ticket_id] );

    useEffect( () => {
        // Every Time wl_category_id changes, need to retrieve new information for status
        if( workload.customer_id !== null ){
            getDepartmentsByUser( workload.customer_id );
        }
    },[workload.customer_id] );
    
    // Every time we render this component, will request for all  necessary data only one time
    useEffect( () => {
        if( show ){
            setWorkload( { ...data } );
            getInitialRequest();
            getWrokloadList();
            getDepartmentsByUser( util.user.employee_id );
        }
    }, [show] );

    return (
        <>
            {util.LOADING_SCREEN( loading )}
            <Modal show={show} onHide={handleClose} size="xl" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <span>{`Duplicate workload :- "${workload.short_description && workload.short_description.substring( 0,30 )}..."`}</span>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <div className="col">
                        <div className="row m-1 d-flex justify-content-between align-items-center">
                            <div>
                                <div className="d-flex align-items-center">
                                    <span className="rounded-circle border m-1">
                                        <img src="/images/avatar.svg" width="50" />
                                    </span>
                                    <span>
                                        <span className="p-0 font-weight-bold">Customer Name</span>
                                        <CustomerSelector
                                            list={customerList}
                                            value={workload.customer_id}
                                            customerName={workload.customer_name}
                                            setData={setWorkload}
                                            isChildTicket={isChildTicket}
                                            toggleChildTicket={toggleChildTicket}
                                        />

                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-between">
                            <div className="col-md-7" id="left-side-form">
                                <Paper elevation={3} className="mt-2 py-4 px-5 rounded">
                                   
                                    <RowLeft title="Assignment Group">
                                        <select className="form-control" value={workload.group_id} name="group_id"  onChange={handleChange}>
                                            <option value={null}> -- </option>
                                            {groupList.map( ( item ) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.itemName}
                                                </option>
                                            ) )}
                                        </select>
                                    </RowLeft>

                                    <RowLeft title="Workload Category">
                                        <select className="form-control" value={workload.wl_category_id} name="wl_category_id"  onChange={handleChange}>
                                            <option value={null}> -- </option>
                                            {categoriesByGroup.map( ( item ) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ) )}
                                        </select>
                                    </RowLeft>

                                    <RowLeft title="Assign To">
                                        <select className="form-control" value={workload.resolver_id} name="resolver_id"  onChange={handleChange}>
                                            <option value={null}> -- </option>
                                            {membersOfGroup.map( ( item ) => (
                                                <option key={item.id} value={item.employee_id}>
                                                    {item.name}
                                                </option>
                                            ) )}
                                        </select>
                                    </RowLeft>

                                    <div className="row">
                                        <div className="col mb-1 p-2 d-flex align-items-center">
                                            <span className="col-2 mr-2 p-0 font-weight-bold">Workload Title:</span>
                                            <span className="col-10 p-0">
                                                <input className="form-control" type="text" name={"short_description"} onChange={handleChange} value={workload.short_description}/>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col mb-1 p-2 d-flex align-items-start">
                                            <span className="col-2 mr-2 p-0 font-weight-bold">Long Description:</span>
                                            <span className="col-10 p-0">
                                                <Editor
                                                    className="editor"
                                                    apiKey='co5tvvfh4ltli0l9urtqbvfbrevuswtkqdmu3lvf0t3lme9p'
                                                    value={workload.long_description || ""}
                                                    init={{
                                                    // height: 175,
                                                        menubar: false,
                                                        branding: false,
                                                        statusbar: false,
                                                        toolbar:
												"formatselect | bold italic| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat",
                                                    }}
                                                    onEditorChange={handleDescription}
                                                />
                                                <div>
                                                    <Tooltip title="Click to select attachments">
                                                        <span >
                                                            <label htmlFor="wl_attachment"  className="btn btn-primary mt-2">
                                                                <FontAwesomeIcon icon={faPaperclip} />
                                                                <span className="p-1">Attachments</span>
                                                            </label>
                                                        </span>
                                                    </Tooltip>
                                                    <input
                                                        type="file"
                                                        multiple name="files"
                                                        id="wl_attachment"
                                                        onChange={( e ) => addAttachment( e )}
                                                        accept={`image/*,.pdf,.txt,text/csv,${WORD_FILES},${EXCEL_FILES},${PPT_FILES}`}
                                                        className="invisible" />
                                            
                                                    <div className="mt-1 d-flex flex-wrap">
                                                        {
                                                            attachmentFiles.map( ( a, i ) => {
                                                                return <span key={i} className="mr-1 mb-1 d-flex align-items-center attachmentBtn p-1">
                                                                    <span>{a.name}</span>
                                                                    <span className="p-1 isPointer" onClick={() => removeAttachmentfile( a.name )}><FontAwesomeIcon icon={faTimes} /></span>
                                                                </span>;
                                                            } )
                                                        }
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </Paper>
                            </div>

                            <div className="col-md-5" id="right-side-form">
                                <Paper elevation={3} className="mt-2 py-4 px-3 rounded">
                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Customer Department:</span>
                                            <span className="col-9">
                                                <select className="form-control" value={workload.department_id ? workload.department_id : ""} name="department_id"  onChange={handleChange}>
                                                    <option value={null}> -- </option>
                                                    {departmentList.map( ( item ) => (
                                                        <option key={item.id} value={item.id}>
                                                            {item.name}
                                                        </option>
                                                    ) )}
                                                </select>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Priority:</span>
                                            <span className="col-9">
                                                <select className="form-control" value={workload.priority} name="priority"  onChange={handleChange}>
                                                    <option value={null}> -- </option>
                                                    {['Low','Medium','High','Critical'].map( ( item ) => (
                                                        <option key={item} value={item}>
                                                            {item}
                                                        </option>
                                                    ) )}
                                                </select>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Status:</span>
                                            <span className="col-9">
                                                <select className="form-control" value={workload.status + " " + workload.status_from_categories}>
                                                    {statusListByCategory && statusListByCategory.map( ( item ) => (
                                                        <option key={item.wl_status_category} value={item.wl_status_id + " " + item.wl_status_category}>
                                                            {item.name}
                                                        </option>
                                                    ) )}
                                                </select>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Region:</span>
                                            <span className="col-9">
                                                <select className="form-control" value={workload.region} name="region"  onChange={handleChange}>
                                                    <option value={null}> -- </option>
                                                    {util.regionList.map( ( item ) => (
                                                        <option key={item} value={item}>
                                                            {item}
                                                        </option>
                                                    ) )}
                                                </select>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Country:</span>
                                            <span className="col-9">
                                                <select className="form-control" value={workload.country} name="country"  onChange={handleChange}>
                                                    <option value={null}> -- </option>
                                                    {util.fixCountryList().map( ( item ) => (
                                                        <option key={item.id} value={item.value}>
                                                            {item.value}
                                                        </option>
                                                    ) )}
                                                </select>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-1">
                                            <span className="col-4 font-weight-bold">Child Ticket:</span>
                                            <span className="col-8 d-flex align-items-center wl_childTicketDiv">
                                                <input aria-describedby="text-input-popover" type="checkbox" className="form-check-input" checked={isChildTicket} value={isChildTicket} onChange={toggleChildTicket} />
                                                <ParentTicketSearch
                                                    workloadSimple={workloadSimple}
                                                    setData = {setWorkload}
                                                    isChildTicket={isChildTicket}
                                                    toggleChildTicket={toggleChildTicket}
                                                    value={workload.parent_ticket_id}/>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Ticket Category:</span>
                                            <span className="col-9">
                                                <input className="form-control" type="text" name={"ticket_category"} onChange={handleChange} value={workload.ticket_category}/>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Ticket Type:</span>
                                            <span className="col-9">
                                                <input className="form-control" type="text" name={"ticket_type"} onChange={handleChange} value={workload.ticket_type}/>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Ticket Item:</span>
                                            <span className="col-9">
                                                <input className="form-control" type="text" name={"item"} onChange={handleChange} value={workload.ticket_item}/>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="row m-1">
                                        <div className="col mb-1 d-flex align-items-center p-0">
                                            <span className="col-3 font-weight-bold">Reference Number:</span>
                                            <span className="col-9">
                                                <input className="form-control" type="text" name={"reference_number"} onChange={handleChange} value={workload.reference_number}/>
                                            </span>
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <span onClick={handleClose} className="btn btn-danger mr-1">Cancel</span>
                    {showSubmit && <span onClick={()=>handleSaveWorkload( workload )} className="btn btn-primary">Save</span> }
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateDuplicateWorkload;