import React, { useState, useEffect } from "react";
import util from "../../utils/miniUtils";
import AllStatus from '../shared/Status/AllStatus';
import {    WAITING_TIME,L1_CHECK_TARGETS,  L1_ACCEPTED, L1_DECLINED, L2_ACCEPTED, L2_DECLINED, L3_ACCEPTED, L3_DECLINED, HR_ACCEPTED, HR_DECLINED, L1_START_REVIEW, L2_START_REVIEW, L3_START_REVIEW, HR_START_REVIEW, EMPLOYEE_START_REVIEW, WAITING_FOR_EMPLOYEE_APPROVAL, EMPLOYEE_ACCEPTED, EMPLOYEE_DECLINED, L1_REVIEW_DETAILS, COMPLETED } from "../../../constants";
import StartReview from "../shared/StartReview/StartReview";
import CheckTargets from "../shared/CheckTargets/CheckTargets";
import AllAppraisalTable from "./AllAppraisalTable";
import FinalAppraisal from "../shared/FinalAppraisal/FinalAppraisal";

const TABLE_OF_OTHER_MEMBERS = 1;
const DISPLAY_CHECK_TARGETS = 2;
const DISPLAY_START_REVIEW = 3;
const DISPLAY_APPRAISAL_RESULT=4; 

function AllAppraisals(  { role }  ) {

  
    const [detailData, setDetailData] = useState( {
        appraisal_id: null,
        employee_id: null,
        name: null,
        componentToMount: TABLE_OF_OTHER_MEMBERS
    } );
    const [loading, setLoading] = useState( false );
    const [data, setData] = useState( [] );

    const [filterData, setFilterData] = useState( {

    } );
    const [filterByStatus, setFilterByStatus] = useState( "all" );
    // function for showing button in material table as status changed
    const buttonDependingOnStatus = ( rowData ) => {
        function multipleExist( arr, values ) {
            return values.some( value => {
                return arr.includes( value );
            } );
        }
        const { status, appraisal_id } = rowData;
        switch ( status ) {
            case WAITING_FOR_EMPLOYEE_APPROVAL: return "With Appraisee";//1
            case EMPLOYEE_ACCEPTED: return "L1 Targets Review";//2
            case EMPLOYEE_DECLINED: return "Appraisee Declined"; // 3render set target component
            case L1_ACCEPTED: return role.includes( "L2" )?"L2 Review Targets" :  multipleExist( role,['L3','HR'] )?"L2 Targets Review":'L2 Targets Review';//4
            case L1_DECLINED: return "L1 Declined";//5 render set target component
            case L2_ACCEPTED: return  role.includes( "L3" )?"L3 Review Targets" :  multipleExist( role,['L2','HR'] )?"L3 Targets Review":'L3 Targets Review';//6
            case L2_DECLINED: return "L2 Declined";//7 render set target component
            case L3_ACCEPTED: return role.includes( "HR" )?"HR Review Targets" :  multipleExist( role,['L2','L3'] )?"HR Targets Review":'HR Targets Review';//8
            case L3_DECLINED: return "L3 Declined";//9 render set target component
            case HR_ACCEPTED: return "View Targets";//10
            case HR_DECLINED: return "HR Declined";//11 render set target component
            case WAITING_TIME: return "View Targets"; //12 Read only component
            case EMPLOYEE_START_REVIEW: return "With Appraisee";//13
            case L1_START_REVIEW: return  role.includes( "L1" )?"L1 Evaluation" :  multipleExist( role,['L2','L3','HR'] )?"L1 Evaluation":"L1 Evaluation";//14
            case L2_START_REVIEW: return role.includes( "L2" )?"L2 Evaluation" : multipleExist( role,['L3','HR'] )?"L2 Evaluation":"L2 Evaluation";// Read only component//15
            case L3_START_REVIEW: return role.includes( "L3" )?"L3 Evaluation" :  multipleExist( role,['HR'] )?"L3 Evaluation":"L3 Evaluation";// Read only component//16
            case HR_START_REVIEW: return role.includes( "HR" )?"HR Evaluation" : "HR Evaluation";// Read only component//17
            case COMPLETED: return "Completed";
            default:
                // console.log( status );
                return "No Appraisal";
        }
    };


    // function for component to mount based opn status
    const setSelectedItem = ( rowData ) => {
        const { status, appraisal_id } = rowData;
        switch ( status ) {
            case WAITING_FOR_EMPLOYEE_APPROVAL:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            case EMPLOYEE_ACCEPTED:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            case EMPLOYEE_DECLINED:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            case L1_CHECK_TARGETS:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            
            case L1_ACCEPTED:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            case L1_DECLINED:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            case L2_ACCEPTED:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            case L2_DECLINED:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            case L3_ACCEPTED:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            case L3_DECLINED:
                updateDetailInfo( rowData,  DISPLAY_CHECK_TARGETS );
                break;
            case HR_ACCEPTED:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            case HR_DECLINED:
                updateDetailInfo( rowData,  DISPLAY_CHECK_TARGETS );
                break;
            case WAITING_TIME:
                updateDetailInfo( rowData, DISPLAY_CHECK_TARGETS );
                break;
            case EMPLOYEE_START_REVIEW:
                updateDetailInfo( rowData,  DISPLAY_START_REVIEW  );
                break;
            case L1_START_REVIEW:
                updateDetailInfo( rowData,  DISPLAY_START_REVIEW  );
                break;
            case L2_START_REVIEW:
                updateDetailInfo( rowData,  DISPLAY_START_REVIEW  );
                break;
            case L3_START_REVIEW:
                updateDetailInfo( rowData,  DISPLAY_START_REVIEW );
                break;
            case HR_START_REVIEW:
                updateDetailInfo( rowData,  DISPLAY_START_REVIEW );
                break;
            case COMPLETED:
                updateDetailInfo( rowData,  DISPLAY_APPRAISAL_RESULT );
                break;
            default:
                return  "NO-VALUE";
        }
    };


    //function for component to mount
    const updateDetailInfo = ( rowData, component ) => {
        const { appraisal_id, employee_id, name,status } = rowData;
        setDetailData( {
            appraisal_id: appraisal_id,
            employee_id: employee_id,
            name: name,
            componentToMount: component,
            status:status
        } );
       
    };
    // console.log( detailData.status );

    const handleBack = () => {
        setDetailData( { ...detailData, componentToMount: 1 } );
        getAllAppraisal();
    };


    //function for filter
    
    // Other  members table data  request
    const getAllAppraisal = async () => {
        
        util.axios.get( `/get_all_appraisals` )
            .then( response => {
                setLoading( true );
                const { data, error, msg } = response.data;
                if ( error ) throw msg;
                // console.log( employees );
                setData( data );
                setLoading( false );

                return ( {
                    data: data,
               
                } );
            } )
            .catch( error=>{
                util.handleError( error );
            } );
    };
    useEffect( () => {
        getAllAppraisal();
    }, [] );

    if ( loading ) return util.LOADING_SCREEN( loading );
    
    return (
        <>
            {detailData.componentToMount === TABLE_OF_OTHER_MEMBERS && (
                <AllAppraisalTable
                    filterByStatus={filterByStatus}
                    filterData={filterData}
                    setFilterByStatus={setFilterByStatus}
                    setFilterData={setFilterData}
                    setSelectedITem={setSelectedItem} 
                    buttonDependingOnStatus={buttonDependingOnStatus} 
                    data={data} 
                    role={role}
                    loading={loading}
                    getAllAppraisal={getAllAppraisal}
                />
            )}
            {detailData.componentToMount === DISPLAY_CHECK_TARGETS ? 
                <CheckTargets  data={detailData} handleBack={handleBack} role={role}/>
                :
                null
            }
            {detailData.componentToMount === DISPLAY_START_REVIEW ?
                <StartReview 
                    appraisal_id={detailData.appraisal_id} 
                    handleBack={handleBack}  
                    user={{ name: detailData.name }}  
                    currentAppraisal={{}}
                    teamReviewStatus={detailData.status}
                    role={role} />
                  
                    
                :
                ""
            }
            { detailData.componentToMount === DISPLAY_APPRAISAL_RESULT  &&
                
                <FinalAppraisal data={detailData} handleBack={handleBack}  role={role} />
        
            }
            <AllStatus />
        </>

    );
}

export default AllAppraisals;





