import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import { createStore } from "redux";
import allReducers from "./redux/reducers/";
import './fonts/Roboto-Regular.ttf';
import { composeWithDevTools } from 'redux-devtools-extension';

const store = createStore(
    allReducers,
    composeWithDevTools()
);

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>,
    document.getElementById( "root" )
);
