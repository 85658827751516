import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";


function ButtonCX( { text, onClick, icon, variant, style } ) {

    return(
        <Fragment>
            <button
                onClick={()=> onClick()}
                className={`mr-2 btn btn-sm ${variant || "btn-secondary"}`}>
                {icon? <FontAwesomeIcon icon={icon} className="mr-2"/>:"" }
                {text || ""}
            </button>
        </Fragment>
    );
}

export default ButtonCX;