import { Collapse, Paper } from "@material-ui/core";
import util from "../../utils/miniUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt,faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment, useEffect } from "react";
import Button from "@material-ui/core/Button";
import SkillRow from "../SkillRow";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import SweetAlert from "react-bootstrap-sweetalert";
import useEvaluationMethods from './UseEvaluationMethods';

export default function EvaluationComponent( { evaluation_id, reloadTalents } ) {
	
    const { state, setState, addNeSkill, getInterviewData, deleteSkill,
        resetNewSkill, checkThisOption,
        handleChanges, handleChange, styles,
        realFinalDecision, submitEvaluationConfirmed
    } = useEvaluationMethods( reloadTalents, evaluation_id );
	
    useEffect( () => {
        getInterviewData( evaluation_id ).then( null );
		
    },[evaluation_id] );
	
    if ( !state.clientEvaluation ){
        return "Nothing to show";
    }
	
	
    return(
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <Paper className="w-100">
						
                        {/* Personal Information */}
                        <div className="col-md-12">
                            <div className="skillTitleEvaluation">
								Talent - Skills Evaluation
                            </div>
                            <hr className="mt-2 mb-3"/>
                            <div className="row">
                                <div className="col-md-2">
                                    { util.isProfilePicAvailable( state.talent, true, '100%' )}
                                    
                                </div>
                                <div className="col-md-3">
                                    <h4>{state.talent.name}</h4>
                                    <p>{util.isResumeAvailable( state.talent ) !== "" ? util.isResumeAvailable( state.talent, true ) : "No resume available"}</p>
                                    <p><FontAwesomeIcon icon={faMapMarkedAlt} /> {util.location( state.talent.country,state.talent.state,state.talent.city )}</p>
                                </div>
                                <div className="col-md-7">
                                    {state.clientEvaluation["internal_evaluation"] === 0 && state.job ?
                                        <div className="container mb-4">
                                            <h4>{state.job.job_title}</h4>
                                            <Collapse in={state.showLongDesc} collapsedHeight={100}>
                                                <div dangerouslySetInnerHTML={{ __html: state.job.job_description }} />
                                            </Collapse>
                                            <div className="text-right">
                                                <button className="btn btn-sm btn-primary" onClick={()=> setState( { ...state, showLongDesc: !state.showLongDesc } )}>
                                                    {state.showLongDesc ? "Show less":"Show more"}
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        ""
                                    }
                                </div>
                            </div>
                        </div>
                    </Paper>
					
                    {
                        state.isFinished ?
                            <Paper className="w-100 mt-3">
                                {/* Fina decision resume and add skills  */}
                                <div className="col-md-12 mt-4">
									
                                    <Fragment>
                                        <div className="skillTitleEvaluation">
											Final decision
                                        </div>
										
                                        <p>{realFinalDecision()}</p>
                                        <p><b>Feedback:</b></p>
                                        <p className="quoteFeedback">
                                            <q>{state.ser.notes}</q></p>
									
                                    </Fragment>
								
								
                                </div>
                            </Paper>
                            :
                            ""
                    }
					
                    <Paper className="w-100 mt-3">
                        <div className="col-md-12">
							
                            {state.isFinished ?
                                <div className="skillTitleEvaluation">
									Skills
                                </div>
                                :
                                <div className="">
                                    <div className="d-flex justify-content-between p-3">
                                        <div className="skillTitleEvaluation">
											Skills
                                        </div>
                                        <Button
                                            className="mt-1"
                                            size="small"
                                            variant="contained"
                                            onClick={()=> {
                                                if ( !state.showAddSkill ) resetNewSkill();
                                                setState( { ...state, showAddSkill: !state.showAddSkill } );
                                            }}
                                            color={"primary"}
                                            style={!state.showAddSkill ?{ ...styles.buttonBlue }: { ...styles.buttonRed }}
                                        >
                                            {!state.showAddSkill ?'Add A Skill to Evaluate':'Cancel'}
                                        </Button>
                                    </div>
                                    {state.showAddSkill ?
                                        <Fragment>
                                            <table className="w-100">
                                                <thead/>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <util.RowComponent
                                                                c={12}
                                                                t={"Skill Name"}
                                                                n={"skill_name"}
                                                                m={handleChange}
                                                                v={state.skill.skill_name}
                                                            />
                                                        </td>
                                                        <td>
                                                            <util.RowComponent
                                                                c={12}
                                                                t={"Years of Experience"}
                                                                n={"years_experience"}
                                                                ty={"number"}
                                                                m={handleChange}
                                                                v={state.skill.years_experience}
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="w-100 text-right">
                                                <button className="btn btn-primary btn-sm" onClick={()=>addNeSkill( state.ser.id )}>
                                                    <FontAwesomeIcon icon={faPlusCircle} className="mr-1"  /> Save
                                                </button>
                                            </div>
                                        </Fragment>
                                        :
                                        null
                                    }
                                </div>
                            }
                            <hr className="mt-2 mb-2"/>
                            {state.skills.map( ( skill, index )=>{
                                return (
                                    <SkillRow
                                        key={skill.id}
                                        index={index}
                                        skill={skill}
                                        deleteSkill={deleteSkill}
                                        interviewId={state.ser.id}
                                        isFinished={state.isFinished}
                                    />
                                );
                            } )}
                        </div>
                    </Paper>
					
                    {!state.isFinished ?
                        <Paper className="w-100 mt-3">
                            <div className="col-md-12 mb-5">
                                <div className="">
                                    {state.isFinished ?
                                        "":
                                        <Fragment>
                                            <div className="skillTitleEvaluation">
												Final decision
                                            </div>
                                            <hr className=" mt-2 mb-2"/>
                                            <div className="text-center">
                                                <ToggleButtonGroup
                                                    size="small"
                                                    value={state.ser.final_decision}
                                                    exclusive
                                                    onChange={( event, value )=>checkThisOption( value )}
                                                >
                                                    <ToggleButton size={"small"} value="4">
														Selected At Resume Level
                                                    </ToggleButton>
                                                    <ToggleButton size={"small"}  value="5">
														Rejected At Resume Level
                                                    </ToggleButton>
                                                    <ToggleButton size={"small"}  value="1">
														Selected At Interview Level
                                                    </ToggleButton>
                                                    <ToggleButton size={"small"}  value="2">
														Rejected At Interview Level
                                                    </ToggleButton>
                                                    <ToggleButton size={"small"}  value="3">
														On Hold
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
												
                                                <br/>
                                                <div className="text-left">
                                                    <div className="skillTitleEvaluation">
														Comments
                                                    </div>
                                                    {/*<label htmlFor="final_decision_feedback">Comments</label>*/}
                                                </div>
                                                <div className="d-flex align-content-around">
                                                    <textarea
                                                        id="final_decision_feedback"
                                                        onChange={handleChanges}
                                                        className="form-control"
                                                        name="notes"
                                                        defaultValue={state.ser.notes}
                                                        rows={5}
                                                    />
                                                </div>
                                            </div>
                                            <br/>
                                            <button onClick={()=>{
                                                setState( { ...state, showSA: true } );
                                            }} className="btn btn-md btn-primary">
												Submit
                                            </button>
                                            <br/>
                                            <br/>
                                        </Fragment>
										
                                    }
                                </div>
                            </div>
                        </Paper>
                        :
                        null
                    }
                    <br/>
                </div>
            </div>
            {util.LOADING_SCREEN( state.loading )}
			
            <SweetAlert
                show={state.showSA}
                warning
                showCancel
                confirmBtnText="Yes, submit evaluation"
                confirmBtnBsStyle="success"
                title="Are you sure?"
                onConfirm={()=>submitEvaluationConfirmed()}
                onCancel={()=>setState( { ...state, showSA: false } )}
            >
				You will not able to make changes later
            </SweetAlert>
        </Fragment>
    );
}