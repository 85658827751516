import React from 'react';
import util from '../../utils/miniUtils';
import CatalogCatagory from './components/catalogCatagory';

const departmentSettings = {
   
    getListData: `${util.url}/get_catalogs_list`,
    deletePath:`${util.url}/get_catalogs_list/delete`,
    editPath: `${util.url}/get_catalogs_list/edit`,
    createPath:`${util.url}/get_catalogs_list/insertData/`,
    getDetailPath:`${util.url}/get_catalogs_list/itemDetials`,
    getScreeningQListData:`${util.url}/get_screeningQ_list`,
    title: "Catalogs ",
    subTitle: "Catalog list",
    parentData:[
        { id:1,name:"Screening questions",type:"job_sq" },
        { id:2,name:"Skills ",type:"catalog_skills" },
        { id:3,name:"Certifications ",type:"catalog_certification" },
        { id:4,name:"Degree ",type:"catalog_degree" },
        { id:5,name:"Organizations ",type:"catalog_organization" },
        { id:6,name:"Schools ",type:"catalog_school" },
        { id:7,name:"Field of study ",type:"catalog_field_of_study" },
        { id:8,name:"Job title",type:"catalog_job_title" },
        ]
};


export default function CatalogModule() {
    return (
        <div className="container-fluid">
            <div className="row">
                <CatalogCatagory settings={departmentSettings} />
            </div>
        </div>
    );
}
