import { faDollarSign, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
// import { PositionsStore } from "../../../positions/stores/PositionsStore";
import util from "../../../utils/miniUtils";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const FeaturedCard = ( { id, name, location, salary, job_array } ) => {
    const history = useHistory();

    const { level, role, primary_skill, secondary_skill, } = job_array;
    const a = [level, role, primary_skill, secondary_skill];

    const  selectedJob = () => {
        const queryParams = `?job=${id}`;
        history.push( `careers${queryParams}` );
    };

    let chips = [];
    for ( let i = 2; i < a.length; i++ ) {
        if ( !util.noValues.includes( a[i] ) ) {
            chips.push( a[i].toString() );
        }
    }

    return (
        <>
            <div className='carousalBox_PJ bg-white ' onClick={selectedJob} >
                <div className="d-flex justify-content-between">
                    <span>
                        <span className="jobTitle_PJ ">{name.substring( 0, 16 )} </span>
                    </span>
                </div>
                <div className="mr-2 mb-2 mt-0" >
                    <FontAwesomeIcon icon={faMapMarkerAlt} className=" iconGray mr-1" style={{ fontSize: "14px" }} /> <span className="mb-2" style={{ fontSize: "15px", color: "#75788F" }}>
                        {location.substring( 0, 25 )}
                    </span>   </div>
                <div className="ml-1.9 mb-2">
                    {chips.map( ( c, i ) => {
                        return <span className="chip_JP" style={{ fontSize: "15px" }} key={i}>
                            {c.substring( 0,9 )}
                        </span>;
                    } )}
                </div>
                <div className=" ml-1.9" style={{ fontSize: "15px" }}>

                    <FontAwesomeIcon icon={faDollarSign} className=" iconGray mr-1" style={{ fontSize: "16px" }} />   {salary}
                </div>
            </div>
        </>
    );
};

export default FeaturedCard;