import JobPositionCandidatesReducer from './JobPositionCandidatesReducer';
import JobPositionInformationReducer from './JobPositionReducer';
import candidateProfile from '../reducers/candidateProfile';
import CtcDataReducer from '../reducers/CtcDataReducer';
import { combineReducers } from 'redux';
import screeningQuestionsReducer from './ScreeningQuestionsReducer';
import filterReducer from './dateFilter';

const allReducers = combineReducers( {
    jobPositionCandidates : JobPositionCandidatesReducer,
    jobPosition: JobPositionInformationReducer,
    candidateProfile,
    CtcDataReducer,
    sqReducer: screeningQuestionsReducer,
    filter: filterReducer
} );

export default allReducers;