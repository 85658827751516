import React, { useEffect } from 'react';
import TeamAppraisal from './team_appraisal/TeamAppraisal';
import MyAppraisal from './my_appraisal/MyAppraisal';
import { Tab, Tabs } from 'react-bootstrap';
import useAppraisal from './useAppraisal';

import util from '../utils/miniUtils';
import OthersAppraisal from './other_appraisals/OthersAppraisal';
import GoalCompetencyTable from './Manage/GoalCompetencyTable';
import AllAppraisal from './all_appraisal/AllAppraisals';
export const Appraisal = () => {
    const { role, getPermissions, loading  } = useAppraisal();
    
    useEffect( ()=>{
        getPermissions();
    }, [] );

    if ( loading ) return util.LOADING_SCREEN( loading );

    return (
        <div className='mb-5'>
            <Tabs
                mountOnEnter={true}
                variant={"tabs"}
                unmountOnExit={true}
                defaultActiveKey="my_appraisal"
            >
                <Tab eventKey="my_appraisal" title="My Appraisal">
                    <MyAppraisal role={role}/>
                </Tab>
               
                {( role.includes( 'MANAGER' ) || role.includes( 'L1' ) || role.includes( 'L2' ) || role.includes( 'L3' ) )?
                    <Tab eventKey="team_appraisals" title="Team Appraisal" id="teamAppraisalTab">
                        <TeamAppraisal role={role} />
                    </Tab>
                    : 
                    null }

                {( role.includes( 'L1' ) || role.includes( 'L2' ) || role.includes( 'L3' ) || role.includes( 'HR' ) )?
                    <Tab eventKey="other_appraisals" title="Others Appraisal" id="otherAppraisalTab">
                        <OthersAppraisal role={role} />
                    </Tab>
                    : 
                    null }

                { role.length > 0 &&
                    role.includes( "HR" ) ?
                    <Tab eventKey="all_appraisal" title="All Appraisal" id="allappraisalTab">
                        {/* <GoalOrCompetencyTable /> */}
                        <AllAppraisal role={role} />
                    </Tab> : null
                }
                   
                { role.length > 0 &&
                    role.includes( "HR" ) ?
                    <Tab eventKey="manage_settings" title="Manage" id="manageTab">
                        {/* <GoalOrCompetencyTable /> */}
                        <GoalCompetencyTable />
                    </Tab> : null
                }
            </Tabs> 
        </div>
    );
    
};