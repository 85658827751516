import React,{ useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import util from "../../../utils/miniUtils";
import { Collapse, Grid, Typography } from "@material-ui/core";
import { Badge } from "react-bootstrap";
import UserBox from "../UserBox";


const CheckTargetAccordion = ( { data } ) => {
    
    console.log( data );
    const { type, gc_title,weight,gc_target,gc_desc, l1_name,l2_name,l3_name,hr_name,l2_evaluator,l3_evaluator
    } = data;

    const [state, setState] = useState( {
        expanded: false,
        data: data,
    } );

    // console.log( data );

    const expand = () => setState( { ...state, expanded: !state.expanded } );

 

    return (

        <div className="p-4 mb-3">
            <div onClick={expand}  className="d-flex justify-content-between isPointer">
                <div className="mb-3" style={{ flex:'9' }}>
                    <span className="position-absolute">
                        <FontAwesomeIcon size="2x" className="my-icon" icon={state.expanded ? faCaretUp: faCaretDown} />
                    </span>  
                    <b className="ml-4">
                        {util.isGoalOrCompetence( type )}: 
                    </b><b className="text-secondary ml-1">{gc_title}</b>
                    
                </div>
                <div style={{ flex:'2' }}>
                    <b className="mb-3 text-secondary">Target: {gc_target}</b>
                </div>
               
                <div style={{ flex:'1' }}>
                    <b className="mb-5 text-secondary">Weight: {weight}%</b>
                    
                </div>
            </div>
            <Collapse in={state.expanded}>
                <div className="p-1 border ap_frame_border">
                    
                
                    <Grid container justifyContent="center">
                        <Grid xs={6} md={3} lg={2}>
                            <UserBox name={l1_name} title="L1 Evaluator" />
                        </Grid>
                        {l2_evaluator ? <Grid xs={6} md={3} lg={2}><UserBox name={l2_name} title="L2 Evaluator" /></Grid> : "" }
                        {l3_evaluator ? <Grid xs={6} md={3} lg={2}><UserBox name={l3_name} title="L3 Evaluator" /></Grid> : " "}
                        <Grid xs={6} md={3} lg={2}><UserBox name={hr_name} title="HR Evaluator" /></Grid>
                    </Grid>
                    <div className="container-fluid">
                        <Grid container spacing={1} className="mt-2 p-3">
                            <Grid item  xs={12} md={12} lg={1}>
                                <b>Description:</b>
                            </Grid>
                            <Grid item xs={12} md={12} lg={10} className=" ml-3">
                                
                               <div> {gc_desc}</div>
                                  
                            </Grid>
                        </Grid>
                        {data.attachments.length>0 ?  <Grid container spacing={3} className="mt-3">
                            <Grid item lg={1} >
                                <b>Attachments:</b>
                            </Grid>
                            <Grid item lg={9}>
                                {
                                    data.attachments?.map( ( a,i )=> {
                                        const { appraisal_item_id, name } = a;
                                        return (
                                            <Badge key={i} pill bg="secondary" className="mr-1 isPointer badgeCustom">
                                                <a href={`${util.url}/${util.AWSURL}/appraisal_attachments/${appraisal_item_id}/${name}`} target="_blank" rel="noopener noreferrer">{a.name}</a>
                                            </Badge>
                                        );
                                    } )
                                }
                            </Grid>
                        </Grid> : ""}
                       
                    </div>
                    
                </div>
            </Collapse>
           
        </div>
    );
};

export default CheckTargetAccordion;
